import React, { Component } from "react";
import group from "../../../services/group";
import "filepond/dist/filepond.css";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  CustomInput
} from "reactstrap";
import ReactSummernote from "react-summernote";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import withTag from "../Common/withTag";
import { Link, withRouter } from "react-router-dom";
import LeftMenu from "../Common/LeftMenu";
import http from "../../../services/http";

class CreateGroup extends Component {
  state = {
    enableFileUpload: "",
    uploadIcon: "cloud-upload-alt",
    spinner: false,
    loader: true,
    fields: { status: "Open", access: "2" },
    errors: {},
    icon: "",
    submitted: false,
    description: "",
    id: this.props.id ? this.props.id : "",
    step: 1,
    tools: [
      {
        name: "enable_blog",
        label: "Enable group blog",
        checked: false
      },
      {
        name: "enable_bookmark",
        label: "Enable group bookmarks",
        checked: false
      },
      {
        name: "enable_discussion",
        label: "Enable group discussions",
        checked: false
      },
      {
        name: "enable_wall",
        label: "Enable group wall",
        checked: true
      },
      {
        name: "enable_poll",
        label: "Enable polls",
        checked: true
      }
    ],
    groups: [],
  };
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleTextArea = description => {
    this.setState({ description });
  };

  handleSubmit = e => {
    e.preventDefault();
    let frm = e.target.attributes.name.value;
    if (this.handleValidation(frm)) {
      this.setState({ submitted: true });
      const params = {
        icon: this.state.icon,
        name: this.state.fields["name"],
        description: this.state.description,
        tags: this.props.tags,
        tools: this.state.tools,
        fields: this.state.fields
      };

      group.create(params).then(response => {
        this.setState({ submitted: false }, () => {
          if (response.data.success) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            this.props.redirect.push("/groups");
          } else if (response.data.error) {
            let errors = {};
            if (response.data.message.name) {
              errors["name"] = response.data.message.name[0];
            }
            this.setState({ errors: errors });
          }
        });
      });
    }
  };
  handleValidation = frm => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (frm === "step-1") {
      if (!fields["name"]) {
        formIsValid = false;
        errors["name"] = "Name can not be empty!";
      }
      this.setState({ errors: errors });
    }
    if (formIsValid === true && (frm === "step-1" || frm === "step-2")) {
      formIsValid = false;
      this.setState(prevState => {
        return { step: prevState.step + 1 };
      });
    }
    return formIsValid;
  };

  selectAll = e => {
    let tools = this.state.tools.map(tool => {
      tool.checked = e.target.checked;
      return tool;
    });
    this.setState({ tools });
  };
  selectOne = (e, index) => {
    let tools = this.state.tools.map((tool, t) => {
      if (t === index) {
        tool.checked = e.target.checked;
      }
      return tool;
    });
    this.setState({ tools });
  };
  getGroup = id => {
    group.getOne({ id: id }).then(response => {
      if (response.data.success) {
        let fields = response.data.group;
        let tags = [];
        if (response.data.group.tags.length > 0) {
          tags = response.data.group.tags.map(tag => tag.tag.tag);
        }
        this.setState({
          fields,
          description: response.data.group.description,
          icon: this.props.baseUrl + "/images/" + response.data.group.icon
        });
        this.props.updateTagsInHoc(tags);
      } else if (response.data.error) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };
  componentDidMount() {
    if (this.props.id) {
      this.getGroup(this.props.id);
    }
    group
      .parentGroups()
      .then(response => {
        if (response.data.success) {
          this.setState({
            groups: response.data.groups
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  navigateStep = e => {
    let innerHTML = e.target.innerHTML;
    this.setState(prevState => {
      if (innerHTML === "Next Step") {
        return { step: prevState.step + 1 };
      } else {
        return { step: prevState.step - 1 };
      }
    });
  };
  renderButtons = step => {
    return (
      <Row>
        <Col md={6} lg={6} className="col-12 mt-2">
          <Link to="/groups" className="btn btn-outline-secondary grey-btn">
            Cancel
          </Link>
        </Col>
        <Col md={6} lg={6} className="col-12 mt-2 text-lg-right">
          {step > 1 && (
            <button
              type="button"
              className="btn btn-outline-secondary grey-btn mr-lg-2 mb-2 mt-lg-2"
              onClick={this.navigateStep}
            >
              Back
            </button>
          )}

          <button
            type="submit"
            className="btn btn-danger post-comment crate-group-next"
          >
            {this.state.submitted && (
              <FontAwesomeIcon icon="spinner" className="mr-1" spin={true} />
            )}
            {step === 3 ? "Finish" : "Next Step"}
          </button>
        </Col>
      </Row>
    );
  };
  uploadFile = e => {
    this.setState({
      spinner: true,
      uploadIcon: "spinner",
      enableFileUpload: "disabled"
    });
    const data = new FormData();
    data.append("filepond", e.target.files[0]);
    http.post(this.props.apiUrl + "/group/upload", data).then(response => {
      if (response.data.success) {
        document.getElementById("group-icon-container").innerHTML =
          "<img src='" +
          this.props.baseUrl +
          "/images/temp/" +
          response.data.file_name +
          "' width='144'/>";
        this.setState({
          spinner: false,
          uploadIcon: "cloud-upload-alt",
          enableFileUpload: "",
          icon: response.data.file_name
        });
      }
    });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.id === "" ? "Create New" : "Update"} Group : Civ Works
          </title>
        </Helmet>
        <Row className="mt-5">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={9} lg={10} className="col-12 px-lg-5 mt-5 pt-2">
            <h4 className="create-new-group mb-4">
              {this.state.id === "" ? "Create a new" : "Update"} group
            </h4>
            <div className="central-meta create-new-group-form">
              <div className="step-form mb-5">
                <ul className="step d-flex flex-nowrap">
                  <li
                    className={
                      this.state.step === 1 ? "step-item active" : "step-item"
                    }
                  >
                    <span />
                  </li>
                  <li
                    className={
                      this.state.step === 2 ? "step-item active" : "step-item"
                    }
                  >
                    <span />
                  </li>
                  <li
                    className={
                      this.state.step === 3 ? "step-item active" : "step-item"
                    }
                  >
                    <span />
                  </li>
                </ul>
              </div>
              {this.state.step === 1 && (
                <Form method="post" onSubmit={this.handleSubmit} name="step-1">
                  <Row className="vertical-align">
                    <Col md={5} lg={3} className="col-12 pr-lg-0">
                      {this.state.id !== "" ? (
                        <figure
                          className="d-flex align-items-center bg-gray-200 border-success"
                          style={{ width: 144, minHeight: 100 }}
                          id="group-icon-container"
                        >
                          <img src={this.state.icon} width={144} />
                        </figure>
                      ) : (
                          <figure
                            className="d-flex align-items-center bg-gray-200 border-success"
                            style={{ width: 144, minHeight: 100 }}
                            id="group-icon-container"
                          >
                            <FontAwesomeIcon
                              icon="image"
                              size="2x"
                              className="mx-auto"
                              color="gray"
                            />
                          </figure>
                        )}
                    </Col>
                    <Col md={7} lg={9} className="col-12 pl-lg-0">
                      <p className="upload-icon mb-2">
                        Upload group icon, you can also leave it unchanged.
                      </p>
                      <div
                        className="form-group upload-cloud"
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon
                          icon={this.state.uploadIcon}
                          spin={this.state.spinner}
                        />
                        <label className="fileContainer upload-text">
                          Upload
                          <input
                            type="file"
                            onChange={this.uploadFile}
                            disabled={this.state.enableFileUpload}
                          />
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="row">
                    <Col md={12} lg={12} className="col-12">
                      <FormGroup>
                        <Label for="group">Group Hierarchy</Label>
                        <Input
                          type="select"
                          bsSize="lg"
                          name="parent_id"
                          id="parent_id"
                          onChange={event => this.handleChange("parent_id", event)}
                          value={
                            this.state.fields["parent_id"]
                              ? this.state.fields["parent_id"]
                              : ""
                          }
                          className="input-bg"
                        >
                          <option value="0">Parent Group</option>
                          {this.state.groups.map(g => (
                            <option
                              value={g.id}
                              key={`key-g-${g.id}`}
                            >
                              {g.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="name">Group Name</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          value={
                            this.state.fields["name"]
                              ? this.state.fields["name"]
                              : ""
                          }
                          onChange={event => this.handleChange("name", event)}
                          invalid={this.state.errors["name"] ? true : false}
                          className="input-bg"
                          bsSize="lg"
                        />
                        {this.state.errors["name"] && (
                          <FormFeedback>
                            {this.state.errors["name"]}
                          </FormFeedback>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="description">About this group</Label>
                        <ReactSummernote
                          options={{
                            height: 150,
                            dialogsInBody: true,
                            toolbar: [
                              ["style", ["style"]],
                              ["font", ["bold", "underline", "clear"]],
                              ["fontname", ["fontname"]],
                              ["para", ["ul", "ol", "paragraph"]],
                              ["table", ["table"]],
                              /* ["insert", ["link", "picture", "video"]], */
                              ["view", ["fullscreen", "codeview"]]
                            ]
                          }}
                          value={this.state.description}
                          onChange={this.handleTextArea}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="brief_description">Brief Description</Label>
                        <Input
                          type="textarea"
                          name="brief_description"
                          id="brief_description"
                          value={
                            this.state.fields["brief_description"]
                              ? this.state.fields["brief_description"]
                              : ""
                          }
                          onChange={event =>
                            this.handleChange("brief_description", event)
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="tags">Tags</Label>
                        <TagsInput
                          value={this.props.tags}
                          onChange={this.props.handleTagChange}
                          onlyUnique={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.renderButtons(1)}
                </Form>
              )}
              {this.state.step === 2 && (
                <Form method="post" onSubmit={this.handleSubmit} name="step-2">
                  <Row className="row">
                    <Col md={12} lg={12} className="col-12">
                      <p className="mb-4">Select Tools </p>
                      <FormGroup>
                        <CustomInput
                          type="checkbox"
                          id="select_all"
                          label="Select All"
                          className="mb-3"
                          onChange={this.selectAll}
                        />
                        {this.state.tools.map((tool, index) => (
                          <CustomInput
                            type="checkbox"
                            id={tool.name}
                            name={tool.name}
                            label={tool.label}
                            className="mb-3"
                            key={`check-box-${index}`}
                            checked={tool.checked}
                            onChange={event => this.selectOne(event, index)}
                          />
                        ))}
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.renderButtons(2)}
                </Form>
              )}
              {this.state.step === 3 && (
                <Form method="post" onSubmit={this.handleSubmit} name="step-3">
                  <p className="mb-4">Access </p>
                  <Row className="row">
                    <Col md={5} lg={5} className="col-12 mr-lg-auto">
                      <FormGroup>
                        <Label for="status">Group membership permissions</Label>
                        <Input
                          type="select"
                          bsSize="lg"
                          name="status"
                          onChange={event => this.handleChange("status", event)}
                          value={
                            this.state.fields["status"]
                              ? this.state.fields["status"]
                              : ""
                          }
                        >
                          <option value="Open">OPEN - ANY USER MAY JOIN</option>
                          <option value="Closed">
                            CLOSED - USERS MUST BE INVITED
                          </option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="accessibility">
                          Accessibility of group content
                        </Label>
                        <Input
                          type="select"
                          bsSize="lg"
                          name="access"
                          onChange={event => this.handleChange("access", event)}
                          value={
                            this.state.fields["access"]
                              ? this.state.fields["access"]
                              : ""
                          }
                        >
                          <option value="1">
                            UNRESTRICTED - ACCESS DEPENDS ON CONTENT-LEVEL
                            SETTINGS
                          </option>
                          <option value="2">
                            MEMBERS ONLY - NON-MEMBERS CAN NEVER ACCESS GROUP
                            CONTENT
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.renderButtons(3)}
                </Form>
              )}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl,
    token: state.token
  };
};
export default withRouter(withTag(connect(mapStateToProps)(CreateGroup)));
