import React, { Component } from "react";
import action from "../../../services/action";
import common from "../../../services/common";
import { connect, dispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StarRatings from "react-star-ratings";

class Rating extends Component {
  state = {
    rating: 0,
    changeRating: false
  };
  changeRating = (newRating, name) => {
    action
      .saveRating({
        action_id: this.props.action.id,
        rating: newRating
      })
      .then(response => {
        if (response.data.success) {
          this.setState({
            rating: parseInt(response.data.rating)
          });
        }
      });
    this.setState({ rating: newRating });
  };
  componentDidMount = () => {
    action.getRating({ action_id: this.props.action.id }).then(response => {
      if (response.data.success) {
        this.setState({
          rating: parseInt(response.data.rating)
        });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <StarRatings
          rating={this.state.rating}
          starRatedColor="red"
          changeRating={this.changeRating}
          numberOfStars={5}
          name="Civ.Works"
        />
        <div className="d-inline-block font-sm ml-2">4.75 / 5</div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId
  };
};

export default connect(mapStateToProps)(Rating);
