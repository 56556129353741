import http from "./http";

const page = {
  add: params => http.post("/page/add", params),
  list: param => http.get("/page/list", { params: param }),
  getOne: param => http.get("/page/get-one", { params: param }),
  getOneByName: param => http.get("/page/get-one-by-name", { params: param }),
  delete: param => http.post("/page/delete", param),
  myRepresentatives: param =>
    http.get("/page/my-representatives", { params: param }),
  getRepresentatives: param => http.post("/page/get-representatives", param)
};

export default page;
