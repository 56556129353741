import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import axios from "axios";
import TwitterLogin from "react-twitter-auth";
import IssuesModal from "./IssuesModal";
import { doLogin } from "../../../store/actions";
import { connect } from "react-redux";
import OverlayModal from "./OverlayModal";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const facebookId = "408227003344859";
const googleClientId =
  "1098634301715-raphhoi5rkgb6gf5vh67bfj2rqdfbgj7.apps.googleusercontent.com";

class SocialButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issues: [],
      issue_modal_display: false,
      issueLoader: true,
      showOverlayModal: false
    };
  }
  responseGoogle = response => {
    if (!response.error) {
      this.setState({ showOverlayModal: true });
      let google_data = {
        name: response.profileObj.name,
        email: response.profileObj.email,
        image: response.profileObj.imageUrl,
        google_id: response.profileObj.googleId,
        scenario: "SocialSignup"
      };
      var that = this;
      axios
        .post(that.props.apiUrl + "/user/add", google_data)
        .then(function(response) {
          if (response.data.success) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("userType", response.data.user_type);
            localStorage.setItem("userName", response.data.username);
            localStorage.setItem("userId", response.data.user_id);
            localStorage.setItem("userImage", response.data.image);
            localStorage.setItem("uName", response.data.name);
            that.props.doLogin({
              token: response.data.token,
              authenticated: true,
              userName: response.data.username,
              uName: response.data.name,
              userId: response.data.user_id,
              userType: response.data.user_type,
              userImage: response.data.image
            });
            if (response.data.issues === "0") {
              that.setState({
                issue_modal_display: true,
                issueLoader: true
              });
              that.getCategory();
            } else {
              that.setState({ showOverlayModal: false });
              window.location.href = "/";
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  };
  responseFacebook = response => {
    if (response.userId !== undefined || response.userId !== null) {
      this.setState({ showOverlayModal: true });
      let image = "";
      if (response.picture.data.url) {
        image = response.picture.data.url;
      }
      let facebook_data = {
        name: response.name,
        email: response.email,
        image: image,
        facebook_id: response.userID,
        scenario: "SocialSignup"
      };
      var that = this;
      axios
        .post(that.props.apiUrl + "/user/add", facebook_data)
        .then(function(response) {
          if (response.data.success) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("userType", response.data.user_type);
            localStorage.setItem("userName", response.data.username);
            localStorage.setItem("uName", response.data.name);
            localStorage.setItem("userId", response.data.user_id);
            localStorage.setItem("userImage", response.data.image);
            that.props.doLogin({
              token: response.data.token,
              authenticated: true,
              userName: response.data.username,
              uName: response.data.name,
              userId: response.data.user_id,
              userType: response.data.user_type,
              userImage: response.data.image
            });
            if (response.data.issues === "0") {
              that.setState({
                issue_modal_display: true,
                issueLoader: true
              });
              that.getCategory();
            } else {
              that.setState({ showOverlayModal: false });
              window.location.href = "/";
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  };

  responseTwitter = response => {
    if (response.ok) {
      this.setState({ showOverlayModal: true });
      response.json().then(result => {
        let image = "";
        if (result.profile_image_url) {
          image = result.profile_image_url;
        }
        let twitter_data = {
          name: result.name,
          email: result.email,
          image: image,
          twitter_id: result.id,
          scenario: "SocialSignup"
        };
        var that = this;
        axios
          .post(that.props.apiUrl + "/user/add", twitter_data)
          .then(function(response) {
            if (response.data.success) {
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("userType", response.data.user_type);
              localStorage.setItem("userName", response.data.username);
              localStorage.setItem("uName", response.data.name);
              localStorage.setItem("userId", response.data.user_id);
              localStorage.setItem("userImage", response.data.image);
              that.props.doLogin({
                token: response.data.token,
                authenticated: true,
                userName: response.data.username,
                uName: response.data.name,
                userId: response.data.user_id,
                userType: response.data.user_type,
                userImage: response.data.image
              });
              if (response.data.issues === "0") {
                that.setState({
                  issue_modal_display: true,
                  issueLoader: true
                });
                that.getCategory();
              } else {
                that.setState({ showOverlayModal: false });
                window.location.href = "/";
              }
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      });
    }
  };
  /* responseTwitter = response => {
    console.log(response);
    if (response.id !== undefined || response.id !== null) {
      
    }
  }; */
  getCategory = () => {
    let that = this;
    axios
      .get(that.props.apiUrl + "/category/list")
      .then(function(response) {
        if (response.data.success) {
          that.setState({
            issues: response.data.categories,
            issueLoader: false
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  toggleModal = action => {
    if (action === "skip") {
      this.setState(prevState => ({
        issue_modal_display: !prevState.issue_modal_display
      }));
    } else {
      this.setState(prevState => ({
        issue_modal_display: !prevState.issue_modal_display
      }));
    }
    window.location.href = "/";
  };
  render() {
    return (
      <React.Fragment>
        <div className="social-media-buttons">
          <GoogleLogin
            clientId={googleClientId}
            buttonText="Sign In With Google"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            cookiePolicy={"single_host_origin"}
            render={renderProps => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="button github-button social-account-button button-fullwidth icon"
              >
                <FontAwesomeIcon icon={["fab", "google"]} color="#EA4335" />
              </button>
            )}
          />
          <FacebookLogin
            appId={facebookId}
            autoLoad={false}
            fields="name,email,picture"
            callback={this.responseFacebook}
            render={renderProps => (
              <button
                onClick={renderProps.onClick}
                className="button social-account-button facebook-button button-fullwidth icon"
              >
                <FontAwesomeIcon icon={["fab", "facebook-f"]} color="#4267B2" />
              </button>
            )}
          />

        </div>
        <IssuesModal
          show={this.state.issue_modal_display}
          issues={this.state.issues}
          issueLoader={this.state.issueLoader}
          handleToggleModal={this.toggleModal}
        />
        <OverlayModal showModal={this.state.showOverlayModal} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    apiUrl: state.apiUrl
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doLogin: data => {
      dispatch(doLogin(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialButtons);
