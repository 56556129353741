import React, { Component } from "react";
import user from "../../../services/user";
import { toast } from "react-toastify";
import { saveFriendRequests } from "../../../store/actions";
import { connect, dispatch } from "react-redux";

const withFriend = WrappedComponent => {
  class WithFriend extends Component {
    constructor(props) {
      super(props);
      this.state = {
        sendRequestButton: this.props.sendRequestButton
          ? this.props.sendRequestButton
          : false,
        confirmRequestButton: this.props.confirmRequestButton
          ? this.props.confirmRequestButton
          : false,
        declineRequestButton: this.props.declineRequestButton
          ? this.props.declineRequestButton
          : false,
        unFriendButton: this.props.unFriendButton
          ? this.props.unFriendButton
          : false,
        cancelRequestButton: this.props.cancelRequestButton
          ? this.props.cancelRequestButton
          : false,
        loader: false,
        disableButton: false
      };
    }
    cancelFriendRequest = user_id => {
      this.setState({
        disableButton: true
      });
      user
        .cancelFriendRequest({
          second_user_id: user_id
        })
        .then(response => {
          if (response.data.error) {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          } else {
            this.setState({
              cancelRequestButton: false,
              sendRequestButton: true
            });
          }
          this.setState({
            disableButton: false
          });
        });
    };
    declineFriendRequest = user_id => {
      this.setState({
        disableButton: true
      });
      user
        .declineFriendRequest({
          user_id: user_id
        })
        .then(response => {
          if (response.data.error) {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          } else {
            this.setState({
              declineRequestButton: false,
              sendRequestButton: true,
              confirmRequestButton: false
            });
          }
          this.setState({
            disableButton: false
          });
        });
    };
    confirmFriendRequest = first_user_id => {
      this.setState({
        disableButton: true
      });
      user
        .confirmFriendRequest({
          user_id: first_user_id
        })
        .then(response => {
          if (response.data.error) {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          } else {
            if (this.props.updateRequests) {
              let requests = this.props.requests.filter(
                request => request.first_user_id !== first_user_id
              );
              this.props.updateRequests(requests);
              this.props.saveFriendRequests(requests);
            }
            this.setState({
              disableButton: false,
              confirmRequestButton: false,
              unFriendButton: true,
              declineRequestButton: false
            });
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        });
    };
    sendFriendRequest = user_id => {
      this.setState({
        disableButton: true
      });
      user
        .sendFriendRequest({
          second_user_id: user_id
        })
        .then(response => {
          if (response.data.error) {
            toast.error(response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT
            });
          } else {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            user.sendFriendRequestNotification({ user_id: user_id });
            if (this.props.users) {
              let users = this.props.users.filter(user => user.id !== user_id);
              if (this.props.updateUser) {
                this.props.updateUser(users);
              }
            }
            this.setState({
              cancelRequestButton: true,
              sendRequestButton: false
            });
          }
          this.setState({
            disableButton: false
          });
        });
    };
    removeFriend = (indx, user_id) => {
      if (window.confirm("Are you sure to un-friend this user?")) {
        this.setState({ disableButton: true });
        user
          .removeFriend({
            second_user_id: user_id
          })
          .then(response => {
            if (response.data.error) {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT
              });
            } else {
              if (this.props.friends) {
                let friends = this.props.friends.filter(
                  (friend, index) => index !== indx
                );
                if (this.props.updateFriends) {
                  this.props.updateFriends(friends);
                }
              }
              this.setState({ sendRequestButton: true, unFriendButton: false });
            }
            this.setState({ disableButton: false });
          });
      }
    };
    updateButtons = button => {
      this.setState(button);
      // console.log(this.state);
    };
    checkFriend = user_id => {
      user.checkFriend({ user_id: this.props.user.id }).then(response => {
        if (response.data.success) {
          let friendDetails = response.data.friend;
          if (friendDetails.status === 0) {
            if (friendDetails.first_user_id === parseInt(this.props.userId)) {
              this.setState({ cancelRequestButton: true });
            } else {
              this.setState({
                confirmRequestButton: true,
                declineRequestButton: true
              });
            }
          } else {
            this.setState({
              unFriendButton: true
            });
          }
        } else {
          this.setState({
            sendRequestButton: true
          });
        }
      });
    };

    render() {
      return (
        <WrappedComponent
          friend={this.props.friend}
          request={this.props.request}
          removeFriend={this.removeFriend}
          sendFriendRequest={this.sendFriendRequest}
          confirmFriendRequest={this.confirmFriendRequest}
          cancelFriendRequest={this.cancelFriendRequest}
          declineFriendRequest={this.declineFriendRequest}
          userId={this.props.userId}
          disableButton={this.state.disableButton}
          index={this.props.index}
          user={this.props.user}
          sendRequestButton={this.state.sendRequestButton}
          confirmRequestButton={this.state.confirmRequestButton}
          declineRequestButton={this.state.declineRequestButton}
          cancelRequestButton={this.state.cancelRequestButton}
          unFriendButton={this.state.unFriendButton}
          updateButtons={this.updateButtons}
          baseUrl={this.props.baseUrl}
          checkFriend={this.checkFriend}
          friends={this.props.friends}
        />
      );
    }
  }
  const mapStateToProps = state => {
    return {
      friendRequests: state.friendRequests,
      baseUrl: state.baseUrl,
      userId: state.userId
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      saveFriendRequests: friendRequests => {
        dispatch(
          saveFriendRequests({
            friendRequests
          })
        );
      }
    };
  };
  return connect(mapStateToProps, mapDispatchToProps)(WithFriend);
};
export default withFriend;
