import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import withJoinLeave from "./withJoinLeave";

class Groups extends Component {
  state = {
    submitted: false
  };
  render() {
    let group = this.props.group;
    return (
      <div className="user-post central-meta create-new-group-form item mb-2">
        <Row>
          <Col md={3} lg={3}>
            <Link to={`/groups/details/${group.id}`}>
              <figure className="mb-0">
                {group.icon !== null &&
                group.icon !== "null" &&
                group.icon !== "" ? (
                  <img
                    src={`${this.props.baseUrl}/images/${group.icon}`}
                    alt={group.name}
                    width={100}
                    height={100}
                    className="rounded post-image"
                  />
                ) : (
                  <FontAwesomeIcon icon="users" size="7x" title={group.name} />
                )}
              </figure>
            </Link>
          </Col>
          <Col md={9} lg={9}>
            <Row>
              <Col md={12} lg={12}>
                <h5 className="group-user">
                  <Link to={`/groups/details/${group.id}`}>{group.name}</Link>
                </h5>
              </Col>
              <Col md={12} lg={12}>
                <ul className="grop-links p-0 m-0">
                  <li>
                    {this.props.totalGroupMembers}
                    {this.props.totalGroupMembers > 1 ? " Members" : " Member"}
                  </li>
                  <li>∙ {group.contents.length} posts</li>
                  <li>
                    ∙ {group.status === "Open" ? "Public" : "Private"} Group
                  </li>
                </ul>
              </Col>
              <Col md={12} lg={12}>
                <div className="date-time-info">{group.brief_description}</div>
              </Col>

              <Col md={12} lg={12}>
                {this.props.joinGroupButton && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary join-btn mt-2"
                    disabled={this.props.submitted}
                    onClick={this.props.joinGroup}
                  >
                    {this.props.submitted && (
                      <FontAwesomeIcon
                        icon="spinner"
                        className="mr-1"
                        spin={true}
                      />
                    )}
                    Join Group
                  </button>
                )}
                {this.props.leaveGroupButton && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary leave-btn mt-2"
                    disabled={this.props.submitted}
                    onClick={this.props.leaveGroup}
                  >
                    {this.props.submitted && (
                      <FontAwesomeIcon
                        icon="spinner"
                        className="mr-1"
                        spin={true}
                      />
                    )}
                    Leave Group
                  </button>
                )}
                {this.props.acceptInvitationButton && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary join-btn mt-2"
                    disabled={this.props.submitted}
                    onClick={this.props.acceptInvitation}
                  >
                    Accept Invitation
                  </button>
                )}
                {this.props.declineInvitationButton && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary leave-btn mt-2 ml-2"
                    disabled={this.props.submitted}
                    onClick={this.props.declineInvitation}
                  >
                    Decline Invitation
                  </button>
                )}
                {parseInt(group.user_id) === parseInt(this.props.userId) && (
                  <React.Fragment>
                    <Link
                      to={`/groups/edit/${group.id}`}
                      className="btn btn-outline-info btn-sm mr-2 mt-2"
                    >
                      Edit Group
                    </Link>
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-sm mt-2"
                      disabled={this.props.submitted}
                      onClick={() => this.props.deleteGroup(group.id)}
                    >
                      {this.props.submitted && (
                        <FontAwesomeIcon
                          icon="spinner"
                          className="mr-1"
                          spin={true}
                        />
                      )}
                      Delete Group
                    </button>
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    baseUrl: state.baseUrl
  };
};
export default withJoinLeave(connect(mapStateToProps)(Groups));
