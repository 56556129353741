import React, { Component } from "react";
import { Row, Col, Collapse } from "reactstrap";
import Avtar from "../Common/Avtar";
import withFriend from "./withFriend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import common from "../../../services/common";
import { Link } from "react-router-dom";
import user from "../../../services/user";

class Suggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      loader: true,
      totalFriends: ""
    };
  }

  getFriendsCount = () => {
    user.getFriendsCount({ user_id: this.props.user.id }).then(response => {
      if (response.data.success) {
        let totalFriends;
        if (parseInt(response.data.total) === 0) {
          totalFriends = `${response.data.total} friend`;
        }
        else if (parseInt(response.data.total) > 1) {
          totalFriends = `${response.data.total} friends`;
        }
        else if (parseInt(response.data.total) === 1) {
          totalFriends = `${response.data.total} friend`;
        }
        this.setState({
          loader: false,
          totalFriends
        });
      }
    });
  };
  componentDidMount() {
    this.setState({ collapse: true });
    this.getFriendsCount();
  }
  render() {
    const user = this.props.user;
    return (
      <Collapse isOpen={this.state.collapse}>
        <Row>
          <Col md={3} lg={1} className="col-2">
            <Link to={`/profile/${user.username}`}>
              <Avtar
                image={user.image}
                size="3x"
                name={user.name !== "" ? user.name : user.username}
                width="43"
              />
            </Link>
          </Col>
          <Col md={9} lg={5} className="col-5 request-info">
            <h5 className="mb-0">
              <Link to={`/profile/${user.username}`}>
                {user.name !== "" ? common.ucwords(user.name) : user.username}
              </Link>
            </h5>
            {this.state.loader ? <FontAwesomeIcon
              icon="spinner"
              className="mr-1"
              spin={true}
            /> : this.state.totalFriends}

          </Col>
          <Col md={12} lg={6} className="col-5 request-info text-lg-right">
            {this.props.sendRequestButton && (
              <button
                type="button"
                className="btn btn-danger btn-sm text-uppercase color-swatches"
                disabled={this.props.disableButton}
                onClick={() => this.props.sendFriendRequest(user.id)}
              >
                {this.props.disableButton && (
                  <FontAwesomeIcon
                    icon="spinner"
                    className="mr-1"
                    spin={true}
                  />
                )}
                ADD FRIEND
              </button>
            )}
            {this.props.cancelRequestButton && (
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm text-uppercase color-swatches"
                disabled={this.props.disableButton}
                onClick={() => this.props.cancelFriendRequest(user.id)}
              >
                {this.props.disableButton && (
                  <FontAwesomeIcon
                    icon="spinner"
                    className="mr-1"
                    spin={true}
                  />
                )}
                Cancel Friend Request
              </button>
            )}
          </Col>
        </Row>
      </Collapse>
    );
  }
}

export default withFriend(Suggestion);
