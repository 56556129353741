import React from "react";
import content from "../../../services/content";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "reactstrap";
import { doLike } from "../../../store/actions";
import StripePricingModal from "../Common/StripePricingModal ";
import { toast } from "react-toastify";


class Likes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      totalLikes: this.props.totalLikes,
      likeArr: this.props.likeArr,
      showSubsriptionModel: false,
      showPricingTable: false,
      freeTrialState: false,
      email: ''
    };
    this.iconRef = React.createRef();
    this.totalLikesRef = React.createRef();
  }

  toggleToolTip = () => {
    this.setState(prevState => ({
      tooltipOpen: !prevState.tooltipOpen
    }));
  };
  likeContent = (content_id, iconRef, totalLikesRef) => {
    if (this.state.showSubsriptionModel === true) {

      this.setState({ showPricingTable: true });
    } else {
      if (iconRef.current.className.indexOf("liked") >= 0) {
        content.unLike({ content_id: content_id }).then(response => {
          if (response.data.success) {
            iconRef.current.className = "like";
            totalLikesRef.current.innerHTML = response.data.total_likes;
            if (this.props.enableStore) {
              this.props.doLike(
                response.data.likes,
                response.data.total_likes,
                content_id
              );
            }
            this.setState({
              totalLikes: response.data.total_likes,
              likeArr: response.data.likes
            });
          }
        });
      } else {
        content.like({ content_id: content_id }).then(response => {
          if (response.data.success) {
            iconRef.current.className = "like liked";
            totalLikesRef.current.innerHTML = response.data.total_likes;
            if (this.props.enableStore) {
              this.props.doLike(
                response.data.likes,
                response.data.total_likes,
                content_id
              );
            }
            this.setState({
              totalLikes: response.data.total_likes,
              likeArr: response.data.likes
            });
            content.sendLikeNotification(response.data.id);
          }
        });
      }
    }
  };
  handleClose = () => {
    this.setState({ showPricingTable: false });
  };

  componentDidMount() {
    this.getSubscriptionStatus()
  }

  getSubscriptionStatus = () => {
    content.getSubsriptionStatus().then((response) => {
      if (response.data.success) {
        const isUserSubscription = response.data.showStripeModel
        const subscription = response.data.user;
        if (subscription) {
          const emailData = response.data.user.email
          this.setState({ email: emailData })
        }
        this.setState({
          showSubsriptionModel: isUserSubscription,
        });
      }
    });
  };

  render() {
    let likeClass = content.addLikeClass(this.props.userId, this.props.likeArr);
    const emaildemo = this.state.email
    return (
      <>
        <StripePricingModal
          show={this.state.showPricingTable}
          onClose={this.handleClose}
          customerEmail={emaildemo}
        />
        <span key={`like-children-${this.props.key}`}>
          {this.props.enableLike ? (
            <span
              className={likeClass}
              onClick={() =>
                this.likeContent(
                  this.props.feedId,
                  this.iconRef,
                  this.totalLikesRef
                )
              }
              ref={this.iconRef}
            >
              <FontAwesomeIcon icon="thumbs-up" />
            </span>
          ) : (
            <span className={likeClass} ref={this.iconRef}>
              <FontAwesomeIcon icon="thumbs-up" />
            </span>
          )}

          <span className="ml-1 like-count-info">
            <span
              className="total-likes"
              ref={this.totalLikesRef}
              id={`userlikes_${this.props.feedId}`}
            >
              {this.state.totalLikes > 0 && this.state.totalLikes}
            </span>

            <Tooltip
              placement="top"
              isOpen={this.state.tooltipOpen}
              target={`userlikes_${this.props.feedId}`}
              toggle={this.toggleToolTip}
            >
              <span className="text-left">
                {this.state.likeArr.map((u, j) => (
                  <span key={`liked-users-${j}`}>
                    {u.user.name}
                    <br />
                  </span>
                ))}
              </span>
            </Tooltip>
          </span>
        </span>
      </>
    );
  }
}

const mapPropsToState = state => {
  return {
    userId: state.userId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    doLike: (likes, total_likes, content_id) => {
      dispatch(
        doLike({
          likes: likes,
          total_likes: total_likes,
          content_id: content_id
        })
      );
    }
  };
};
export default connect(mapPropsToState, mapDispatchToProps)(Likes);
