import React, { Component } from "react";
import { Form, Row, Col, Modal, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect, dispatch } from "react-redux";
import ReactCrop from "react-image-crop";
// import "react-image-crop/lib/ReactCrop.scss";
import account from "../../../services/account";

class CropAvtarModal extends Component {
  state = {
    crop: {
      unit: "px", // default, can be 'px' or '%'
      aspect: 1 / 1,
      x: 100,
      y: 100,
      width: 150,
      height: 150
    },
    submitted: false
  };
  onChange = crop => {
    this.setState({ crop });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });
    const params = {
      image: this.props.image,
      crop: this.state.crop
    };
    account.cropAvtar(params).then(response => {
      this.setState({ submitted: false }, () => {
        if (response.data.success) {
          this.props.updateImage(response.data.file_name);
          this.props.toggleModal();
        } else if (response.data.error) {
          alert(response.data.message);
        }
      });
    });
  };
  render() {
    return (
      <Modal isOpen={this.props.showModal}>
        <Form method="post" onSubmit={this.handleSubmit} name="step-1">
          <ModalBody style={{ padding: 2 }}>
            <ReactCrop
              src={`${this.props.baseUrl}/images/${this.props.image}`}
              crop={this.state.crop}
              onChange={this.onChange}
              circularCrop={true}
            />
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-outline-danger"
              disabled={this.state.submitted}
              onClick={this.props.toggleModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-outline-success"
              disabled={this.state.submitted}
            >
              {this.state.submitted && (
                <FontAwesomeIcon icon="spinner" className="mr-1" spin={true} />
              )}
              Crop
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl
  };
};
export default connect(mapStateToProps)(CropAvtarModal);
