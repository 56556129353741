import http from "./http";

const group = {
  create: params => http.post("/group/create", params),
  update: params => http.post("/group/update", params),
  list: param => http.get("/group/list", { params: param }),
  getOne: param => http.get("/group/get-one", { params: param }),
  delete: param => http.post("/group/delete", param),
  join: param => http.post("/group/join", param),
  leave: param => http.post("/group/leave", param),
  users: param => http.get("/group/users", { params: param }),
  searchUser: param => http.get("/group/search-user", { params: param }),
  inviteUsers: params => http.post("/group/invite-users", params),
  inviteUserEmails: params => http.post("/group/invite-user-emails", params),
  inviteFriends: params => http.post("/group/invite-friends", params),
  countGroupByStatus: param =>
    http.get("/group/count-groups-by-status", { params: param }),
  arrangeGroupCounts: (
    totalGroupCounts,
    totalJoinedGroup,
    groupInvitations,
    userId
  ) => {
    let privateGroups = totalGroupCounts.filter(
      count => count.status === "Closed"
    );
    let totalPrivateGroups = privateGroups.reduce(
      (private_sum, count) => private_sum + parseInt(count.total),
      0
    );

    let publicGroups = totalGroupCounts.filter(
      count => count.status === "Open"
    );
    let totalPublicGroups = publicGroups.reduce(
      (public_sum, count) => public_sum + parseInt(count.total),
      0
    );

    let myGroups = totalGroupCounts.filter(
      count => parseInt(count.user_id) === parseInt(userId)
    );
    let totalMyGroups = myGroups.reduce(
      (my_sum, count) => my_sum + parseInt(count.total),
      0
    );
    return {
      totalPrivateGroups,
      totalPublicGroups,
      totalMyGroups,
      totalGroupsJoined: totalJoinedGroup,
      groupInvitations
    };
  },
  acceptInvitation: param => http.post("/group/accept-invitation", param),
  declineInvitation: param => http.post("/group/decline-invitation", param),
  parentGroups: param => http.get("/group/parent-groups", { params: param }),
};

export default group;
