import http from "./http";

const notification = {
  getDescription: entity_type_id => {
    const descriptions = [
      {
        entity_type_id: "1",
        entity: "Post",
        description: "Created a new post."
      },
      { entity_type_id: "2", entity: "Post", description: "Updated a post." },
      { entity_type_id: "3", entity: "Post", description: "Deleted a post." },
      {
        entity_type_id: "4",
        entity: "Message",
        description: "Sent a message."
      },
      {
        entity_type_id: "5",
        entity: "Group",
        description: "Created a new group."
      },
      { entity_type_id: "6", entity: "Group", description: "Updated a group." },
      { entity_type_id: "7", entity: "Group", description: "Deleted a group." },
      {
        entity_type_id: "8",
        entity: "Group",
        description: "Invitted to a group."
      },
      {
        entity_type_id: "9",
        entity: "Poll",
        description: "Created a new poll."
      },
      { entity_type_id: "10", entity: "Poll", description: "Updated a poll." },
      { entity_type_id: "11", entity: "Poll", description: "Deleted a poll." },
      {
        entity_type_id: "12",
        entity: "Blog",
        description: "Created a new blog."
      },
      { entity_type_id: "13", entity: "Blog", description: "Updated a blog." },
      { entity_type_id: "14", entity: "Blog", description: "Deleted a blog." },
      {
        entity_type_id: "15",
        entity: "Friend",
        description: "Sent a friend request."
      },
      {
        entity_type_id: "16",
        entity: "Friend",
        description: "Accepted your friend request."
      },
      {
        entity_type_id: "17",
        entity: "Friend",
        description: "Declined your friend request."
      },
      {
        entity_type_id: "18",
        entity: "Comment",
        description: "Commented on post."
      }
    ];
    let entities = descriptions.filter(
      entity => entity.entity_type_id === entity_type_id
    );

    if (entities.length > 0) {
      return entities[0].description;
    }
  },
  list: param => http.get("/notification/list", { params: param }),
  read: param => http.get("/notification/read", { params: param })
};

export default notification;
