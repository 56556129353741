import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Row, Col } from "reactstrap";
import LeftMenu from "../Common/LeftMenu";
import { Helmet } from "react-helmet";
import Notification from "./Notification";

class Notifications extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Notifications : Civ Works</title>
        </Helmet>

        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={9} lg={10} className="col-12 px-lg-5 mt-5 pt-2">
            <Row className="mb-2">
              <Col md={6} lg={6} className="col-12">
                <h2>Notifications</h2>
              </Col>
              <Col md={6} lg={6} className="col-12 text-right">
                <button type="button" className="btn btn-link mark-read pr-0">
                  Mark All as Read
                </button>
              </Col>
            </Row>

            <Card className="rounded-top mb-0" id="notification-container">
              <Notification />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    notifications: state.notifications,
    userId: state.userId,
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl
  };
};

export default connect(mapStateToProps)(Notifications);
