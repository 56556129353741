import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import content from "../../../services/content";
import { deleteFeed } from "../../../store/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Gallery from "react-grid-gallery";
import common from "../../../services/common";
import Likes from "../Likes";
import Avtar from "../Common/Avtar";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Collapse
} from "reactstrap";
import CommentContainer from "./CommentContainer";
import Poll from "../Poll/Poll";
import SocialShare from "./SocialShare";
import PostContentFormModal from "../Common/PostContentFormModal";
import nl2br from "react-nl2br";
import Action from "../Action/Action";
import Linkify from "react-linkify";

const mapStateToProps = state => {
  return {
    feeds: state.feeds,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteFeed: (deleted_content_id, feeds) => {
      dispatch(
        deleteFeed({
          deleted_content_id,
          feeds
        })
      );
    }
  };
};

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      pageNo: 1,
      pageSize: 10,
      hasMore: false,
      showEditPostModal: false,
      content_id: "",
      collapse: false,
      totalComments: this.props.feed.total_comments,
      is_comment: true,
      socialShare: false,
      editPostModal: false
    };
  }
  toggleSocialShare = () => {
    this.setState(prevState => ({
      socialShare: !prevState.socialShare
    }));
  };
  toggleEditPost = () => {
    this.props.getFeeds();
    this.setState(prevState => ({
      editPostModal: !prevState.editPostModal
    }));
  };
  toggleCommentLoader = () => {
    this.setState(prevState => ({
      commentLoader: !prevState.commentLoader
    }));
  };
  deleteContent = (e, content_id) => {
    if (window.confirm("Are you sure to delete this post?")) {
      content.delete({ id: content_id }).then(response => {
        if (response.data.success) {
          this.setState({ collapse: false }, () => {
            this.props.deleteFeed(content_id, this.props.feeds);
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          });

          this.setState({ collapse: true });
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    }
  };
  collectAttachment = attachments => {
    let attachment_arr = [];
    attachments.forEach((element, index) => {
      attachment_arr[index] = {
        src: `${this.props.baseUrl}/images/${element.file_name}`,
        thumbnail: `${this.props.baseUrl}/images/${element.file_name}`,
        thumbnailWidth: 150,
        thumbnailHeight: 100
        //caption: "After Rain (Jeshu John - designerspics.com)"
      };
    });
    return attachment_arr;
  };
  componentDidMount() {
    if (parseInt(this.props.feed.total_comments) > 0) {
      this.toggleCommentLoader();
    }
    this.setState({ collapse: true });
  }
  updateTotalComments = totalComments => {
    this.setState({ totalComments: totalComments });
  };
  checkIsCommentEnabled = () => {
    if (this.props.enableComment === false) {
      return this.props.enableComment;
    }
    if (!localStorage.token) {
      return false;
    }
    if (parseInt(this.props.feed.content_type) === 2) {
      if (this.props.feed.blog.is_comment === "Y") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  render() {

    const LinkifyWithTargetBlank = (href, text, key) => (
      <a href={href} key={key} target="_blank" className="unique_link">
        {text}
      </a>
    );
    let feed = this.props.feed;


    const isVerified = feed.user.verifiedSubscriber
      && feed.user.verifiedSubscriber.status === 'active'
      && new Date(feed.user.verifiedSubscriber.expiry_date) > new Date();

    return (
      <Collapse isOpen={this.state.collapse}>
        <div
          className="user-post central-meta item mb-2"
          id={`post-${feed.id}`}
        >
          <Row
            className={this.checkIsCommentEnabled() ? "pb-3 multi-comment" : ""}
          >
            {this.props.enableEditDelete && parseInt(feed.user_id) === parseInt(this.props.userId) && (
              <div className="post-control">
                {feed.content_type === "1" && (
                  <Link to={`/polls/edit/${feed.poll.id}`}>
                    <FontAwesomeIcon
                      icon="edit"
                      size="1x"
                      className="mr-2"
                      style={{ cursor: "pointer" }}
                    />
                  </Link>
                )}
                {feed.content_type === "2" && (
                  <Link to={`/blogs/edit/${feed.blog.id}`}>
                    <FontAwesomeIcon
                      icon="edit"
                      size="1x"
                      className="mr-2"
                      style={{ cursor: "pointer" }}
                    />
                  </Link>
                )}
                {feed.content_type === "3" && (
                  <Link to={`/groups/edit/${feed.group.id}`}>
                    <FontAwesomeIcon
                      icon="edit"
                      size="1x"
                      className="mr-2"
                      style={{ cursor: "pointer" }}
                    />
                  </Link>
                )}
                {feed.content_type === "0" && (
                  <FontAwesomeIcon
                    icon="edit"
                    size="1x"
                    className="mr-2"
                    style={{ cursor: "pointer" }}
                    onClick={this.toggleEditPost}
                  />
                )}
                <FontAwesomeIcon
                  icon="spinner"
                  size="1x"
                  title="Loading"
                  style={{
                    color: "#CCCCCC",
                    display: "none",
                    width: 12.25
                  }}
                  spin
                  id={`delete-spinner-${feed.id}`}
                />
                <FontAwesomeIcon
                  icon="trash"
                  size="1x"
                  style={{ cursor: "pointer" }}
                  title="Delete Post"
                  onClick={event => this.deleteContent(event, feed.id)}
                />
              </div>
            )}

            <Col md={2} lg={1} className="col-2">
              <Link to={`/profile/${feed.user.username}`}>
                <Avtar
                  image={feed.user.image}
                  image_thumb={feed.user.image_thumb}
                  size="3x"
                  name={feed.user.username}
                  width="50"
                />
              </Link>
            </Col>

            <Col md={10} lg={11} className="col-10">
              <div className="user-details">
                <span className="user-name">
                  <Link
                    to={`/profile/${feed.user.username}`}
                    title={feed.user.name}
                  >
                    {common.ucwords(feed.user.name)}
                  </Link>
                  {isVerified && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="ml-2"
                      style={{ color: '#3897f0', fontSize: '16px' }}
                    />
                  )}

                </span>
                <span
                  className="user-post-date"
                  dangerouslySetInnerHTML={{
                    __html: `.` + common.timeSince(feed.added_on)
                  }}
                />

                {feed.text && (
                  <p className="mb-1">
                    <Linkify componentDecorator={LinkifyWithTargetBlank}>
                      {nl2br(feed.text)}
                    </Linkify>
                  </p>
                )}
                {feed.content_type === "2" && (
                  <React.Fragment>
                    <p className="mb-1">{feed.blog.title}</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feed.blog.description
                      }}
                    />
                  </React.Fragment>
                )}
                {feed.content_type === "3" && (
                  <React.Fragment>
                    <p className="mb-1">
                      <Link to={`/groups/details/${feed.group.id}`}>
                        {feed.group.name}
                      </Link>
                    </p>
                    <p>{feed.group.brief_description}</p>
                  </React.Fragment>
                )}
                {parseInt(feed.content_type) === 4 && parseInt(feed.action.approved) === 1 && (
                  <Action
                    action={feed.action}
                    showIssues={false}
                    showRating={false}
                    editDelete={false}
                  />
                )}
                {feed.location && (
                  <p>
                    <strong>Near-</strong> {feed.location}
                  </p>
                )}
                {feed.link !== "" &&
                  feed.link !== null &&
                  feed.link !== undefined && (
                    <a href={feed.link} target="_blank">
                      <Card
                        style={{ backgroundColor: "#f9f9f9" }}
                        className="mb-0"
                      >
                        <CardBody>
                          <Row>
                            <Col md={3}>
                              {feed.link_image ? (
                                <CardImg
                                  top
                                  width="100%"
                                  src={
                                    this.props.baseUrl +
                                    "/images/" +
                                    feed.link_image
                                  }
                                  className="meta-image"
                                />
                              ) : (
                                <FontAwesomeIcon icon="image" size="6x" />
                              )}
                            </Col>
                            <Col md={9}>
                              <CardTitle>{feed.link_title}</CardTitle>
                              <CardText>{feed.link_description}</CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </a>
                  )}
                {feed.attachments && feed.attachments.length > 0 && (
                  <Row>
                    <Col className="mt-2">
                      {feed.attachments.length === 1 ? (
                        <React.Fragment>
                          <img
                            src={`${this.props.baseUrl}/images/${feed.attachments[0].file_name}`}
                            className="w-100"
                          />
                        </React.Fragment>
                      ) : (
                        <Gallery
                          images={this.collectAttachment(feed.attachments)}
                          id={`gallery-${feed.id}`}
                          enableImageSelection={false}
                        />
                      )}
                    </Col>
                  </Row>
                )}
                {feed.content_type === "1" && <Poll poll={feed.poll} />}
                <Row className="comment-counts">
                  <Col md={3} lg={2} className="col-4 pr-0">
                    <Likes
                      totalLikes={feed.total_likes}
                      likeArr={feed.likes}
                      key={`like-key-${feed.id}`}
                      feedId={feed.id}
                      enableLike={true}
                      enableStore={true}
                    />
                  </Col>
                  <Col md={3} lg={2} className="col-4 pr-0">
                    <span className="comment-icons">
                      <span className="Comments commented">
                        <FontAwesomeIcon icon="comment-dots" />
                      </span>
                      {parseInt(feed.total_comments) > 0 && (
                        <span className="ml-1">
                          <span className="total-Comments Total-count like-count-info">
                            {feed.total_comments}
                          </span>
                        </span>
                      )}
                    </span>
                  </Col>
                  <Col md={6} lg={8} className="col-4 text-right">
                    <span
                      className="comment-icons"
                      style={{ cursor: "pointer" }}
                      onClick={this.toggleSocialShare}
                    >
                      <span className="Comments commented">
                        <FontAwesomeIcon icon="share" />
                      </span>
                      <span className="ml-1 like-count-info">Share</span>
                    </span>
                  </Col>
                </Row>
                <SocialShare
                  socialShare={this.state.socialShare}
                  title={feed.text}
                  shareUrl={`${this.props.baseUrl}/feed/${feed.id}/details`}
                  iconSize={32}
                  media={
                    feed.attachments.length > 0
                      ? `${this.props.baseUrl}/images/${feed.attachments[0].file_name}`
                      : ""
                  }
                />
              </div>
            </Col>
          </Row>
          <CommentContainer
            totalComments={feed.total_comments}
            handleCommentLoader={this.toggleCommentLoader}
            contentId={feed.id}
            feedIndex={this.props.feedIndex}
            key={`comment-container-${feed.id}`}
            handleTotalComments={this.updateTotalComments}
            enableComment={this.checkIsCommentEnabled()}
            feedId={feed.id}
            allComments={this.props.allComments}
          />
        </div>
        {this.state.editPostModal && (
          <PostContentFormModal
            isOpen={this.state.editPostModal}
            toggleEditPost={this.toggleEditPost}
            content_id={feed.id}
            heading="Edit Post"
            redux={true}
          />
        )}
      </Collapse>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Content);
