import React, { Component } from "react";
import user from "../../../services/user";
import { Carousel, CarouselItem, CarouselControl, Spinner } from "reactstrap";
import Avtar from "../Common/Avtar";
import LoadingOverlay from "react-loading-overlay";

class InviteFriends extends Component {
  state = {
    items: [],
    activeIndex: 0,
    animating: false,
    overLayLoader: true
  };

  next = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };
  previous = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };
  goToIndex = newIndex => {
    if (this.state.animating) return;
    this.setState({ activeIndex: newIndex });
  };
  setAnimating = animate => {
    this.setState({ animating: animate });
  };
  componentDidMount() {
    user.friends({}).then(response => {
      if (response.data.success) {
        this.setState({ items: response.data.friends, overLayLoader: false });
      }
    });
  }

  render() {
    return (
      <div className="mb-2 friend-slider">
        <p className="mb-0">
          {this.state.items.map((item, index) => (
            <span
              onClick={() => this.goToIndex(index)}
              key={index}
              className={this.state.activeIndex === index ? "active" : ""}
            >
              {item.alphabet}
            </span>
          ))}
        </p>
        <LoadingOverlay
          active={this.state.overLayLoader}
          spinner={<Spinner color="dark" />}
          classNamePrefix="mitiz"
          fadeSpeed={200}
        >
          <div style={{ height: 300 }}>
            {this.state.items.length > 0 && (
              <Carousel
                activeIndex={this.state.activeIndex}
                next={this.next}
                previous={this.previous}
                interval={false}
              >
                {this.state.items.map(item => (
                  <CarouselItem
                    onExiting={() => this.setAnimating(true)}
                    onExited={() => this.setAnimating(false)}
                    key={item.alphabet}
                  >
                    <div className="custom-slider-container">
                      <span>{item.alphabet}</span>
                      {item.friends.length > 0 && (
                        <ul>
                          {item.friends.map(friend => (
                            <li key={friend.id}>
                              <div
                                style={{
                                  float: "left",
                                  position: "relative"
                                }}
                              >
                                <Avtar
                                  image={friend.image}
                                  image_thumb={friend.image_thumb}
                                  size="2x"
                                  name={friend.username}
                                  width="30"
                                ></Avtar>
                                <input
                                  type="checkbox"
                                  value={friend.id}
                                  onChange={this.props.handleChange}
                                />
                              </div>
                              <div className="frnd-right pl-2">
                                {friend.username}
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </CarouselItem>
                ))}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={this.previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={this.next}
                />
              </Carousel>
            )}
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default InviteFriends;
