import React, { Component } from "react";
import account from "../../../services/account";
import user from "../../../services/user";
import { connect, dispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col, Label, Form, Spinner, CustomInput } from "reactstrap";
import { toast } from "react-toastify";
import RightMenu from "./RightMenu";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class NotificationSettings extends Component {
  state = {
    loader: true,
    submitted: false,
    fields: {},
    errors: {},
    spinner: false,
    collections: [],
    settings: {},
    collectionLoader: true
  };
  getNotificationSettings = () => {
    this.setState({ loader: true });
    account.getNotificationSettings().then(response => {
      if (response.data.success) {
        let fields = response.data.settings;
        this.setState({
          fields
        });
      }
      this.setState({
        loader: false,
        submitted: false
      });
      this.getFriendCollections();
    });
  };
  getFriendCollections = () => {
    user.getFriendCollections().then(response => {
      if (response.data.success) {
        this.setState({
          collections: response.data.collections,
          collectionLoader: false
        });
      }
    });
  };
  handleChange = (field, e) => {
    let fields = this.state.fields;
    if (e.target.checked) {
      fields[field] = "Y";
    } else {
      fields[field] = "N";
    }
    this.setState({ fields });
  };
  componentDidMount = () => {
    this.getNotificationSettings();
  };
  handleSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });
    let fields = this.state.fields;
    const params = {
      ...fields
    };

    account.saveNotificationSettings(params).then(response => {
      this.setState({ submitted: false }, () => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        } else if (response.data.error) {
          let errors = {};
          this.setState({ errors: errors });
        }
      });
    });
  };
  getChecked(val) {
    if (val === "Y") return true;
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Notification Settings : Civ Works</title>
        </Helmet>
        <Row className="mt-4">
          <Col md={7} lg={9} className="col-12 ml-lg-auto mt-5 pt-2">
            <Row className="mb-2">
              <Col md={12} lg={6} className="col-8">
                <h4 className="text-left status">Notification Settings </h4>
              </Col>
            </Row>
            <Form
              name="add-edit-action-form"
              method="post"
              onSubmit={this.handleSubmit}
            >
              <div className="central-meta create-new-group-form mb-2 mb-2">
                <LoadingOverlay
                  active={this.state.loader}
                  spinner={<Spinner color="dark" />}
                  fadeSpeed={200}
                  styles={{
                    overlay: base => ({
                      ...base,
                      background: "rgba(255, 0, 0, 0)"
                    })
                  }}
                >
                  <Row className="justify-content-center align-items-center">
                    <Col md={12} lg={12} className="col-12">
                      Receive notifications when actions are performed on your
                      content.
                    </Col>
                    <Col md={12} lg={12} className="col-12 mt-2">
                      <CustomInput
                        type="checkbox"
                        id="my_content_email"
                        label="Email"
                        onChange={event =>
                          this.handleChange("my_content_email", event)
                        }
                        inline
                        defaultChecked={this.getChecked(
                          this.state.fields["my_content_email"]
                        )}
                      />
                      <CustomInput
                        type="checkbox"
                        id="my_content_site"
                        label="Site"
                        onChange={event =>
                          this.handleChange("my_content_site", event)
                        }
                        defaultChecked={this.getChecked(
                          this.state.fields["my_content_site"]
                        )}
                        inline
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center mt-4">
                    <Col md={12} lg={12} className="col-12">
                      Receive notifications when Activism Actions matching your
                      interests/location are posted
                    </Col>
                    <Col md={12} lg={12} className="col-12 mt-2">
                      <CustomInput
                        type="checkbox"
                        id="action_email"
                        label="Email"
                        onChange={event =>
                          this.handleChange("action_email", event)
                        }
                        defaultChecked={this.getChecked(
                          this.state.fields["action_email"]
                        )}
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="action_site"
                        label="Site"
                        onChange={event =>
                          this.handleChange("action_site", event)
                        }
                        defaultChecked={this.getChecked(
                          this.state.fields["action_site"]
                        )}
                        inline
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center mt-4">
                    <Col md={12} lg={12} className="col-12">
                      Below are collections of your friends. Selecting a
                      collection turns on notifications for the users in that
                      collection.
                    </Col>
                  </Row>
                  <Row>
                    <Col md={7} className="mt-2">
                      All friends
                    </Col>
                    <Col md={5} className="mt-2">
                      <CustomInput
                        type="checkbox"
                        id="all_friends_email"
                        label="Email"
                        onChange={event =>
                          this.handleChange("all_friends_email", event)
                        }
                        defaultChecked={this.getChecked(
                          this.state.fields["all_friends_email"]
                        )}
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="all_friends_site"
                        label="Site"
                        onChange={event =>
                          this.handleChange("all_friends_site", event)
                        }
                        defaultChecked={this.getChecked(
                          this.state.fields["all_friends_site"]
                        )}
                        inline
                      />
                    </Col>
                  </Row>
                  {this.state.collectionLoader && (
                    <React.Fragment>
                      <Spinner size="sm" color="dark" /> Please wait..
                    </React.Fragment>
                  )}
                  {this.state.collections.length > 0 &&
                    this.state.collections.map(collection => (
                      <Row key={`collection-key-${collection.id}`}>
                        <Col md={12}>
                          <hr className="mt-1 mb-2" />
                        </Col>
                        <Col md={7}>{collection.name}</Col>
                        <Col md={5}>
                          <CustomInput
                            type="checkbox"
                            id={`${collection.id}-email`}
                            label="Email"
                            onChange={event =>
                              this.handleChange(
                                `friend_collection-${collection.id}-email`,
                                event
                              )
                            }
                            inline
                          />
                          <CustomInput
                            type="checkbox"
                            id={`${collection.id}-site`}
                            label="Site"
                            onChange={event =>
                              this.handleChange(
                                `friend_collection-${collection.id}-site`,
                                event
                              )
                            }
                            inline
                          />
                        </Col>
                      </Row>
                    ))}
                </LoadingOverlay>
              </div>
              <button
                type="submit"
                className="btn btn-danger Create-Bookmark-btn mb-3"
                disabled={this.state.submitted}
              >
                {this.state.submitted && (
                  <FontAwesomeIcon
                    icon="spinner"
                    className="mr-1"
                    spin={true}
                  />
                )}
                Save
              </button>
            </Form>
          </Col>
          <Col
            md={3}
            lg={2}
            className="col-12 right-section px-0 text-center mt-lg-5 pt-1 ml-lg-3 mt-md-5"
          >
            <RightMenu />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl
  };
};

export default connect(mapStateToProps)(NotificationSettings);
