import axios from "axios";
import store from "../store/store";
import { toast } from "react-toastify";

let apiUrl = "https://api.civ.works";
if (document.URL.indexOf("social.civ.works") > 0) {
  if (document.URL.indexOf("https") === 0) {
    apiUrl = "https://api.civ.works";
  }
  else {
    apiUrl = "https://api.civ.works";
  }
}
else if (document.URL.indexOf("mitiztechnologies") > 0) {
  apiUrl = "https://api.civ.works";
  if (document.URL.indexOf("https") === 0) {
    apiUrl = "https://civ-works.mitiztechnologies.in/api";
  }
}
else if (
  document.URL.indexOf("8081") > 0 ||
  document.URL.indexOf("3000") > 0
) {
  apiUrl = "https://api.civ.works";
  if (document.URL.indexOf("https") === 0) {
    apiUrl = "https://civ-works.mitiztechnologies.in/api";
  }
}

const http = axios.create({
  baseURL: apiUrl,
  //timeout: 1000,
  headers: { "Content-Type": "application/json" }
});
http.interceptors.request.use(
  config => {
    if (store.getState().token) {
      config.headers.common["X-Api-Key"] = `Bearer  ${store.getState().token}`;
    }
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    return response;
  },
  // error => {
  //   if (error.response.status === 401) {
  //     window.location.href = "/logout";
  //   } else if (error.response.status === 404) {
  //     toast.error(error.message, {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     });
  //   } else if (error.response.status === 500) {
  //     toast.error(error.message, {
  //       position: toast.POSITION.BOTTOM_RIGHT
  //     });
  //   } else {
  //     console.log(error);
  //   }
  //   return Promise.reject(error);
  // }
);

export default http;
