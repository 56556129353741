import React, { Component } from "react";
import search from "../../../services/search";
import common from "../../../services/common";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Card, CardImg, CardFooter, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LeftMenu from "../Common/LeftMenu";
import classnames from 'classnames';
import SkeletonScreen from "../Common/SkeletonScreen";
import NotFound from "../Common/NotFound";
import { Link } from "react-router-dom"
import Rating from "../Action/Rating";
import Content from "../Feeds/Content"

class Search extends Component {
    state = {
        loader: true,
        submitted: false,
        activeTab: "1",
        users: [],
        groups: [],
        actions: [],
        posts: []
    };
    setActiveTab = (activeTab) => {
        if (activeTab !== this.state.activeTab) {
            this.setState({ activeTab });
        }
    }
    getResult = (keyword) => {
        search.getResult({ keyword: keyword }).then(response => {
            if (response.data.success) {
                let activeTab = "1";
                if (response.data.users.length > 0) {
                    activeTab = "1";
                }
                else if (response.data.groups.length > 0) {
                    activeTab = "2";
                }
                else if (response.data.actions.length > 0) {
                    activeTab = "3";
                }
                else if (response.data.posts.length > 0) {
                    activeTab = "4";
                }
                this.setState({
                    loader: false,
                    users: response.data.users,
                    groups: response.data.groups,
                    actions: response.data.actions,
                    posts: response.data.posts,
                    activeTab
                });

            }
            else {
                this.setState({
                    loader: false,
                });
            }
        });
    }
    componentDidMount() {
        this.getResult(decodeURIComponent(this.props.match.params.keyword));
    }
    componentWillReceiveProps(nextProps) {
        /* if (nextProps.match.params.keyword !== this.props.match.params.keyword) {
            this.getResult(nextProps.match.params.keyword);
        } */
        this.getResult(decodeURIComponent(nextProps.match.params.keyword));
    }
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Search Result : Civ Works</title>
                </Helmet>
                <Row className="mt-5">
                    <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
                        <LeftMenu links={this.props.leftMenu} />
                    </Col>
                    <Col md={9} lg={10} className="col-12 px-lg-5 mt-5 pt-2">
                        <h4 className="create-new-group mb-4">Search Result for "{decodeURIComponent(this.props.match.params.keyword)}"</h4>
                        <div className="central-meta create-new-group-form">
                            {this.state.loader ? <SkeletonScreen /> : <React.Fragment>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => this.setActiveTab('1')}
                                        >Users{this.state.users.length > 0 && <strong>{`(${this.state.users.length})`}</strong>}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => this.setActiveTab('2')}>Groups{this.state.groups.length > 0 && <strong>{`(${this.state.groups.length})`}</strong>}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => this.setActiveTab('3')}>Actions{this.state.actions.length > 0 && <strong>{`(${this.state.actions.length})`}</strong>}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => this.setActiveTab('4')}>Posts{this.state.posts.length > 0 && <strong>{`(${this.state.posts.length})`}</strong>}</NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab} className="mb-3">
                                    <TabPane tabId="1">
                                        <Row>
                                            {this.state.users.length > 0 ? this.state.users.map(user => (
                                                <Col md={3}>
                                                    <Link to={`/profile/${user.username}`}>
                                                        <Card>
                                                            <CardImg top width="100%" src={user.image !== null ? `${this.props.baseUrl}/images/${user.image}` : require("../../../assets/images/upload-image.png")} alt={user.name ? user.name : user.username} />
                                                            <CardFooter className="text-center">{user.name ? common.ucwords(user.name) : common.ucwords(user.username)}</CardFooter>
                                                        </Card>
                                                    </Link>
                                                </Col>
                                            )) : <Col md={12}><NotFound message="User not found." /></Col>}
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {this.state.groups.length > 0 ? this.state.groups.map(group => (
                                            <Card>
                                                <Link to={`/groups/details/${group.id}`}>
                                                    <Row className="multi-comment pb-3">
                                                        <Col md={12} lg={3} className="col-12">
                                                            <figure className="ml-3 mt-2">
                                                                {group.icon !== null &&
                                                                    group.icon !== "null" &&
                                                                    group.icon !== "" ? (
                                                                        <img
                                                                            src={`${this.props.baseUrl}/images/${group.icon}`}
                                                                            alt={group.name}
                                                                            className="rounded post-image w-100 h-100"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={require("../../../assets/images/no-image.png")}
                                                                            className="rounded post-image w-100 h-100"
                                                                        />
                                                                    )}
                                                            </figure>
                                                        </Col>
                                                        <Col md={12} lg={9} className="col-12">
                                                            <Col md={12} lg={12} className="col-12">
                                                                <h4 className="topic-user mt-2">{group.name}</h4>
                                                            </Col>
                                                            <Col md={12} lg={12} className="col-12">
                                                                {group.brief_description}
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>
                                        )) : <Col md={12}><NotFound message="Group not found." /></Col>}
                                    </TabPane>
                                    <TabPane tabId="3">
                                        {this.state.actions.length > 0 ? this.state.actions.map(action => (
                                            <Card>
                                                <Link to={`/actions/details/${action.id}`}>
                                                    <Row className="multi-comment pb-3">
                                                        <Col md={12} lg={3} className="col-12">
                                                            <figure className="ml-3 mt-2">
                                                                {action.image !== null &&
                                                                    action.image !== "null" &&
                                                                    action.image !== "" ? (
                                                                        <img
                                                                            src={`${this.props.baseUrl}/images/${action.image}`}
                                                                            alt={action.title}
                                                                            className="rounded post-image w-100 h-100"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={require("../../../assets/images/no-image.png")}
                                                                            className="rounded post-image w-100 h-100"
                                                                        />
                                                                    )}
                                                            </figure>
                                                        </Col>
                                                        <Col md={12} lg={9} className="col-12">
                                                            <Col md={12} lg={12} className="col-12">
                                                                <h4 className="topic-user">{action.title}</h4>
                                                            </Col>
                                                            <Col md={12} lg={12} className="col-12">
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: action.description
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>
                                        )) : <Col md={12}><NotFound message="Action not found." /></Col>}
                                    </TabPane>
                                    <TabPane tabId="4" className="gbgcolor">
                                        {this.state.posts.length > 0 ? this.state.posts.map((feed, i) => (
                                            <Content
                                                feed={feed}
                                                key={`content-${i}`}
                                                feedIndex={i}
                                                enableComment={false}
                                                enableEditDelete={false}
                                            />)) : <NotFound message="Post not found." />}
                                    </TabPane>
                                </TabContent>
                            </React.Fragment>}

                        </div>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
const mapStateToProps = state => {
    return {
        userId: state.userId,
        userName: state.userName,
        baseUrl: state.baseUrl
    };
};
export default connect(mapStateToProps)(Search);
