import http from "./http";
import { connect } from "react-redux";

const user = {
  saveIssues: issues => http.post("/user/save-issues", issues),
  checkUsername: username => {
    return http.get("/user/check-username", {
      params: {
        username: username
      }
    });
  },
  postFeed: data => http.post("/user/post-feed", data),
  add: data => http.post("/user/add", data),
  get: param => http.get("/user/get", { params: param }),
  getIssues: () => http.get("/category/list"),
  sendFriendRequest: params => http.post("/user/send-friend-request", params),
  checkFriend: param => http.get("/user/check-friend", { params: param }),
  cancelFriendRequest: params =>
    http.post("/user/cancel-friend-request", params),
  declineFriendRequest: params =>
    http.post("/user/decline-friend-request", params),
  confirmFriendRequest: params =>
    http.post("/user/confirm-friend-request", params),
  removeFriend: params => http.post("/user/remove-friend", params),
  sendFriendRequestNotification: param =>
    http.get("/user/send-friend-request-notification", { params: param }),
  getFriendRequests: () => http.get("/user/get-friend-requests"),
  getFriendInvite: () => http.get("/user/get-friend-requests-sent"),
  getFriends: (param) => http.get("/user/get-friends", { params: param }),
  acceptFriendRequestNotification: param =>
    http.get("/user/accept-friend-request-notification", { params: param }),
  inviteFriend: params => http.post("/user/invite-friend", params),
  friends: param => http.get("/user/friend-by-alphabets", { params: param }),
  getUsers: param => http.get("/user/get-users", { params: param }),
  saveFriendCollection: params =>
    http.post("/user/save-friend-collection", params),
  getFriendCollections: param =>
    http.get("/user/get-friend-collections", { params: param }),
  getFriendCollection: param =>
    http.get("/user/get-friend-collection", { params: param }),
  deleteFriendCollection: params =>
    http.post("/user/delete-friend-collection", params),
  getFriendsCount: param =>
    http.get("/user/get-friends-count", { params: param }),
  checkBlockedUser: data => http.post("/user/check-blocked-user", data),
  blockUser: data => http.post("/user/block-user", data),
  unBlockUser: data => http.post("/user/un-block-user", data),
  customizeData: data => http.post("/user/set-content-preference", data),
  getCustomizeData: param =>
    http.get("/user/get-content-preference", { params: param }),
};

export default user;
