import React, { Component } from "react";
import group from "../../../services/group";
import { Row, Col, FormGroup, Label, Form } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css"; */
import ReactSummernote from "react-summernote";
//import Autosuggest from "react-autosuggest";
import AsyncSelect from 'react-select/async';
import { toast } from "react-toastify";

class InviteUsers extends Component {
  state = {
    selectedUser: [],
    note: "",
    users: [],
    submitted: false,
  };
  handleTextArea = note => {
    this.setState({ note });
  };

  handleChange = selectedUser => {
    this.setState({ selectedUser });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.selectedUser.length === 0) {
      toast.error("Please add user to invite.", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      this.setState({ submitted: true });
      const params = {
        users: this.state.selectedUser,
        note: this.state.note,
        group_id: this.props.groupId
      };
      group.inviteUsers(params).then(response => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ submitted: false, note: "", tags: [] });
          this.props.closeModal();
        } else if (response.data.error) {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ submitted: false });
        }
      });
    }
  };
  promiseOptions = inputValue =>
    new Promise(resolve => {
      if (inputValue !== "") {
        setTimeout(() => {
          group.searchUser({ group_id: this.props.groupId, keyword: inputValue }).then(response => {
            if (response.data.success) {
              this.setState({ users: response.data.users }, () => {
                resolve(this.filterUser(inputValue));
              });
            }
          });
        }, 1000);
      }
      else {
        resolve(this.filterUser(inputValue));
      }
    });
  filterUser = (inputValue) => {
    return this.state.users.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  render() {
    return (
      <React.Fragment>
        <Form method="post" onSubmit={this.handleSubmit}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="users">Type to search user</Label>
                <AsyncSelect isMulti cacheOptions defaultOptions loadOptions={this.promiseOptions} onChange={this.handleChange} id="users" />
              </FormGroup>
              <FormGroup>
                <Label for="message">Personal Note</Label>
                <ReactSummernote
                  options={{
                    height: 150,
                    dialogsInBody: true,
                    toolbar: [
                      ["style", ["style"]],
                      ["font", ["bold", "underline", "clear"]],
                      ["fontname", ["fontname"]],
                      ["para", ["ul", "ol", "paragraph"]],
                      ["table", ["table"]],
                      /* ["insert", ["link", "picture", "video"]], */
                      ["view", ["fullscreen", "codeview"]]
                    ]
                  }}
                  value={this.state.note}
                  onChange={this.handleTextArea}
                />
              </FormGroup>
            </Col>
            <Col md={12} className="text-right">
              <button
                type="button"
                className="btn btn-outline-secondary mr-2"
                onClick={this.props.closeModal}
                disabled={this.state.submitted}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                disabled={this.state.submitted}
              >
                {this.state.submitted && (
                  <FontAwesomeIcon
                    icon="spinner"
                    className="mr-1"
                    spin={true}
                  />
                )}
                Invite
              </button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default InviteUsers;
