import React, { Component } from "react";
import message from "../../../services/message";
import common from "../../../services/common";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Card,
  Input,
  FormFeedback
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactSummernote from "react-summernote";
import Autosuggest from "react-autosuggest";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.css";
import { connect } from "react-redux";
import LeftMenu from "../Common/LeftMenu";

class Compose extends Component {
  state = {
    tags: [],
    users: [],
    message: "",
    submitted: false,
    errors: {},
    fields: {},
    attachments: []
  };
  handleTagChange = tags => {
    let user = this.state.users.filter(
      user => user.name === tags[tags.length - 1]
    );
    if (user.length > 0) {
      this.setState({ tags });
    } else {
      toast.error("Please choose user from list only.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.tags.length === 0) {
      toast.error("Please add recipient to send.", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (this.handleValidation()) {
      this.setState({ submitted: true });
      const params = {
        recipients: this.state.tags,
        message: this.state.fields["message"],
        subject: this.state.fields["subject"],
        attachments: this.state.attachments,
        parent_id: this.props.messageId ? this.props.messageId : 0
      };
      message.send(params).then(response => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({
            submitted: false,
            message: "",
            fields: {},
            tags: []
          });
          if (this.pond) {
            this.pond.removeFiles();
          }
        } else if (response.data.error) {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ submitted: false });
        }
      });
    }
  };
  handleValidation = () => {
    return true;
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["subject"]) {
      formIsValid = false;
      errors["subject"] = "Subject can not be empty!";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  getMessage = id => {
    let param = {
      id: id
    };
    message.getOne(param).then(response => {
      if (response.data.success) {
        let tags = response.data.recipients.map(
          recipient => recipient.user.username
        );
        this.setState({
          message: response.data.message.message,
          fields: { subject: response.data.message.subject },
          tags: tags
        });
      }
    });
  };
  componentDidMount = () => {
    if (
      this.props.match.params.username &&
      this.props.match.params.username !== "" &&
      this.props.match.params.username !== undefined
    ) {
      let tags = [this.props.match.params.username];
      this.setState({ tags });
    }

    if (this.props.messageId) {
      this.getMessage(this.props.messageId);
    }
    message.users().then(response => {
      if (response.data.success) {
        this.setState({
          users: response.data.users
        });
      }
    });
  };
  render() {
    let users = this.state.users;
    function autocompleteRenderInput({ addTag, ...props }) {
      const handleOnChange = (e, { newValue, method }) => {
        if (method === "enter") {
          e.preventDefault();
        } else {
          props.onChange(e);
        }
      };

      const inputValue =
        (props.value && props.value.trim().toLowerCase()) || "";
      const inputLength = inputValue.length;

      let suggestions = users.filter(user => {
        return user.name.toLowerCase().slice(0, inputLength) === inputValue;
      });

      return (
        <Autosuggest
          ref={props.ref}
          suggestions={suggestions}
          shouldRenderSuggestions={value => value && value.trim().length > 0}
          getSuggestionValue={suggestion => suggestion.name}
          renderSuggestion={suggestion => <span>{suggestion.name}</span>}
          inputProps={{ ...props, onChange: handleOnChange }}
          onSuggestionSelected={(e, { suggestion }) => {
            addTag(suggestion.name);
          }}
          onSuggestionsClearRequested={() => {}}
          onSuggestionsFetchRequested={() => {}}
        />
      );
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>Compose message : Civ Works</title>
        </Helmet>

        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={9} lg={10} className="col-12 px-lg-5 mt-5 pt-2">
            <Row className="mb-2">
              <Col md={12} lg={12} className="col-12">
                <h2>Create a New Private Message</h2>
              </Col>
            </Row>
            <div className="central-meta create-new-group-form">
              <Form method="post" onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="tags">Recipients</Label>
                  <TagsInput
                    renderInput={autocompleteRenderInput}
                    value={this.state.tags}
                    onChange={this.handleTagChange}
                    onlyUnique={true}
                    inputProps={{
                      placeholder: "Add Recipients"
                    }}
                    style={{ backgroundColor: "red" }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="tags">Subject</Label>
                  <Input
                    type="text"
                    name="subject"
                    id="subject"
                    value={
                      this.state.fields["subject"]
                        ? this.state.fields["subject"]
                        : ""
                    }
                    onChange={event => this.handleChange("subject", event)}
                    invalid={this.state.errors["subject"] ? true : false}
                    className="input-bg"
                    bsSize="lg"
                  />
                  {this.state.errors["subject"] && (
                    <FormFeedback>{this.state.errors["subject"]}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="message">Personal Note</Label>

                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    value={
                      this.state.fields["message"]
                        ? this.state.fields["message"]
                        : ""
                    }
                    onChange={event => this.handleChange("message", event)}
                    invalid={this.state.errors["message"] ? true : false}
                    rows="10"
                    className="input-bg"
                  />
                </FormGroup>
                <FormGroup>
                  <FilePond
                    allowMultiple={true}
                    allowRemove={true}
                    ref={ref => (this.pond = ref)}
                    server={{
                      url: this.props.apiUrl,
                      process: {
                        url: "/message/upload",
                        headers: {
                          "X-Api-Key": `Bearer  ${this.props.token}`
                        },
                        onload: response => {
                          let response_josn = JSON.parse(response);
                          if (response_josn.success) {
                            let attachments = this.state.attachments;
                            attachments.push(response_josn.file);
                            this.setState({
                              attachments
                            });
                            return response_josn.file.name;
                          }
                        }
                      },
                      revert: {
                        url: "/message/delete-attachment",
                        headers: {
                          "X-Api-Key": `Bearer  ${this.props.token}`
                          //file_name: files
                        },
                        onload: response => {
                          //console.log(response);
                        }
                      }
                    }}
                    /* onprocessfileprogress={(file, progress) =>
                                this.checkCallBack(file, progress)
                              } */
                    onprocessfilerevert={file => {
                      this.setState({
                        icon: "",
                        preview: ""
                      });
                    }}
                  />
                </FormGroup>

                <Col md={12} className="px-0 text-right mb-3">
                  <Link
                    to="/messages"
                    className="btn btn-outline-secondary mr-1"
                  >
                    <FontAwesomeIcon icon="undo" className="mr-1" />
                    Back
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-danger cbd-color cp"
                    disabled={this.state.submitted}
                  >
                    {this.state.submitted && (
                      <FontAwesomeIcon
                        icon="spinner"
                        className="mr-1"
                        spin={true}
                      />
                    )}
                    Send Message
                  </button>
                </Col>
              </Form>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    apiUrl: state.apiUrl,
    token: state.token
  };
};
export default connect(mapStateToProps)(Compose);
