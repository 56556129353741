import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import news from "../../../services/news";
import User from "./User";
import { Carousel, CarouselItem, CarouselControl, Spinner } from "reactstrap";

class NewsSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      pageNo: 1,
      pageSize: 4,
      hasMore: false,
      loader: false,
      activeIndex: 0,
      animating: false
    };
  }
  next = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.news.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };
  previous = () => {
    if (this.state.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.news.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  setAnimating = animate => {
    this.setState({ animating: animate });
  };

  getNews = () => {
    let data = { page: this.state.pageNo, pageSize: 5, featured: "Y" };
    news.list(data).then(response => {
      if (response.data.success) {
        this.setState({
          news: response.data.news
        });
      }
      /* this.setState({        
        loader: false
      }); */
    });
  };

  componentDidMount() {
    this.getNews();
  }

  render() {
    return (
      <div>
        <div className="sd-title time-slides text-left mt-2">
          <h4 className="Act">In The News</h4>
          <div style={{ height: 215 }} className="news-slider">
            {this.state.news.length > 0 && (
              <Carousel
                activeIndex={this.state.activeIndex}
                next={this.next}
                previous={this.previous}
                interval={2000}
              >
                {this.state.news.map(n => (
                  <CarouselItem
                    onExiting={() => this.setAnimating(true)}
                    onExited={() => this.setAnimating(false)}
                    key={n.id}
                  >
                    <div className="custom-slider-container">
                      <p>
                        <img
                          src={`${this.props.baseUrl}/images/${n.image}`}
                          alt={n.title}
                          className="w-100"
                        />
                      </p>
                      <h6>
                        <a href={n.url} target="_blank">
                          {n.title}
                        </a>
                      </h6>
                    </div>
                  </CarouselItem>
                ))}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={this.previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={this.next}
                />
              </Carousel>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapPropsToState = state => {
  return {
    baseUrl: state.baseUrl
  };
};

export default connect(mapPropsToState)(NewsSlider);
