import React, { Component } from "react";
import group from "../../../services/group";
import common from "../../../services/common";
import { updateFeed } from "../../../store/actions";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import SkeletonScreen from "../Common/SkeletonScreen";
import LeftMenu from "../Common/LeftMenu";
import GroupDetails from "./GroupDetails";
import NotFound from "../Common/NotFound";
import Avtar from "../Common/Avtar";
import SocialShare from "../Feeds/SocialShare";

class Details extends Component {
  state = {
    groupIcon: true,
    loader: true,
    group: {},
    tags: [],
    showEditPostModal: false,
    pageNo: 1,
    pageSize: 5,
    hasMore: false,
    disableButton: false,
    submitted: false,
    disablePost: true,
    totalGroupMember: 0,
    notFound: false,
    createAction: false
  };
  getGroup = id => {
    group.getOne({ id: id }).then(response => {
      if (response.data.success) {
        let groupIcon = response.data.group.icon !== "" ? false : true;
        let tags = [];
        if (response.data.group.tags.length > 0) {
          tags = response.data.group.tags.map(tag => tag.tag.tag);
        }
        let createAction = false;

        if (
          parseInt(this.props.userId) === parseInt(response.data.group.user_id)
        ) {
          createAction = true;
        }
        this.setState({
          createAction,
          loader: false,
          groupIcon,
          tags,
          group: response.data.group,
          totalGroupMember: response.data.group.members.length
        });
      } else if (response.data.error) {
        this.setState({
          notFound: true
        });
      }
    });
  };

  handleScroll = e => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    //console.log(windowBottom, docHeight);
    if (windowBottom >= docHeight) {
      if (this.state.hasMore) {
        this.getFeeds();
      }
    }
  };
  componentDidMount() {
    if (this.props.match.params.id) {
      this.getGroup(this.props.match.params.id);
      window.addEventListener("scroll", this.handleScroll);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  toggleEditPost = (content_id = "") => {
    this.setState(prevState => ({
      showEditPostModal: !prevState.showEditPostModal,
      content_id: content_id
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Groups : Civ Works</title>
        </Helmet>
        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={6} lg={8} className="col-12 pr-4 mt-3">
            {this.state.loader ? (
              <div className="user-post central-meta create-new-group-form item mb-2 pt-3">
                <SkeletonScreen />
              </div>
            ) : (
              <GroupDetails group={this.state.group} />
            )}
            {this.state.notFound && <NotFound message="Group not found." />}
          </Col>
          <Col
            md={3}
            lg={2}
            className="col-12 right-section px-0 text-center mt-3"
          >
            <div className="widgets widgets-coment">
              <div className="Navigation text-left mt-3 mb-3">
                <h4 className="Act text-left pl-3 mb-3 pt-2">
                  <b>Group</b>
                </h4>
                <div className="menu-block">
                  <ul>
                    {this.state.createAction && (
                      <li>
                        <NavLink
                          to={`/actions/group/create/${this.state.group.id}`}
                          activeClassName="is-active"
                          exact={true}
                        >
                          Create Action
                        </NavLink>
                      </li>
                    )}

                    <li>
                      <NavLink
                        to={`/actions/group/${this.state.group.id}`}
                        activeClassName="is-active"
                        exact={true}
                      >
                        Actions {this.state.group.actions
                          ? `(${this.state.group.actions.length})`
                          : `(0)`}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="widgets widgets-coment">
              <div className="Navigation text-left mt-3 mb-3">
                <h4 className="Act text-left pl-3 mb-3 pt-2">
                  <b>Search </b>
                </h4>
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control input-hashtag"
                      id="Type here... "
                      placeholder="Type here... "
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="widgets widgets-coment">
              {!common.isEmptyObject(this.state.group) &&
                this.state.group.members.length > 0 && (
                  <div className="sd-title">
                    <h6 className="widgets-title text-left">
                      <b>Members</b>
                    </h6>
                    <div className="col-md-12 px-0">
                      <div className="members-img">
                        {this.state.group.members.map((member, index) => (
                          <div
                            className={`member-auth-${index + 1}`}
                            key={`group-member-key-${index}`}
                          >
                            <Link to={`/profile/${member.user.username}`}>
                              <Avtar
                                image={member.user.image}
                                size="3x"
                                name={member.user.username}
                                width="38"
                              />
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link action-history mt-3 mb-3"
                    >
                      view all
                      <i className="fas fa-angle-right"></i>
                    </button>
                  </div>
                )}

              <div className="sd-title text-left switch-btn">
                <h4 className="Act mb-3">Group Notifications </h4>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitch1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customSwitch1"
                  >
                    Email
                  </label>
                </div>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitch2"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customSwitch2"
                  >
                    Site
                  </label>
                </div>
              </div>
              {!common.isEmptyObject(this.state.group) && (
                <div className="sd-title text-left">
                  <h4 className="Act mb-3">Share </h4>
                  <div className="col-md-12 px-0">
                    <div className="social-sahre-buttons">
                      <SocialShare
                        iconSize={22}
                        title={group.name}
                        shareUrl="https://api.civ.works/"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    feeds: state.feeds,
    userName: state.userName,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFeed: feeds => {
      dispatch(
        updateFeed({
          feeds
        })
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
