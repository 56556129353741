import http from "./http";

const common = {
  timeSince: timeStamp => {
    let rawTimeStamp = new Date(timeStamp);
    let now = new Date(),
      secondsPast = (now.getTime() - rawTimeStamp.getTime()) / 1000;
    if (secondsPast < 60) {
      let seconds = parseInt(secondsPast);
      if (seconds < 60) {
        return " Just now";
      }
    }
    if (secondsPast < 3600) {
      let minutes = parseInt(secondsPast / 60);
      if (minutes === 1) {
        return minutes + " minute ago";
      } else {
        return minutes + " minutes ago";
      }
    }
    if (secondsPast <= 86400) {
      let hours = parseInt(secondsPast / 3600);
      if (hours === 1) {
        return hours + " hr ago";
      } else {
        return hours + " hrs ago";
      }
    }
    if (secondsPast > 86400) {
      let day = rawTimeStamp.getDate();
      let month = rawTimeStamp
        .toDateString()
        .match(/ [a-zA-Z]*/)[0]
        .replace(" ", "");
      let year =
        rawTimeStamp.getFullYear() === now.getFullYear()
          ? ""
          : " " + rawTimeStamp.getFullYear();
      let hour = rawTimeStamp.getHours();

      let minutes = rawTimeStamp.getMinutes();
      let ampm = hour >= 12 ? "pm" : "am";
      hour = hour % 12;
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return (
        "<strong>On</strong> " +
        day +
        " " +
        month +
        year +
        " <strong>At</strong> " +
        hour +
        ":" +
        minutes +
        " " +
        ampm
      );
    }
  },
  reSizeTextAreaByRow: textarea => {
    /* let maxrows = 2;
    let txt = textarea.target.value;

    let cols = textarea.target.cols;

    let arraytxt = txt.split("\n");
    let rows = arraytxt.length;

    for (let i = 0; i < arraytxt.length; i++) {
      rows += parseInt(arraytxt[i].length / cols);
    }

    if (rows <= maxrows) {
      textarea.target.rows = maxrows;
    } else {
      textarea.target.rows = rows;
    } */
    textarea.target.style.height = "inherit";
    textarea.target.style.height = `${textarea.target.scrollHeight}px`;
  },
  reSizeTextAreaByHeight: textarea => {
    let height = 38;
    let maxrows = 2;
    let txt = textarea.target.value;

    let cols = textarea.target.cols;

    let arraytxt = txt.split("\n");
    let rows = arraytxt.length;

    for (let i = 0; i < arraytxt.length; i++) {
      rows += parseInt(arraytxt[i].length / cols);
    }

    if (rows <= maxrows) {
      textarea.target.style.height = height + "px";
    } else {
      let newHeight = rows * 13;
      textarea.target.style.height = Number(height + newHeight) + "px";
    }
  },
  ucwords: txt => {
    let str =txt? txt.toLowerCase():"";
    return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, s => {
      return s.toUpperCase();
    });
  },
  mySqlFormat: date => {
    let d = new Date(date);
    return d.getFullYear() + "-" + d.getMonth() + 1 + "-" + d.getDate();
  },
  customeFormat: (date, time = false) => {
    let d = new Date(date);
    var date_format =
      d.getDate() + "-" + Number(d.getMonth() + 1) + "-" + d.getFullYear();
    if (time) {
      //date_format = date_format + " at " + d.getHours() + ":" + d.getMinutes();
      date_format = date_format + " at " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    }
    return date_format;
  },
  dateFormat: (date, format, time = false) => {
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];
    let d = new Date(date);
    if (format === 'dd-mm-yyyy') {
      let month = Number(d.getMonth() + 1);
      if (month <= 9) {
        month = "0" + month;
      }
      var date_format =
        d.getDate() + "-" + month + "-" + d.getFullYear();
    }
    else if (format === 'dd MM, yyyy') {
      let month = d.getMonth();

      var date_format =
        d.getDate() + " " + months[month] + ", " + d.getFullYear();
    }
    if (time) {
      date_format = date_format + " at " + d.getHours() + ":" + d.getMinutes();
    }
    return date_format;
  },
  randomNumber: () => {
    return Math.random();
  },
  isValidEmail: email => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  },
  isEmptyObject(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
  },
  dateDiff(date) {
    let today = new Date();
    date = new Date(date);
    let difference = date.getTime() - today.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
  }
};

export default common;
