import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import group from "../../../services/group";
import { updateGroupCounts } from "../../../store/actions";

const withJoinLeave = WrappedComponent => {
  class WithJoinLeave extends Component {
    constructor(props) {
      super(props);
      this.state = {
        joinGroupButton: false,
        leaveGroupButton: false,
        acceptInvitationButton: false,
        declineInvitationButton: false,
        submitted: false,
        totalGroupMembers: this.props.group.members.length
      };
    }
    componentDidMount = () => {
      if (parseInt(this.props.group.user_id) !== parseInt(this.props.userId)) {
        if (this.props.group.members.length > 0) {
          let checkMember = this.props.group.members.filter(
            member => parseInt(member.user_id) === parseInt(this.props.userId)
          );
          if (checkMember.length > 0) {
            this.setState({ leaveGroupButton: true });
          }
        } else {
          this.setState({ joinGroupButton: true });
        }
      }
      if (
        this.props.group.invitations &&
        this.props.group.invitations.length > 0
      ) {
        let checkInvitation = this.props.group.invitations.filter(
          invitation => this.props.userId === invitation.to_user_id
        );
        if (checkInvitation.length > 0) {
          this.setState({
            acceptInvitationButton: true,
            declineInvitationButton: true,
            joinGroupButton: false
          });
        }
      }
    };
    acceptInvitation = () => {
      this.setState({
        submitted: true
      });
      group
        .acceptInvitation({ group_id: this.props.group.id })
        .then(response => {
          if (response.data.success) {
            this.setState(prevState => {
              return {
                leaveGroupButton: true,
                totalGroupMembers: prevState.totalGroupMembers + 1,
                acceptInvitationButton: false,
                declineInvitationButton: false
              };
            });
            let arrangedCounts = {
              totalGroupsJoined: this.props.totalGroupsJoined + 1,
              groupInvitations: this.props.groupInvitations - 1
            };
            this.props.updateGroupCounts(arrangedCounts);
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          } else if (response.data.error) {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          this.setState({
            submitted: false
          });
        });
    };
    declineInvitation = () => {
      this.setState({
        submitted: true
      });
      group
        .declineInvitation({ group_id: this.props.group.id })
        .then(response => {
          if (response.data.success) {
            this.setState(prevState => {
              return {
                joinGroupButton: false,
                leaveGroupButton: true,
                totalGroupMembers: prevState.totalGroupMembers + 1
              };
            });
            let arrangedCounts = {
              totalGroupsJoined: this.props.totalGroupsJoined + 1
            };
            this.props.updateGroupCounts(arrangedCounts);
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          } else if (response.data.error) {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          this.setState({
            submitted: false
          });
        });
    };
    leaveGroup = () => {
      this.setState({
        submitted: true
      });

      group.leave({ group_id: this.props.group.id }).then(response => {
        if (response.data.success) {
          this.setState(prevState => {
            return {
              joinGroupButton: true,
              leaveGroupButton: false,
              totalGroupMembers: prevState.totalGroupMembers - 1
            };
          });
          let arrangedCounts = {
            totalGroupsJoined: this.props.totalGroupsJoined - 1
          };
          this.props.updateGroupCounts(arrangedCounts);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        } else if (response.data.error) {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        this.setState({
          submitted: false
        });
      });
    };
    joinGroup = () => {
      this.setState({
        submitted: true
      });

      group.join({ group_id: this.props.group.id }).then(response => {
        if (response.data.success) {
          this.setState(prevState => {
            return {
              joinGroupButton: false,
              leaveGroupButton: true,
              totalGroupMembers: prevState.totalGroupMembers + 1
            };
          });
          let arrangedCounts = {
            totalGroupsJoined: this.props.totalGroupsJoined + 1
          };
          this.props.updateGroupCounts(arrangedCounts);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        } else if (response.data.error) {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        this.setState({
          submitted: false
        });
      });
    };
    render() {
      return (
        <WrappedComponent
          group={this.props.group}
          joinGroupButton={this.state.joinGroupButton}
          leaveGroupButton={this.state.leaveGroupButton}
          acceptInvitationButton={this.state.acceptInvitationButton}
          declineInvitationButton={this.state.declineInvitationButton}
          submitted={this.state.submitted}
          joinGroup={this.joinGroup}
          leaveGroup={this.leaveGroup}
          acceptInvitation={this.acceptInvitation}
          declineInvitation={this.declineInvitation}
          totalGroupMembers={this.state.totalGroupMembers}
          deleteGroup={this.props.deleteGroup}
        />
      );
    }
  }
  const mapStateToProps = state => {
    return {
      userId: state.userId,
      totalGroupsJoined: state.totalGroupsJoined,
      groupInvitations: state.groupInvitations
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      updateGroupCounts: totalCounts => {
        dispatch(
          updateGroupCounts({
            totalCounts
          })
        );
      }
    };
  };
  return connect(mapStateToProps, mapDispatchToProps)(WithJoinLeave);
};

export default withJoinLeave;
