import React, { Component } from "react";
import { Col, Row, Card, Label, FormGroup, Input, Collapse } from "reactstrap";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  PinterestShareButton
} from "react-share";

class SocialShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareUrl: this.props.shareUrl ? this.props.shareUrl : "",
      title: this.props.title,
      collapse: false
    };
  }
  handleChange = e => {
    this.setState({ shareUrl: e.target.value });
  };

  render() {
    return (
      <Collapse isOpen={this.props.socialShare}>
        <Card className="p-3 mt-2 mb-1">
          <Row>
            <Col md={12}>
              <ul className="social-share-icons">
                <li>
                  <FacebookShareButton
                    url={this.state.shareUrl}
                    quote={this.state.title}
                  >
                    <FacebookIcon size={this.props.iconSize} round />
                  </FacebookShareButton>
                </li>
                <li>
                  <TwitterShareButton
                    url={this.state.shareUrl}
                    quote={this.state.title}
                  >
                    <TwitterIcon size={this.props.iconSize} round />
                  </TwitterShareButton>
                </li>
                <li>
                  <LinkedinShareButton
                    url={this.state.shareUrl}
                    quote={this.state.title}
                  >
                    <LinkedinIcon size={this.props.iconSize} round />
                  </LinkedinShareButton>
                </li>
                <li>
                  <PinterestShareButton
                    url={this.state.shareUrl}
                    quote={this.state.title}
                    media={this.props.media}
                  >
                    <PinterestIcon size={this.props.iconSize} round />
                  </PinterestShareButton>
                </li>
              </ul>
            </Col>
            <Col md={12} className="pt-2">
              <FormGroup>
                <Label for="name">Permalink</Label>
                <Input
                  type="textarea"
                  name="url"
                  id="url"
                  value={this.state.shareUrl}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </Card>
      </Collapse>
    );
  }
}

export default SocialShare;
