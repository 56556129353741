import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Col, Row, Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import RightMenu from "./RightMenu";
import { toast } from "react-toastify";
import content from "../../../services/content";
import SkeletonScreen from "../Common/SkeletonScreen";
import NotFound from "../Common/NotFound";

class Subscriptions extends Component {
    state = {
        subscription_id: "",
        subscriptionDetails: null,
        loader: true, // Introduce loader state
    };

    componentDidMount() {
        this.getSubscriptionStatus();
        // Load Stripe pricing table script here
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
    }

    getSubscriptionStatus = () => {
        content.getSubsriptionStatus().then((response) => {
            if (response.data.user.subscription.status === "active") {
                this.setState({
                    subscription_id: response.data.user.subscription.subscription_id,
                });
                this.getSubscriptionData();
            } else {
                this.setState({
                    loader: false,
                });
            }
        }).catch((error) => {
            console.error("Error fetching subscription status:", error);
            this.setState({
                loader: false,
            });
        });
    };

    getSubscriptionData = () => {
        content.getSubsriptionUserData({ subscription: this.state.subscription_id }).then((response) => {
            if (response.data.success) {
                this.setState({
                    subscriptionDetails: response.data.subscriptionDetails,
                    loader: false, // Update loader state once data is fetched
                });
            } else {
                this.setState({
                    loader: false,
                });
            }
        }).catch((error) => {
            console.error("Error fetching subscription data:", error);
            this.setState({
                loader: false,
            });
        });
    };

    unsubscribe = () => {
        const { subscription_id } = this.state;
        if (!subscription_id) {
            console.error("No subscription ID available to unsubscribe.");
            return;
        }

        content.unsubscribeUserStripe({ subscription: subscription_id }).then((response) => {
            if (response.data.success) {
                toast.success(response.data.message);
                this.setState({
                    subscriptionDetails: null, // Clear subscription details on successful unsubscribe
                });
            } else {
                toast.error("Failed to unsubscribe. Please try again later.");
                console.error("Error unsubscribing:", response.data.message);
            }
        }).catch((error) => {
            toast.error("Failed to unsubscribe. Please try again later.");
            console.error("Error unsubscribing:", error);
        });
    };

    render() {
        const { subscriptionDetails, loader } = this.state;

        if (loader) {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>Subscriptions : Civ Works</title>
                    </Helmet>
                    <Row className="mt-4">
                        <Col md={9} style={{ marginTop: "52px" }}>
                            <h3>Manage Subscription</h3>
                            <SkeletonScreen /> {/* Render SkeletonScreen while loading */}
                        </Col>
                        <Col md={3}>
                            <RightMenu />
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }

        // If no subscription details are available, show message
        if (!subscriptionDetails) {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>Subscriptions : Civ Works</title>
                    </Helmet>
                    <Row className="mt-4">
                        <Col md={9} style={{ marginTop: "52px" }}>
                            <h3>Manage Subscription</h3>
                            <NotFound message="No Active Subscription." />
                        </Col>
                        <Col md={3}>
                            <RightMenu />
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }

        // Once loaded and no errors, extract values from subscriptionDetails or fallback to defaults
        const planName = subscriptionDetails.name || "Regular Monthly Plan";
        const planDescription = subscriptionDetails.description || "Monthly plan for regular customers";
        const planPrice = `US$${subscriptionDetails.amount}` || "US$5 per month";
        const perData = `per ${subscriptionDetails.interval}`;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Subscriptions : Civ Works</title>
                </Helmet>
                <Row className="mt-4">
                    <Col md={9} style={{ marginTop: "52px" }}>
                        <h3>Manage Subscription</h3>
                        <Row className="mb-2">
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={9}>
                                                <CardTitle tag="h5">{planName}</CardTitle>
                                                <CardText>{planDescription}</CardText>
                                                <CardText>{planPrice} {perData}</CardText>
                                            </Col>
                                            <Col md={3} className="d-flex align-items-center justify-content-end">
                                                <Button color="danger" onClick={this.unsubscribe}>Unsubscribe</Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <RightMenu />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.userId,
        baseUrl: state.baseUrl,
        apiUrl: state.apiUrl,
    };
};

export default connect(mapStateToProps)(Subscriptions);
