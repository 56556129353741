import http from "./http";

const message = {
  send: params => http.post("/message/send", params),
  inbox: param => http.get("/message/inbox", { params: param }),
  getMessages: param => http.get("/message/get-messages", { params: param }),
  getOne: param => http.get("/message/get-one", { params: param }),
  delete: param => http.post("/message/delete", param),
  users: () => http.get("/message/users")
};

export default message;
