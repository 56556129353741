import React, { Component } from "react";
import { Col, Row, FormGroup } from "reactstrap";
import common from "../../../services/common";
import content from "../../../services/content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import SkeletonScreen from "../Common/SkeletonScreen";
import Comment from "./Comment";
import { updateFeed } from "../../../store/actions";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.css";
import Avtar from "../Common/Avtar";
import PostCommentForm from "./PostCommentForm";

const mapStateToProps = state => {
  return {
    feeds: state.feeds,
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl,
    userId: state.userId,
    token: state.token,
    userImage: state.userImage,
    userImageThumb: state.userImageThumb,
    userName: state.userName
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFeed: feeds => {
      dispatch(
        updateFeed({
          feeds
        })
      );
    }
  };

};
class CommentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalComments:
        parseInt(this.props.totalComments) > 0
          ? parseInt(this.props.totalComments)
          : 0,
      comments: [],
      contentId: this.props.contentId,
      collapse: false,
      commentLoader: parseInt(this.props.totalComments) > 0 ? true : false,
      feedIndex: this.props.feedIndex,
      pageNo: 1,
      pageSize: 2,
      hasMore: false,
      loadMore: false
    };
  }

  getComments = contentId => {
    let data = {
      page: this.state.pageNo,
      pageSize: this.state.pageSize,
      content_id: contentId
    };
    this.setState({
      loadMore: true
    });
    content.getComments(data).then(response => {
      if (response.data.success) {
        let comments = [...response.data.comment, ...this.state.comments];
        comments.sort((a, b) => a.id - b.id);
        if (
          response.data.comment.length > 0 &&
          this.state.pageNo < response.data.pageCount
        ) {
          this.setState(prevState => ({
            hasMore: true,
            pageNo: prevState.pageNo + 1,
            comments: comments,
            commentLoader: false,
            loadMore: false
          }));
        } else {
          this.setState({
            hasMore: false,
            comments: comments,
            commentLoader: false,
            loadMore: false
          });
        }
      }
    });
  };

  componentDidMount() {
    if (this.state.totalComments > 0) {
      this.getComments(this.props.contentId);
    }
    this.setState({ collapse: true });
  }
  updateTotalComments = (comment_id, parentId) => {
    let comments;
    if (parseInt(parentId) === 0) {
      comments = this.state.comments.filter(
        comment => comment.id !== comment_id
      );
    } else {
      let commentIndex = this.state.comments.findIndex(
        c => parseInt(c.id) === parseInt(parentId)
      );

      let replies = this.state.comments[commentIndex].reply.filter(
        comment => comment.id !== comment_id
      );
      comments = this.state.comments;
      comments[commentIndex].reply = replies;
    }

    let currentFeeds = this.props.feeds;
    currentFeeds[this.props.feedIndex].total_comments =
      parseInt(this.props.totalComments) - 1;
    this.props.updateFeed(currentFeeds);
    this.setState(
      {
        comments,
        totalComments: this.props.feeds[this.props.feedIndex].total_comments
      },
      () => {
        this.props.handleTotalComments(this.state.totalComments);
        if (this.state.comments.length === 0 && this.state.totalComments > 0) {
          this.getComments(this.props.contentId);
        }
      }
    );
  };

  updateComment = (comment, parentId) => {
    let currentFeeds = this.props.feeds;
    let totalComments = (currentFeeds[this.props.feedIndex].total_comments =
      this.state.totalComments + 1);
    this.props.updateFeed(currentFeeds);

    let comments = this.state.comments;
    if (parentId === 0) {
      comments.push(comment);
    } else {
      let commentIndex = comments.findIndex(c => c.id === parentId);
      comments[commentIndex].reply.push(comment);
    }
    this.setState({ comments, totalComments });
    this.props.handleTotalComments(totalComments);
    content.sendCommentNotification(comment.id);
  };


  /* shouldComponentUpdate = (nextProps, nextState) => {
    //alert(nextProps.contentId);
    if (nextProps.contentId !== this.props.contentId) {
      return false;
    } else {
      return true;
    }
  }; */
  render() {
    return (
      <React.Fragment>
        {this.props.enableComment && (
          <Row className="mt-3">
            <Col md={2} lg={1} className="col-2">
              <Avtar
                image={this.props.userImage}
                image_thumb={this.props.userImageThumb}
                size="3x"
                name={this.props.userName}
                width="50"
              />
            </Col>
            <Col md={10} lg={11} className="col-10">
              <PostCommentForm
                feedIndex={this.props.feedIndex}
                contentId={this.props.contentId}
                handleUpdateComment={this.updateComment}
                buttonText="Post Comment"
                md1={9}
                md2={3}
                parentId={0}
                level={1}
              />
            </Col>
          </Row>
        )}
        {this.state.hasMore && (
          <Row className="mt-3">
            <Col md={12} className="pr-3">
              {this.state.loadMore && (
                <FontAwesomeIcon
                  icon="spinner"
                  className="mr-1"
                  spin={true}
                />
              )}
              <span
                className="custome-link"
                onClick={() => this.getComments(this.props.contentId)}
              >
                View previous comments
              </span>
            </Col>
          </Row>
        )}
        {this.state.comments.map((comment, cI) => (
          <React.Fragment key={`cIR-${cI}`}>
            <Comment
              comment={comment}
              key={`cI-${cI}`}
              commentIndex={cI}
              totalComments={this.state.totalComments}
              parentId={comment.id}
              handleUpdateComment={this.updateComment}
              handleUpdateTotalComment={this.updateTotalComments}
              feedId={this.props.feedId}
              level={2}
            />
            {comment.reply.map((r, rI) => (
              <Comment
                comment={r}
                key={`cIr-${rI}`}
                commentIndex={rI}
                totalComments={this.state.totalComments}
                parentId={comment.id}
                className="ml-5"
                handleUpdateComment={this.updateComment}
                handleUpdateTotalComment={this.updateTotalComments}
                feedId={this.props.feedId}
                level={3}
              />
            ))}
          </React.Fragment>
        ))}
        {this.state.commentLoader && (
          <Col>
            <SkeletonScreen height={130} />
          </Col>
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentContainer);
