import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Label
} from "reactstrap";
import withFriend from "../Friends/withFriend";
import user from "../../../services/user";
import ReportContent from "./ReportContent";
import common from "../../../services/common";

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      report: false,
      accordion: {
        interest: { show: false, icon: "plus" },
        social: { show: false, icon: "plus" },
        professional: { show: false, icon: "plus" }
      },
      activeAccordion: [],
      blockUserButton: false,
      unBlockUserButton: false
    };
  }
  toggleModal = () => {
    this.setState(prevState => ({
      report: !prevState.report
    }));
  };
  componentDidMount() {
    if (parseInt(this.props.user.id) !== parseInt(this.props.userId)) {
      this.props.checkFriend(this.props.user.id);
      this.checkBlockedUser(this.props.user.id);
    }
  }
  handleAccordion = type => {
    let accordion = this.state.accordion;
    if (type === "interest") {
      accordion.interest.show = !accordion.interest.show;
      accordion.interest.icon =
        accordion.interest.icon === "plus" ? "minus" : "plus";
    } else if (type === "social") {
      accordion.social.show = !accordion.social.show;
      accordion.social.icon =
        accordion.social.icon === "plus" ? "minus" : "plus";
    } else if (type === "professional") {
      accordion.professional.show = !accordion.professional.show;
      accordion.professional.icon =
        accordion.professional.icon === "plus" ? "minus" : "plus";
    }
    this.setState({ accordion });
  };
  checkVisibility = visibility => {
    const user = this.props.user;
    const friends = this.props.friends;
    if (parseInt(this.props.userId) === parseInt(user.id)) {
      return true;
    } else if (visibility === "Public") {
      return true;
    } else if (visibility === "Friends") {
      if (friends.length > 0) {
        for (let i = 0; i < friends.length; i++) {
          if (
            parseInt(this.props.userId) ===
            parseInt(friends[i].first_user_id) ||
            parseInt(this.props.userId) === parseInt(friends[i].second_user_id)
          ) {
            return true;
          }
        }
      }
      return false;
    } else {
      return false;
    }
  };
  checkBlockedUser = (user_id) => {
    let blockUserButton = false;
    user.checkBlockedUser({ user_id: user_id }).then(response => {
      if (response.data.success) {
        this.setState({ blockUserButton: false, unBlockUserButton: true });
      } else {
        this.setState({ blockUserButton: true, unBlockUserButton: false });
      }
    });
  };

  blockUser = () => {
    user.blockUser({ user_id: this.props.user.id }).then(response => {
      if (response.data.success) {
        this.setState({ blockUserButton: false, unBlockUserButton: true });
      }
    });
  };
  unBlockUser = () => {
    user.unBlockUser({ user_id: this.props.user.id }).then(response => {
      if (response.data.success) {
        this.setState({ blockUserButton: true, unBlockUserButton: false });
      }
    });
  };
  render() {
    const userDetails = this.props.user;

    return (
      <React.Fragment>
        <Row>
          <Col md={12} lg={12} className="col-12 mb-3 pl-0">
            <Link to="/groups" className="btn btn-link back-btn">
              <FontAwesomeIcon icon="arrow-left" className="mr-1" />
              Back
            </Link>
          </Col>
          <Col md={12} lg={3} className="col-12">
            <figure>
              {userDetails.image !== null &&
                userDetails.image !== "null" &&
                userDetails.image !== "" ? (
                  <img
                    src={`${this.props.baseUrl}/images/${
                      userDetails.image_thumb
                        ? userDetails.image_thumb
                        : userDetails.image
                      }`}
                    alt={userDetails.username}
                    className="rounded post-image"
                  />
                ) : (
                  <img
                    src={require("../../../assets/images/no-image.png")}
                    alt={userDetails.username}
                    className="rounded post-image"
                  />
                )}
            </figure>
            <Col md={12} className="col-12 px-0">
              {this.props.sendRequestButton && (
                <button
                  type="button"
                  className="btn btn-danger join-btn text-white"
                  onClick={() => this.props.sendFriendRequest(userDetails.id)}
                  disabled={this.props.disableButton}
                >
                  Add Friend
                </button>
              )}
              {this.props.confirmRequestButton && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    this.props.confirmFriendRequest(userDetails.id)
                  }
                  disabled={this.props.disableButton}
                  style={{ paddingLeft: "24px", paddingRight: "24px" }}
                >
                  Confirm Friend
                </button>
              )}
              {this.props.declineRequestButton && (
                <button
                  type="button"
                  className="btn btn-danger mt-1"
                  onClick={() =>
                    this.props.declineFriendRequest(userDetails.id)
                  }
                  disabled={this.props.disableButton}
                  style={{ paddingLeft: "24px", paddingRight: "24px" }}
                >
                  Decline Friend
                </button>
              )}
              {this.props.cancelRequestButton && (
                <button
                  type="button"
                  className="btn btn-danger mt-1"
                  onClick={() => this.props.cancelFriendRequest(userDetails.id)}
                  disabled={this.props.disableButton}
                >
                  Cancel Friend Request
                </button>
              )}
              {this.props.unFriendButton && (
                <button
                  type="button"
                  className="btn btn-danger join-btn text-white"
                  onClick={() => this.props.removeFriend(0, userDetails.id)}
                  disabled={this.props.disableButton}
                >
                  Un-Friend
                </button>
              )}
            </Col>
            <Col md={12} className="col-12 px-0 mt-2">
              <Link
                to={`/messages/compose/${userDetails.username}`}
                className="btn btn-outline-dark"
              >Send Message
              </Link>
            </Col>

            <Col md={12} className="col-12 px-0 mt-2">
              <button onClick={this.toggleModal} className="btn btn-outline-dark">
                <FontAwesomeIcon icon="exclamation" className="mr-1" />
                Report User
              </button>
            </Col>
            {this.state.blockUserButton && (
              <Col md={12} className="col-12 px-0 mt-2">
                <button onClick={this.blockUser} className="btn btn-outline-warning" disabled={this.state.loader}>
                  <FontAwesomeIcon icon="ban" className="mr-1" />
                  Block
              </button>
              </Col>
            )}
            {this.state.unBlockUserButton && (
              <Col md={12} className="col-12 px-0 mt-2">
                <button onClick={this.unBlockUser} className="btn btn-outline-warning" disabled={this.state.loader}>
                  <FontAwesomeIcon icon="ban" className="mr-1" />
                  Un-Block
              </button>
              </Col>
            )}
          </Col>
          <Col md={12} lg={9} className="col-9">
            <Row>
              <Col md={12} lg={12} className="col-12">
                <h5 className="group-abt-auth">
                  {userDetails.name
                    ? common.ucwords(userDetails.name)
                    : userDetails.username}
                </h5>
              </Col>
              <Col md={12} lg={12} className="col-12">
                <p className="group-abt-details mb-0">About Me</p>
              </Col>
              <Col md={12} lg={12} className="col-12">
                <p
                  className="group-descriptions mb-2"
                  dangerouslySetInnerHTML={{
                    __html: userDetails.about_me
                  }}
                />
              </Col>
              {this.checkVisibility(userDetails.email_visibility) && (
                <Col md={6} lg={6} className="col-6">
                  <p className="m-0 text-uppercase profile-about-info ">
                    Contact Email
                  </p>
                  <p className="time-details mb-3">{userDetails.email}</p>
                </Col>
              )}
              {this.checkVisibility(userDetails.dob_visibility) &&
                userDetails.dob !== "0000-00-00" && (
                  <Col md={6} lg={6} className="col-6">
                    <p className="m-0 text-uppercase profile-about-info">
                      Date of Birth
                    </p>
                    <p className="time-details mb-3">{userDetails.dob}</p>
                  </Col>
                )}
              {this.checkVisibility(userDetails.website_visibility) &&
                userDetails.website !== "" && (
                  <Col md={12} lg={12} className="col-12">
                    <p className="m-0 text-uppercase profile-about-info">
                      Website
                    </p>
                    <p className="time-details mb-3">{userDetails.website}</p>
                  </Col>
                )}
              {this.checkVisibility(userDetails.phone_visibility) && (
                <Col md={12} lg={12} className="col-12">
                  <p className="m-0 text-uppercase profile-about-info">Phone</p>
                  <p className="time-details mb-3">{userDetails.phone}</p>
                </Col>
              )}
              <Col md={12} lg={12} className="col-12">
                <Card className="mb-0">
                  <CardHeader onClick={() => this.handleAccordion("interest")}>
                    <span>Interests</span>
                    <span style={{ float: "right" }}>
                      <FontAwesomeIcon
                        icon={this.state.accordion.interest.icon}
                      />
                    </span>
                  </CardHeader>
                  <Collapse isOpen={this.state.accordion.interest.show}>
                    <CardBody className="p-1 pt-2 group-abt-tag">
                      {userDetails.issues.length > 0 &&
                        userDetails.issues.map(issue => (
                          <span key={`key-${issue.id}`}>
                            {issue.category.name}
                          </span>
                        ))}
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={12} lg={12} className="col-12 mt-1">
                <Card className="mb-0">
                  <CardHeader onClick={() => this.handleAccordion("social")}>
                    <span>Social</span>
                    <span style={{ float: "right" }}>
                      <FontAwesomeIcon
                        icon={this.state.accordion.social.icon}
                      />
                    </span>
                  </CardHeader>
                  <Collapse isOpen={this.state.accordion.social.show}>
                    <CardBody className="p-2">
                      {this.checkVisibility(
                        userDetails.facebook_url_visibility
                      ) &&
                        userDetails.facebook_url !== "" && (
                          <React.Fragment>
                            <Label className="mb-0">Facebook</Label>
                            <p className="text-muted mb-2">
                              {userDetails.facebook_url}
                            </p>
                          </React.Fragment>
                        )}
                      {this.checkVisibility(
                        userDetails.twitter_url_visibility
                      ) &&
                        userDetails.twitter_url !== "" && (
                          <React.Fragment>
                            <Label className="mb-0">Twitter</Label>
                            <p className="text-muted mb-2">
                              {userDetails.twitter_url}
                            </p>
                          </React.Fragment>
                        )}
                      {this.checkVisibility(
                        userDetails.linkedin_url_visibility
                      ) &&
                        userDetails.linkedin_url !== "" && (
                          <React.Fragment>
                            <Label>LinkedIn</Label>
                            <p className="text-muted mb-2">
                              {userDetails.linkedin_url}
                            </p>
                          </React.Fragment>
                        )}
                      {this.checkVisibility(userDetails.books_visibility) &&
                        userDetails.books !== "" && (
                          <React.Fragment>
                            <Label className="mb-0">Books</Label>
                            <p className="text-muted mb-2">
                              {userDetails.books}
                            </p>
                          </React.Fragment>
                        )}
                      {this.checkVisibility(userDetails.movies_visibility) &&
                        userDetails.movies !== "" && (
                          <React.Fragment>
                            <Label className="mb-0">Movies</Label>
                            <p className="text-muted mb-2">
                              {userDetails.movies}
                            </p>
                          </React.Fragment>
                        )}
                      {this.checkVisibility(userDetails.music_visibility) &&
                        userDetails.music !== "" && (
                          <React.Fragment>
                            <Label className="mb-0">Music</Label>
                            <p className="text-muted mb-2">
                              {userDetails.music}
                            </p>
                          </React.Fragment>
                        )}
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={12} lg={12} className="col-12 mt-1">
                <Card className="mb-0">
                  <CardHeader
                    onClick={() => this.handleAccordion("professional")}
                  >
                    <span>Professional</span>
                    <span style={{ float: "right" }}>
                      <FontAwesomeIcon
                        icon={this.state.accordion.professional.icon}
                      />
                    </span>
                  </CardHeader>
                  <Collapse isOpen={this.state.accordion.professional.show}>
                    <CardBody className="p-2">
                      {this.checkVisibility(userDetails.education_visibility) &&
                        userDetails.education !== "" && (
                          <React.Fragment>
                            <Label>Education</Label>
                            <div
                              className="text-muted mb-2"
                              dangerouslySetInnerHTML={{
                                __html: userDetails.education
                              }}
                            />
                          </React.Fragment>
                        )}
                      {this.checkVisibility(
                        userDetails.work_history_visibility
                      ) &&
                        userDetails.work_history !== "" && (
                          <React.Fragment>
                            <Label>Work History</Label>
                            <div
                              className="text-muted mb-2"
                              dangerouslySetInnerHTML={{
                                __html: userDetails.work_history
                              }}
                            />
                          </React.Fragment>
                        )}
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        {userDetails.name && (
          <ReportContent
            show={this.state.report}
            closeModal={this.toggleModal}
            title={userDetails.name}
            url={`${this.props.baseUrl}/profile/${userDetails.username}`}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withFriend(UserDetails);
