export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_FEED = "UPDATE_FEED";
export const DELETE_FEED = "DELETE_FEED";
export const GET_ABUSING_CONTENT = "GET_ABUSING_CONTENT"
export const POST_ACCEPT = "POST_ACCEPT";
export const POST_REJECT = "POST_REJECT";
export const POST_IMAGE_STATUS = "POST_IMAGE_STATUS"
export const GET_ACTIVITY = "GET_ACTIVITY"
export const GET_INVITE_CODE = "GET_INVITE_CODE";
export const POST_INVITE_CODE = "POST_INVITE_CODE";

