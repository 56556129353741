import React, { Component } from "react";
import group from "../../../services/group";
import { Row, Col, FormGroup, Label, Form } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSummernote from "react-summernote";
import FriendCarousel from "../Common/FriendCarousel";
import { toast } from "react-toastify";

class InviteFriends extends Component {
  state = {
    friends: [],
    note: "",
    submitted: false
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.friends.length === 0) {
      toast.error("Please choose your friends to invite.", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      this.setState({ submitted: true });
      const params = {
        friends: this.state.friends,
        note: this.state.note,
        group_id: this.props.groupId
      };
      group.inviteFriends(params).then(response => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ submitted: false, note: "", friends: [] });
          this.props.closeModal();
        } else if (response.data.error) {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ submitted: false });
        }
      });
    }
  };
  handleChange = e => {
    let friends = this.state.friends;
    if (e.target.checked) {
      friends.push(e.target.value);
      this.setState({ friends: friends });
    } else {
      let index_to_be_removed = friends.indexOf(e.target.value);
      friends.splice(index_to_be_removed, 1);
      this.setState({ friends: friends });
    }
  };
  render() {
    return (
      <React.Fragment>
        <Form method="post" onSubmit={this.handleSubmit}>
          <Row>
            <Col md={12}>
              <FriendCarousel handleChange={this.handleChange}></FriendCarousel>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="message">Personal Note</Label>
                <ReactSummernote
                  options={{
                    height: 150,
                    dialogsInBody: true,
                    toolbar: [
                      ["style", ["style"]],
                      ["font", ["bold", "underline", "clear"]],
                      ["fontname", ["fontname"]],
                      ["para", ["ul", "ol", "paragraph"]],
                      ["table", ["table"]],
                      /* ["insert", ["link", "picture", "video"]], */
                      ["view", ["fullscreen", "codeview"]]
                    ]
                  }}
                  value={this.state.note}
                  onChange={this.handleTextArea}
                />
              </FormGroup>
            </Col>
            <Col md={12} className="text-right">
              <button
                type="button"
                className="btn btn-outline-secondary mr-2"
                onClick={this.props.closeModal}
                disabled={this.state.submitted}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                disabled={this.state.submitted}
              >
                {this.state.submitted && (
                  <FontAwesomeIcon
                    icon="spinner"
                    className="mr-1"
                    spin={true}
                  />
                )}
                Invite
              </button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default InviteFriends;
