import React from "react";
import content from "../../../services/content";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "reactstrap";

class CommentLikes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      totalLikes: 0,
      likeArr: [],
      enableLike: false
    };
    this.iconRef = React.createRef();
    this.totalLikesRef = React.createRef();
  }

  toggleToolTip = () => {
    this.setState(prevState => ({
      tooltipOpen: !prevState.tooltipOpen
    }));
  };
  componentDidMount = () => {
    content
      .getCommentLikes({ comment_id: this.props.commentId })
      .then(response => {
        if (response.data.success) {
          this.setState({
            totalLikes: response.data.likes.length,
            likeArr: response.data.likes,
            //enableLike: true
          });
        }
      });
  };
  render() {
    let likeClass = content.addLikeClass(this.props.userId, this.state.likeArr);
    return (
      <span>
        {this.state.enableLike ? (
          <span
            className={likeClass}
            ref={this.iconRef}
          >
            <FontAwesomeIcon icon="thumbs-up" />
          </span>
        ) : (
          <span className={likeClass} ref={this.iconRef}>
            <FontAwesomeIcon icon="thumbs-up" />
          </span>
        )}

        <span className="ml-1 like-count-info">
          <span
            className="total-likes"
            ref={this.totalLikesRef}
            id={`userlikes_${this.props.commentId}`}
          >
            {this.state.totalLikes > 0 && this.state.totalLikes}
          </span>

          <Tooltip
            placement="top"
            isOpen={this.state.tooltipOpen}
            target={`userlikes_${this.props.commentId}`}
            toggle={this.toggleToolTip}
          >
            <span className="text-left">
              {this.state.likeArr.map((u, j) => (
                <span key={`liked-users-${j}`}>
                  {u.user.name}
                  <br />
                </span>
              ))}
            </span>
          </Tooltip>
        </span>
      </span>
    );
  }
}

const mapPropsToState = state => {
  return {
    userId: state.userId
  };
};
export default connect(mapPropsToState)(CommentLikes);
