import http from "./http";

const account = {
  saveProfile: data => http.post("/account/save-profile", data),
  getProfile: param => http.get("/account/get-profile", { params: param }),
  getIssues: () => http.get("/category/list"),
  getWeight: () => http.get("setting/get-weight"),
  getProfileadmin: () => http.get("admin/get-profile"),
  getReferrelCode: () => http.get("user/get-referral-code"),
  getSettingProfile: () => http.get("setting/get-profile"),
  cropAvtar: data => http.post("/account/crop-avtar", data),
  changePassword: data => http.post("/user/change-password", data),
  addWeight: data => http.post("setting/add-weight", data),
  addProfile: data => http.post("admin/update-admin-profile", data),
  getNotificationSettings: data =>
    http.post("/account/get-notification-settings", data),
  saveNotificationSettings: data =>
    http.post("/account/save-notification-settings", data),
  saveAdminData: data =>
    http.post("/admin/add", data)
};

export default account;
