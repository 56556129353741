import React, { Component } from "react";
import action from "../../../services/action";
import http from "../../../services/http";
import { connect, dispatch } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner
} from "reactstrap";
import { toast } from "react-toastify";
import RightMenu from "./RightMenu";
import LeftMenu from "../Common/LeftMenu";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ActionPreferences extends Component {
  state = {
    loader: false,
    submitted: false,
    fields: { radius: 25 },
    errors: {},
    issues: [],
    issueLoader: true,
    selected_issues: []
  };
  getPreferences = () => {
    let that = this;
    this.setState({ loader: true });
    action.getPreferences().then(response => {
      if (response.data.success) {
        let fields = {};
        fields["address"] = response.data.preferences.address;
        fields["radius"] = response.data.preferences.radius;
        let selected_issues = response.data.preferences.issues.split(",");
        this.setState({ selected_issues, fields });
      }
      http.get("/category/list").then(function(response) {
        if (response.data.success) {
          that.setState({
            issues: response.data.categories,
            issueLoader: false
          });
        }
      });
      this.setState({
        loader: false,
        submitted: false
      });
    });
  };
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };
  componentDidMount = () => {
    this.getPreferences();
  };
  handleIssue = e => {
    let selected_issues = this.state.selected_issues;
    if (e.target.className === "btn interests-btn") {
      selected_issues.push(e.target.id);
      e.target.className = "btn interests-btn selected";
      this.setState({ selected_issues });
    } else {
      let index_to_be_removed = selected_issues.indexOf(e.target.id);
      selected_issues.splice(index_to_be_removed, 1);
      e.target.className = "btn interests-btn";
    }
    this.setState({ selected_issues });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });
    const params = {
      address: this.state.fields["address"],
      radius: this.state.fields["radius"],
      issues: this.state.selected_issues
    };

    action.savePreferences(params).then(response => {
      this.setState({ submitted: false }, () => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Actions : Civ Works</title>
        </Helmet>
        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={6} lg={8} className="col-12 col-lg-8 pr-4 mt-5 pt-lg-4">
            <Row className="mb-2">
              <Col md={12} lg={6} className="col-8">
                <h4 className="text-left status">Actions </h4>
              </Col>
            </Row>
            <Form
              name="add-edit-action-form"
              method="post"
              onSubmit={this.handleSubmit}
            >
              <LoadingOverlay
                active={this.state.loader}
                spinner={<Spinner color="dark" />}
                fadeSpeed={200}
                classNamePrefix="mitiz"
              >
                <div className="user-post central-meta create-new-group-form item mb-2">
                  <h5 className="mb-4"> Location </h5>

                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <Label for="address">Address</Label>
                      <Input
                        bsSize="lg"
                        type="text"
                        name="address"
                        id="address"
                        value={
                          this.state.fields["address"]
                            ? this.state.fields["address"]
                            : ""
                        }
                        onChange={event => this.handleChange("address", event)}
                        invalid={this.state.errors["address"] ? true : false}
                        className="input-bg"
                      />
                    </FormGroup>
                    <FormGroup className="col-md-3 mr-auto">
                      <Label for="title">Radius</Label>
                      <Input
                        style={{ width: 200 }}
                        type="select"
                        bsSize="lg"
                        name="radius"
                        id="radius"
                        onChange={event => this.handleChange("radius", event)}
                        value={
                          this.state.fields["radius"]
                            ? this.state.fields["radius"]
                            : ""
                        }
                        className="input-bg"
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </Input>
                    </FormGroup>
                  </div>
                </div>
                <div className="user-post central-meta create-new-group-form item mb-2 Interests">
                  <h5 className="mb-4"> Interests </h5>
                  {this.state.issueLoader && (
                    <React.Fragment>
                      <Spinner size="sm" color="dark" /> Please wait..
                    </React.Fragment>
                  )}
                  {this.state.issues.length > 0 &&
                    this.state.issues.map(issue => (
                      <button
                        type="button"
                        className={`btn interests-btn${
                          this.state.selected_issues.includes(issue.id)
                            ? " selected"
                            : ""
                        }`}
                        onClick={this.handleIssue}
                        key={issue.id}
                        id={issue.id}
                        key={`issue-key-${issue.id}`}
                      >
                        {issue.name}
                      </button>
                    ))}
                </div>
                <button
                  type="submit"
                  className="btn btn-danger Create-Bookmark-btn mt-3 mb-3"
                  disabled={this.state.submitted}
                >
                  {this.state.submitted && (
                    <FontAwesomeIcon
                      icon="spinner"
                      className="mr-1"
                      spin={true}
                    />
                  )}
                  Save
                </button>
              </LoadingOverlay>
            </Form>
          </Col>

          <Col
            md={3}
            lg={2}
            className="col-12 right-section px-0 text-center mt-lg-4 pt-lg-4"
          >
            <RightMenu />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl
  };
};

export default connect(mapStateToProps)(ActionPreferences);
