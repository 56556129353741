import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Spinner,
  Progress
} from "reactstrap";
import { connect } from "react-redux";
import poll from "../../../services/poll";
import common from "../../../services/common";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import SkeletonScreen from "../Common/SkeletonScreen";
import { Link } from "react-router-dom";

const mapStateToProps = state => {
  return {
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};
class Poll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: this.props.poll.answer_allowed > "1" ? [] : "",
      submitted: false,
      retults: [],
      result: false,
      voting: false,
      loader: true
    };
  }
  handleChange = e => {
    if (this.props.poll.answer_allowed > "1") {
      let answer = this.state.answer;
      if (e.target.checked) {
        answer.push(e.target.value);
        this.setState({ answer });
      } else {
        let index_to_be_removed = answer.indexOf(e.target.value);
        answer.splice(index_to_be_removed, 1);
        this.setState({ answer });
      }
    } else {
      this.setState({ answer: e.target.value });
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.answer === "" || this.state.answer.length === 0) {
      toast.error("Please choose your answer to vote.", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (
      Array.isArray(this.state.answer) &&
      this.state.answer.length > parseInt(this.props.poll.answer_allowed)
    ) {
      toast.error(
        `You can select multiple poll choices up to a maximum of ${this.props.poll.answer_allowed} on your vote.`,
        {
          position: toast.POSITION.TOP_RIGHT
        }
      );
    } else {
      let params = {
        answer_id: this.state.answer,
        poll_id: this.props.poll.id,
        content_id: this.props.poll.content_id
      };
      this.setState({ submitted: true });
      poll.doVote(params).then(response => {
        this.setState({ submitted: false }, () => {
          if (response.data.success) {
            this.getResult();
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          } else if (response.data.error) {
            toast.error(response.data.success, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        });
      });
    }
  };
  getResult = () => {
    poll.result({ poll_id: this.props.poll.id }).then(response => {
      if (response.data.success) {
        if (response.data.result.length > 0) {
          let checkVoter = response.data.result.filter(
            r => parseInt(r.user_id) === parseInt(this.props.userId)
          );
          this.setState({
            results: response.data.result,
            result: checkVoter.length > 0 ? true : false,
            voting: checkVoter.length > 0 ? false : true,
            loader: false
          });
        } else {
          this.setState({
            voting: true,
            result: false,
            loader: false
          });
        }
      }
    });
  };
  componentDidMount() {
    this.getResult();
  }
  /* shouldComponentUpdate = (nextProps, nextState) => {
    if (this.state === nextState) {
      return false;
    } else {
      return true;
    }
  }; */
  render() {
    return (
      <React.Fragment>
        <p className="mb-1">
          <Link to={`/polls/${this.props.poll.content_id}/details`}>
            {this.props.poll.question}
          </Link>
        </p>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.poll.description
          }}
        />

        <Card className="mb-0 bg-transparent border-0 poll-progress">
          {this.state.loader && (
            <div className="mt-4">
              <SkeletonScreen height={130} />
            </div>
          )}
          {this.state.voting && (
            <LoadingOverlay
              active={this.state.submitted}
              spinner={<Spinner color="dark" />}
              fadeSpeed={200}
              classNamePrefix="mitiz"
            >
              <CardBody className="p-0">
                <Row>
                  {this.props.poll.image !== "" &&
                    this.props.poll.image !== null && (
                      <Col md={2}>
                        <img
                          src={`${this.props.baseUrl}/images/${this.props.poll.image}`}
                          alt={this.props.poll.question}
                          className="rounded post-image mr-3"
                          style={{ width: 100 }}
                        />
                      </Col>
                    )}
                  <Col
                    md={
                      this.props.poll.image !== "" &&
                      this.props.poll.image !== "null"
                        ? 10
                        : 12
                    }
                  >
                    <form
                      name={this.props.poll.content_id}
                      method="post"
                      onSubmit={this.handleSubmit}
                    >
                      {this.props.poll.options.length > 0 &&
                        this.props.poll.options.map(option => (
                          <FormGroup
                            check
                            className="mb-3 bg-gray-100 pt-2 pb-2 rounded"
                            key={`vote-option-${this.props.poll.content_id}-${option.id}`}
                          >
                            <Label
                              check
                              className="ml-3"
                              style={{ lineHeight: 1 }}
                            >
                              {this.props.poll.answer_allowed > "1" ? (
                                <React.Fragment>
                                  <Input
                                    type="checkbox"
                                    className="mt-0"
                                    value={option.id}
                                    onChange={this.handleChange}
                                  />
                                  {option.option_text}
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <Input
                                    type="radio"
                                    className="mt-0"
                                    value={option.id}
                                    name={this.props.poll.content_id}
                                    onClick={this.handleChange}
                                  />
                                  {option.option_text}
                                </React.Fragment>
                              )}
                            </Label>
                          </FormGroup>
                        ))}
                      <button
                        type="submit"
                        className="btn btn-danger btn-sm cbd-color cp"
                        disabled={this.state.submitted}
                      >
                        Vote
                      </button>
                    </form>
                  </Col>
                </Row>
              </CardBody>
            </LoadingOverlay>
          )}
          {this.state.result && (
            <CardBody className="p-0">
              <Row>
                {this.props.poll.image !== "" &&
                  this.props.poll.image !== null && (
                    <Col md={2}>
                      <img
                        src={`${this.props.baseUrl}/images/${this.props.poll.image}`}
                        alt={this.props.poll.question}
                        className="rounded post-image mr-3"
                        style={{ width: 100 }}
                      />
                    </Col>
                  )}
                <Col
                  md={
                    this.props.poll.image !== "" &&
                    this.props.poll.image !== "null"
                      ? 10
                      : 12
                  }
                >
                  {this.props.poll.options.map(option => (
                    <div
                      key={`poll-result-${
                        this.props.poll.content_id
                      }-${common.randomNumber()}`}
                      className="progress-details"
                    >
                      <span className="progress-yes">{option.option_text}</span>
                      <span className="progress-count">
                        {poll.totalVotesByOption(this.state.results, option.id)}
                      </span>
                      <Progress
                        value={poll.pollParcentage(
                          this.state.results,
                          option.id
                        )}
                        color="danger"
                      ></Progress>
                    </div>
                  ))}
                </Col>
              </Row>
              <p className="mt-2">
                <strong>Total Votes: </strong>
                {poll.totalVotes(this.state.results)}
              </p>
            </CardBody>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Poll);
