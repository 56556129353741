import React, { Component } from "react";
import content from "../../../services/content";
import common from "../../../services/common";
import { updateFeed } from "../../../store/actions";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PostContentForm from "../Common/PostContentForm";
import Content from "../Feeds/Content";
import PostContentFormModal from "../Common/PostContentFormModal";
import withJoinLeave from "./withJoinLeave";
import Avtar from "../Common/Avtar";
import SkeletonScreen from "../Common/SkeletonScreen";
import Invite from "./Invite";

class GroupDetails extends Component {
  state = {
    loader: true,
    pageNo: 1,
    pageSize: 5,
    hasMore: false,
    disablePost: false,
    totalPost: 0,
    showInviteModal: false,
  };
  componentDidMount = () => {
    this.getFeeds();
  };
  getFeeds = () => {
    let feeds = [];
    let data = {
      page: this.state.pageNo,
      pageSize: this.state.pageSize,
      group_id: this.props.group.id
    };
    content.list(data).then(response => {
      if (response.data.success) {
        feeds = response.data.contents;
        if (this.state.pageNo > 1) {
          feeds = [...this.props.feeds, ...feeds];
        }
        this.props.updateFeed(feeds);
        this.setState({
          loader: false,
          totalPost: response.data.pages.totalCount
        });
        if (
          response.data.contents.length > 0 &&
          this.state.pageNo < response.data.pageCount
        ) {
          this.setState(prevState => ({
            hasMore: true,
            pageNo: prevState.pageNo + 1
          }));
        } else {
          this.setState({ hasMore: false });
        }
      }
    });
  };
  handleScroll = e => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      if (this.state.hasMore) {
        this.getFeeds();
      }
    }
  };
  toggleModal = e => {
    this.setState(prevState => ({
      showInviteModal: !prevState.showInviteModal
    }));
  };
  render() {
    const group = this.props.group;
    return (
      <React.Fragment>
        <div className="user-post central-meta create-new-group-form item mb-2 pt-3">
          <Row>
            <Col md={4} lg={4} className="col-12 mb-3 pl-0">
              <Link to="/groups" className="btn btn-link back-btn">
                <FontAwesomeIcon icon="arrow-left" className="mr-1" /> Back
              </Link>
            </Col>
            <Col md={8} lg={8} className="col-12 mb-3 text-right">
              {this.props.acceptInvitationButton && (
                <button
                  type="button"
                  className="btn btn-outline-secondary join-btn mt-2"
                  disabled={this.props.submitted}
                  onClick={this.props.acceptInvitation}
                >
                  Accept Invitation
                </button>
              )}
              {this.props.declineInvitationButton && (
                <button
                  type="button"
                  className="btn btn-outline-secondary leave-btn mt-2 ml-2"
                  disabled={this.props.submitted}
                  onClick={this.props.declineInvitation}
                >
                  Decline Invitation
                </button>
              )}
            </Col>
            <Col md={12} lg={3} className="col-12">
              <figure>
                {group.icon !== null &&
                  group.icon !== "null" &&
                  group.icon !== "" ? (
                  <img
                    src={`${this.props.baseUrl}/images/${group.icon}`}
                    alt={group.name}
                    className="rounded post-image"
                  />
                ) : (
                  <FontAwesomeIcon icon="users" size="7x" title={group.name} />
                )}
              </figure>
              <Col md={12} className="col-12 px-0">
                <button
                  type="button"
                  className="btn btn-outline-danger join-btn"
                  onClick={this.toggleModal}
                >
                  Invite Users
                </button>
              </Col>
              <Col md={12} className="col-12 px-0 mt-3">
                {this.props.joinGroupButton && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary join-btn"
                    disabled={this.props.submitted}
                    onClick={this.props.joinGroup}
                  >
                    {this.props.submitted && (
                      <FontAwesomeIcon
                        icon="spinner"
                        className="mr-1"
                        spin={true}
                      />
                    )}
                    Join Group
                  </button>
                )}
                {this.props.leaveGroupButton && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary leave-btn"
                    disabled={this.props.submitted}
                    onClick={this.props.leaveGroup}
                  >
                    {this.props.submitted && (
                      <FontAwesomeIcon
                        icon="spinner"
                        className="mr-1"
                        spin={true}
                      />
                    )}
                    Leave Group
                  </button>
                )}
              </Col>

              {group.status == "Closed" && (
                <Col md={12} className="col-12 px-0 text-center mt-2">
                  <FontAwesomeIcon icon="lock" className="mr-1" /> Private Group
                </Col>
              )}
            </Col>
            <Col md={12} lg={9} className="col-9">
              <Row>
                <Col md={12} lg={12} className="col-12">
                  <h5 className="group-abt-auth">{group.name}</h5>
                </Col>
                <Col md={12} lg={12} className="col-12">
                  <p className="group-abt-details mb-0">
                    {group.brief_description}
                  </p>
                </Col>
                <Col md={12} lg={12} className="col-12">
                  <p
                    className="group-descriptions mb-2"
                    dangerouslySetInnerHTML={{
                      __html: group.description
                    }}
                  />
                </Col>
                <Col md={12} lg={12} className="col-12">
                  <p className="m-0 grp-time">Date and time</p>
                  <p
                    className="time-details"
                    dangerouslySetInnerHTML={{
                      __html: `.` + common.timeSince(group.added_on)
                    }}
                  />
                </Col>

                <Col md={12} lg={12} className="col-12">
                  <Row>
                    <Col md={12}>
                      <p className="mb-0 grp-time">Owner</p>
                    </Col>
                    <Col md={1}>
                      <Link to={`/profile/${group.user.username}`}>
                        <Avtar
                          image={group.user.image}
                          image_thumb={group.user.image_thumb}
                          size="3x"
                          name={group.user.username}
                          width="45"
                        />
                      </Link>
                    </Col>
                    <Col md={11} className="pt-2">
                      <p className="ml-2">
                        {group.user.name
                          ? group.user.name
                          : group.user.username}
                      </p>
                    </Col>
                    {group.tags.length > 0 && (
                      <Col className="col-md-12 col-12 col-lg-12">
                        <Row>
                          <Col md={12}>
                            <p className="mb-2 mt-0 grp-time">Tags</p>
                          </Col>
                          <Col md={12} className="col-12 group-abt-tag">
                            {group.tags.map(tag => (
                              <Link to="#" key={`group-details-tag-${tag.id}`}>
                                {tag.tag.tag}
                              </Link>
                            ))}
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="user-post central-meta item mb-4">
          <Row>
            <Col className="col-md-12">
              <ul className="followers-dts mt-2 mb-2">
                <li>
                  <div className="followers-dt-sm">
                    <h4>{group.members.length}</h4>
                    <span>
                      {group.members.length > 1 ? "Members" : "Member"}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="followers-dt-sm">
                    <h4>{this.state.totalPost}</h4>
                    <span>Posts Published</span>
                  </div>
                </li>
                <li>
                  <div className="followers-dt-sm">
                    <h4>{group.actions ? group.actions.length : 0}</h4>
                    <span>Actions </span>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <PostContentForm
              groupId={group.id}
              disablePost={this.state.disablePost}
            ></PostContentForm>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="pl-4 pr-4 pt-1 pb-3">
            {this.state.loader ? (
              <SkeletonScreen />
            ) : (
              <React.Fragment>
                {this.props.feeds.map((feed, i) => (
                  <Content
                    feed={feed}
                    key={`content-${i}`}
                    handleEditContentPopup={this.toggleEditPost}
                    feedIndex={i}
                  />
                ))}
                {this.state.hasMore && (
                  <div className="mt-3">
                    <SkeletonScreen height={130} />
                  </div>
                )}
              </React.Fragment>
            )}
            {this.state.showEditPostModal && (
              <PostContentFormModal
                isOpen={this.state.showEditPostModal}
                closeEditPost={this.toggleEditPost}
                content_id={this.state.content_id}
              />
            )}
            {this.state.showInviteModal && (
              <Invite
                showModal={this.state.showInviteModal}
                closeModal={this.toggleModal}
                groupId={this.props.group.id}
                toggleModal={this.toggleModal}
              />
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    feeds: state.feeds,
    userName: state.userName,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFeed: feeds => {
      dispatch(
        updateFeed({
          feeds
        })
      );
    }
  };
};

export default withJoinLeave(
  connect(mapStateToProps, mapDispatchToProps)(GroupDetails)
);
