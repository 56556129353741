import React, { Component } from "react";
import message from "../../../services/message";
import Compose from "./Compose";

class Reply extends Component {
  state = {
    message: {},
    isloader: true
  };

  render() {
    return <Compose messageId={this.props.match.params.id} />;
  }
}

export default Reply;
