import React, { Component } from "react";
import { Row, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PostContentForm from "./PostContentForm";

class PostContentFormModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        top="true"
      >
        <ModalHeader toggle={this.props.toggleEditPost}>
          {this.props.heading}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <PostContentForm
                width="700"
                content_id={this.props.content_id}
                toggleEditPost={this.props.toggleEditPost}
                link={this.props.link ? this.props.link : false}
                url={this.props.url}
                redux={this.props.redux}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}
export default PostContentFormModal;
