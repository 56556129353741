import React, { Component } from "react";
import { Row, Col, Collapse } from "reactstrap";
import Avtar from "../Common/Avtar";
import withFriend from "./withFriend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import common from "../../../services/common";
import { Link } from "react-router-dom";

class Request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
    };
  }
  componentDidMount() {
    this.setState({ collapse: true });
  }

  render() {
    const request = this.props.request;

    return (
      <Collapse isOpen={this.state.collapse}>
        <Row>
          <Col md={3} lg={1} className="col-2">
            <Avtar
              image={request.firstuser.image}
              image_thumb={request.firstuser.image_thumb}
              size="3x"
              name={
                request.firstuser.name !== ""
                  ? request.firstuser.name
                  : request.firstuser.username
              }
              width="43"
            />
          </Col>
          <Col md={9} lg={5} className="col-5 request-info">
            <h5 className="mb-0">
            <Link to={`/profile/${request.firstuser.username}`}>
                {request.firstuser.name !== "" ?
                    common.ucwords(request.firstuser.name) :
                    request.firstuser.username
                }
            </Link>
            </h5>
            <span>{request.friendCount} {request.friendCount === 1 ? 'Friend' : 'Friends'} </span>

          </Col>
          <Col md={12} lg={6} className="col-5 request-info text-lg-right">
            {this.props.confirmRequestButton && (
              <button
                type="button"
                className="btn btn-danger cbd-color cp btn-sm text-uppercase mr-3"
                disabled={this.props.disableButton}
                onClick={() =>
                  this.props.confirmFriendRequest(request.first_user_id)
                }
              >
                Confirm
              </button>
            )}
            {this.props.declineRequestButton && (
              <button
                type="button"
                className="btn btn-outline-secondary cp coc btn-sm text-uppercase"
                disabled={this.props.disableButton}
                onClick={() =>
                  this.props.declineFriendRequest(request.first_user_id)
                }
              >
                Decline
              </button>
            )}
          </Col>
        </Row>
      </Collapse>
    );
  }
}

export default withFriend(Request);
