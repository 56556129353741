import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

class RightMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="widgets widgets-coment mt-lg-5 mt-md-5">
          <div className="Navigation text-left mt-3 mb-3">
            <h4 className="Act text-left pl-3 mb-3 pt-2">My Account</h4>
            <div className="menu-block">
              <ul>
                <li>
                  <NavLink
                    to="/account/profile-settings"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Profile Settings
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/account/change-password"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Change Password
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/account/notification-settings"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Notification Settings
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/account/connections"
                    activeClassName="is-active"
                    exact={true}

                  >
                    Connections
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/account/ActionModal"
                    activeClassName="is-active"
                    exact={true}

                  >
                    Actions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/account/CustomizeFeeds"
                    activeClassName="is-active"
                    exact={true}

                  >
                    Customize Feeds
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/account/Subscriptions"
                    activeClassName="is-active"
                    exact={true}

                  >
                    Subscriptions
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};

export default withRouter(connect(mapStateToProps)(RightMenu));
