import http from "./http";

const content = {

  getAbusing: param => http.get("/content/get-abusive-content", { params: param }),
  add: param => http.post("/content/add", param),
  ChangeImageStatus: (param) => http.post("/content/check-image-status", param),
  getOne: param => http.get("/content/get-one", { params: param }),
  getSubsriptionStatus: param => http.get("/user/get-subscription-data", { params: param }),
  getSubsriptionUserData: param => http.post("/stripe/get-user-subscription", param), 
  unsubscribeUserStripe: param => http.post("stripe/unsubscribe", param), 
  list: param => http.get("/content/list", { params: param }),
  dataList: param => http.get("/content/get-filtered-content ", { params: param }),
  search: param => http.get("/content/search", { params: param }),
  delete: param => http.post("/content/delete", param),
  getInviteCode: () => http.get("/user/create-referral-code"),
  getActivityData: param => http.get("/activity/get-user-activity", { params: param }),
  acceptAbusing: param => http.post("/content/update-status", param),
  like: param => http.post("/content/like", param),
  unLike: param => http.post("/content/un-like", param),
  likeComment: param => http.post("/comment/like", param),
  unLikeComment: param => http.post("/comment/un-like", param),
  addLikeClass: (user_id, likesArr) => {
    if (likesArr.length > 0) {
      const liked = likesArr.filter(
        like => parseInt(like.user_id) === parseInt(user_id)
      );
      if (liked.length > 0) {
        return "like liked";
      } else {
        return "like";
      }
    } else {
      return "like";
    }
  },
  sendLikeNotification: id => {
    http.post("/content/send-like-notification", { id: id });
  },
  sendCommentLikeNotification: id => {
    http.post("/comment/send-like-notification", { id: id });
  },
  getComments: param => http.get("/comment/list", { params: param }),
  postComment: param => http.post("/comment/add", param),
  deleteComment: param => http.post("/comment/delete", param),
  sendCommentNotification: id => {
    http.post("/comment/send-comment-notification", { id: id });
  },
  report: param => http.post("/content/report", param),
  getCommentLikes: param => http.get("/comment/get-likes", { params: param }),
  getComment: param => http.get("/comment/get-one", { params: param }),
  postInvite_Code: param => http.post("/user/add-friends-by-code", param),
};

export default content;
