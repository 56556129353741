import React, { Component } from "react";
import SocialButtons from "../Common/SocialButtons";
import { Link } from "react-router-dom";
import { doLogin } from "../../../store/actions";
import EmailVarificationModal from "./EmailVarificationModal";
import http from "../../../services/http";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import LoginSignupHeader from "../Common/LoginSignupHeader";
import {
  Col,
  Row,
  FormGroup,
  Spinner,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import ConfirmEmailVerificationModal from "./ConfirmEmailModal";

const mapStateToProps = state => {
  return {
    userId: state.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doLogin: data => {
      dispatch(doLogin(data));
    }
  };
};
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      remember_me: false,
      loader: false,
      error: false,
      isFormValid: false,
      httpReferer: window.document.referer ? window.document.referer : "",
      confirmEmailPopUp: null,
    };
    this.usernameRef = React.createRef();
  }

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Please enter your username or email.";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Please enter your password.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ loader: true });
      this.handleLogin();
    }
  };

  handleLogin() {
    let reqData = {
      username: this.state.fields["username"],
      password: this.state.fields["password"]
    };
    var that = this;
    http
      .post("/user/login", reqData)
      .then(function (response) {
        that.setState({ loader: false });
        if (response.data.error) {
          let errors = {};
          errors["password"] = response.data.error;
          that.setState({ errors });
        } else if (response.data.success) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userType", response.data.user_type);
          localStorage.setItem("userName", response.data.username);
          localStorage.setItem("userId", response.data.user_id);
          localStorage.setItem("userImage", response.data.image);
          localStorage.setItem("uName", response.data.name);
          localStorage.setItem("userImageThumb", response.data.image_thumb);
          localStorage.setItem("permissions", response.data.permissions);
          localStorage.setItem("user_role", response.data.user_role);

          that.props.doLogin({
            token: response.data.token,
            authenticated: true,
            userName: response.data.username,
            userId: response.data.user_id,
            userType: response.data.user_type,
            userImage: response.data.image,
            uName: response.data.name,
            userImageThumb: response.data.image_thumb,
          });

          if (that.state.remember_me === true) {
            localStorage.setItem("userName", that.state.username);
            localStorage.setItem("password", that.state.password);
          }

          if (response.data.user_type === 1) {
            that.props.history.push("/admin/dashboard");
          } else {
            that.props.history.push("/dashboard");
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  componentWillMount() {

    if (this.props.userId) {
      this.props.history.push("/dashboard");
    }
  }
  componentDidMount() {
    const containsConfirmAdminEmail = window.location.pathname.includes('confirm-admin-email');
    if (containsConfirmAdminEmail) {
      this.setState({
        confirmEmailPopUp: true,
      });
    }
    else {
      this.setState({
        confirmEmailPopUp: false,
      });
    }

    this.usernameRef.current.focus();
  }

  render() {
    const { loader } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Login : Civ Works</title>
        </Helmet>
        <LoginSignupHeader />
        <div id="lp-register">
          <div className="container wrapper">
            <Row className="mt-5">
              <Col sm={4} md={4} lg={4}>
                <img
                  src={require("../../../assets/images/logo.png")}
                  alt="Civ.Works"
                  className="mb-5"
                />
                <h2 className="privacy-protected pt-lg-5">
                  A powerful privacy-protected social platform for civic
                  engagement
                </h2>
                <Link
                  to="/signup"
                  className="btn btn-warning Register-btn text-uppercase"
                >
                  Register
                </Link>
              </Col>
              <Col sm={7} md={7} lg={7} className="mx-auto">
                <div className="card log-reg-area">
                  <div className="log-reg">
                    <h2 className="login-title text-center mt-5">
                      <b>Log In</b>
                    </h2>
                    <SocialButtons handleOverlay={this.toggleOverlay} />

                    <form
                      className="px-lg-5 login-form"
                      method="post"
                      onSubmit={this.handleSubmit}
                    >
                      <FormGroup>
                        <Label for="username">Email Address Or Username</Label>
                        <input
                          className="input-bg form-control"
                          type="text"
                          name="username"
                          id="username"
                          value={
                            this.state.fields["username"]
                              ? this.state.fields["username"]
                              : ""
                          }
                          onChange={event =>
                            this.handleChange("username", event)
                          }
                          ref={this.usernameRef}
                        />
                        {this.state.errors["username"] && (
                          <small className="fa-1x text-danger">{this.state.errors["username"]}</small>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="password">Password</Label>
                        <Input
                          className="input-bg"
                          type="password"
                          name="password"
                          id="password"
                          value={
                            this.state.fields["password"]
                              ? this.state.fields["password"]
                              : ""
                          }
                          onChange={event =>
                            this.handleChange("password", event)
                          }
                          invalid={this.state.errors["password"] ? true : false}
                        />
                        {this.state.errors["password"] && (
                          <FormFeedback>
                            {this.state.errors["password"]}
                          </FormFeedback>
                        )}
                      </FormGroup>
                      <Col md={12} className="text-center log-bt pb-5 pt-4">
                        <button
                          className="btn btn btn-danger text-uppercase login-btn"
                          type="submit"
                          disabled={loader}
                        >
                          {loader && (
                            <Spinner
                              size="sm"
                              color="#887d7d"
                              className="mr-1"
                            />
                          )}
                          Log in
                        </button>
                        <div className="back-btn mt-4">

                          <Link to="/forgot-password">Forgot password | </Link>
                          <Link to="/terms-and-condition">
                            Terms & Conditions
                          </Link>
                          <Link to="/privacy-policy"> | Privacy Policy</Link>
                        </div>
                      </Col>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {this.state.confirmEmailPopUp == false &&
          <EmailVarificationModal
            show={this.props.match.params.code ? true : false}
            code={this.props.match.params.code || null}
          />
        }

        {this.state.confirmEmailPopUp == true &&
          <ConfirmEmailVerificationModal
            show={this.props.match.params.code ? true : false}
            code={this.props.match.params.code || null}
          />
        }
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
