import {
  LOGIN,
  LOGOUT,
  UPDATE_FEED,
  DELETE_FEED,
  GET_ABUSING_CONTENT,
  POST_ACCEPT,
  POST_REJECT,
  POST_IMAGE_STATUS,
  GET_ACTIVITY,
  GET_INVITE_CODE,
  POST_INVITE_CODE,
} from "./constants";

export function doLogin(payload) {
  return { type: LOGIN, payload };
}
export function doLogout(payload) {
  return { type: LOGOUT, payload };
}
export function updateFeed(payload) {
  return { type: UPDATE_FEED, payload };
}
export function deleteFeed(payload) {
  return { type: DELETE_FEED, payload };
}
export function doLike(payload) {
  return { type: "LIKE", payload };
}
export function updateComment(payload) {
  return { type: "UPDATE_COMMENT", payload };
}
export function deleteComment(payload) {
  return { type: "DELETE_COMMENT", payload };
}
export function saveFriendRequests(payload) {
  return { type: "SAVE_FRIEND_REQUESTS", payload };
}
export function updateGroupCounts(payload) {
  return { type: "UPDATE_TOTAL_COUNTS", payload };
}
export function updateNotification(payload) {
  return { type: "UPDATE_NOTIFICATION", payload };
}
export function setSideMenu(payload) {
  return { type: "SET_SIDE_MENU", payload };
}

// Abusing Feeds
export function getAbusingContent(payload) {
  return { type: GET_ABUSING_CONTENT, payload };
}

export function PostAccept(payload) {
  return { type: POST_ACCEPT, payload };
}

export function PostReject(payload) {
  return { type: POST_REJECT, payload };
}

export function PostImageStatus(payload) {
  return { type: POST_IMAGE_STATUS, payload };
}

export function getActivity(payload) {
  return { type: GET_ACTIVITY, payload };
}

export function getInviteCode(payload) {
  return { type: GET_INVITE_CODE, payload };
}

export function postInviteCode(payload) {
  return { type: POST_INVITE_CODE, payload };
}
