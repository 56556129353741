import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
    Row,
    Col,
} from "reactstrap";
import RightMenu from "./RightMenu";
import action from "../../../services/action";
import SkeletonScreen from "../Common/SkeletonScreen";
import NotFound from "../Common/NotFound";
import Action from "../Action/Action";
import { toast } from "react-toastify";


class ActionsModal extends Component {
    state = {
        rating: 3.5,
        activeTab: "home",
        loader: false,
        actions: [],
        followingAction: [],
        ActionPerformed: [],
        notFoundaction: false,
        notFoundfollowingaction: false,
        notFoundActionPerformed: []
    };
    getActions = path => {
        let type = "upcoming";
        this.setState({ loader: true });
        let data = {
            type: type,
            page: this.state.pageNo,
            pageSize: this.state.pageSize,
            group_id: this.props.match.params.groupId
                ? this.props.match.params.groupId
                : 0
        };
        action.list(data).then(response => {

            if (response.data.success) {
                const mainAction = response.data.actions;
                const filteredActions = mainAction.filter(action => !action.mark_as_completed);
                this.setState({
                    loader: false,
                    actions: filteredActions,
                    notFound: response.data.actions.length > 0 ? false : true
                });
            }
        });
    };

    deleteAction = content_id => {
        if (window.confirm("Are you sure to delete this Action?")) {
            action.delete({ id: content_id }).then(response => {
                if (response.data.success) {
                    let actions = this.state.actions.filter(
                        action => action.content_id !== content_id
                    );
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.setState({ actions });
                    this.getActions();
                    this.getActionsfollowing()
                    this.getPastActionPerformed()
                }
            });
        }
    };
    getActionsfollowing = path => {
        let type = "following";
        this.setState({ loader: true });
        let data = {
            type: type,
            page: this.state.pageNo,
            pageSize: this.state.pageSize,
            group_id: this.props.match.params.groupId
                ? this.props.match.params.groupId
                : 0
        };
        action.list(data).then(response => {

            if (response.data.success) {
                this.setState({
                    loader: false,
                    followingAction: response.data.actions,
                    notFoundfollowingaction: response.data.actions.length > 0 ? false : true
                });
            }
        });
    };
    getPastActionPerformed = path => {
        let type = "past";
        this.setState({ loader: true });
        let data = {
            type: type,
            page: this.state.pageNo,
            pageSize: this.state.pageSize,
            group_id: this.props.match.params.groupId
                ? this.props.match.params.groupId
                : 0
        };
        action.list(data).then(response => {

            if (response.data.success) {
                this.setState({
                    loader: false,
                    ActionPerformed: response.data.actions,
                    notFoundActionPerformed: response.data.actions.length > 0 ? false : true
                });
            }
        });
    };

    componentDidMount = () => {
        this.getActions();
        this.getActionsfollowing()
        this.getPastActionPerformed()
    };

    setActiveTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    render() {
        const { activeTab } = this.state; // Added this line
        return (
            <React.Fragment>
                <Helmet>
                    <title>Actions : Civ Works</title>
                </Helmet>
                <Row className="mt-4">
                    <Col md={7} lg={9} className="col-12 ml-lg-auto mt-5 pt-2">
                        <Row className="mb-2">
                            <Col md={12} lg={6} className="col-8">
                                <h4 className="text-left status">Actions</h4>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={12}>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${activeTab === "home" ? "active" : ""}`}
                                            id="home-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#home"
                                            type="button"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected={activeTab === "home"}
                                            onClick={() => this.setActiveTab("home")}
                                        >
                                            Action Saved
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${activeTab === "profile" ? "active" : ""}`}
                                            id="profile-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="profile"
                                            aria-selected={activeTab === "profile"}
                                            onClick={() => this.setActiveTab("profile")}
                                        >
                                            Upcoming Actions
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${activeTab === "contact" ? "active" : ""}`}
                                            id="contact-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#contact"
                                            type="button"
                                            role="tab"
                                            aria-controls="contact"
                                            aria-selected={activeTab === "contact"}
                                            onClick={() => this.setActiveTab("contact")}
                                        >
                                            Action Performed
                                        </button>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={12}>
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className={`tab-pane fade ${activeTab === "home" ? "show active" : ""}`}
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                    >
                                        {this.state.loader ? (
                                            <div className="mt-4">
                                                <SkeletonScreen />
                                            </div>
                                        ) : (
                                            this.state.followingAction.length > 0 ? (
                                                this.state.followingAction.map((action, index) => (
                                                    <Action
                                                        action={action}
                                                        key={`action-index-${index}`}
                                                        deleteAction={this.deleteAction}
                                                        showIssues={true}
                                                        showRating={true}
                                                        editDelete={true}
                                                        showLeftMenu={true}
                                                    />
                                                ))
                                            ) : (
                                                <Col md={12}><NotFound message="Action not found." /></Col>
                                            )
                                        )}
                                    </div>
                                    <div
                                        className={`tab-pane fade ${activeTab === "profile" ? "show active" : ""}`}
                                        id="profile"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                        {this.state.loader ? (
                                            <div className="mt-4">
                                                <SkeletonScreen />
                                            </div>
                                        ) : (
                                            this.state.actions.length > 0 ? (
                                                this.state.actions.map((action, index) => (
                                                    <Action
                                                        action={action}
                                                        key={`action-index-${index}`}
                                                        deleteAction={this.deleteAction}
                                                        showIssues={true}
                                                        showRating={true}
                                                        editDelete={true}
                                                        showLeftMenu={true}
                                                    />
                                                ))
                                            ) : (
                                                <Col md={12}><NotFound message="Action not found." /></Col>
                                            )
                                        )}
                                    </div>
                                    <div
                                        className={`tab-pane fade ${activeTab === "contact" ? "show active" : ""}`}
                                        id="contact"
                                        role="tabpanel"
                                        aria-labelledby="contact-tab"
                                    >
                                        {this.state.loader ? (
                                            <div className="mt-4">
                                                <SkeletonScreen />
                                            </div>
                                        ) : (
                                            this.state.ActionPerformed.length > 0 ? (
                                                this.state.ActionPerformed.map((action, index) => (
                                                    <Action
                                                        action={action}
                                                        key={`action-index-${index}`}
                                                        deleteAction={this.deleteAction}
                                                        showIssues={true}
                                                        showRating={true}
                                                        editDelete={true}
                                                        showLeftMenu={true}
                                                    />
                                                ))
                                            ) : (
                                                <Col md={12}><NotFound message="Action not found." /></Col>
                                            )
                                        )}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Col>

                    <Col
                        md={3}
                        lg={2}
                        className="col-12 right-section px-0 text-center mt-lg-5 pt-1 ml-lg-3 mt-md-5"
                    >
                        <RightMenu />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.userId,
        baseUrl: state.baseUrl,
        apiUrl: state.apiUrl,
    };
};

export default connect(mapStateToProps)(ActionsModal);
