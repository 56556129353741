import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Input, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import common from "../../../services/common";
import Avtar from "../Common/Avtar";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import message from "../../../services/message";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.css";
import Attachments from "./Attachments";
import { toast } from "react-toastify";

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: "",
      attachments: [],
      loader: false,
      fileUploader: false
    };
  }

  handleChange = e => {
    this.setState({ msg: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const params = {
      recipients: [this.props.toUser.username],
      message: this.state.msg,
      attachments: this.state.attachments,
      parent_id: 0,
      action: "conversation"
    };
    this.setState({
      msg: "",
      loader: true
    });
    message.send(params).then(response => {
      if (response.data.success) {
        let messages = [...this.props.messages, ...response.data.messages];
        this.props.updateConversation(messages);
        let maxMessageId =
          response.data.messages[response.data.messages.length - 1].id;
        this.props.updateMaxMessageId(maxMessageId);
        this.props.scrollToBottom();
        this.setState({
          loader: false
        });
        if (this.pond) {
          this.pond.removeFiles();
          this.toggleUploader();
        }
      } else if (response.data.error) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          loader: false
        });
      }
    });
  };

  toggleUploader = () => {
    this.setState(prevState => ({
      fileUploader: !prevState.fileUploader
    }));
  };

  componentDidMount = () => {
    setInterval(() => {
      if (
        this.props.toUser.id !== undefined &&
        this.props.maxMessageId !== undefined
      ) {
        this.props.getMessages(this.props.toUser.id, this.props.maxMessageId);
      }
    }, 3000);
  };

  deleteMessage = msgId => {
    let messages = [];
    let params = { id: msgId };
    message.delete(params).then(response => {
      if (response.data.success) {
        messages = this.props.messages.filter(message => message.id != msgId);
      }
      this.props.updateConversation(messages);
    });
  };

  render() {
    return (
      <Card
        className="card-chat rounded-0 border-left-0"
        style={{ height: 520 }}
      >
        <LoadingOverlay
          active={this.props.messageLoader}
          spinner={<Spinner color="dark" className="mt-5" />}
          classNamePrefix="mitiz"
          fadeSpeed={200}
        >
          {this.props.messages.length > 0 && (
            <React.Fragment>
              <Row className="no-gutters border-bottom">
                <Col lg={12} className="p-2">
                  <div className="media justify-content-between align-items-center">
                    <div className="Leaderboard-img mr-2">
                      <Link to={`/profile/${this.props.toUser.username}`}>
                        <Avtar
                          image={this.props.toUser.image}
                          image_thumb={this.props.toUser.image_thumb}
                          size="3x"
                          name={this.props.toUser.username}
                          width="38"
                          className="m-0"
                        />
                      </Link>
                    </div>
                    <div className="media-body request-info">
                      <h5>
                        {this.props.toUser.name
                          ? common.ucwords(this.props.toUser.name)
                          : common.ucwords(this.props.toUser.username)}
                      </h5>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="contacts-list-scrollbar bg-white"
                id="conversation-box"
              >
                {this.props.messages.map(message => {
                  let chkFolder = message.folders.filter(
                    folder =>
                      parseInt(folder.user_id) === parseInt(this.props.userId)
                  );
                  if (chkFolder.length > 0) {
                    return (
                      <Row
                        className="no-gutters p-1 mt-2"
                        key={`chat-key-${message.id}`}
                      >
                        {parseInt(message.user_id) ===
                          parseInt(this.props.userId) ? (
                          <React.Fragment>
                            <Col
                              lg={7}
                              className={`ml-auto media justify-content-end ${message
                                .message.length < 40 && "text-right"}`}
                            >
                              <div>
                                <div className="position-relative mb-0 btn-group">
                                  <button
                                    type="button"
                                    className="btn btn-link chat-delete-btn dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    <FontAwesomeIcon icon="ellipsis-v" />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() =>
                                        this.deleteMessage(message.id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="chat-message mb-2 position-relative p-2 px-3 another-persion-chat"
                                style={{ minWidth: 115 }}
                              >
                                {message.message}
                                <Attachments
                                  attachments={message.attachments}
                                />
                                <span
                                  className="text-timing"
                                  dangerouslySetInnerHTML={{
                                    __html: common.timeSince(message.added_on)
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={2} className="text-center">
                              <Link to={`/profile/${message.user.username}`}>
                                <Avtar
                                  image={message.user.image}
                                  image_thumb={message.user.image_thumb}
                                  size="3x"
                                  name={message.user.username}
                                  width="38"
                                />
                              </Link>
                            </Col>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Col lg={2} className="text-center">
                              <Link to={`/profile/${message.user.username}`}>
                                <Avtar
                                  image={message.user.image}
                                  image_thumb={message.user.image_thumb}
                                  size="3x"
                                  name={message.user.username}
                                  width="38"
                                />
                              </Link>
                            </Col>
                            <Col lg={7} className="mr-auto media">
                              <div
                                className="chat-message mb-2 position-relative p-2 px-3"
                                style={{ minWidth: 115 }}
                              >
                                {message.message}
                                <Attachments
                                  attachments={message.attachments}
                                />
                                <span
                                  className="text-timing"
                                  dangerouslySetInnerHTML={{
                                    __html: common.timeSince(message.added_on)
                                  }}
                                />
                              </div>
                              <div>
                                <div className="position-relative mb-0 btn-group">
                                  <button
                                    type="button"
                                    className="btn btn-link chat-delete-btn dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    <FontAwesomeIcon icon="ellipsis-v" />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      onClick={() =>
                                        this.deleteMessage(message.id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </React.Fragment>
                        )}
                      </Row>
                    );
                  }
                })}
              </div>
            </React.Fragment>
          )}
        </LoadingOverlay>
        {this.props.toUser.id && (
          <form
            className="post-comt-box mt-2 position-absolute"
            style={{ bottom: 0, width: "100%" }}
            onSubmit={this.handleSubmit}
          >
            {this.state.fileUploader && (
              <Row className="mr-0 ml-0" style={{ backgroundColor: "#f1f0ef" }}>
                <Col className="mt-2">
                  <FilePond
                    allowMultiple={true}
                    allowRemove={true}
                    ref={ref => (this.pond = ref)}
                    server={{
                      process: {
                        url: this.props.apiUrl + "/message/upload",
                        headers: {
                          "X-Api-Key": `Bearer  ${this.props.token}`
                        },
                        onload: response => {
                          let response_josn = JSON.parse(response);
                          if (response_josn.success) {
                            let attachments = this.state.attachments;
                            attachments.push(response_josn.file);
                            this.setState({
                              attachments
                            });
                            return response_josn.file.name;
                          }
                        }
                      },
                      revert: {
                        url: this.props.apiUrl + "/message/delete-image",
                        headers: {
                          "X-Api-Key": `Bearer  ${this.props.token}`
                          //file_name: files
                        },
                        onload: response => {
                          //console.log(response);
                        }
                      }
                    }}
                    onprocessfilerevert={file => {
                      let uploaded_files = this.state.attachments.filter(
                        attachment => attachment != file.serverId
                      );
                      this.setState({
                        attachments: uploaded_files
                      });
                    }}
                    className="mb-0"
                  />
                </Col>
              </Row>
            )}
            <Row className="no-gutters no-gutters border-top comment-counts mt-0">
              <Col lg={11} md={11} className="col-12 pr-0 p-1">
                <Input
                  type="textarea"
                  name="msg"
                  id="msg"
                  value={this.state.msg}
                  onChange={this.handleChange}
                  className="border-0 messages-textarea"
                />
                <FontAwesomeIcon
                  icon="paperclip"
                  onClick={this.toggleUploader}
                  className="upload_svg"
                />
              </Col>
              <Col lg={1} md={1} className="col-12 pr-2 pt-1">
                <button
                  className="btn btn-danger send-msg"
                  disabled={
                    (this.state.msg.length > 0 ||
                      this.state.attachments.length) > 0
                      ? false
                      : true
                  }
                >
                  {this.state.loader ? (
                    <FontAwesomeIcon icon="spinner" spin={true} />
                  ) : (
                    <FontAwesomeIcon icon="paper-plane" />
                  )}
                </button>
              </Col>
            </Row>
          </form>
        )}
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    token: state.token
  };
};

export default connect(mapStateToProps)(Messages);
