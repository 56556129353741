import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import LeftMenu from "../Common/LeftMenu";
import news from "../../../services/news";
import common from "../../../services/common";
import { connect } from "react-redux";
import SkeletonScreen from "../Common/SkeletonScreen";
import Headline from "./Headline";

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      news: [],
      pageNo: 1,
      pageSize: 5,
      hasMore: false,
      socialShare: false
    };
  }
  toggleSocialShare = () => {
    this.setState(prevState => ({
      socialShare: !prevState.socialShare
    }));
  };
  componentDidMount() {
    this.getNews();
    window.addEventListener("scroll", this.handleScroll);
  }
  getNews = () => {
    let data = {
      page: this.state.pageNo,
      pageSize: this.state.pageSize
    };
    news.list(data).then(response => {
      if (response.data.success) {
        let news = response.data.news;
        if (this.state.pageNo > 1) {
          news = [...this.state.news, ...news];
        }
        this.setState({
          news,
          loader: false
        });
        if (
          response.data.news.length > 0 &&
          this.state.pageNo < response.data.pageCount
        ) {
          this.setState(prevState => ({
            hasMore: true,
            pageNo: prevState.pageNo + 1
          }));
        } else {
          this.setState({ hasMore: false });
        }
      }
    });
  };
  handleScroll = e => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    //console.log(windowBottom, docHeight);
    if (windowBottom >= docHeight) {
      if (this.state.hasMore) {
        this.getNews();
      }
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> Headlines : Civ Works</title>
        </Helmet>

        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={8} lg={10} className="col-12 px-lg-5 mt-5 pt-2">
            <Row>
              <Col md={12} className="pt-4">
                <h4 className="create-new-group mb-4">News</h4>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {this.state.loader && <SkeletonScreen />}
                {this.state.news.length > 0 &&
                  this.state.news.map(n => (
                    <Headline news={n} key={`news-key-${n.id}`} />
                  ))}
                {this.state.hasMore && <SkeletonScreen height={130} />}
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};

export default connect(mapStateToProps)(News);
