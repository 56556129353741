import http from "./http";

const action = {
  create: params => http.post("/action/create", params),
  update: params => http.post("/action/update", params),
  list: param => http.get("/action/list", { params: param }),
  getOne: param => http.get("/action/get-one", { params: param }),
  delete: param => http.post("/action/delete", param),
  follow: param => http.post("/action/follow", param),
  unFollow: param => http.post("/action/un-follow", param),
  getCountries: param => http.get("/common/get-countries", { params: param }),
  getPreferences: param =>
    http.get("/action/get-preferences", { params: param }),
  savePreferences: params => http.post("/action/save-preferences", params),
  getRating: param => http.get("/action/get-rating", { params: param }),
  saveRating: params => http.post("/action/save-rating", params),
  groupAffiliations: param =>
    http.get("/action/group-affiliations", { params: param }),
  scrapImage: params => http.post("/action/scrap-image", params),
  markComplete: params => http.post("/action/mark-complete", params),
  logOut: params => http.post("/user/logout", params)
};

export default action;
