import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Col, Row, Card, CardBody, CardTitle } from "reactstrap";
import { Form } from "react-bootstrap";
import RightMenu from "./RightMenu";
import user from "../../../services/user";
import { toast } from "react-toastify";

class CustomizeFeeds extends Component {
    state = {
        selectedOption: "", // Track the selected option
    };

    handleToggle = (field) => {
        // Check if the clicked option is already selected
        if (this.state.selectedOption === field) {
            // If yes, deselect it by setting selectedOption to an empty string
            this.setState({
                selectedOption: null,
            });
            // Send empty string to API
            this.sendDataToAPI(null);
        } else {
            // If the clicked option is not selected, update the selected option
            this.setState({
                selectedOption: field,
            });
            // Send data to API
            this.sendDataToAPI(field);
        }
    };

    sendDataToAPI = (selectedOption) => {
        // Send selected option to API
        // Your API call logic goes here

        const data = {
            contentPreference: selectedOption,
        };
        user.customizeData(data).then((response) => {
            if (response) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                console.log(response);
            }
        });
    };

    componentDidMount() {
        // Fetch data from API when component mounts
        this.fetchData();
    }

    fetchData = () => {
        // Make API call to get the user's customization data
        user.getCustomizeData()
            .then((response) => {
                // Check if response contains data
                if (response && response.data.content_preference) {
                    // Update state with fetched data
                    this.setState({
                        selectedOption: response.data.content_preference,
                    });
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Actions : Civ Works</title>
                </Helmet>
                <Row className="mt-4">
                    <Col md={7} lg={9} className="col-12 ml-lg-auto mt-5 pt-2">
                        <h2>Customize Feeds</h2>
                        <Card>
                            <CardBody>
                                <CardTitle>

                                    <h5 style={{ fontWeight: '600' }}>Please select only one at a time.</h5>
                                </CardTitle>
                                <Row className="mt-4">
                                    <Col>
                                        <h5>Friends Post</h5>
                                    </Col>
                                    <Col className="text-right">
                                        <Form.Switch
                                            id="friendsPostSwitch"
                                            checked={this.state.selectedOption === "friends_post"}
                                            onChange={() => this.handleToggle("friends_post")}
                                            label=""
                                            style={{ accentColor: "#2c3279" }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <h5>News Issues</h5>
                                    </Col>
                                    <Col className="text-right">
                                        <Form.Switch
                                            id="newsIssuesSwitch"
                                            checked={this.state.selectedOption === "news_issues"}
                                            onChange={() => this.handleToggle("news_issues")}
                                            label=""
                                            style={{ accentColor: "#2c3279" }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <h5>Popular Public Post</h5>
                                    </Col>
                                    <Col className="text-right">
                                        <Form.Switch
                                            id="popularPublicPostSwitch"
                                            checked={this.state.selectedOption === "popular_public_post"}
                                            onChange={() => this.handleToggle("popular_public_post")}
                                            label=""
                                            style={{ accentColor: "#2c3279" }}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col
                        md={3}
                        lg={2}
                        className="col-12 right-section px-0 text-center mt-lg-5 pt-1 ml-lg-3 mt-md-5"
                    >
                        <RightMenu />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.userId,
        baseUrl: state.baseUrl,
        apiUrl: state.apiUrl,
    };
};

export default connect(mapStateToProps)(CustomizeFeeds);
