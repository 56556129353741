import React, { Component } from "react";
import { Link } from "react-router-dom";
import http from "../../../services/http";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {
  Col,
  Row,
  FormGroup,
  Spinner,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import LoginSignupHeader from "../Common/LoginSignupHeader";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      loader: false,
      isFormValid: false
    };
  }

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Please enter your username or email.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ loader: true });
      this.handleForgotPassword();
    }
  };

  handleForgotPassword() {
    let reqData = {
      username: this.state.fields["username"]
    };
    var that = this;
    http
      .post("/user/forgot-password", reqData)
      .then(function(response) {
        that.setState({ loader: false });
        if (response.data.error) {
          let errors = {};
          errors = { username: response.data.message };
          that.setState({ errors });
        } else if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
    const { loader } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Forgot Password : Civ Works</title>
        </Helmet>
        <LoginSignupHeader />
        <div id="lp-register">
          <div className="container wrapper">
            <Row className="mt-5">
              <Col sm={7} md={7} lg={7} className="mx-auto">
                <div className="card log-reg-area">
                  <div className="log-reg">
                    <h2 className="login-title text-center mt-5">
                      <b>Forgot your password?</b>
                    </h2>
                    <p className="text-center reset mb-5">
                      We’ll help you reset it and get back on track.
                    </p>
                    <form
                      className="px-5 login-form"
                      method="post"
                      onSubmit={this.handleSubmit}
                    >
                      <FormGroup>
                        <Label for="username">Email Address Or Username</Label>
                        <Input
                          className="input-bg"
                          type="text"
                          name="username"
                          id="username"
                          value={
                            this.state.fields["username"]
                              ? this.state.fields["username"]
                              : ""
                          }
                          onChange={event =>
                            this.handleChange("username", event)
                          }
                          invalid={this.state.errors["username"] ? true : false}
                        />
                        {this.state.errors["username"] && (
                          <FormFeedback>
                            {this.state.errors["username"]}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      <Col md={12} className="text-center log-bt pb-5 pt-4">
                        <button
                          className="btn btn btn-danger text-uppercase login-btn"
                          type="submit"
                          disabled={loader}
                        >
                          {loader && (
                            <Spinner
                              size="sm"
                              color="#887d7d"
                              className="mr-1"
                            />
                          )}
                          Submit
                        </button>
                        <div className="back-btn mt-4">
                          <Link to="/login">Back</Link>
                        </div>
                      </Col>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
