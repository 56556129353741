import React, { Component } from "react";
import action from "../../../services/action";
import common from "../../../services/common";
import { connect, dispatch } from "react-redux";
import { Row, Col, Card, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Follow from "./Follow";
import Rating from "./Rating";

class Action extends Component {
  state = {
    rating: 3.5
  };

  render() {
    const action = this.props.action;
    return (
      <div className="user-post central-meta create-new-group-form item mb-2 pt-3">
        {this.props.editDelete && (
          <Row>
            <Col md={6}>
              {parseInt(this.props.userId) === parseInt(action.user_id) && (
                <React.Fragment>
                  <Link
                    to={`/actions/edit/${action.id}`}
                    className="btn btn-outline-primary btn-sm"
                  >
                    Edit
                  </Link>
                  <button
                    className="btn btn-outline-danger btn-sm ml-2"
                    onClick={() => this.props.deleteAction(action.content_id)}
                  >
                    Delete
                  </button>
                </React.Fragment>
              )}
            </Col>
            <Col md={6}>
              <div className="is-bullhorn ml-auto">
                <FontAwesomeIcon icon="bullhorn" />
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={3} lg={3} className="col-3">
            <figure>
              {action.image !== null &&
                action.image !== "null" &&
                action.image !== "" ? (
                <img
                  src={`${this.props.baseUrl}/images/${action.image}`}
                  alt={action.title}
                  className="rounded post-image w-100 h-100"
                />
              ) : (
                <img
                  src={require("../../../assets/images/no-image.png")}
                  className="rounded post-image w-100 h-100"
                />
              )}
            </figure>
            <Col md={12} className="text-center">
              {parseInt(this.props.userId) !== parseInt(action.user_id) && (
                <Follow action={action} />
              )}
            </Col>
          </Col>
          <Col md={9} lg={9} className="col-3 pl-0 user-details">
            <p className="user-name">
              <Link to={`/actions/details/${action.id}`}>{action.title}</Link>
            </p>
            <Row>
              <Col md={12} lg={6} className="col-6 rating">
                <Rating action={action} />
              </Col>

              <Col
                md={12}
                lg={6}
                className="col-6 text-lg-right end-post-date mb-0"
              >
                {action.end_on != null && `Ends in ${common.dateDiff(action.end_on)} days`}

              </Col>
            </Row>
            <div
              className="user-post-comments"
              dangerouslySetInnerHTML={{
                __html: action.description
              }}
            />
          </Col>
        </Row>
        {this.props.showIssues && action.issues.length > 0 && (
          <Row>
            <Col md={12} className="col-12 mt-3 group-abt-tag">
              {action.issues.map(issue => (
                <span key={`issue-key-${issue.issue.id}`}>
                  {issue.issue.name}
                </span>
              ))}
              {action.issues.length > 4 && (
                <span className="bg-transparent">More</span>
              )}
            </Col>
          </Row>
        )}
        {parseInt(this.props.userId) === parseInt(action.user_id) && (
          <Row>
            <Col md={12} className="col-12 mt-3">
              <strong>Total followers:</strong> <Badge color="info" pill>{action.followers && action.followers.length}</Badge>
            </Col>

          </Row>
        )}

      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl
  };
};

export default connect(mapStateToProps)(Action);
