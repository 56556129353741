import React, { Component } from "react";
import user from "../../../services/user";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form
} from "reactstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LeftMenu from "../Common/LeftMenu";

class Invite extends Component {
  state = {
    emails: [],
    loader: true,
    fields: {
      message: `Hi, \n\nI want to invite you to join my network here on Civ Works.`
    },
    errors: {},
    submitted: false
  };

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ submitted: true });
      let emails = this.state.fields["emails"].split(",");
      let emailArr = emails.map(email => email.trim());
      const params = {
        emails: emailArr,
        message: this.state.fields["message"]
      };

      user.inviteFriend(params).then(response => {
        this.setState({ submitted: false }, () => {
          if (response.data.success) {
            if (response.data.already_exists_email.length > 0) {
              let errors = {};
              let emails = "";
              response.data.already_exists_email.forEach(email => {
                emails += `${email.trim()},`;
              });
              errors["emails"] = `${emails} already registered with Civ Works.`;
              this.setState({ errors: errors });
            }
            if (response.data.sent_emails) {
              let emails = "";
              response.data.sent_emails.forEach(email => {
                emails += `${email},`;
              });
              toast.success(`Invitation sent to ${emails} sucessfully.`, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          } else if (response.data.error) {
            let errors = {};
            if (response.data.already_exists_email) {
              let emails = "";
              response.data.already_exists_email.forEach(email => {
                emails += `${email},`;
              });
              errors["emails"] = `${emails} already registered with Civ Works.`;
            }
            this.setState({ errors: errors });
          }
        });
      });
    }
  };
  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["emails"]) {
      formIsValid = false;
      errors["emails"] = "Emails can not be empty!";
    } else {
      let email_format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let emails = fields["emails"].split(",");
      emails.forEach(email => {
        let email_with_trim = email.trim();
        if (!email_format.test(email_with_trim)) {
          errors["emails"] = `${email} seems to be invalid.`;
          return;
        }
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Invite People : Civ Works</title>
        </Helmet>
        <Row className="mt-5">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={9} lg={10} className="col-12 px-lg-5 mt-5 pt-2">
            <Form
              name="edit-profile-form"
              method="post"
              onSubmit={this.handleSubmit}
            >
              <h4 className="create-new-group mb-4">Invite People</h4>
              <p>
                Help us grow! Invite family, friends, and associates by listing
                their email below and the message you'd like to send them with
                your invitation. You can help us even more by providing a Gift
                Subscription
              </p>
              <div className="central-meta create-new-group-form">
                <FormGroup>
                  <Label for="question">
                    Email addresses (with comma separator)
                  </Label>
                  <Input
                    type="textarea"
                    name="emails"
                    id="emails"
                    rows="2"
                    value={
                      this.state.fields["emails"]
                        ? this.state.fields["emails"]
                        : ""
                    }
                    onChange={event => this.handleChange("emails", event)}
                    invalid={this.state.errors["emails"] ? true : false}
                    className="input-bg"
                  />
                  {this.state.errors["emails"] && (
                    <FormFeedback>{this.state.errors["emails"]}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="message">Message</Label>
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    value={
                      this.state.fields["message"]
                        ? this.state.fields["message"]
                        : ""
                    }
                    onChange={event => this.handleChange("message", event)}
                    invalid={this.state.errors["message"] ? true : false}
                    rows="10"
                    className="input-bg"
                  />
                  {this.state.errors["message"] && (
                    <FormFeedback>{this.state.errors["message"]}</FormFeedback>
                  )}
                </FormGroup>
                <Col md={12} className="px-0 text-right mb-3">
                  <button
                    type="submit"
                    className="btn btn-danger cbd-color cp"
                    disabled={this.state.submitted}
                  >
                    {this.state.submitted && (
                      <FontAwesomeIcon
                        icon="spinner"
                        className="mr-1"
                        spin={true}
                      />
                    )}
                    Send
                  </button>
                </Col>
              </div>
            </Form>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl
  };
};
export default connect(mapStateToProps)(Invite);
