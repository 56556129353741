import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import SocialShare from "../Feeds/SocialShare";
import { Scrollbars } from "react-custom-scrollbars";
import PostContentFormModal from "../Common/PostContentFormModal";
import common from "../../../services/common";

class Headline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialShare: false,
      rePost: false
    };
  }
  toggleSocialShare = () => {
    this.setState(prevState => ({
      socialShare: !prevState.socialShare
    }));
  };
  toggleEditPost = () => {
    this.setState(prevState => ({
      rePost: !prevState.rePost
    }));
  };
  render() {
    const news = this.props.news;
    return (
      <Card>
        <Row>
          <Col md={12} className="pt-2 pb-1 pr-4 text-right">
            <FontAwesomeIcon
              icon="retweet"
              style={{ fontSize: 20, cursor: "pointer" }}
              className="mr-2"
              onClick={this.toggleEditPost}
            />
            <FontAwesomeIcon
              icon="share"
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={this.toggleSocialShare}
            />
          </Col>

          <Col md={12} className="pl-5 pr-5">
            <SocialShare
              socialShare={this.state.socialShare}
              title={news.description}
              shareUrl={news.url}
              iconSize={32}
              media={`${this.props.baseUrl}/images/${news.image}`}
            />
          </Col>

          <Col md={3} className="pl-5">
            {news.image !== null &&
              news.image !== "null" &&
              news.image !== "" ? (
                <img
                  src={`${this.props.baseUrl}/images/${news.image}`}
                  alt={news.title}
                  className="card-img w-100"
                />
              ) : (
                <img
                  src={require("../../../assets/images/no-image.png")}
                  className="card-img w-100"
                />
              )}
            <div style={{ fontSize: 12 }}>
              <strong>Published On: </strong><br /> <span dangerouslySetInnerHTML={{
                __html: common.dateFormat(news.added_on, 'dd MM, yyyy', true)
              }} />
              {news.source !== null && <React.Fragment><br /><strong>Source: </strong> <br /> {news.source}</React.Fragment>}
            </div>
          </Col>
          <Col md={9} className="pb-3 pr-5">
            <h4>
              <a href={news.url} target="_blank">
                {news.title}
              </a>
            </h4>
            <Scrollbars
              style={{ minHeight: 120, maxHeight: 120 }}
              autoHide={true}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: news.description
                }}
              />
            </Scrollbars>
            <a href={news.url} target="_blank" className="Read-more">
              Read More
            </a>
          </Col>
        </Row>
        {this.state.rePost && (
          <PostContentFormModal
            isOpen={this.state.rePost}
            toggleEditPost={this.toggleEditPost}
            heading="Re-post News"
            link={true}
            url={news.url}
            redux={false}
          />
        )}
      </Card>
    );
  }
}
const mapStateToProps = state => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};

export default connect(mapStateToProps)(Headline);
