import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import { toast } from "react-toastify";
import http from "../../../services/http";
import content from "../../../services/content";
import StripePricingModal from "../Common/StripePricingModal ";

class RightMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: true, // Initially set loader to true
      profileData: null, // To store the profile data fetched from the API
      showSubsriptionModel: false,
      showPricingTable: false,
      freeTrialState: false,
      email: ""
    };
  }

  componentDidMount() {
    this.getContent();
    this.getSubscriptionStatus()
  }
  getSubscriptionStatus = () => {
    content.getSubsriptionStatus().then((response) => {
      if (response.data.success) {
        const isUserSubscription = response.data.showStripeModel
        const today = new Date();
        const subscription = response.data.user;
        if (subscription) {
          const emailData = response.data.user.email
          this.setState({ email: emailData })

        }
        this.setState({
          showSubsriptionModel: isUserSubscription,
        });
        // const subscription = response.data.user.subscription;
        // const emailData = response.data.user.email
        // this.setState({ email: emailData })
        // const freeTrialEndDate = new Date(response.data.freeTrialEndDate);
        // const today = new Date();
        // today.setHours(0, 0, 0, 0)
        // if (today < freeTrialEndDate) {
        //   const hasVisitedBefore = sessionStorage.getItem('hasVisitedBefore');
        //   if (!hasVisitedBefore) {
        //     sessionStorage.setItem('hasVisitedBefore', 'true');
        //     toast.success("You are on trial period for 7 days")
        //   }
        //   this.setState({
        //     freeTrialState: true,
        //   });
        // }


        // // Check if subscription exists and is not null
        // if (subscription && today > freeTrialEndDate) {
        //   const expiryDate = new Date(subscription.expiry_date);
        //   const today = new Date();
        //   today.setHours(0, 0, 0, 0); // Set the time to the start of the day

        //   if (subscription.status === "active" || expiryDate > today) {
        //     this.setState({
        //       showSubsriptionModel: true,
        //     });
        //   }
        // }
      }
    });
  };

  handleClick = () => {

    if (this.state.showSubsriptionModel === true) {

      this.setState({ showPricingTable: true });
    } else {
      this.props.history.push('/actions/create');
    }
  };


  handleClose = () => {
    this.setState({ showPricingTable: false });
  };

  getContent = () => {
    http
      .get("/user/get-profile")
      .then((response) => {
        if (response.data.success) {
          this.setState({
            profileData: response.data.user.adminAccess,
            isLoader: false, // Set loader to false after data is fetched
          });
        } else {
          toast.error("Failed to fetch content.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ isLoader: false }); // Ensure loader is turned off in case of error
        }
      })
      .catch((error) => {
        console.error("Error fetching content:", error);
        toast.error("Network or server error. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({ isLoader: false }); // Ensure loader is turned off in case of error
      });
  };

  render() {
    const { profileData, isLoader } = this.state;
    console.log(profileData, "fkdjsalk")
    const emaildemo = this.state.email

    // Render loader if data is still loading or if profileData is null
    if (isLoader || !profileData) {
      return <div>Loading...</div>;
    }

    return (
      <React.Fragment>
        <StripePricingModal
          show={this.state.showPricingTable}
          onClose={this.handleClose}
          customerEmail={emaildemo}
        />
        <Col md={12} className="px-0">
          {profileData[0] && profileData[0].admin_role === "action_editor" && profileData[0].status === "Y" && (
            <button
              className="btn btn-danger new-group-btn mt-3 mb-3"
              onClick={this.handleClick}
            >
              <FontAwesomeIcon icon="plus" className="mr-1" />
              Create a new action
            </button>
          )}
        </Col>
        <div className="widgets widgets-coment">
          <div className="Navigation text-left mt-3 mb-3">
            <h4 className="Act text-left pl-3 mb-3 pt-2">
              <b>Navigation</b>
            </h4>
            <div className="menu-block">
              <ul>
                <li>
                  <NavLink
                    to="/actions/upcoming"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Upcoming
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/actions/completed"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Completed
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/actions/following"
                    activeClassName="is-active"
                    exact={true}
                    strict
                  >
                    Following
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/actions/preferences"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Preferences
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    baseUrl: state.baseUrl,
    userId: state.userId,
  };
};

export default withRouter(connect(mapStateToProps)(RightMenu));
