import React, { Component } from "react";
import { Row, Col, Collapse } from "reactstrap";
import common from "../../../services/common";
import content from "../../../services/content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Avtar from "../Common/Avtar";
import Gallery from "react-grid-gallery";
import PostCommentForm from "./PostCommentForm";
import CommentLikes from "../Likes/CommentLikes";
import Linkify from "react-linkify";
import EditCommentModal from "./EditCommentModal";

const mapStateToProps = state => {
  return {
    feeds: state.feeds,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};

class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      replyForm: false,
      comment: this.props.comment,
      showModal: false
    };
  }

  toggleReply = () => {
    this.setState(prevState => ({
      replyForm: !prevState.replyForm
    }));
  };
  componentDidMount() {
    this.setState({ collapse: true });
  }
  deleteComment = (e, commentId, parentId) => {
    if (window.confirm("Are you sure to delete this comment?")) {
      let data = {
        id: commentId
      };
      content.deleteComment(data).then(response => {
        if (response.data.success) {
          this.props.handleUpdateTotalComment(commentId, parentId);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    }
  };
  collectAttachment = image => {
    let attachment_arr = [];
    let attachments = [image];
    attachments.forEach((element, index) => {
      attachment_arr[index] = {
        src: `${this.props.baseUrl}/images/${element}`,
        thumbnail: `${this.props.baseUrl}/images/${element}`
        //thumbnailWidth: 350
        //thumbnailHeight: 180
        //caption: "After Rain (Jeshu John - designerspics.com)"
      };
    });
    return attachment_arr;
  };
  toggleModal = e => {
    this.setState(prevState => ({
      showModal: !prevState.showModal,
    }));
  };
  updateComment = (comment) => {
    this.setState({ comment, showModal: false })
  }
  render() {
    let comment = this.state.comment;
    const LinkifyWithTargetBlank = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
    return (
      <Collapse isOpen={this.state.collapse}>
        <Row className={`mt-3 ${this.props.className}`}>
          <Col md={2} lg={1} className="col-2">
            <Link to={`/profile/${comment.user.username}`}>
              <Avtar
                image={comment.user.image}
                image_thumb={comment.user.image_thumb}
                size="3x"
                name={comment.user.username}
                width={this.props.className ? "38" : "48"}
              />
            </Link>
          </Col>
          <Col md={10} lg={11} className="col-10">
            <div className="user-details">
              <span className="user-name">
                <Link to={`/profile/${comment.user.username}`}>
                  {comment.user.name}
                </Link>
              </span>
              <span
                className="user-post-date"
                dangerouslySetInnerHTML={{
                  __html: `.` + common.timeSince(comment.added_on)
                }}
              />
              <Row>
                <Col>
                  {comment.image !== null && comment.image !== "null" && (
                    <Gallery
                      images={this.collectAttachment(comment.image)}
                      id={`comment-gallery-${comment.id}`}
                      enableImageSelection={false}
                    />
                  )}
                </Col>
              </Row>
              <p className="user-post-comments mb-0">
                <Linkify componentDecorator={LinkifyWithTargetBlank}>
                  {comment.comment}
                </Linkify>
              </p>
              <Row className="comment-counts">
                <Col md={4} lg={2} className="pr-0 col-4">
                  <span
                    className="comment-icons"
                    style={{ cursor: "pointer" }}
                    onClick={this.toggleReply}
                  >
                    <span className="Comments commented">
                      <FontAwesomeIcon icon="reply" />
                    </span>
                    <span className="ml-1">
                      <span className="total-Comments Total-count like-count-info">
                        Reply
                      </span>
                    </span>
                  </span>
                </Col>
                <Col md={4} lg={2} className="pl-0 col-4">
                  <CommentLikes commentId={comment.id} />
                </Col>

                {parseInt(comment.user_id) === parseInt(this.props.userId) && (
                  <Col md={4} lg={4} className="pl-0 col-4">
                    <FontAwesomeIcon
                      icon="edit"
                      size="1x"
                      className="mr-2"
                      style={{ cursor: "pointer" }}
                      onClick={this.toggleModal}
                    />
                    <FontAwesomeIcon
                      icon="spinner"
                      size="1x"
                      title="Loading"
                      style={{
                        color: "#CCCCCC",
                        display: "none",
                        width: 12.25
                      }}
                      spin
                      id={`delete-comment-spinner-${comment.id}`}
                    />
                    <FontAwesomeIcon
                      icon="trash"
                      size="1x"
                      style={{ cursor: "pointer" }}
                      title="Delete Post"
                      onClick={event =>
                        this.deleteComment(event, comment.id, comment.parent_id)
                      }
                    />
                  </Col>
                )}

              </Row>
              {this.state.replyForm && (
                <PostCommentForm
                  buttonText="Reply"
                  md1={10}
                  md2={2}
                  parentId={this.props.parentId}
                  contentId={comment.content_id}
                  handleUpdateComment={this.props.handleUpdateComment}
                  level={this.props.level}
                  toggleReply={this.toggleReply}
                />
              )}
              {this.state.showModal && (
                <EditCommentModal
                  showModal={this.state.showModal}
                  toggleModal={this.toggleModal}
                  commentId={comment.id}
                  updateComment={this.updateComment}
                />
              )}
            </div>
          </Col>
        </Row>
      </Collapse>
    );
  }
}

export default connect(mapStateToProps)(Comment);
