import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Avtar from "../Common/Avtar";
import withFriend from "./withFriend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import common from "../../../services/common";
import user from "../../../services/user";

class Friend extends Component {
  constructor(props) {
    super(props);
    this.state = { loader: true, totalFriends: "" };
  }
  componentDidMount() {
    this.getFriendsCount();
  }
  getFriendsCount = () => {
    const friend = this.props.friend;
    let user_id;
    if (parseInt(friend.firstuser.id) !== parseInt(this.props.userId)) {
      user_id = friend.firstuser.id;
    } else {
      user_id = friend.seconduser.id;
    }
    user.getFriendsCount({ user_id: user_id }).then(response => {
      if (response.data.success) {
        let totalFriends;
        if (parseInt(response.data.total) === 0) {
          totalFriends = `${response.data.total} friend`;
        }
        else if (parseInt(response.data.total) > 1) {
          totalFriends = `${response.data.total} friends`;
        }
        else if (parseInt(response.data.total) === 1) {
          totalFriends = `${response.data.total} friend`;
        }
        this.setState({
          loader: false,
          totalFriends
        });
      }
    });
  };
  render() {
    const friend = this.props.friend;
    let user;
    if (parseInt(friend.firstuser.id) !== parseInt(this.props.userId)) {
      user = friend.firstuser;
    } else {
      user = friend.seconduser;
    }
    return (
      <Row>
        <Col md={3} lg={1} className="col-2">
          <Link to={`/profile/${user.username}`}>
            <Avtar
              image={user.image}
              image_thumb={user.image_thumb}
              size="3x"
              name={user.username}
              width="45"
            />
          </Link>
        </Col>
        <Col md={9} lg={5} className="col-5 request-info">
          <h5 className="mb-0">
            <Link to={`/profile/${user.username}`}>
              {user.name ? common.ucwords(user.name) : user.username}
            </Link>
          </h5>
          {this.state.loader ? <FontAwesomeIcon
            icon="spinner"
            className="mr-1"
            spin={true}
          /> : this.state.totalFriends}
        </Col>
        <Col md={12} lg={6} className="col-5 request-info text-lg-right">
          <button
            type="button"
            className="btn btn-outline-secondary cp coc btn-sm text-uppercase"
            disabled={this.props.disableButton}
            onClick={() => this.props.removeFriend(this.props.index, user.id)}
          >
            {this.props.disableButton && (
              <FontAwesomeIcon icon="spinner" className="mr-1" spin={true} />
            )}
            Un-friend
          </button>
        </Col>
      </Row>
    );
  }
}

export default withFriend(Friend);
