import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { CardBody, Spinner } from "reactstrap";
import notification from "../../../services/notification";
import common from "../../../services/common";
import { updateNotification } from "../../../store/actions";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      new: 0,
      intervalId: null
    };
  }
  componentDidMount = () => {
    var intervalId = setInterval(() => {
      let notifications = [];
      notification.list().then(response => {
        if (response.data.success) {
          notifications = response.data.notifications;
          this.props.updateNotification(notifications);
          this.setState({ loader: false, new: parseInt(response.data.new) });
        }
      });
    }, 15000);
    this.setState({ intervalId });
  }

  markNotifiationRead = () => {
    notification.read();
  }
  componentWillUnmount = () => {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }
  render() {
    return (
      <React.Fragment>

        {this.state.loader ? (
          <CardBody className="text-center p-5">
            <Spinner color="dark" />
          </CardBody>
        ) : (
            <Scrollbars style={{ height: this.props.scrollHeight ? this.props.scrollHeight : window.outerHeight }} autoHide={true}>
              {this.props.notifications.map(n => (
                <CardBody
                  className={`py-3 pl-0 border-bottom-1 ${
                    n.status === "1" ? "read-bg" : ""
                    }`}
                  style={{ borderBottom: "1px solid #cccccc" }}
                  key={`notification-key-${n.id}`}
                >
                  <div className="media notify-details ml-5">
                    <FontAwesomeIcon icon="circle" className="mx-3 mt-1" />
                    <div className="media-body notify-user-details">
                      <p className="my-0">
                        <Link to={n.url !== null ? n.url : "/dashboard"}>
                          {`${n.object.actor.name
                            ? common.ucwords(n.object.actor.name)
                            : common.ucwords(n.object.actor.username)}                    
                     ${notification.getDescription(n.object.entity_type)}`}
                        </Link>
                      </p>
                      <p
                        className="mb-0 mt-1 notify-time"
                        dangerouslySetInnerHTML={{
                          __html: `.` + common.timeSince(n.object.created_on)
                        }}
                      />
                    </div>
                  </div>
                </CardBody>
              ))}
            </Scrollbars>
          )
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    notifications: state.notifications,
    userId: state.userId,
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateNotification: notifications => {
      dispatch(
        updateNotification({
          notifications
        })
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
