import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Form,
  ModalHeader
} from "reactstrap";
import content from "../../../services/content";
import { toast } from "react-toastify";

class ReportContent extends Component {
  state = {
    fields: { title: this.props.title, url: this.props.url },
    errors: {},
    submitted: false
  };

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ submitted: true });
      const params = {
        title: this.state.fields["title"],
        url: this.state.fields["url"],
        reason: this.state.fields["reason"]
      };
      content.report(params).then(response => {
        this.setState({ submitted: false }, () => {
          if (response.data.success) {
            this.props.closeModal();
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          } else if (response.data.error) {
            let errors = {};
            this.setState({ errors: errors });
          }
        });
      });
    }
  };
  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = "Title can not be empty!";
    }
    if (!fields["url"]) {
      formIsValid = false;
      errors["url"] = "Url can not be empty!";
    }
    if (!fields["reason"]) {
      formIsValid = false;
      errors["reason"] = "Reason can not be empty!";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  render() {
    return (
      <Modal
        isOpen={this.props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalHeader>Report Content</ModalHeader>
        <ModalBody style={{ padding: 2 }} className="pr-4 pl-4 mt-3">
          <Row>
            <Col md={12} className="px-3">
              <Form
                name="report-content-form"
                method="post"
                onSubmit={this.handleSubmit}
              >
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="name">Title</Label>
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        value={
                          this.state.fields["title"]
                            ? this.state.fields["title"]
                            : ""
                        }
                        onChange={event => this.handleChange("title", event)}
                        invalid={this.state.errors["title"] ? true : false}
                      />
                      {this.state.errors["title"] && (
                        <FormFeedback>
                          {this.state.errors["title"]}
                        </FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="url">Location of the item</Label>
                      <Input
                        type="url"
                        name="url"
                        id="url"
                        value={
                          this.state.fields["url"]
                            ? this.state.fields["url"]
                            : ""
                        }
                        onChange={event => this.handleChange("url", event)}
                        invalid={this.state.errors["url"] ? true : false}
                      />
                      {this.state.errors["url"] && (
                        <FormFeedback>{this.state.errors["url"]}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="reason">Why are you reporting this?</Label>
                      <Input
                        type="textarea"
                        rows={10}
                        name="reason"
                        id="reason"
                        value={
                          this.state.fields["reason"]
                            ? this.state.fields["reason"]
                            : ""
                        }
                        invalid={this.state.errors["reason"] ? true : false}
                        onChange={event => this.handleChange("reason", event)}
                      />
                      {this.state.errors["reason"] && (
                        <FormFeedback>
                          {this.state.errors["reason"]}
                        </FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={12} className="text-right mb-3">
                    <button
                      type="submit"
                      className="btn btn-success mr-2"
                      disabled={this.state.submitted}
                    >
                      {this.state.submitted && (
                        <FontAwesomeIcon
                          icon="spinner"
                          className="mr-1"
                          spin={true}
                        />
                      )}
                      Report This
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      disabled={this.state.submitted}
                      onClick={this.props.closeModal}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}
export default ReportContent;
