import React, { Component } from "react";
import content from "../../../services/content";
import common from "../../../services/common";
import NotFound from "../Common/NotFound";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Card, CardBody, Spinner, CardFooter, Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LeftMenu from "../Common/LeftMenu";
import SkeletonScreen from "../Common/SkeletonScreen";
import Content from "../Feeds/Content";
import { updateFeed } from "../../../store/actions";
import { ToastContainer } from "react-toastify";
import { Scrollbars } from "react-custom-scrollbars";
import Notification from "../../../views/Front/Notifications/Notification";

class FeedDetails extends Component {
  state = {
    loader: true,
    content: [],
    socialShare: false
  };

  getFeed = id => {
    content.getOne({ id: id }).then(response => {
      if (response.data.success) {
        let feeds = [response.data.content];
        this.props.updateFeed(feeds);
        this.setState({
          content: feeds,
          loader: false
        });
      } else if (response.data.error) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };
  componentDidMount() {
    if (this.props.match.params.id) {
      this.getFeed(this.props.match.params.id);
    }
  }
  toggleSocialShare = () => {
    this.setState(prevState => ({
      socialShare: !prevState.socialShare
    }));
  };
  toggleCommentLoader = () => {
    this.setState(prevState => ({
      commentLoader: !prevState.commentLoader
    }));
  };
  updateTotalComments = totalComments => {
    this.setState({ totalComments: totalComments });
  };
  render() {
    const feed = this.state.content[0];
    return (
      <div>
        <ToastContainer />
        <header>
          <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark brand-header px-lg-5 px-md-5">
            <div className="container-fluid px-lg-5 pr-lg-0 nav-main">
              <a className="navbar-brand brand-logo" href="/dashboard">
                <img
                  src={require("../../../assets/images/logo.png")}
                  alt="Civ.Works"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarsExample07"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarsExample07">
                <div className="searching">
                  <form className="form-inline" action="" onSubmit={this.handleSubmit}>
                    <button className="brand-serach-btn" type="button">
                      <FontAwesomeIcon icon="search" className="mr-2" />
                    </button>
                    <input
                      className="form-control"
                      type="text"
                      name="search"
                      placeholder="Search for people or issues..."
                      value={this.state.keyword}
                      onChange={this.handleChange}
                    />
                  </form>
                </div>
                <ul className="navbar-nav ml-auto brand-nav-icon">
                  <li className="nav-item">
                    <div className="add-listing">
                      <a
                        href="https://www.networkforgood.org/donation/ExpressDonation.aspx?ORGID2=472336244&vlrStratCode=5Xfjw0ufyGCj%2bzy04mitD0cxyoxtwId8kSyZDRl6Vuj3lPgyQkcoyTi5FQJcK5vQ"
                        className="btn btn-success supporter" target="_blank"
                      >
                        Become a Supporter
                      </a>
                    </div>
                  </li>

                  <li className="nav-link user-toggle user-menu add-listing">
                    <Link to="/login" className="btn btn-danger" style={{ padding: `4px 22px` }}>Login</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="nav-scroller bg-dark shadow-sm brand-scroller"></div>
        </header>
        <section>
          <div className="container">
            <React.Fragment>
              <Helmet>
                <title> Feed Details : Civ Works</title>
              </Helmet>

              <Row className="mt-4">
                <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
                  <LeftMenu links={this.props.leftMenu} />
                </Col>
                <Col md={9} lg={10} className="col-12 pr-4 mt-3">
                  <div className="central-meta create-new-group-form item mb-2 pt-3">
                    {this.state.loader ? (
                      <div className="mt-4">
                        <SkeletonScreen />
                      </div>
                    ) : (
                      <Row className="multi-comment pb-3">
                        {this.state.NotFound ? (
                          <NotFound message="Feed not found." icon="card" />
                        ) : (
                          <React.Fragment>
                            <Col md={12} lg={12} className="col-12">
                              <Content
                                feed={feed}
                                key={`content-0`}
                                handleEditContentPopup={this.toggleEditPost}
                                feedIndex={0}
                              />
                            </Col>
                          </React.Fragment>
                        )}
                      </Row>
                    )}
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          </div>
        </section>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    feeds: state.feeds,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFeed: feeds => {
      dispatch(
        updateFeed({
          feeds
        })
      );
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FeedDetails);
