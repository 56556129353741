import React from "react";
import { Redirect } from "react-router-dom";
import store from "../store/store";
import { doLogout } from "../store/actions";

class Logout extends React.Component {
  state = {};
  componentDidMount() {
    localStorage.clear();
    store.dispatch(doLogout({}));
  }
  render() {
    return <Redirect to="/login" />;
  }
}

export default Logout;
