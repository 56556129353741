import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
import { Col } from "reactstrap";

class RightBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: ""
    };
  }
  handleChange = (e) => {
    let keyword = e.target.value;
    this.setState({ keyword });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.updateSearchFields(this.state.keyword);
  };
  render() {
    return (
      <React.Fragment>
        <Col md={12} className="px-0">
          <Link
            to="/friends/invite"
            className="btn btn-danger new-group-btn mt-3 mb-3"
          >
            <FontAwesomeIcon icon="plus" className="mr-1" />
            Invite People
          </Link>
        </Col>
        <div className="widgets widgets-coment">
          <div className="Navigation text-left mt-3 mb-3">
            <h4 className="Act text-left pl-3 mb-3 pt-2">
              <b>Navigation</b>
            </h4>
            <div className="menu-block">
              <ul>
                <li>
                  <NavLink
                    to="/friends"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Friends
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/friends/requests"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Friends Requests ({this.props.friendRequests.length})
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/friends/suggestions"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Suggested Friends
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/friends/collections"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Friends Collections
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="widgets widgets-coment">
          <div className="Navigation text-left mt-3 mb-3">
            <h4 className="Act text-left pl-3 mb-3 pt-2">
              <b>{this.props.searchText ? this.props.searchText : "Search"}</b>
            </h4>
            <form method="post" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control input-hashtag"
                  id="keyword"
                  id="keyword"
                  onChange={this.handleChange}
                  placeholder="Type here..."
                />
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapPropsToState = state => {
  return {
    friendRequests: state.friendRequests,
    baseUrl: state.baseUrl
  };
};

export default connect(mapPropsToState)(RightBox);
