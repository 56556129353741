import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Masonry from "react-masonry-component";

class Attachments extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let attachments = [];
    if (this.props.attachments !== "") {
      attachments = JSON.parse(this.props.attachments);
    }

    return (
      <React.Fragment>
        {attachments && attachments.length > 0 && (
          <Masonry
            className={"my-gallery-class"} // default ''
            elementType={"ul"} // default 'div'
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            id={`message-attachment-container-${Math.random()}`}
          >
            {attachments.map(attachment => {
              if (
                attachment.type === "jpg" ||
                attachment.type === "jpeg" ||
                attachment.type === "png" ||
                attachment.type === "gif"
              ) {
                return (
                  <li
                    className="image-element-class"
                    key={`attachment-${Math.random()}`}
                  >
                    <img
                      src={`${this.props.baseUrl}/images/${attachment.name}`}
                      style={{ width: 50 }}
                    />
                  </li>
                );
              } else if (attachment.type === "pdf") {
                return (
                  <li
                    key={`attachment-${Math.random()}`}
                    className="image-element-class"
                  >
                    <FontAwesomeIcon
                      icon="file-pdf"
                      size="3x"
                      style={{ width: 50, border: "1px solid #cccccc" }}
                      className="p-1"
                    />
                  </li>
                );
              }
            })}
          </Masonry>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId,
    token: state.token
  };
};

export default connect(mapStateToProps)(Attachments);
