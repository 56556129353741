import Home from "./views/Front/Home/Home";
import Profile from "./views/Front/Profile";
import Edit from "./views/Front/Profile/edit";
import Friends from "./views/Front/Friends";
import Invite from "./views/Front/Friends/Invite";
import Polls from "./views/Front/Poll";
import PollDetails from "./views/Front/Poll/Details";
import CreatePoll from "./views/Front/Poll/Create";
import Groups from "./views/Front/Group";
import CreateGroup from "./views/Front/Group/Create";
import Blogs from "./views/Front/Blog/Blogs";
import CreateBlog from "./views/Front/Blog/Create";
import GroupDetails from "./views/Front/Group/Details";
import InviteToGroup from "./views/Front/Group/Invite";
import Inbox from "./views/Front/Message";
import Read from "./views/Front/Message/Read";
import Reply from "./views/Front/Message/Reply";
import Compose from "./views/Front/Message/Compose";
import Suggestions from "./views/Front/Friends/Suggestions";
import Requests from "./views/Front/Friends/Requests";
import Collections from "./views/Front/Friends/Collections";
import Notifications from "./views/Front/Notifications";
import Actions from "./views/Front/Action";
import ActionDetails from "./views/Front/Action/ActionDetails";
import CreateAction from "./views/Front/Action/Create";
import ActionPreferences from "./views/Front/Action/ActionPreferences";
import ProfileSettings from "./views/Front/Account/ProfileSettings";
import ChangePassword from "./views/Front/Account/ChangePassword";
import NotificationSettings from "./views/Front/Account/NotificationSettings";
import News from "./views/Front/News";
import FeedDetails from "./views/Front/Feeds/Details";
import StaticContent from "./views/Front/StaticContent";
import MyRepresentatives from "./views/Front/Home/MyRepresentatives";
import Search from "./views/Front/Search";
import ActivityDashboard from "./views/Front/ActivityDashboard/ActivityDashboard";
import Connections from "./views/Front/Account/Connections";
import ActionsModal from "./views/Front/Account/ActionsModal";
import CustomizeFeeds from "./views/Front/Account/CustomizeFeeds";
import Subscriptions from "./views/Front/Account/Subscriptions";


const commonMenu = [];

const routes = [
  {
    protected: true,
    path: "/",
    exact: true,
    name: "Home",
    component: Home,
    leftMenu: commonMenu,
  },
  {
    protected: true,
    path: "/my-representatives",
    exact: true,
    name: "My Representatives",
    component: MyRepresentatives,
    leftMenu: commonMenu,
  },
  {
    protected: true,
    path: "/dashboard",
    exact: true,
    name: "Home",
    component: Home,
    leftMenu: commonMenu,
  },
  {
    path: "/profile/:username",
    exact: true,
    name: "Profile",
    component: Profile,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/profile/:username/feed-activity",
    exact: true,
    name: "Feed Activity",
    component: Profile,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/profile/:username/blog",
    exact: true,
    name: "User's Blog",
    component: Profile,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/profile/:username/actions",
    exact: true,
    name: "User's Actions",
    component: Profile,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/profile/:username/polls",
    exact: true,
    name: "User's Polls",
    component: Profile,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/profile/:username/edit",
    exact: true,
    name: "Edit Profile",
    component: Edit,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/notifications",
    exact: true,
    name: "Notifications",
    component: Notifications,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/friends",
    exact: true,
    name: "Friends",
    component: Friends,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/friends/suggestions",
    exact: true,
    name: "Friends",
    component: Suggestions,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/friends/requests",
    exact: true,
    name: "Friends",
    component: Requests,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/friends/collections",
    exact: true,
    name: "Friend Collections",
    component: Collections,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/friends/invite",
    exact: true,
    name: "InviteFriend",
    component: Invite,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/polls",
    exact: true,
    name: "Polls",
    component: Polls,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/polls/mine",
    exact: true,
    name: "Polls",
    component: Polls,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/polls/friends",
    exact: true,
    name: "Polls",
    component: Polls,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/polls/create",
    exact: true,
    name: "Create Poll",
    component: CreatePoll,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/polls/edit/:id",
    exact: true,
    name: "Update Poll",
    component: CreatePoll,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/polls/:id/details",
    exact: true,
    name: "Poll Details",
    component: PollDetails,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/news",
    exact: true,
    name: "News",
    component: News,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/groups",
    exact: true,
    name: "Groups",
    component: Groups,
    protected: true,
    leftMenu: commonMenu,
  },

  {
    path: "/activity",
    exact: true,
    name: "Activity",
    component: ActivityDashboard,
    protected: true,
    leftMenu: commonMenu,
  },

  {
    path: "/groups/public",
    exact: true,
    name: "Groups",
    component: Groups,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/groups/private",
    exact: true,
    name: "Groups",
    component: Groups,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/groups/my-group",
    exact: true,
    name: "Groups",
    component: Groups,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/groups/i-am-in",
    exact: true,
    name: "Groups",
    component: Groups,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/groups/invitations",
    exact: true,
    name: "Groups",
    component: Groups,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/actions/upcoming",
    exact: true,
    name: "Actions",
    component: Actions,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/actions",
    exact: true,
    name: "Actions",
    component: Actions,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/actions/group/:groupId",
    exact: true,
    name: "Actions",
    component: Actions,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/actions/completed",
    exact: true,
    name: "Actions",
    component: Actions,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/actions/following",
    exact: true,
    name: "Actions",
    component: Actions,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/actions/preferences",
    exact: true,
    name: "Actions",
    component: ActionPreferences,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/actions/create",
    exact: true,
    name: "Create Action",
    component: CreateAction,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/actions/group/create/:groupId",
    exact: true,
    name: "Create Action",
    component: CreateAction,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/actions/edit/:id",
    exact: true,
    name: "Edit Action",
    component: CreateAction,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/actions/details/:id",
    exact: true,
    name: "Action Details",
    component: ActionDetails,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/messages",
    exact: true,
    name: "Message",
    component: Inbox,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/messages/read/:id/:folder",
    exact: true,
    name: "Message",
    component: Read,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/messages/reply/:id",
    exact: true,
    name: "Message",
    component: Reply,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/messages/compose",
    exact: true,
    name: "Message",
    component: Compose,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/messages/compose/:username",
    exact: true,
    name: "Message",
    component: Compose,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/groups/create",
    exact: true,
    name: "Create Group",
    component: CreateGroup,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/groups/edit/:id",
    exact: true,
    name: "Edit Group",
    component: CreateGroup,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/groups/details/:id",
    exact: true,
    name: "Group Details",
    component: GroupDetails,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/groups/invite-users/:id",
    exact: true,
    name: "Invite Users",
    component: InviteToGroup,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/blogs",
    exact: true,
    name: "Blog",
    component: Blogs,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/blogs/create",
    exact: true,
    name: "Create Blog",
    component: CreateBlog,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/blogs/edit/:id",
    exact: true,
    name: "Edit Blog",
    component: CreateBlog,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/account/profile-settings",
    exact: true,
    name: "Profile Settings",
    component: ProfileSettings,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/account/change-password",
    exact: true,
    name: "Change Password",
    component: ChangePassword,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/account/notification-settings",
    exact: true,
    name: "Notification Settings",
    component: NotificationSettings,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/account/Connections",
    exact: true,
    name: "Connections",
    component: Connections,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/account/ActionModal",
    exact: true,
    name: "Actions",
    component: ActionsModal,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/account/CustomizeFeeds",
    exact: true,
    name: "Customize Feeds",
    component: CustomizeFeeds,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/account/Subscriptions",
    exact: true,
    name: "Subscriptions",
    component: Subscriptions,
    protected: true,
    leftMenu: commonMenu,
  },
  {
    path: "/feed/:id/details",
    exact: true,
    name: "Feed Details",
    component: FeedDetails,
    protected: false,
    leftMenu: commonMenu,
  },
  {
    path: "/page/:url",
    exact: true,
    name: "Static Content",
    component: StaticContent,
    protected: true,
    leftMenu: commonMenu,
  },
  { path: "/admin/dashboard", exact: true, name: "Admin" },
  {
    path: "/search/:keyword",
    exact: true,
    name: "Search",
    component: Search,
    protected: true,
    leftMenu: commonMenu,
  },
];

export default routes;
