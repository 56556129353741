import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Avtar = props => {
  return (
    <figure className={props.className}>
      {props.image !== null && props.image !== "null" ? (
        <img
          src={`${props.baseUrl}/images/${props.image_thumb ? props.image_thumb : props.image
            }`}
          alt={props.name}
          className="rounded-circle"
          /* width={props.size === "2x" ? 30 : 57} */
          width={props.width}
          style={{ height: "40px" }}
        />
      ) : (
        <FontAwesomeIcon
          icon="user-circle"
          size={props.size}
          title={props.name}
        />
      )}
    </figure>
  );
};

const mapPropsToState = state => {
  return {
    baseUrl: state.baseUrl
  };
};

export default connect(mapPropsToState)(Avtar);
