import React, { Component } from "react";
import action from "../../../services/action";
import { connect, dispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col, Card } from "reactstrap";
import SkeletonScreen from "../Common/SkeletonScreen";
import { toast } from "react-toastify";
import RightMenu from "./RightMenu";
import LeftMenu from "../Common/LeftMenu";
import Action from "./Action";
import { withRouter } from "react-router-dom";
import NotFound from "../Common/NotFound";

class Actions extends Component {
  state = {
    notFound: false,
    loader: true,
    actions: [],
    pageNo: 1,
    pageSize: 20,
    hasMore: false
  };
  getActions = path => {
    let type = "";
    if (path.search("upcoming") > 0) {
      type = "upcoming";
    } else if (path.search("past") > 0) {
      type = "past";
    } else if (path.search("following") > 0) {
      type = "following";
    }
    this.setState({ loader: true });
    let data = {
      type: type,
      page: this.state.pageNo,
      pageSize: this.state.pageSize,
      group_id: this.props.match.params.groupId
        ? this.props.match.params.groupId
        : 0
    };
    action.list(data).then(response => {
      if (response.data.success) {
        this.setState({
          loader: false,
          actions: response.data.actions,
          notFound: response.data.actions.length > 0 ? false : true
        });
      }
    });
  };

  componentDidMount = () => {
    this.getActions(this.props.location.pathname);
  };
  deleteAction = content_id => {
    if (window.confirm("Are you sure to delete this Action?")) {
      action.delete({ id: content_id }).then(response => {
        if (response.data.success) {
          let actions = this.state.actions.filter(
            action => action.content_id !== content_id
          );
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ actions });
        }
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Actions : Civ Works</title>
        </Helmet>
        <div className="row mt-4">
          <div className="col-md-3 col-lg-2 col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </div>
          <div className="col-md-6 col-12 col-lg-8 pr-4 mt-5 pt-lg-4">
            <Row className="mb-2">
              <Col md={12} lg={6} className="col-8">
                <h4 className="text-left status">Actions </h4>
              </Col>
            </Row>

            {this.state.loader ? (
              <div className="mt-4">
                <SkeletonScreen />
              </div>
            ) : (
              this.state.actions.length > 0 &&
              this.state.actions.map((action, index) => (
                <Action
                  action={action}
                  key={`action-index-${index}`}
                  deleteAction={this.deleteAction}
                  showIssues={true}
                  showRating={true}
                  editDelete={true}
                />
              ))
            )}
            {this.state.notFound && (
              <div className="user-post central-meta create-new-group-form item mb-2">
                <NotFound message="Action not found." />
              </div>
            )}
          </div>

          <div className="col-md-3 col-12 col-lg-2 right-section px-0 text-center mt-lg-4 pt-lg-4">
            <RightMenu />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl
  };
};

export default withRouter(connect(mapStateToProps)(Actions));
