import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Row, Col, Spinner, FormGroup, Label, Input } from "reactstrap";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.css";
import content from "../../../services/content";
import { updateFeed } from "../../../store/actions";
import { connect } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { toast } from "react-toastify";
import common from "../../../services/common";
import Avtar from "./Avtar";
import LoadingOverlay from "react-loading-overlay";
import TextareaAutosize from "react-autosize-textarea";
import StripePricingModal from "./StripePricingModal ";


class PostContentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contents_types: [
        { icon: "link", label: "link", position: 1, title: "Share Link" },
        {
          icon: "camera",
          label: "camera",
          position: 5,
          title: "Share Picture",
        },
        /* {
          icon: "paperclip",
          label: "paperclip",
          position: 2,
          title: "Tag your friend"
        }, */
        {
          icon: "map-marker-alt",
          label: "location",
          position: 3,
          title: "Share Location",
        },
        { icon: "tag", label: "tag", position: 4, title: "Tag your friend" },
      ],
      fields: { shared_as: "Public", link: this.props.url },
      errors: {},
      link: this.props.link ? this.props.link : false,
      camera: false,
      paperclip: false,
      location: false,
      tag: false,
      attachments: [],
      submitted: false,
      showMenu: "",
      showSubsriptionModel: false,
      showPricingTable: false,
      freeTrialState: false,
      email: ""
    };
    this.pricingTableContainer = React.createRef();
  }

  toogleContainer = (type) => {
    this.setState((prevState) => ({
      [type]: !prevState[type],
    }));
  };
  checkCallBack(file, progress) {
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.showSubsriptionModel === true) {

      this.setState({ showPricingTable: true });
    } else {
      let data = {
        ...this.state.fields,
        attachments: this.state.attachments,
        id: this.props.content_id ? this.props.content_id : "",
        group_id: this.props.groupId ? this.props.groupId : "",
      };

      if (
        !this.state.fields["text"] &&
        !this.state.fields["location"] &&
        !this.state.fields["link"] &&
        this.state.attachments.length === 0
      ) {
        toast.error("No information found to be shared!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        this.setState({
          submitted: true,
        });

        content.add(data).then((response) => {
          if (response.data.error == true) {
            return (
              toast.error(response.data.message),
              this.setState({
                fields: {},
                attachments: [],
                submitted: false,
                link: false,
                camera: false,
                paperclip: false,
                location: false,
                tag: false,
              })
            );
          }

          if (response.data.success) {
            if (response.data.response_id) {
              const callChangeImageStatus = () => {
                content
                  .ChangeImageStatus({
                    response_id: response.data.response_id,
                    content_id: response.data.content_id,
                  })
                  .then((changeResponse) => {
                    if (changeResponse.data.isSuccess !== true) {
                      setTimeout(callChangeImageStatus, 1000);
                    }
                    if (changeResponse.data.isAbusive == true) {
                      return (
                        toast.error(changeResponse.data.message),
                        setTimeout(() => {
                          window.location.reload()
                        })
                      );
                    }
                    if (changeResponse.data.isSuccess) {
                      return toast.success(changeResponse.data.message)
                    }
                  })
                  .catch((error) => { });
              };
              callChangeImageStatus();
            }

            if (data.id !== "") {
              let currentFeeds = this.props.currentFeeds;
              let feed_index = currentFeeds.findIndex((f) => f.id === data.id);
              currentFeeds[feed_index] = response.data.content;
              this.props.injectFeed(currentFeeds);
              if (this.props.toggleEditPost) {
                this.props.toggleEditPost();
              }

            } else {
              if (this.props.redux) {
                let feed;
                if (Array.isArray(this.props.currentFeeds)) {
                  feed = [response.data.content, ...this.props.currentFeeds];
                } else {
                  feed = [response.data.content];
                }
                this.props.appendFeed(feed);
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                if (this.props.toggleEditPost) {
                  this.props.toggleEditPost();
                }
              }
            }

            this.setState({
              fields: {},
              attachments: [],
              submitted: false,
              link: false,
              camera: false,
              paperclip: false,
              location: false,
              tag: false,
            });
            if (this.pond) {
              this.pond.removeFiles();
            }
          }
        });
      }
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleGoogleChange = (address) => {
    let fields = this.state.fields;
    fields["location"] = address;
    this.setState({ fields });
  };

  deleteImage = (file) => {
    if (window.confirm("Are you sure to delete this image?")) {
      let attachments = this.state.attachments.filter((f) => f !== file);
      this.setState({ attachments });
    }
  };


  getContent = (content_id) => {
    this.setState({
      overlay: true,
    });
    content.getOne({ id: content_id }).then((response) => {
      if (response.data.success) {
        let content = response.data.content;
        let attachments = [];
        if (content.attachments.length > 0) {
          attachments = content.attachments.map((file) => file.file_name);
        }
        let fields = {
          text: content.text !== "" ? content.text : "",
          link: content.link !== "" ? content.link : "",
          location: content.location !== "" ? content.location : "",
          sharedAs: content.shared_as !== "" ? content.shared_as : "Public",
        };
        this.setState({
          overlay: false,
          fields,
          attachments,
          location: content.location !== "" && true,
          link: content.link !== "" && true,
          camera: attachments.length > 0 && true,
          id: content_id,
        });
      }
    });
  };
  getSubscriptionStatus = () => {
    content.getSubsriptionStatus().then((response) => {
      if (response.data.success) {
        const isUserSubscription = response.data.showStripeModel
        const subscription = response.data.user;
        const today = new Date();
        const freeTrialEndDate = new Date(response.data.freeTrialEndDate)
        today.setHours(0, 0, 0, 0);
        if (subscription) {
          const emailData = response.data.user.email
          this.setState({ email: emailData })
        }
        this.setState({
          showSubsriptionModel: isUserSubscription,
        });
        if (today < freeTrialEndDate) {
          const hasVisitedBefore = sessionStorage.getItem('hasVisitedBefore');
          if (!hasVisitedBefore) {
            sessionStorage.setItem('hasVisitedBefore', 'true');
            toast.success("You are on trial period for 7 days")
          }
          this.setState({
            freeTrialState: true,
          });
        }


        // // Check if subscription exists and is not null
        // if (subscription && today > freeTrialEndDate) {
        //   const expiryDate = new Date(subscription.expiry_date);
        //   const today = new Date();
        //   today.setHours(0, 0, 0, 0); // Set the time to the start of the day

        //   if (subscription.status === "active" || expiryDate > today) {
        //     this.setState({
        //       showSubsriptionModel: true,
        //     });
        //   }
        // }
      }
    });
  };

  handleClose = () => {
    this.setState({ showPricingTable: false });
  };

  componentDidMount() {
    this.getSubscriptionStatus()
    if (this.props.content_id) {
      this.getContent(this.props.content_id);
    }
  }
  render() {
    const emaildemo = this.state.email
    return (
      <>
        <StripePricingModal
          show={this.state.showPricingTable}
          onClose={this.handleClose}
          customerEmail={emaildemo}
        />
        <div className="post-comment-box">
          <LoadingOverlay
            active={this.state.overlay}
            spinner={<Spinner color="dark" />}
            fadeSpeed={200}
            classNamePrefix="mitiz"
          >
            <form method="post" onSubmit={this.handleSubmit}>

              <Row>
                <Col md={2} lg={1}>
                  <Avtar
                    image={this.props.userImage}
                    image_thumb={this.props.userImageThumb}
                    size="3x"
                    className="mr-2"
                    width="50"
                  />
                </Col>
                <Col md={10} lg={11}>
                  <TextareaAutosize
                    placeholder={`Hi, ${common.ucwords(
                      this.props.uName ? this.props.uName : this.props.userName
                    )}! What's on your mind? ..`}
                    value={
                      this.state.fields["text"] ? this.state.fields["text"] : ""
                    }
                    onChange={(event) => this.handleChange("text", event)}
                    name="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={7} lg={6} className="col-7 mt-2">
                  <div className="file-post-link">
                    <ul>
                      {this.state.contents_types.map((content, index) => (
                        <li
                          key={index}
                          onClick={() => this.toogleContainer(content.label)}
                          title={content.title}
                        >
                          <FontAwesomeIcon icon={content.icon} className="mr-2" />
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
                <Col md={5} lg={6} className="col-5 px-0 text-right">
                  <Input
                    style={{ width: 100 }}
                    type="select"
                    name="shared_as"
                    id="shared_as"
                    onChange={(event) => this.handleChange("shared_as", event)}
                    value={
                      this.state.fields["shared_as"]
                        ? this.state.fields["shared_as"]
                        : ""
                    }
                    className="pt-1 mr-2 d-inline"
                  >
                    <option value="Public">Public</option>
                    {this.props.groupId && <option value="Group">Group</option>}
                    <option value="Friends">Friends</option>
                    <option value="Private">Private</option>
                  </Input>

                  <button
                    type="submit"
                    className="btn btn-success post-comment"
                    disabled={this.state.submitted || this.props.disablePost}
                  >
                    {this.state.submitted && (
                      <FontAwesomeIcon
                        icon="spinner"
                        className="mr-1"
                        spin={true}
                      />
                    )}
                    Post
                  </button>
                </Col>
              </Row>

              {(this.state.link ||
                this.state.camera ||
                this.state.location ||
                this.state.tag) && (
                  <Row>
                    {this.state.link && (
                      <Col md={6} className="mt-3 pl-0 padding-modal">
                        <Card className="p-3 mb-0">
                          <div className="form-group">
                            <label htmlFor="link">
                              <strong>Share a link</strong>
                            </label>
                            <input
                              type="url"
                              className="form-control"
                              id="link"
                              value={
                                this.state.fields["link"]
                                  ? this.state.fields["link"]
                                  : ""
                              }
                              onChange={(event) => this.handleChange("link", event)}
                              name="link"
                            />
                          </div>
                        </Card>
                      </Col>
                    )}
                    {this.state.camera && (
                      <Col md={12} className="mt-3 pl-0 padding-modal">
                        <Card className="pt-3 pl-3 pr-3 pb-0 mb-0">
                          <div className="form-group">
                            <label htmlFor="email">
                              <strong>Share a photo</strong>
                            </label>
                            {(this.props.id !== "" &&
                              this.state.attachments.length) > 0 && (
                                <Row>
                                  {this.state.attachments.map((file, d) => (
                                    <Col
                                      md={4}
                                      style={{ position: "relative" }}
                                      key={`dk-${d}`}
                                    >
                                      <img
                                        src={`${this.props.baseUrl}/images${file.indexOf("temp") >= 0 ? "/temp" : ""
                                          }/${file}`}
                                        width="150"
                                        height="100"
                                        className="mb-2"
                                      />
                                      <FontAwesomeIcon
                                        icon="times"
                                        style={{
                                          position: "absolute",
                                          top: `-9px`,
                                          right: `50px`,
                                          width: `27px`,
                                          height: `27px`,
                                          cursor: `pointer`,
                                          zIndex: 8040,
                                          padding: 5,
                                          backgroundColor: `red`,
                                          color: `#FFF`,
                                          borderRadius: 18,
                                        }}
                                        onClick={() => this.deleteImage(file)}
                                      />
                                    </Col>
                                  ))}
                                </Row>
                              )}
                            <FilePond
                              allowMultiple={true}
                              allowRemove={true}
                              ref={(ref) => (this.pond = ref)}
                              server={{
                                url: this.props.apiUrl,
                                process: {
                                  url: "/content/upload",
                                  headers: {
                                    "X-Api-Key": `Bearer  ${this.props.token}`,
                                  },
                                  onload: (response) => {
                                    let response_josn = JSON.parse(response);
                                    if (response_josn.success) {
                                      let attachments = [
                                        ...this.state.attachments,
                                        response_josn.file_name,
                                      ];
                                      this.setState({
                                        attachments: attachments,
                                      });
                                      return response_josn.file_name;
                                    }
                                  },
                                },
                                revert: {
                                  url: "/content/delete-image",
                                  headers: {
                                    "X-Api-Key": `Bearer  ${this.props.token}`,
                                    //file_name: files
                                  },
                                  onload: (response) => {
                                    //console.log(response);
                                  },
                                },
                              }}
                              onprocessfileprogress={(file, progress) =>
                                this.checkCallBack(file, progress)
                              }
                              onprocessfilerevert={(file) => {
                                let uploaded_files = this.state.attachments.filter(
                                  (attachment) => attachment != file.serverId
                                );
                                this.setState({
                                  attachments: uploaded_files,
                                });
                              }}
                            />
                          </div>
                        </Card>
                      </Col>
                    )}
                    {this.state.location && (
                      <Col md={6} className="mt-3 pl-0 padding-modal">
                        <Card className="p-3 mb-0">
                          <div className="form-group">
                            <label htmlFor="email">
                              <strong>Add location</strong>
                            </label>
                            <PlacesAutocomplete
                              value={
                                this.state.fields["location"]
                                  ? this.state.fields["location"]
                                  : ""
                              }
                              onChange={this.handleGoogleChange}
                              onSelect={this.handleGoogleChange}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Search Places ...",
                                      className:
                                        "location-search-input form-control",
                                    })}
                                  />
                                  <div
                                    className="autocomplete-dropdown-container"
                                    style={{
                                      position: "absolute",
                                      zIndex: 9999999,
                                      marginTop: 10,
                                    }}
                                  >
                                    {loading && (
                                      <div style={{ marginTop: 5, padding: 5 }}>
                                        Loading...
                                      </div>
                                    )}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                          padding: 10,
                                        }
                                        : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                          padding: 10,
                                        };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </Card>
                      </Col>
                    )}
                    {this.state.tag && (
                      <Col md={6} className="mt-3 pl-0 padding-modal">
                        <Card className="p-3 mb-0">
                          <div className="form-group">
                            <label htmlFor="email">
                              <strong>Tag friends</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                            />
                          </div>
                        </Card>
                      </Col>
                    )}
                    <Col className="text-right mt-3" md={12}></Col>
                  </Row>
                )}
            </form>
          </LoadingOverlay>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentFeeds: state.feeds,
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    token: state.token,
    userImage: state.userImage,
    userImageThumb: state.userImageThumb,
    userName: state.userName,
    uName: state.uName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    appendFeed: (feeds) => {
      dispatch(
        updateFeed({
          feeds,
        })
      );
    },
    injectFeed: (feeds) => {
      dispatch(
        updateFeed({
          feeds,
        })
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PostContentForm);
