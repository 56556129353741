import React, { Component } from "react";

const withTag = WrappedComponent => {
  class WidthTag extends Component {
    constructor(props) {
      super(props);
      this.state = {
        tags: [],
        id: this.props.match.params.id ? this.props.match.params.id : ""
      };
    }
    handleTagChange = tags => {
      this.setState({ tags });
    };
    handleDelete(i) {
      const tags = this.state.tags.slice(0);
      tags.splice(i, 1);
      this.setState({ tags });
    }
    handleAddition(tag) {
      const tags = [].concat(this.state.tags, tag);
      this.setState({ tags });
    }
    handleUpdateTags = tags => {
      this.setState({ tags });
    };
    render() {
      return (
        <WrappedComponent
          handleTagChange={this.handleTagChange}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          tags={this.state.tags}
          id={this.state.id}
          updateTagsInHoc={this.handleUpdateTags}
          redirect={this.props.history}
          leftMenu={this.props.leftMenu}
        />
      );
    }
  }
  return WidthTag;
};
export default withTag;
