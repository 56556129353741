import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import user from "../../../services/user";
import SkeletonScreen from "../Common/SkeletonScreen";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import NotFound from "../Common/NotFound";
import LeftMenu from "../Common/LeftMenu";
import Suggestion from "./Suggestion";
import RightBox from "./RightBox";

class Suggestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      users: [],
      loader: true,
      notFound: false,
      pageNo: 1,
      pageSize: 10,
      hasMore: false,
      search: false,
      searchText: "Search"
    };
  }
  handleScroll = e => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    //console.log(windowBottom, docHeight);
    if (windowBottom >= docHeight) {
      if (this.state.hasMore) {
        this.getUsers();
      }
    }
  };
  componentDidMount() {
    this.getUsers();
    window.addEventListener("scroll", this.handleScroll);
  }
  getUsers = () => {
    if (this.state.pageNo > 1) {
      this.setState({ loader: true });
    }
    let data = { keyword: this.state.keyword, page: this.state.pageNo, pageSize: this.state.pageSize };
    user.getUsers(data).then(response => {
      if (response.data.success) {
        let users = [];
        let searchText = "Search";
        if (this.state.search) {
          users = response.data.users;
        }
        else {
          users = [...this.state.users, ...response.data.users];
        }
        this.setState({
          users,
          loader: false,
          search: false,
          searchText
        });
        if (
          response.data.users.length > 0 &&
          this.state.pageNo < response.data.pageCount
        ) {
          this.setState(prevState => ({
            hasMore: true,
            pageNo: prevState.pageNo + 1
          }));
        } else {
          this.setState({ hasMore: false });
        }
      }
    });
  };
  updateSearchFields = (keyword) => {
    this.setState({ keyword, search: true, searchText: "Searching..." }, () => {
      this.getUsers();
    });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Suggested Friends : Civ Works</title>
        </Helmet>
        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={6} lg={8} className="col-12 mt-5 pt-lg-4">
            <Row>
              <Col md={12} lg={12} className="col-12">
                <h4 className="status"> Friends </h4>
                <div
                  className="central-meta create-new-group-form mb-2"
                  style={{ minHeight: 500 }}
                >
                  <Row>
                    <Col md={12} lg={12} className="col-12">
                      <h5 className="mb-4">Suggested Friends</h5>
                    </Col>
                  </Row>
                  {this.state.users.length > 0 &&
                    this.state.users.map((user, indx) => (
                      <Suggestion
                        key={`friends-key-${indx}`}
                        user={user}
                        users={this.state.users}
                        index={indx}
                        sendRequestButton={true}
                      />
                    ))}
                  {this.state.notFound && (
                    <NotFound message="Not friends found." />
                  )}
                  {this.state.loader && (
                    <div className="mt-4">
                      <SkeletonScreen height={100} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            md={3}
            lg={2}
            className="col-12 right-section px-0 text-center mt-lg-4 pt-lg-4"
          >
            <RightBox
              updateSearchFields={this.updateSearchFields}
              searchText={this.state.searchText}
              searchRecord={this.getUsers} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(Suggestions);
