import React, { Component } from "react";
import group from "../../../services/group";
import common from "../../../services/common";
import { Row, Col, Form, FormGroup, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import ReactSummernote from "react-summernote";

class InviteEmails extends Component {
  state = {
    tags: [],
    note: "",
    submitted: false
  };
  handleTagChange = tags => {
    if (common.isValidEmail(tags[tags.length - 1])) {
      this.setState({ tags });
    } else {
      toast.error("Please enter valid email address.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };
  handleDelete = i => {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  };
  handleTextArea = note => {
    this.setState({ note });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.tags.length === 0) {
      toast.error("Please enter email addresses to invite.", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      this.setState({ submitted: true });
      const params = {
        emails: this.state.tags,
        note: this.state.note,
        group_id: this.props.groupId
      };
      group.inviteUserEmails(params).then(response => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ submitted: false, note: "", tags: [] });
          this.props.closeModal();
        } else if (response.data.error) {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ submitted: false });
        }
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <Form method="post" onSubmit={this.handleSubmit}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="tags">Enter valid e-mail addresses</Label>
                <TagsInput
                  value={this.state.tags}
                  onChange={this.handleTagChange}
                  onlyUnique={true}
                  inputProps={{ placeholder: "Email Address" }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="message">Personal Note</Label>
                <ReactSummernote
                  options={{
                    height: 150,
                    dialogsInBody: true,
                    toolbar: [
                      ["style", ["style"]],
                      ["font", ["bold", "underline", "clear"]],
                      ["fontname", ["fontname"]],
                      ["para", ["ul", "ol", "paragraph"]],
                      ["table", ["table"]],
                      /* ["insert", ["link", "picture", "video"]], */
                      ["view", ["fullscreen", "codeview"]]
                    ]
                  }}
                  value={this.state.note}
                  onChange={this.handleTextArea}
                />
              </FormGroup>
            </Col>
            <Col md={12} className="text-right">
              <button
                type="button"
                className="btn btn-outline-secondary mr-2"
                onClick={this.props.closeModal}
                disabled={this.state.submitted}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                disabled={this.state.submitted}
              >
                {this.state.submitted && (
                  <FontAwesomeIcon
                    icon="spinner"
                    className="mr-1"
                    spin={true}
                  />
                )}
                Invite
              </button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default InviteEmails;
