import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import PostContentForm from "../Common/PostContentForm";
import Feeds from "../Feeds";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import LeftMenu from "../Common/LeftMenu";
import RightBox from "./RightBox";

class Home extends Component {
  render() {
    if (this.props.userType == 1) {
      return <Redirect to="/admin/dashboard" />;
    } else if (this.props.userType == 2) {
      return (
        <React.Fragment>
          <Helmet>
            <title>Dashboard : Civ Works</title>
          </Helmet>
          <div className="row mt-4">
            <div className="col-md-3 col-lg-2 col-12 pl-sm-0 pl-lg-2">
              <LeftMenu links={this.props.leftMenu} />
            </div>
            <div className="col-md-6 col-12 col-lg-8 pr-4 mt-3">
              <PostContentForm width="956" redux={true} />
              <Feeds />
            </div>

            <div className="col-md-3 col-12 col-lg-2 mt-3 right-section px-0 text-center mt-3">
              <RightBox />
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <Redirect to={{ pathname: "/login" }} />;
    }
  }
}

const mapStateToProps = state => {
  return {
    userType: state.userType,
  };
};
export default connect(mapStateToProps)(Home);
