import React, { Component } from "react";
import group from "../../../services/group";
import { Helmet } from "react-helmet";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalBody
} from "reactstrap";
import classnames from "classnames";
import InviteEmails from "./InviteEmails";
import InviteUsers from "./InviteUsers";
import InviteFriends from "./InviteFriends";

class InviteToGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "3",
      group: {},
      fields: {},
      errors: {},
      notFound: false
    };
  }

  getGroup = id => {
    group.getOne({ id: id }).then(response => {
      if (response.data.success) {
        this.setState({
          group: response.data.group
        });
      } else if (response.data.error) {
        this.setState({
          notFound: true
        });
      }
    });
  };

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };
  componentDidMount() {
    if (this.props.groupId) {
      this.getGroup(this.props.groupId);
    }
  }

  render() {
    const { group } = this.state;
    return (
      <Modal isOpen={this.props.showModal} className="custom-model" size="lg">
        <ModalBody style={{ padding: 2 }}>
          <Row>
            <Col md={12}>
              <h2>Invite users to {group.name ? group.name : ""}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1"
                    })}
                    onClick={() => this.toggleTab("1")}
                  >
                    Friends
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2"
                    })}
                    onClick={() => this.toggleTab("2")}
                  >
                    Find Users
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "3"
                    })}
                    onClick={() => this.toggleTab("3")}
                  >
                    User e-mail address
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {this.state.activeTab == "1" && (
                    <InviteFriends
                      groupId={this.props.groupId}
                      closeModal={this.props.closeModal}
                    ></InviteFriends>
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {this.state.activeTab == "2" && (
                    <InviteUsers
                      groupId={this.props.groupId}
                      closeModal={this.props.closeModal}
                    ></InviteUsers>
                  )}
                </TabPane>
                <TabPane tabId="3">
                  <InviteEmails
                    groupId={this.props.groupId}
                    closeModal={this.props.closeModal}
                  ></InviteEmails>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default InviteToGroup;
