import React, { Component } from "react";
import group from "../../../services/group";
import { connect, dispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col, Card } from "reactstrap";
import SkeletonScreen from "../Common/SkeletonScreen";
import { toast } from "react-toastify";
import ListRightBox from "./ListRightBox";
import LeftMenu from "../Common/LeftMenu";
import Group from "./Group";
import { withRouter } from "react-router-dom";
import NotFound from "../Common/NotFound";

class Groups extends Component {
  state = {
    notFound: false,
    loader: true,
    groups: [],
    pageNo: 1,
    pageSize: 20,
    hasMore: false,
    selectedSort: "Show all"
  };
  getGroups = (type, sort = "") => {
    this.setState({ loader: true });
    if (type.search("public") > 0) {
      type = "public";
    } else if (type.search("private") > 0) {
      type = "private";
    } else if (type.search("my-group") > 0) {
      type = "my-group";
    } else if (type.search("i-am-in") > 0) {
      type = "i-am-in";
    } else if (type.search("invitations") > 0) {
      type = "invitations";
    }

    let data = {
      type: type,
      page: this.state.pageNo,
      pageSize: this.state.pageSize,
      sort: sort
    };
    group.list(data).then(response => {
      if (response.data.success) {
        this.setState({
          loader: false,
          groups: response.data.contents,
          notFound: response.data.contents.length > 0 ? false : true
        });
      }
    });
  };

  sortGroup = e => {
    let sort;
    if (e.target.innerHTML === "A-Z") {
      sort = "name";
    } else if (e.target.innerHTML === "Newest") {
      sort = "added_on";
    } else if (e.target.innerHTML == "Show all") {
      this.props.history.push("/groups");
    }
    this.setState({ selectedSort: e.target.innerHTML });
    this.getGroups(this.props.location.pathname, sort);
  };

  componentDidMount = () => {
    this.getGroups(this.props.location.pathname);
  };
  deleteGroup = id => {
    if (window.confirm("Are you sure to delete this Group?")) {
      group.delete({ id: id }).then(response => {
        if (response.data.success) {
          let groups = this.state.groups.filter(group => group.id !== id);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ groups });
        }
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Groups : Civ Works</title>
        </Helmet>
        <div className="row mt-4">
          <div className="col-md-3 col-lg-2 col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </div>
          <div className="col-md-6 col-12 col-lg-8 pr-4 mt-5 pt-lg-4">
            <Row className="mb-2">
              <Col md={8} lg={6} className="col-8">
                <h4 className="text-left status">Groups </h4>
              </Col>
              <Col md={4} lg={6} className="col-4 text-right px-0">
                <div className="btn-group mr-2">
                  <button
                    type="button"
                    className="btn btn-light dropdown-toggle show-all-btn"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {this.state.selectedSort}
                  </button>
                  <div className="dropdown-menu group-list">
                    <span
                      className="dropdown-item"
                      onClick={this.sortGroup}
                      style={{ cursor: "pointer" }}
                    >
                      Show all
                    </span>
                    <span
                      className="dropdown-item"
                      onClick={this.sortGroup}
                      style={{ cursor: "pointer" }}
                    >
                      Newest
                    </span>
                    <span
                      className="dropdown-item"
                      onClick={this.sortGroup}
                      style={{ cursor: "pointer" }}
                    >
                      A-Z
                    </span>
                    <span
                      className="dropdown-item"
                      onClick={this.sortGroup}
                      style={{ cursor: "pointer" }}
                    >
                      Popular
                    </span>
                  </div>
                </div>
              </Col>
            </Row>

            {this.state.loader ? (
              <div className="mt-4">
                <SkeletonScreen />
              </div>
            ) : (
              this.state.groups.length > 0 &&
              this.state.groups.map((group, index) => (
                <Group
                  group={group}
                  key={`group-index-${index}`}
                  deleteGroup={this.deleteGroup}
                />
              ))
            )}
            {this.state.notFound && (
              <div className="user-post central-meta create-new-group-form item mb-2">
                <NotFound message="Group not found." />
              </div>
            )}
          </div>

          <div className="col-md-3 col-12 col-lg-2 right-section px-0 text-center mt-lg-4 pt-lg-4">
            <ListRightBox />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl
  };
};

export default withRouter(connect(mapStateToProps)(Groups));
