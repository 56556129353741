import React, { Component } from "react";
import action from "../../../services/action";
import common from "../../../services/common";
import { connect, dispatch } from "react-redux";
//import { Row, Col, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

class Follow extends Component {
  state = {
    submitted: false,
    followButton: false,
    unfollowButton: false
  };
  componentDidMount = () => {
    if (this.props.action.followers) {
      if (this.props.action.followers.length === 0) {
        this.setState({ followButton: true, unfollowButton: false });
      } else if (this.props.action.followers.length > 0) {
        let didIFollow = this.props.action.followers.filter(
          follower => parseInt(follower.user_id) === parseInt(this.props.userId)
        );
        if (didIFollow.length > 0) {
          this.setState({ followButton: false, unfollowButton: true });
        }
      }
    }
  };
  follow = () => {
    this.setState({ submitted: true });
    action.follow({ id: this.props.action.id }).then(response => {
      if (response.data.success) {
        this.setState({
          submitted: false,
          followButton: false,
          unfollowButton: true
        });
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        this.setState({
          submitted: false
        });
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };
  unFollow = () => {
    this.setState({ submitted: true });
    action.unFollow({ id: this.props.action.id }).then(response => {
      if (response.data.success) {
        this.setState({
          submitted: false,
          followButton: true,
          unfollowButton: false
        });
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        this.setState({
          submitted: false
        });
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };
  render() {
    return (
      <React.Fragment>
        {this.state.followButton && (
          <button
            type="button"
            className={`btn btn-outline-primary ${this.props.small &&
              "btn-sm"}`}
            disabled={this.state.submitted}
            onClick={this.follow}
          >
            {this.state.submitted && (
              <FontAwesomeIcon icon="spinner" className="mr-1" spin={true} />
            )}
            Follow
          </button>
        )}
        {this.state.unfollowButton && (
          <button
            type="button"
            className={`btn btn-outline-secondary ${this.props.small &&
              "btn-sm"}`}
            disabled={this.state.submitted}
            onClick={this.unFollow}
          >
            {this.state.submitted && (
              <FontAwesomeIcon icon="spinner" className="mr-1" spin={true} />
            )}
            Un-Follow
          </button>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId
  };
};

export default connect(mapStateToProps)(Follow);
