import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import user from "../../../services/user";
import news from "../../../services/news";
import poll from "../../../services/poll";
import page from "../../../services/page";
import action from "../../../services/action";
import common from "../../../services/common";
import User from "./User";
import Poll from "../Poll/Poll";
import { Carousel, CarouselItem, CarouselControl, Spinner } from "reactstrap";
import NewsSlider from "./NewsSlider";

class RightBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      news: [],
      polls: [],
      pageNo: 1,
      pageSize: 4,
      hasMore: false,
      loader: false,
      actions: [],
      activeIndex: 0,
      animating: false,
      announcement: ""
    };
  }
  getUsers = () => {
    if (this.state.pageNo > 1) {
      this.setState({ loader: true });
    }
    let data = { page: this.state.pageNo, pageSize: this.state.pageSize };
    user.getUsers(data).then(response => {
      if (response.data.success) {
        this.setState({
          users: response.data.users,
          loader: false
        });
        if (
          response.data.users.length > 0 &&
          this.state.pageNo < response.data.pageCount
        ) {
          this.setState(prevState => ({
            hasMore: true,
            pageNo: prevState.pageNo + 1
          }));
        } else {
          this.setState({ hasMore: false });
        }
      }
    });
  };
  updateUser = users => {
    this.setState({ users });
    if (users.length === 1 && this.state.hasMore) {
      this.getUsers();
    }
  };
  getPoll = () => {
    let data = { page: 1, pageSize: 1, featured: "Y" };
    poll.pollsToVote(data).then(response => {
      if (response.data.success) {
        this.setState({
          polls: response.data.polls
        });
      }
    });
  };
  componentDidMount() {
    this.getUsers();
    this.getActions();
    this.getPoll();
    this.getAnnouncement();
  }
  getActions = () => {
    let data = {
      featured: 1,
      page: this.state.pageNo,
      pageSize: this.state.pageSize
    };
    action.list(data).then(response => {
      if (response.data.success) {
        this.setState({
          actions: response.data.actions
        });
      }
    });
  };
  getAnnouncement = () => {
    page.getOneByName({ url: "announcement" }).then(response => {
      if (response.data.success) {
        this.setState({ announcement: response.data.content.content });
      }
    });
  };
  render() {
    return (
      <div className="widgets widgets-coment">
        {/* <div className="sd-title">
          <h6 className="widgets-title text-left">Action Points</h6>
          <h6 className="text-danger count-num">150</h6>
          <h6 className="Profile-alert mt-3">Complete Your Profile</h6>
          <div className="progress" style={{ height: 8, borderRadius: 0 }}>
            <div
              className="progress-bar bg-success complete-profile"
              style={{ width: 30 + "%", height: 8, bordeRadius: 0 }}
            ></div>
          </div>
          <button
            type="button"
            className="btn btn-link action-history mt-3 mb-3"
          >
            View action history
            <i className="fas fa-angle-right"></i>
          </button>
        </div> */}

        {this.state.actions.length > 0 && (
          <React.Fragment>
            <div className="sd-title act-now Act-method pt-4">
              <h6 className="text-left Act">Act Now!</h6>
              <FontAwesomeIcon icon="bullhorn" size="sm" />
              {this.state.actions.map(action => (
                <React.Fragment key={`action-key-${action.id}`}>
                  <h6 className="text-left">
                    <Link to={`/actions/details/${action.id}`}>
                      {action.title}
                    </Link>
                  </h6>
                  <p className="text-left">
                    Ends in {common.dateDiff(action.end_on)} days
                  </p>
                </React.Fragment>
              ))}
              <Link to="/actions" className="btn btn-link action-history mt-3">
                View More & take acton
              </Link>
            </div>
          </React.Fragment>
        )}

        <div className="sd-title">
          <h4 className="Act text-left mb-3">My Electeds</h4>
          <p className="text-left Electeds">
            Find contact information for your elected officials.
          </p>
          <button type="button" className="btn btn-link action-history mt-1">
            <Link to="/my-representatives">Get info </Link>
          </button>
        </div>
        <div className="sd-title Announcements-links text-left mt-3 mb-3 Electeds">
          <h4 className="Act text-left">Announcements</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.announcement
            }}
          /* style={{ fontSize: 13 }} */
          />
        </div>
        <div className="sd-title text-left">
          <h4 className="Act mb-3">Take A Poll</h4>
          {this.state.polls.length > 0 &&
            this.state.polls.map(poll => (
              <Poll poll={poll} key={`poll-key-${Math.random(1)}`} />
            ))}
        </div>
        <NewsSlider />
        {this.state.users.length > 0 && (
          <div className="sd-title text-left">
            <h4 className="Act">Let's Be Friends</h4>
            {this.state.users.map((user, index) => (
              <User
                key={`user-key-${index}`}
                user={user}
                index={index}
                users={this.state.users}
                updateUser={this.updateUser}
              />
            ))}
            {this.state.hasMore && (
              <button
                type="button"
                className="btn btn-link action-history mt-1 mb-2"
                onClick={this.getUsers}
              >
                {this.state.loader ? (
                  <FontAwesomeIcon
                    icon="spinner"
                    className="mr-1"
                    spin={true}
                  />
                ) : (
                  "see more"
                )}
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapPropsToState = state => {
  return {
    baseUrl: state.baseUrl
  };
};

export default connect(mapPropsToState)(RightBox);
