import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Card, CardHeader, Spinner, CardText } from "reactstrap";
import LeftMenu from "../Common/LeftMenu";
import { getActivity } from "../../../store/actions";
import content from "../../../services/content";

class ActivityDashboard extends Component {
  state = {
    loader: true,
    activityContentData: {},
    pageNo: 1,
    pageSize: 20,
  };


  getActivityDashboard = (e) => {
    this.setState({ loader: true });
    let data = {
      page: this.state.pageNo,
      pageSize: this.state.pageSize,
    };
    content
      .getActivityData(data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ loader: false, activityContentData: response.data });
        }
      })
      .catch((error) => {
        console.error("Error fetching activity data:", error);
        this.setState({ loader: false });
      });
  };

  componentDidMount = () => {
    this.getActivityDashboard(this.props.location.pathname);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Activity : Civ Works</title>
        </Helmet>
        <div className="row mt-4">
          <div className="col-md-3 col-lg-2 col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </div>
          <div className="col-md-6 col-12 col-lg-8 pr-4 mt-5 pt-lg-4">
            <div className="heading-wrapper">
              <h4 className="text-left status mb-2">Activity Dashboard </h4>
            </div>
            {this.state.loader ? (
              <div className="activity_loading">
                <Spinner
                  color="dark"
                  size="lg"
                  style={{ marginTop: 45 }}
                >
                  Loading...
                </Spinner>
              </div>
            ) : (
              <div
                className="d-flex align-items-center wrapper-cards flex-wrap"
                style={{ gap: "10px" }}
              >
                <div className="row w-100">
                  <div className="col-md-6">
                    <div className="card" style={{ width: "100%" }}>
                      <div className="card-body">
                        <h5 className="card-title">Total Post</h5>
                        <p className="card-text font-weight-bolder" style={{ fontSize: "30px" }}>
                          {this.state.activityContentData && this.state.activityContentData.data.postCount}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card" style={{ width: "100%" }}>
                      <div className="card-body">
                        <h5 className="card-title">Total Likes</h5>
                        <p className="card-text font-weight-bolder" style={{ fontSize: "30px" }}>
                          {this.state.activityContentData.data.likeCount}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-6">
                    <div className="card" style={{ width: "100%" }}>
                      <div className="card-body">
                        <h5 className="card-title">Total Comments</h5>
                        <p className="card-text font-weight-bolder" style={{ fontSize: "30px" }}>
                          {this.state.activityContentData.data.commentCount}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card" style={{ width: "100%" }}>
                      <div className="card-body">
                        <h5 className="card-title"> Friends Connections</h5>
                        <p className="card-text font-weight-bolder" style={{ fontSize: "30px" }}>
                          {this.state.activityContentData.data.friendsConnection}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-6">
                    <div className="card" style={{ width: "100%" }}>
                      <div className="card-body">
                        <h5 className="card-title">Friends Invites</h5>
                        <p className="card-text font-weight-bolder" style={{ fontSize: "30px" }}>
                          {this.state.activityContentData.data.friendsInvites}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card" style={{ width: "100%" }}>
                      <div className="card-body">
                        <h5 className="card-title">Civic Actions</h5>
                        <p className="card-text font-weight-bolder" style={{ fontSize: "30px" }}>
                          {this.state.activityContentData.data.civicActions}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-6">
                    <div className="card" style={{ width: "100%" }}>
                      <div className="card-body">
                        <h5 className="card-title">Civic Score</h5>
                        <p className="card-text font-weight-bolder" style={{ fontSize: "30px" }}>
                          {this.state.activityContentData.data.civicScore}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card" style={{ width: "100%" }}>
                      <div className="card-body">
                        <h5 className="card-title">Total Likes Received</h5>
                        <p className="card-text font-weight-bolder" style={{ fontSize: "30px" }}>
                          {this.state.activityContentData.data.likeCountReceived}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-md-6">
                    <div className="card" style={{ width: "100%" }}>
                      <div className="card-body">
                        <h5 className="card-title">Total Likes received per Week</h5>
                        <p className="card-text font-weight-bolder" style={{ fontSize: "30px" }}>
                          {this.state.activityContentData.data.likeCountReceivedPerWeek}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state, "mapStateToProps");
  return {
    baseUrl: state.baseUrl,
    activityContent: state.activityContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivity: (activityContent) => {
      dispatch(
        getActivity({
          activityContent,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDashboard);
