import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import action from "../../../services/action";

class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLogout = () => {
    const userId = localStorage.getItem("userId")
    console.log(userId)
    action.logOut({ id: userId }).then(response => {
      if (response.data.success) {
        console.log("Logged out successfully");
        // Additional logout logic (clearing local storage, redirecting, etc.)
      }
    }).catch(error => {
      console.error("Logout failed:", error);
      // Handle logout error (show message, retry, etc.)
    });
  };

  render() {
    return (
      <aside className="brand-side-menu">
        <div className="widget">
          <Scrollbars style={{ height: 500 }} autoHide={true}>
            <ul className="naves" style={{ paddingLeft: 0 }}>
              {this.props.sideMenu.map((menu, index) => (
                <li key={`left-menu-list-${index}`}>
                  {menu.new_window === "Y" ? (
                    <a href={`${menu.url}`} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={menu.icon} className="mr-2" size="lg" />
                      {menu.title}
                    </a>
                  ) : (
                    <NavLink to={menu.url} activeClassName="active">
                      <FontAwesomeIcon icon={menu.icon} className="mr-2" size="lg" />
                      {menu.title}
                    </NavLink>
                  )}
                </li>
              ))}
              <li key={`left-menu-list-settings`}>
                <NavLink to="/account/profile-settings" activeClassName="active">
                  <FontAwesomeIcon icon="chart-bar" className="mr-2" size="lg" />
                  Settings
                </NavLink>
              </li>
              <li key={`left-menu-list-logout`}>
                <NavLink to="/logout" activeClassName="active" onClick={this.handleLogout}>
                  <FontAwesomeIcon icon="sign-out-alt" className="mr-2" size="lg" />
                  Logout
                </NavLink>
              </li>
            </ul>
          </Scrollbars>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = state => ({
  baseUrl: state.baseUrl,
  sideMenu: state.sideMenu
});

export default connect(mapStateToProps)(LeftMenu);
