import React, { Component } from "react";
import blog from "../../../services/blog";
import common from "../../../services/common";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form
} from "reactstrap";
import "react-summernote/dist/react-summernote.css";
import "bootstrap/js/src/tooltip";
import "bootstrap/js/src/modal";
import "bootstrap/js/src/dropdown";
import ReactSummernote from "react-summernote";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

class CreateBlog extends Component {
  state = {
    loader: true,
    fields: { status: "Published", is_comment: "Y", shared_as: "Public" },
    errors: {},
    submitted: false,
    description: "",
    id: this.props.match.params.id ? this.props.match.params.id : "",
    lastSavedOn: ""
  };

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleTextArea = description => {
    this.setState({ description });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ submitted: true });

      const params = {
        title: this.state.fields["title"],
        excerpt: this.state.fields["excerpt"],
        description: this.state.description,
        is_comment: this.state.fields["is_comment"],
        shared_as: this.state.fields["shared_as"],
        status: this.state.fields["status"],
        id: this.state.id
      };

      blog.create(params).then(response => {
        this.setState({ submitted: false }, () => {
          if (response.data.success) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            this.props.history.push("/blogs");
          } else if (response.data.error) {
            let errors = {};
            if (response.data.message.title) {
              errors["title"] = response.data.message.title[0];
            }
            this.setState({ errors: errors });
          }
        });
      });
    }
  };
  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = "Title can not be empty!";
    }
    if (!fields["excerpt"]) {
      formIsValid = false;
      errors["excerpt"] = "Excerpt can not be empty!";
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  getBlog = id => {
    blog.getOne({ id: id }).then(response => {
      if (response.data.success) {
        let fields = {
          title: response.data.blog.title,
          excerpt: response.data.blog.excerpt,
          status: response.data.blog.status,
          is_comment: response.data.blog.is_comment
        };
        this.setState({
          fields,
          description: response.data.blog.description,
          lastSavedOn: response.data.blog.updated_on
        });
      } else if (response.data.error) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };
  componentDidMount() {
    if (this.props.match.params.id) {
      this.getBlog(this.props.match.params.id);
    }
  }
  render() {
    return (
      <div className="video-block section-padding">
        <Helmet>
          <title>
            {this.state.id === "" ? "Create New" : "Update"} Blog : Civ Works
          </title>
        </Helmet>
        <div className="main-item">
          <React.Fragment>
            <Row>
              <Col md={12} className="px-3">
                <Form
                  name="edit-profile-form"
                  method="post"
                  onSubmit={this.handleSubmit}
                >
                  <Col
                    md={12}
                    className="px-0"
                    style={{ borderBottom: "2px solid" }}
                  >
                    <Col md={6} className="px-0">
                      <h2>
                        {this.state.id === "" ? "Create New" : "Update"} Blog
                      </h2>
                    </Col>
                    <Col md={6} className="px-0 text-right">
                      <Link to="/blogs" className="btn btn-primary mr-1">
                        <FontAwesomeIcon icon="undo" className="mr-1" />
                        Back
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={this.state.submitted}
                      >
                        {this.state.submitted && (
                          <FontAwesomeIcon
                            icon="spinner"
                            className="mr-1"
                            spin={true}
                          />
                        )}
                        Save
                      </button>
                    </Col>
                  </Col>
                  <Col md={12} className="px-0">
                    <Card className="p-3 mt-3">
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="title">Title</Label>
                            <Input
                              type="text"
                              name="title"
                              id="title"
                              value={
                                this.state.fields["title"]
                                  ? this.state.fields["title"]
                                  : ""
                              }
                              onChange={event =>
                                this.handleChange("title", event)
                              }
                              invalid={
                                this.state.errors["title"] ? true : false
                              }
                            />
                            {this.state.errors["title"] && (
                              <FormFeedback>
                                {this.state.errors["title"]}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="excerpt">Excerpt</Label>
                            <Input
                              type="text"
                              name="excerpt"
                              id="excerpt"
                              value={
                                this.state.fields["excerpt"]
                                  ? this.state.fields["excerpt"]
                                  : ""
                              }
                              onChange={event =>
                                this.handleChange("excerpt", event)
                              }
                              invalid={
                                this.state.errors["excerpt"] ? true : false
                              }
                            />
                            {this.state.errors["excerpt"] && (
                              <FormFeedback>
                                {this.state.errors["excerpt"]}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="description">Description</Label>
                            <ReactSummernote
                              options={{
                                height: 250,
                                dialogsInBody: true,
                                toolbar: [
                                  ["style", ["style"]],
                                  ["font", ["bold", "underline", "clear"]],
                                  ["fontname", ["fontname"]],
                                  ["para", ["ul", "ol", "paragraph"]],
                                  ["table", ["table"]],
                                  /* ["insert", ["link", "picture", "video"]], */
                                  ["view", ["fullscreen", "codeview"]]
                                ]
                              }}
                              value={this.state.description}
                              onChange={this.handleTextArea}
                            />
                          </FormGroup>
                          <FormGroup tag="fieldset">
                            <h4>Comments</h4>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="is_comment"
                                  onChange={event =>
                                    this.handleChange("is_comment", event)
                                  }
                                  value="Y"
                                  checked={
                                    this.state.fields["is_comment"] === "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={event =>
                                    this.handleChange("is_comment", event)
                                  }
                                />
                                ON
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Label>
                                <Input
                                  type="radio"
                                  name="is_comment"
                                  value="N"
                                  checked={
                                    this.state.fields["is_comment"] === "N"
                                      ? true
                                      : false
                                  }
                                  onChange={event =>
                                    this.handleChange("is_comment", event)
                                  }
                                />
                                OFF
                              </Label>
                            </FormGroup>
                          </FormGroup>
                          <FormGroup>
                            <Label for="shared_as">Access</Label>
                            <Input
                              type="select"
                              name="shared_as"
                              id="shared_as"
                              onChange={event =>
                                this.handleChange("shared_as", event)
                              }
                              value={this.state.fields["shared_as"]}
                            >
                              <option value="Public">Public</option>
                              <option value="Private">Private</option>
                              <option value="Friends">Friends</option>
                            </Input>
                          </FormGroup>
                          <FormGroup tag="fieldset">
                            <h4>Status</h4>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="status"
                                  value="Published"
                                  checked={
                                    this.state.fields["status"] === "Published"
                                      ? true
                                      : false
                                  }
                                  onChange={event =>
                                    this.handleChange("status", event)
                                  }
                                />
                                Published
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="status"
                                  value="Draft"
                                  checked={
                                    this.state.fields["status"] === "Draft"
                                      ? true
                                      : false
                                  }
                                  onChange={event =>
                                    this.handleChange("status", event)
                                  }
                                />
                                Draft
                              </Label>
                            </FormGroup>
                          </FormGroup>
                          {this.state.id !== "" && (
                            <FormGroup className="mt-3 mb-0">
                              <Label>
                                <strong>Last saved:</strong>{" "}
                                <span
                                  className="date-time-info"
                                  dangerouslySetInnerHTML={{
                                    __html: common.timeSince(
                                      this.state.lastSavedOn
                                    )
                                  }}
                                />
                              </Label>
                            </FormGroup>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col md={12} className="px-0 text-right mb-3">
                    <Link to="/blogs" className="btn btn-primary mr-1">
                      <FontAwesomeIcon icon="undo" className="mr-1" />
                      Back
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={this.state.submitted}
                    >
                      {this.state.submitted && (
                        <FontAwesomeIcon
                          icon="spinner"
                          className="mr-1"
                          spin={true}
                        />
                      )}
                      Save
                    </button>
                  </Col>
                </Form>
              </Col>
            </Row>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};
export default connect(mapStateToProps)(CreateBlog);
