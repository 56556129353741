import React, { Component } from "react";
import account from "../../../services/account";
import { connect, dispatch } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
  Button,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { toast } from "react-toastify";
import RightMenu from "./RightMenu";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSummernote from "react-summernote";
import CropAvtarModal from "./CropAvtarModal";
import http from "../../../services/http";
import DatePicker from "react-datepicker";
import { doLogin } from "../../../store/actions";
import content from "../../../services/content";
import { faBold } from "@fortawesome/free-solid-svg-icons";


class ProfileSettings extends Component {
  state = {
    isLoader: false,
    loader: false,
    submitted: false,
    fields: {},
    errors: {},
    issues: [],
    issueLoader: true,
    selected_issues: [],
    uploadIcon: "cloud-upload-alt",
    spinner: false,
    showModal: false,
    crop: false,
    image: "",
    image_thumb: "",
    dob: "",
    about_me: "",
    education: "",
    workHistory: "",
    referralCode: "",
    user_id: "",
    disabledButton: false
  };

  getProfile = () => {
    this.setState({ loader: true });
    account.getProfile().then((response) => {
      if (response.data.success) {
        let image = response.data.user.image;
        let image_thumb = response.data.user.image_thumb;
        let fields = {};
        fields = response.data.user;
        if (response.data.user.website === null) {
          fields["website"] = "https://";
        }
        if (response.data.user.adminAccess[0]) {
          this.setState({
            disabledButton: true
          })
        }
        let selected_issues = [];

        response.data.user.issues.forEach((issue, index) => {
          selected_issues[index] = issue.category_id;
        });
        if (
          response.data.user.dob !== "0000-00-00" &&
          response.data.user.dob !== null
        ) {
          this.setState({ dob: new Date(response.data.user.dob) });
        }
        let crop = false;
        if (
          response.data.user.image !== "" &&
          response.data.user.image !== null
        ) {
          crop = true;
        }
        this.setState({
          selected_issues,
          fields,
          image,
          about_me: response.data.user.about_me,
          education: response.data.user.education,
          workHistory: response.data.user.work_history,
          user_id: response.data.user.id,
          crop,
        });
      }
      this.setState({
        loader: false,
        submitted: false,
      });
      account
        .getIssues()
        .then((response) => {
          if (response.data.success) {
            this.setState({
              issues: response.data.categories,
              issueLoader: false,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };
  getReferralCode = () => {
    this.setState({ loader: true });
    account.getReferrelCode().then((response) => {
      console.log(response.data.referralCode)
      if (response.data.success) {
        this.setState({
          referralCode: response.data.referralCode,
          loader: false,
        });
      }
      console.log(this.state, "stateede")
    }).catch(error => {
      console.error('Error fetching referral code:', error);
      this.setState({ loader: false });
    });
  };

  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  componentDidMount = () => {
    this.getProfile();
    this.getReferralCode();
  };

  handleIssue = (e) => {
    let selected_issues = this.state.selected_issues;
    if (e.target.className === "btn interests-btn") {
      selected_issues.push(e.target.id);
      e.target.className = "btn interests-btn selected";
      this.setState({ selected_issues });
    } else {
      let index_to_be_removed = selected_issues.indexOf(e.target.id);
      selected_issues.splice(index_to_be_removed, 1);
      e.target.className = "btn interests-btn";
    }
    this.setState({ selected_issues });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ submitted: true });
      let fields = this.state.fields;
      const params = {
        ...fields,
        about_me: this.state.about_me,
        education: this.state.education,
        work_history: this.state.workHistory,
        dob: this.formatDob(this.state.dob),
        issues: this.state.selected_issues,
      };

      account.saveProfile(params).then((response) => {
        this.setState({ submitted: false }, () => {
          if (response.data.success) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            if (response.data.image_thumb) {
              localStorage.setItem("userImageThumb", response.data.image_thumb);
              this.props.doLogin({
                userImageThumb: response.data.image_thumb,
              });
            }
          } else if (response.data.error) {
            let errors = {};
            if (response.data.message.email) {
              errors["email"] = response.data.message.email[0];
            }
            if (response.data.message.phone) {
              errors["phone"] = response.data.message.phone[0];
            }
            this.setState({ errors: errors });
          }
        });
      });
    } else {
      toast.error("Error! Please required form fields.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  formatDob(dob) {
    if (dob !== "") {
      let selected_date = new Date(dob);
      return `${selected_date.getFullYear()}-${selected_date.getMonth() + 1
        }-${selected_date.getDate()}`;
    }
    return "";
  }

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Name can not be empty!";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email can not be empty!";
    }
    /* if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Phone can not be empty!";
    } */
    if (this.state.dob === "" || this.state.dob === null) {
      formIsValid = false;
      errors["dob"] = "Please select your date of birth.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  uploadFile = (e) => {
    this.setState({
      spinner: true,
      uploadIcon: "spinner",
      enableFileUpload: "disabled",
    });
    const data = new FormData();
    data.append("filepond", e.target.files[0]);
    http.post(this.props.apiUrl + "/account/upload", data).then((response) => {
      if (response.data.success) {
        let fields = this.state.fields;
        let image;
        fields["image"] =
          fields["image_thumb"] =
          image =
          "temp/" + response.data.file_name;
        this.setState({
          fields,
          spinner: false,
          uploadIcon: "cloud-upload-alt",
          enableFileUpload: "",
          crop: true,
          image,
        });
        this.toggleModal();
      }
    });
  };

  updateImage = (image) => {
    let fields = this.state.fields;
    fields["image_thumb"] = "temp/" + image;
    this.setState({
      fields,
    });
  };

  handleDob = (date) => {
    if (date === null) {
      date = "";
    }
    this.setState({
      dob: date,
    });
  };

  handleAboutMe = (about_me) => {
    this.setState({ about_me });
  };

  handleEducation = (education) => {
    this.setState({ education });
  };

  handleWorkHistory = (workHistory) => {
    this.setState({ workHistory });
  };



  handleButtonClick = (data) => {
    let payload =
    {
      id: this.state.user_id,
      role: data
    }

    http.post("/action/add-action-roles", payload).then((response) => {
      if (response.data.success) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setState({
          disabledButton: true
        })
      } else {
      }
    }).catch((error) => {
      console.error('Error:', error);
    });
  };




  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>My Account : Civ Works</title>
        </Helmet>
        <Row className="mt-4">
          <Col md={7} lg={9} className="col-12 ml-lg-auto mt-5 pt-2">
            <Row className="mb-2">
              <Col md={12} lg={6} className="col-8">
                <h4 className="text-left status">My Account </h4>
              </Col>
            </Row>
            <Form
              name="add-edit-action-form"
              method="post"
              onSubmit={this.handleSubmit}
            >
              <div className="central-meta create-new-group-form mb-2 mb-2">
                <LoadingOverlay
                  active={this.state.loader}
                  spinner={<Spinner color="dark" />}
                  fadeSpeed={200}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(255, 0, 0, 0)",
                    }),
                  }}
                >
                  <h4 className="mb-3">General Information</h4>
                  <Row className="justify-content-center align-items-center">
                    <Col md={6} lg={3} className="col-12 text-left">
                      <div className="p-3 text-center">
                        {!this.state.loader &&
                          this.state.fields["image"] !== null &&
                          this.state.fields["image"] !== "null" &&
                          this.state.fields["image"] !== "" ? (
                          <img
                            src={`${this.props.baseUrl}/images/${this.state.fields["image_thumb"]}`}
                            alt={this.state.fields["name"]}
                            className="img-fluid rounded-circle mb-3 shadow-sm"
                            style={{ width: 100, height: 100 }}
                          />
                        ) : (
                          <FontAwesomeIcon icon="user-circle" size="7x" />
                        )}

                        <label className="fileContainer upload-text btn pl-0 pr-0">
                          <FontAwesomeIcon
                            icon={this.state.uploadIcon}
                            spin={this.state.spinner}
                            className="mr-1"
                          />
                          Upload
                          <input
                            type="file"
                            onChange={this.uploadFile}
                            disabled={this.state.enableFileUpload}
                          />
                        </label>
                        {this.state.crop && (
                          <button
                            type="button"
                            className="btn pl-2 pr-0"
                            onClick={this.toggleModal}
                          >
                            <FontAwesomeIcon icon="crop" /> Crop
                          </button>
                        )}
                      </div>
                    </Col>
                    <Col
                      md={6}
                      lg={9}
                      className="col-12 col-md-6 col-lg-3  text-left"
                    >
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="name">Display Name</Label>
                            <Input
                              bsSize="lg"
                              type="text"
                              name="name"
                              id="name"
                              value={
                                this.state.fields["name"]
                                  ? this.state.fields["name"]
                                  : ""
                              }
                              onChange={(event) =>
                                this.handleChange("name", event)
                              }
                              invalid={this.state.errors["name"] ? true : false}
                              className="input-bg"
                            />
                            {this.state.errors["name"] && (
                              <FormFeedback>
                                {this.state.errors["name"]}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <Label for="email">Email</Label>
                        </Col>
                        <Col md={7}>
                          <FormGroup>
                            <Input
                              bsSize="lg"
                              type="text"
                              name="email"
                              id="email"
                              value={
                                this.state.fields["email"]
                                  ? this.state.fields["email"]
                                  : ""
                              }
                              onChange={(event) =>
                                this.handleChange("email", event)
                              }
                              invalid={
                                this.state.errors["email"] ? true : false
                              }
                              className="input-bg"
                            />
                            {this.state.errors["email"] && (
                              <FormFeedback>
                                {this.state.errors["email"]}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={5}>
                          <FormGroup>
                            <Input
                              style={{ width: 200 }}
                              type="select"
                              bsSize="lg"
                              name="email_visibility"
                              id="email_visibility"
                              onChange={(event) =>
                                this.handleChange("email_visibility", event)
                              }
                              value={
                                this.state.fields["email_visibility"]
                                  ? this.state.fields["email_visibility"]
                                  : ""
                              }
                              className="input-bg"
                            >
                              <option value="Public">Public</option>
                              <option value="Private">Private</option>
                              <option value="Friends">Friends</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <Label for="email">Phone</Label>
                        </Col>
                        <Col md={7}>
                          <FormGroup>
                            <Input
                              bsSize="lg"
                              type="text"
                              name="phone"
                              id="phone"
                              value={
                                this.state.fields["phone"]
                                  ? this.state.fields["phone"]
                                  : ""
                              }
                              onChange={(event) =>
                                this.handleChange("phone", event)
                              }
                              invalid={
                                this.state.errors["phone"] ? true : false
                              }
                              className="input-bg"
                            />
                            {this.state.errors["phone"] && (
                              <FormFeedback>
                                {this.state.errors["phone"]}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={5}>
                          <FormGroup>
                            <Input
                              style={{ width: 200 }}
                              type="select"
                              bsSize="lg"
                              name="phone_visibility"
                              id="phone_visibility"
                              onChange={(event) =>
                                this.handleChange("phone_visibility", event)
                              }
                              value={
                                this.state.fields["phone_visibility"]
                                  ? this.state.fields["phone_visibility"]
                                  : ""
                              }
                              className="input-bg"
                            >
                              <option value="Public">Public</option>
                              <option value="Private">Private</option>
                              <option value="Friends">Friends</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <Label for="email">Website</Label>
                        </Col>
                        <Col md={7}>
                          <FormGroup>
                            <Input
                              bsSize="lg"
                              type="url"
                              name="website"
                              id="phone"
                              value={
                                this.state.fields["website"]
                                  ? this.state.fields["website"]
                                  : ""
                              }
                              onChange={(event) =>
                                this.handleChange("website", event)
                              }
                              invalid={
                                this.state.errors["website"] ? true : false
                              }
                              className="input-bg"
                            />
                            {this.state.errors["website"] && (
                              <FormFeedback>
                                {this.state.errors["website"]}
                              </FormFeedback>
                            )}
                            <small>
                              <strong>Note:</strong> Website name with http://
                              or https://{" "}
                            </small>
                          </FormGroup>
                        </Col>
                        <Col md={5}>
                          <FormGroup>
                            <Input
                              style={{ width: 200 }}
                              type="select"
                              bsSize="lg"
                              name="website_visibility"
                              id="website_visibility"
                              onChange={(event) =>
                                this.handleChange("website_visibility", event)
                              }
                              value={
                                this.state.fields["website_visibility"]
                                  ? this.state.fields["website_visibility"]
                                  : ""
                              }
                              className="input-bg"
                            >
                              <option value="Public">Public</option>
                              <option value="Private">Private</option>
                              <option value="Friends">Friends</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <Label for="email">Date Of Birth</Label>
                        </Col>
                        <Col md={7}>
                          <FormGroup>
                            <DatePicker
                              className="form-control input-bg form-control-lg"
                              style={{ width: 100 + "%", float: "left" }}
                              selected={this.state.dob}
                              onChange={this.handleDob}
                              dateFormat="yyyy-MM-dd"
                              showYearDropdown
                              showMonthDropdown
                              isClearable
                            />
                            {this.state.errors["dob"] && (
                              <small className="fa-1x text-danger">
                                {this.state.errors["dob"]}
                              </small>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={5}>
                          <FormGroup>
                            <Input
                              style={{ width: 200 }}
                              type="select"
                              bsSize="lg"
                              name="dob_visibility"
                              id="dob_visibility"
                              onChange={(event) =>
                                this.handleChange("dob_visibility", event)
                              }
                              value={
                                this.state.fields["dob_visibility"]
                                  ? this.state.fields["dob_visibility"]
                                  : ""
                              }
                              className="input-bg"
                            >
                              <option value="Public">Public</option>
                              <option value="Private">Private</option>
                              <option value="Friends">Friends</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="address">Address</Label>
                            <Input
                              bsSize="lg"
                              type="text"
                              name="address"
                              id="address"
                              value={
                                this.state.fields["address"]
                                  ? this.state.fields["address"]
                                  : ""
                              }
                              onChange={(event) =>
                                this.handleChange("address", event)
                              }
                              invalid={
                                this.state.errors["address"] ? true : false
                              }
                              className="input-bg"
                            />
                            {this.state.errors["address"] && (
                              <FormFeedback>
                                {this.state.errors["address"]}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </LoadingOverlay>
              </div>

              <div className="central-meta create-new-group-form mb-2 mb-2">
                <FormGroup>
                  <Label for="description">About me</Label>
                  <ReactSummernote
                    options={{
                      height: 150,
                      dialogsInBody: true,
                      toolbar: [
                        ["style", ["style"]],
                        ["font", ["bold", "underline", "clear"]],
                        ["fontname", ["fontname"]],
                        ["para", ["ul", "ol", "paragraph"]],
                        ["table", ["table"]],
                        /* ["insert", ["link", "picture", "video"]], */
                        ["view", ["fullscreen", "codeview"]],
                      ],
                    }}
                    value={this.state.about_me}
                    onChange={this.handleAboutMe}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    style={{ width: 200 }}
                    type="select"
                    bsSize="lg"
                    name="about_me_visibility"
                    id="about_me_visibility"
                    onChange={(event) =>
                      this.handleChange("about_me_visibility", event)
                    }
                    value={
                      this.state.fields["about_me_visibility"]
                        ? this.state.fields["about_me_visibility"]
                        : ""
                    }
                    className="input-bg"
                  >
                    <option value="Public">Public</option>
                    <option value="Private">Private</option>
                    <option value="Friends">Friends</option>
                  </Input>
                </FormGroup>
              </div>

              {/*Social block starts*/}
              <div className="central-meta create-new-group-form mb-2 mb-2">
                <h4 className="mb-4"> Social </h4>
                <Row>
                  <Col md={12}>
                    <Label for="description">Facebook</Label>
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <FormGroup>
                        <Input
                          bsSize="lg"
                          type="url"
                          name="facebook_url"
                          id="facebook_url"
                          value={
                            this.state.fields["facebook_url"]
                              ? this.state.fields["facebook_url"]
                              : ""
                          }
                          onChange={(event) =>
                            this.handleChange("facebook_url", event)
                          }
                          invalid={
                            this.state.errors["facebook_url"] ? true : false
                          }
                          className="input-bg"
                        />
                        {this.state.errors["facebook_url"] && (
                          <FormFeedback>
                            {this.state.errors["facebook_url"]}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        style={{ width: 200 }}
                        type="select"
                        bsSize="lg"
                        name="facebook_url_visibility"
                        id="facebook_url_visibility"
                        onChange={(event) =>
                          this.handleChange("facebook_url_visibility", event)
                        }
                        value={
                          this.state.fields["facebook_url_visibility"]
                            ? this.state.fields["facebook_url_visibility"]
                            : ""
                        }
                        className="input-bg"
                      >
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                        <option value="Friends">Friends</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Label for="description">LinkedIn</Label>
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <FormGroup>
                        <Input
                          bsSize="lg"
                          type="url"
                          name="linkedin_url"
                          id="linkedin_url"
                          value={
                            this.state.fields["linkedin_url"]
                              ? this.state.fields["linkedin_url"]
                              : ""
                          }
                          onChange={(event) =>
                            this.handleChange("linkedin_url", event)
                          }
                          invalid={
                            this.state.errors["linkedin_url"] ? true : false
                          }
                          className="input-bg"
                        />
                        {this.state.errors["linkedin_url"] && (
                          <FormFeedback>
                            {this.state.errors["linkedin_url"]}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        style={{ width: 200 }}
                        type="select"
                        bsSize="lg"
                        name="linkedin_url_visibility"
                        id="linkedin_url_visibility"
                        onChange={(event) =>
                          this.handleChange("linkedin_url_visibility", event)
                        }
                        value={
                          this.state.fields["linkedin_url_visibility"]
                            ? this.state.fields["linkedin_url_visibility"]
                            : ""
                        }
                        className="input-bg"
                      >
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                        <option value="Friends">Friends</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Label for="description">Twitter</Label>
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <FormGroup>
                        <Input
                          bsSize="lg"
                          type="url"
                          name="twitter_url"
                          id="twitter_url"
                          value={
                            this.state.fields["twitter_url"]
                              ? this.state.fields["twitter_url"]
                              : ""
                          }
                          onChange={(event) =>
                            this.handleChange("twitter_url", event)
                          }
                          invalid={
                            this.state.errors["twitter_url"] ? true : false
                          }
                          className="input-bg"
                        />
                        {this.state.errors["twitter_url"] && (
                          <FormFeedback>
                            {this.state.errors["twitter_url"]}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        style={{ width: 200 }}
                        type="select"
                        bsSize="lg"
                        name="twitter_url_visibility"
                        id="twitter_url_visibility"
                        onChange={(event) =>
                          this.handleChange("twitter_url_visibility", event)
                        }
                        value={
                          this.state.fields["twitter_url_visibility"]
                            ? this.state.fields["twitter_url_visibility"]
                            : ""
                        }
                        className="input-bg"
                      >
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                        <option value="Friends">Friends</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Label for="books">Books</Label>
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <FormGroup>
                        <Input
                          bsSize="lg"
                          type="text"
                          name="books"
                          id="books"
                          value={
                            this.state.fields["books"]
                              ? this.state.fields["books"]
                              : ""
                          }
                          onChange={(event) =>
                            this.handleChange("books", event)
                          }
                          invalid={this.state.errors["books"] ? true : false}
                          className="input-bg"
                        />
                        {this.state.errors["books"] && (
                          <FormFeedback>
                            {this.state.errors["books"]}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        style={{ width: 200 }}
                        type="select"
                        bsSize="lg"
                        name="books_visibility"
                        id="books_visibility"
                        onChange={(event) =>
                          this.handleChange("books_visibility", event)
                        }
                        value={
                          this.state.fields["books_visibility"]
                            ? this.state.fields["books_visibility"]
                            : ""
                        }
                        className="input-bg"
                      >
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                        <option value="Friends">Friends</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Label for="movies">Movies</Label>
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <FormGroup>
                        <Input
                          bsSize="lg"
                          type="text"
                          name="movies"
                          id="movies"
                          value={
                            this.state.fields["movies"]
                              ? this.state.fields["movies"]
                              : ""
                          }
                          onChange={(event) =>
                            this.handleChange("movies", event)
                          }
                          invalid={this.state.errors["movies"] ? true : false}
                          className="input-bg"
                        />
                        {this.state.errors["movies"] && (
                          <FormFeedback>
                            {this.state.errors["movies"]}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        style={{ width: 200 }}
                        type="select"
                        bsSize="lg"
                        name="movies_visibility"
                        id="movies_visibility"
                        onChange={(event) =>
                          this.handleChange("movies_visibility", event)
                        }
                        value={
                          this.state.fields["movies_visibility"]
                            ? this.state.fields["movies_visibility"]
                            : ""
                        }
                        className="input-bg"
                      >
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                        <option value="Friends">Friends</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Label for="music">Music</Label>
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <FormGroup>
                        <Input
                          bsSize="lg"
                          type="text"
                          name="music"
                          id="music"
                          value={
                            this.state.fields["music"]
                              ? this.state.fields["music"]
                              : ""
                          }
                          onChange={(event) =>
                            this.handleChange("music", event)
                          }
                          invalid={this.state.errors["name"] ? true : false}
                          className="input-bg"
                        />
                        {this.state.errors["music"] && (
                          <FormFeedback>
                            {this.state.errors["music"]}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        style={{ width: 200 }}
                        type="select"
                        bsSize="lg"
                        name="music_visibility"
                        id="music_visibility"
                        onChange={(event) =>
                          this.handleChange("music_visibility", event)
                        }
                        value={
                          this.state.fields["music_visibility"]
                            ? this.state.fields["music_visibility"]
                            : ""
                        }
                        className="input-bg"
                      >
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                        <option value="Friends">Friends</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              {/*Socal block End*/}

              {/*Professional block starts*/}
              <div className="central-meta create-new-group-form mb-2 mb-2">
                <h4 className="mb-4"> Professional </h4>
                <FormGroup>
                  <Label for="description">Education</Label>
                  <ReactSummernote
                    options={{
                      height: 150,
                      dialogsInBody: true,
                      toolbar: [
                        ["style", ["style"]],
                        ["font", ["bold", "underline", "clear"]],
                        ["fontname", ["fontname"]],
                        ["para", ["ul", "ol", "paragraph"]],
                        ["table", ["table"]],
                        /* ["insert", ["link", "picture", "video"]], */
                        ["view", ["fullscreen", "codeview"]],
                      ],
                    }}
                    value={this.state.education}
                    onChange={this.handleEducation}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    style={{ width: 200 }}
                    type="select"
                    bsSize="lg"
                    name="education_visibility"
                    id="education_visibility"
                    onChange={(event) =>
                      this.handleChange("education_visibility", event)
                    }
                    value={
                      this.state.fields["education_visibility"]
                        ? this.state.fields["education_visibility"]
                        : ""
                    }
                    className="input-bg"
                  >
                    <option value="Public">Public</option>
                    <option value="Private">Private</option>
                    <option value="Friends">Friends</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="description">Work History</Label>
                  <ReactSummernote
                    options={{
                      height: 150,
                      dialogsInBody: true,
                      toolbar: [
                        ["style", ["style"]],
                        ["font", ["bold", "underline", "clear"]],
                        ["fontname", ["fontname"]],
                        ["para", ["ul", "ol", "paragraph"]],
                        ["table", ["table"]],
                        /* ["insert", ["link", "picture", "video"]], */
                        ["view", ["fullscreen", "codeview"]],
                      ],
                    }}
                    value={this.state.workHistory}
                    onChange={this.handleWorkHistory}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    style={{ width: 200 }}
                    type="select"
                    bsSize="lg"
                    name="work_history_visibility"
                    id="work_history_visibility"
                    onChange={(event) =>
                      this.handleChange("work_history_visibility", event)
                    }
                    value={
                      this.state.fields["work_history_visibility"]
                        ? this.state.fields["work_history_visibility"]
                        : ""
                    }
                    className="input-bg"
                  >
                    <option value="Public">Public</option>
                    <option value="Private">Private</option>
                    <option value="Friends">Friends</option>
                  </Input>
                </FormGroup>
                <Row>
                  <Col md={12}>
                    <Label for="description">Skills</Label>
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <FormGroup>
                        <Input
                          bsSize="lg"
                          type="text"
                          name="skills"
                          id="skills"
                          value={
                            this.state.fields["skills"]
                              ? this.state.fields["skills"]
                              : ""
                          }
                          onChange={(event) =>
                            this.handleChange("skills", event)
                          }
                          invalid={this.state.errors["skills"] ? true : false}
                          className="input-bg"
                        />
                        {this.state.errors["skills"] && (
                          <FormFeedback>
                            {this.state.errors["skills"]}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        style={{ width: 200 }}
                        type="select"
                        bsSize="lg"
                        name="skills_visibility"
                        id="skills_visibility"
                        onChange={(event) =>
                          this.handleChange("skills_visibility", event)
                        }
                        value={
                          this.state.fields["skills_visibility"]
                            ? this.state.fields["skills_visibility"]
                            : ""
                        }
                        className="input-bg"
                      >
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                        <option value="Friends">Friends</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              {/*Professional block ends*/}

              {/* Interest Section Starts */}
              <div className="user-post central-meta create-new-group-form item mb-2 Interests">
                <h4 className="mb-4"> Interests </h4>
                {this.state.issueLoader && (
                  <React.Fragment>
                    <Spinner size="sm" color="dark" /> Please wait..
                  </React.Fragment>
                )}
                {this.state.issues.length > 0 &&
                  this.state.issues.map((issue) => (
                    <button
                      type="button"
                      className={`btn interests-btn${this.state.selected_issues.includes(issue.id)
                        ? " selected"
                        : ""
                        }`}
                      onClick={this.handleIssue}
                      // key={issue.id}
                      id={issue.id}
                      key={`issue-key-${issue.id}`}
                    >
                      {issue.name}
                    </button>
                  ))}
              </div>
              {/* Interest Section End */}

              {/* Invite Friends Section End */}
              <div className="user-post central-meta create-new-group-form item mb-2 Interests">
                <h4 className="mb-4">Action Role</h4>
                <p className="py-2">If you want to join as a Action Editor or Action Admin, Please click on the button below</p>
                <p className="py-2 mb-2" style={{ ...faBold, fontWeight: 'bold' }}> Please Note:- You can choose only one role. After sending the Request, Please wait till the admin approves or disappove your request. You will get the notification related to this. Thankyou </p>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Button
                        color="success"
                        size="lg"
                        block
                        disabled={this.state.disabledButton}
                        onClick={() => this.handleButtonClick("action_editor")}
                      >
                        Action Editor
                      </Button>
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <Button
                        color="success"
                        size="lg"
                        block
                        disabled={this.state.disabledButton}
                        onClick={() => this.handleButtonClick("action_admin")}
                      >
                        Action Admin
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </div>


              <button
                type="submit"
                className="btn btn-danger Create-Bookmark-btn mt-3 mb-3"
                disabled={this.state.submitted}
              >
                {this.state.submitted && (
                  <FontAwesomeIcon
                    icon="spinner"
                    className="mr-1"
                    spin={true}
                  />
                )}
                Save
              </button>
            </Form>
          </Col>

          <Col
            md={3}
            lg={2}
            className="col-12 right-section px-0 text-center mt-lg-5 pt-1 ml-lg-3 mt-md-5"
          >
            <RightMenu />
          </Col>
        </Row>
        {this.state.showModal && (
          <CropAvtarModal
            showModal={this.state.showModal}
            toggleModal={this.toggleModal}
            image={this.state.image}
            updateImage={this.updateImage}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.userId,
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doLogin: (data) => {
      dispatch(doLogin(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
