import React, { Component } from "react";
import account from "../../../services/account";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form
} from "reactstrap";
import { toast } from "react-toastify";
import RightMenu from "./RightMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ChangePassword extends Component {
  state = {
    loader: false,
    submitted: false,
    fields: {},
    errors: {}
  };

  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ submitted: true });
      const params = {
        current_password: this.state.fields["current_password"],
        new_password: this.state.fields["new_password"],
        confirm_password: this.state.fields["confirm_password"]
      };

      account.changePassword(params).then(response => {
        this.setState({ submitted: false });
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ fields: {} });
        } else {
          let errors = {};
          if (response.data.error.current_password) {
            errors["current_password"] =
              response.data.error.current_password[0];
          }
          if (response.data.error.confirm_password) {
            errors["confirm_password"] =
              response.data.error.confirm_password[0];
          }
          this.setState({ errors: errors });
        }
      });
    }
  };
  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["current_password"]) {
      formIsValid = false;
      errors["current_password"] = "Please enter your current password.";
    }
    if (!fields["new_password"]) {
      formIsValid = false;
      errors["new_password"] = "Please enter your new password.";
    }
    if (!fields["confirm_password"]) {
      formIsValid = false;
      errors["confirm_password"] = "Please confirm your new password.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Change Password : Civ Works</title>
        </Helmet>
        <Row className="mt-4">
          <Col md={7} lg={9} className="col-12 ml-lg-auto mt-5 pt-2">
            <Row className="mb-2">
              <Col md={12} lg={6} className="col-8">
                <h4 className="text-left status">Change Password </h4>
              </Col>
            </Row>
            <Form
              name="add-edit-action-form"
              method="post"
              onSubmit={this.handleSubmit}
            >
              <div className="central-meta create-new-group-form mb-2 mb-2">
                <FormGroup>
                  <Label for="name">Current Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="current_password"
                    id="current_password"
                    value={
                      this.state.fields["current_password"]
                        ? this.state.fields["current_password"]
                        : ""
                    }
                    onChange={event =>
                      this.handleChange("current_password", event)
                    }
                    invalid={
                      this.state.errors["current_password"] ? true : false
                    }
                    className="input-bg"
                  />
                  {this.state.errors["current_password"] && (
                    <FormFeedback>
                      {this.state.errors["current_password"]}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="name">New Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="new_password"
                    id="new_password"
                    value={
                      this.state.fields["new_password"]
                        ? this.state.fields["new_password"]
                        : ""
                    }
                    onChange={event => this.handleChange("new_password", event)}
                    invalid={this.state.errors["new_password"] ? true : false}
                    className="input-bg"
                  />
                  {this.state.errors["new_password"] && (
                    <FormFeedback>
                      {this.state.errors["new_password"]}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="name">Confirm Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    value={
                      this.state.fields["confirm_password"]
                        ? this.state.fields["confirm_password"]
                        : ""
                    }
                    onChange={event =>
                      this.handleChange("confirm_password", event)
                    }
                    invalid={
                      this.state.errors["confirm_password"] ? true : false
                    }
                    className="input-bg"
                  />
                  {this.state.errors["confirm_password"] && (
                    <FormFeedback>
                      {this.state.errors["confirm_password"]}
                    </FormFeedback>
                  )}
                </FormGroup>
              </div>

              <button
                type="submit"
                className="btn btn-danger Create-Bookmark-btn mt-3 mb-3"
                disabled={this.state.submitted}
              >
                {this.state.submitted && (
                  <FontAwesomeIcon
                    icon="spinner"
                    className="mr-1"
                    spin={true}
                  />
                )}
                Save
              </button>
            </Form>
          </Col>

          <Col
            md={3}
            lg={2}
            className="col-12 right-section px-0 text-center mt-lg-5 pt-1 ml-lg-3 mt-md-5"
          >
            <RightMenu />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl
  };
};

export default connect(mapStateToProps)(ChangePassword);
