import React, { Component } from "react";
import { connect, dispatch } from "react-redux";
import group from "../../../services/group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import { updateGroupCounts } from "../../../store/actions";

class ListRightBox extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    group.countGroupByStatus({}).then(response => {
      if (response.data.success) {
        let totalGroupCounts = response.data.counts;
        let arrangedCounts = group.arrangeGroupCounts(
          totalGroupCounts,
          response.data.joined_group.length,
          response.data.group_invitations,
          this.props.userId
        );
        this.props.updateGroupCounts(arrangedCounts);
      }
    });
  };
  render() {
    return (
      <React.Fragment>
        <Col md={12} className="px-0">
          <Link
            to="/groups/create"
            className="btn btn-danger new-group-btn mt-3 mb-3"
          >
            <FontAwesomeIcon icon="plus" className="mr-1" />
            Create a new group
          </Link>
        </Col>
        <div className="widgets widgets-coment">
          <div className="Navigation text-left mt-3 mb-3">
            <h4 className="Act text-left pl-3 mb-3 pt-2">
              <b>Navigation</b>
            </h4>
            <div className="menu-block">
              <ul>
                <li>
                  <NavLink
                    to="/groups"
                    activeClassName="is-active"
                    exact={true}
                  >
                    All groups
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/groups/public"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Public Groups ({this.props.totalPublicGroups})
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/groups/private"
                    activeClassName="is-active"
                    exact={true}
                    strict
                  >
                    Private Groups ({this.props.totalPrivateGroups})
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/groups/my-group"
                    activeClassName="is-active"
                    exact={true}
                  >
                    My Groups ({this.props.totalMyGroups})
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/groups/i-am-in"
                    activeClassName="is-active"
                    exact={true}
                  >
                    Groups I'm in ({this.props.totalGroupsJoined})
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/groups/invitations" activeClassName="is-active">
                    Groups invintations({this.props.groupInvitations})
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/adsfs" activeClassName="is-active">
                    Latest discussions
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="widgets widgets-coment">
          <div className="Navigation text-left mt-3 mb-3">
            <h4 className="Act text-left pl-3 mb-3 pt-2">
              <b>Search by hashtag</b>
            </h4>
            <form>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control input-hashtag"
                  id=" #typehere"
                  placeholder=" #typehere"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="widgets widgets-coment">
          <div className="Navigation text-left mt-3 mb-3">
            <h4 className="Act text-left pl-3 mb-3 pt-2">
              <b>Featured groups </b>
            </h4>

            <div className="col-md-12 text-center px-lg-0 mb-lg-4">
              <img
                src={require("../../../assets/images/feature.png")}
                className="rounded feature-img"
              />
              <p className="mb-0 mt-2">Civic Writers</p>
              <ul className="grop-links text-center">
                <li>
                  <a href="">51.1K Members</a>
                </li>
                <li>
                  <a href="">∙ 15K posts</a>
                </li>
              </ul>
            </div>
            <div className="col-md-12 text-center px-lg-0 mb-lg-4">
              <img
                src={require("../../../assets/images/feature2.png")}
                className="rounded feature-img"
              />
              <p className="mb-0 mt-2">Open Works</p>
              <ul className="grop-links text-center">
                <li>
                  <a href="">51.1K Members</a>
                </li>
                <li>
                  <a href="">∙ 15K posts</a>
                </li>
              </ul>
            </div>
            <div className="col-md-12 text-center px-lg-0 mb-lg-4">
              <img
                src={require("../../../assets/images/feature3.png")}
                className="rounded feature-img"
              />
              <p className="mb-0 mt-2">Activism 101</p>
              <ul className="grop-links text-center">
                <li>
                  <a href="">51.1K Members</a>
                </li>
                <li>
                  <a href="">∙ 15K posts</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapPropsToState = state => {
  return {
    baseUrl: state.baseUrl,
    userId: state.userId,
    totalPrivateGroups: state.totalPrivateGroups,
    totalPublicGroups: state.totalPublicGroups,
    totalMyGroups: state.totalMyGroups,
    totalInvitations: state.totalInvitations,
    totalGroupsJoined: state.totalGroupsJoined,
    groupInvitations: state.groupInvitations
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateGroupCounts: totalCounts => {
      dispatch(
        updateGroupCounts({
          totalCounts
        })
      );
    }
  };
};

export default withRouter(
  connect(mapPropsToState, mapDispatchToProps)(ListRightBox)
);
