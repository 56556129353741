import React, { Component } from "react";
import { Spinner, Button, Modal, ModalBody } from "reactstrap";
import axios from "axios";
import store from "../../../store/store";

const STATE = store.getState();

class ConfirmEmailVerificationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.show,
      message: "We are verifying your email address. Please wait...",
      showSpinner: true,
      closeButton: false,
    };
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };

  confirmEmail = () => {
    axios
      .get(`${STATE.apiUrl}/admin/confirm-admin-email`, {
        params: {
          code: this.props.code,
        },
      })
      .then((response) => {
        const { success, message } = response.data;
        this.setState({
          message: message,
          showSpinner: false,
          closeButton: true,
        });
      })
      .catch((error) => {
        console.error("Error confirming email:", error);
        this.setState({
          message: "An error occurred while confirming your email.",
          showSpinner: false,
          closeButton: true,
        });
      });
  };

  componentDidMount() {
    const { code } = this.props;
    if (code) {
      this.confirmEmail();
    }
  }

  render() {
    const { showModal, message, showSpinner, closeButton } = this.state;

    return (
      <Modal
        isOpen={showModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ModalBody>
          <p className="text-center mt-3">{message}</p>
          {showSpinner && (
            <p className="text-center">
              <Spinner size="lg" color="dark" className="mt-3" />
            </p>
          )}
          {closeButton && (
            <p className="text-center">
              <Button color="danger" onClick={this.hideModal}>
                Close
              </Button>
            </p>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default ConfirmEmailVerificationModal;
