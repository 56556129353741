import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import user from "../../../services/user";
import action from "../../../services/action";
import content from "../../../services/content";
import NotFound from "../Common/NotFound";
import SkeletonScreen from "../Common/SkeletonScreen";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import RightBox from "./RightBox";
import { Helmet } from "react-helmet";
import LeftMenu from "../Common/LeftMenu";
import PostContentFormModal from "../Common/PostContentFormModal";
import Content from "../Feeds/Content";
import UserDetails from "./UserDetails";
import { updateFeed } from "../../../store/actions";
import Action from "../Action/Action";

class Profile extends Component {
  state = {
    profileLoader: true,
    contentLoader: true,
    profileNotFound: false,
    contentNotFound: false,
    contentTypes: {
      feed: false,
      action: false,
      poll: false,
      blog: false,
      bookmark: false,
      files: false
    },
    userDetails: {},
    pageNo: 1,
    pageSize: 5,
    hasMore: false,
    totalPosts: 0,
    blog: false,
    poll: false,
    heading: "Top Recent Posts",
    notFoundMessage: "",
    totalFriends: [],
    totalActions: 0,
    actions: [],
    feeds: []
  };
  getUser = username => {
    this.setState({
      profileLoader: true,
      contentLoader: true
    });
    user.get({ id: username }).then(response => {
      if (response.data.error) {
        this.setState({
          contentLoader: false,
          profileLoader: false,
          profileNotFound: true,
          contentNotFound: true
        });
      } else {
        this.setState({
          profileLoader: false,
          userDetails: response.data.user,
          totalFriends: response.data.friends,
          totalActions: response.data.user.actions.length
        });
        if (this.props.match.path.includes("actions")) {
          let data = {
            user_id: response.data.user.id,
            type: "upcoming",
            page: this.state.pageNo,
            pageSize: this.state.pageSize
          };
          action.list(data).then(response => {
            if (response.data.success) {
              let contentTypes = {
                feed: false,
                action: true,
                bookmark: false,
                files: false
              };
              this.setState({
                contentTypes,
                contentLoader: false,
                actions: response.data.actions,
                contentNotFound:
                  response.data.actions.length > 0 ? false : true,
                notFoundMessage: "Action not found."
              });
            }
          });
        } else {
          this.getFeeds(response.data.user.id);
        }
      }
    });
  };
  getFeeds = user_id => {
    let data = {
      page: this.state.pageNo,
      pageSize: this.state.pageSize,
      user_id: user_id,
      blog: this.state.blog,
      poll: this.state.poll
    };
    content.list(data).then(response => {
      if (response.data.success) {
        let feeds = [];
        feeds = response.data.contents;
        if (this.state.pageNo > 1) {
          feeds = [...this.props.feeds, ...feeds];
        }
        this.props.updateFeed(feeds);
        let contentTypes = {
          feed: true,
          action: false,
          bookmark: false,
          files: false
        };
        this.setState({
          contentTypes,
          contentLoader: false,
          totalPosts: response.data.pages.totalCount,
          contentNotFound: response.data.contents.length > 0 ? false : true,
          notFoundMessage: "Content not found."
        });
        if (
          response.data.contents.length > 0 &&
          this.state.pageNo < response.data.pageCount
        ) {
          this.setState(prevState => ({
            hasMore: true,
            pageNo: prevState.pageNo + 1
          }));
        } else {
          this.setState({ hasMore: false, contentNotFound: false });
        }
      }
    });
  };
  setHeader = () => {
    if (this.props.match.path.includes("blog")) {
      this.setState({
        blog: true,
        heading: "Blogs",
        notFoundMessage: "This user has no blog posts yet"
      });
    } else if (this.props.match.path.includes("polls")) {
      this.setState({
        poll: true,
        heading: "Polls",
        notFoundMessage: "This user has no poll posts yet."
      });
    } else if (this.props.match.path.includes("feed-activity")) {
      this.setState({
        heading: "Feed / Activity",
        notFoundMessage: "This user has no feed/activity yet."
      });
    } else if (this.props.match.path.includes("actions")) {
      this.setState({
        heading: "Actions",
        notFoundMessage: "This user has not any actions."
      });
    }
  };
  componentDidMount() {
    this.setHeader();
    this.getUser(this.props.match.params.username);
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.username !== this.props.match.params.username) {
      this.getUser(nextProps.match.params.username);
      this.setHeader();
    }
  }
  handleScroll = e => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      if (this.state.hasMore) {
        this.getFeeds(this.state.userDetails.id);
      }
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  render() {
    const userDetails = this.state.userDetails;
    return (
      <React.Fragment>
        <Helmet>
          <title>Profile : Civ Works</title>
        </Helmet>
        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={6} lg={8} className="col-12 pr-4 mt-3">
            <React.Fragment>
              <div className="user-post central-meta create-new-group-form item mb-2 pt-3">
                {this.state.profileLoader ? (
                  <div className="mt-5">
                    <SkeletonScreen height={150} />
                  </div>
                ) : (
                  <React.Fragment>
                    {this.state.profileNotFound ? (
                      <NotFound message="User not found." />
                    ) : (
                      <UserDetails
                        user={userDetails}
                        friends={this.state.totalFriends}
                      />
                    )}
                  </React.Fragment>
                )}
              </div>
              {!this.state.notFound && !this.state.profileLoader && (
                <div className="user-post central-meta item mb-4">
                  <Row>
                    <Col className="col-md-12">
                      <ul className="followers-dts mt-2 mb-2">
                        <li>
                          <div className="followers-dt-sm">
                            <h4>{this.state.totalFriends.length}</h4>
                            <span>
                              {this.state.totalFriends.length > 1
                                ? "Friends"
                                : "Friend"}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="followers-dt-sm">
                            <h4>{this.state.totalPosts}</h4>
                            <span>Posts Published</span>
                          </div>
                        </li>
                        <li>
                          <div className="followers-dt-sm">
                            <h4>{this.state.totalActions}</h4>
                            <span>Actions </span>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              )}
              <Row>
                <Col md={12} className="pl-4 pr-4 pt-1 pb-3">
                  <Row className="row mb-2">
                    <Col md={12} lg={12} className="col-12">
                      <h4 className="text-left status">{this.state.heading}</h4>
                    </Col>
                  </Row>
                  {this.state.contentLoader ? (
                    <SkeletonScreen />
                  ) : (
                    <React.Fragment>
                      {this.state.contentTypes.feed &&
                        this.props.feeds.map((feed, i) => (
                          <React.Fragment key={`content-${i}`}>
                            <Content
                              feed={feed}
                              key={`content-${i}`}
                              handleEditContentPopup={this.toggleEditPost}
                              feedIndex={i}
                            />
                          </React.Fragment>
                        ))}
                      {this.state.contentTypes.action &&
                        this.state.actions.map((action, i) => (
                          <Action action={action} key={`action-${i}`} />
                        ))}
                      {this.state.contentNotFound && (
                        <NotFound
                          message={this.state.notFoundMessage}
                          minHeight="20"
                        />
                      )}
                      {this.state.hasMore && (
                        <div className="mt-3">
                          <SkeletonScreen height={130} />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  {this.state.showEditPostModal && (
                    <PostContentFormModal
                      isOpen={this.state.showEditPostModal}
                      closeEditPost={this.toggleEditPost}
                      content_id={this.state.content_id}
                    />
                  )}
                </Col>
              </Row>
            </React.Fragment>
          </Col>
          <Col
            md={3}
            lg={2}
            className="col-12 right-section px-0 text-center mt-3"
          >
            <RightBox
              userDetails={userDetails}
              friends={this.state.totalFriends}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    feeds: state.feeds,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFeed: feeds => {
      dispatch(
        updateFeed({
          feeds
        })
      );
    }
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
