import http from "./http";

const poll = {
  create: params => http.post("/poll/create", params),
  update: params => http.post("/poll/update", params),
  list: param => http.get("/poll/list", { params: param }),
  pollsToVote: param => http.get("/poll/polls-to-vote", { params: param }),
  listAdmin: param => http.get("/poll/list-admin", { params: param }),
  getOne: param => http.get("/poll/get-one", { params: param }),
  getContent: param => http.get("/poll/get-content", { params: param }),
  doVote: params => http.post("/poll/do-vote", params),
  result: param => http.get("/poll/result", { params: param }),
  pollParcentage: (results, option_id) => {
    let totalVotes = poll.totalVotes(results, option_id);
    let totalVotesByOption = poll.totalVotesByOption(results, option_id);
    return Math.ceil((totalVotesByOption / totalVotes) * 100);
  },
  totalVotes: results => {
    let totalVotes = results.reduce(
      (total, result) => parseInt(total) + parseInt(result.totalVote),
      0
    );
    return parseInt(totalVotes);
  },
  totalVotesByOption: (results, option_id) => {
    let options = results.filter(
      option => parseInt(option.answer_id) === parseInt(option_id)
    );
    let totalVotes = options.reduce(
      (total, result) => parseInt(total) + parseInt(result.totalVote),
      0
    );
    return parseInt(totalVotes);
  },
  delete: param => http.post("/poll/delete", param)
};

export default poll;
