import React, { Component } from "react";
import content from "../../../services/content";
import common from "../../../services/common";
import NotFound from "../Common/NotFound";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LeftMenu from "../Common/LeftMenu";
import SkeletonScreen from "../Common/SkeletonScreen";
import Content from "../Feeds/Content";
import { updateFeed } from "../../../store/actions";

class FeedDetails extends Component {
  state = {
    loader: true,
    content: [],
    socialShare: false
  };

  getFeed = id => {
    content.getOne({ id: id }).then(response => {
      if (response.data.success) {
        let feeds = [response.data.content];
        this.props.updateFeed(feeds);
        this.setState({
          content: feeds,
          loader: false
        });
      } else if (response.data.error) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };
  componentDidMount() {
    if (this.props.match.params.id) {
      this.getFeed(this.props.match.params.id);
    }
  }
  toggleSocialShare = () => {
    this.setState(prevState => ({
      socialShare: !prevState.socialShare
    }));
  };
  toggleCommentLoader = () => {
    this.setState(prevState => ({
      commentLoader: !prevState.commentLoader
    }));
  };
  updateTotalComments = totalComments => {
    this.setState({ totalComments: totalComments });
  };
  render() {
    const feed = this.state.content[0];
    return (
      <React.Fragment>
        <Helmet>
          <title> Feed Details : Civ Works</title>
        </Helmet>

        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={9} lg={10} className="col-12 pr-4 mt-3">
            <div className="central-meta create-new-group-form item mb-2 pt-3">
              {this.state.loader ? (
                <div className="mt-4">
                  <SkeletonScreen />
                </div>
              ) : (
                  <Row className="multi-comment pb-3">
                    <Col md={12} lg={12} className="col-12 mb-3 pl-0">
                      <Link to="/dashboard" className="btn btn-link back-btn">
                        <FontAwesomeIcon icon="arrow-left" className="mr-1" />
                        Back
                    </Link>
                    </Col>
                    {this.state.NotFound ? (
                      <NotFound message="Feed not found." icon="card" />
                    ) : (
                        <React.Fragment>
                          <Col md={12} lg={12} className="col-12">
                            <Content
                              feed={feed}
                              key={`content-0`}
                              handleEditContentPopup={this.toggleEditPost}
                              feedIndex={0}
                              allComments={1}
                            />
                          </Col>
                        </React.Fragment>
                      )}
                  </Row>
                )}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    feeds: state.feeds,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFeed: feeds => {
      dispatch(
        updateFeed({
          feeds
        })
      );
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FeedDetails);
