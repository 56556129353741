import React, { Component } from "react";
import blog from "../../../services/blog";
import common from "../../../services/common";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import SkeletonScreen from "../Common/SkeletonScreen";
import Avtar from "../Common/Avtar";
import Likes from "../Likes";
import { toast } from "react-toastify";

class Blogs extends Component {
  state = {
    notFound: false,
    loader: true,
    contents: [],
    pageNo: 1,
    pageSize: 20,
    hasMore: false,
    allButtonActive: true,
    friendButtonActive: false,
    mineButtonActive: false
  };
  getBlogs = type => {
    if (type === "A") {
      this.setState({
        allButtonActive: true,
        friendButtonActive: false,
        mineButtonActive: false
      });
    } else if (type === "F") {
      this.setState({
        allButtonActive: false,
        friendButtonActive: true,
        mineButtonActive: false
      });
    } else if (type === "M") {
      this.setState({
        allButtonActive: false,
        friendButtonActive: false,
        mineButtonActive: true
      });
    }
    this.setState({ loader: true });

    let data = {
      type: type,
      page: this.state.pageNo,
      pageSize: this.state.pageSize
    };

    blog.list(data).then(response => {
      if (response.data.success) {
        this.setState({ loader: false, contents: response.data.contents });
      }
    });
  };

  componentDidMount() {
    this.getBlogs("A");
  }
  deleteBlog = content_id => {
    if (window.confirm("Are you sure to delete this Blog?")) {
      blog.delete({ id: content_id }).then(response => {
        if (response.data.success) {
          let contents = this.state.contents.filter(
            content => content.id !== content_id
          );
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ contents });
        }
      });
    }
  };
  render() {
    return (
      <div className="video-block section-padding">
        <Helmet>
          <title>Blogs : Civ Works</title>
        </Helmet>
        <div className="main-item">
          <React.Fragment>
            <Row>
              <Col md={12} className="px-0">
                <Col md={8} xs={12}>
                  <h2>Blogs </h2>
                </Col>
                <Col md={4} xs={12} className="text-right radar">
                  <div className="add-polls visible-title">
                    <Link
                      to="/blogs/create"
                      className="btn btn-light blue-button"
                    >
                      <FontAwesomeIcon
                        icon="plus"
                        size="1x"
                        className="mr-1"
                        style={{ fontSize: 10 }}
                      />
                      Add new blog post
                    </Link>
                  </div>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="pt-2">
                <ul className="category-links mt-0 mb-3">
                  <li className="nav-item">
                    <span
                      className={`nav-link ${this.state.allButtonActive &&
                        "active"}`}
                      onClick={() => this.getBlogs("A")}
                      style={{ cursor: "pointer" }}
                    >
                      All
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`nav-link ${this.state.friendButtonActive &&
                        "active"}`}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.getBlogs("F")}
                    >
                      Friends
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`nav-link ${this.state.mineButtonActive &&
                        "active"}`}
                      onClick={() => this.getBlogs("M")}
                      style={{ cursor: "pointer" }}
                    >
                      Mine
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            {this.state.loader ? (
              <div className="mt-4">
                <SkeletonScreen />
              </div>
            ) : (
              this.state.contents.map((content, index) => (
                <Row key={`poll-index-key-${index}`}>
                  <Col md={12} xl={12} lg={12} sm={12} className="pt-3">
                    <div className="usr-question pt-3">
                      <div className="col-md-1 pr-0">
                        <Avtar
                          image={content.user.image}
                          size="4x"
                          name={content.user.username}
                          width="30"
                        ></Avtar>
                      </div>
                      <div className="col-md-11 px-0">
                        <div className="col-md-6 px-0">
                          <p className="mb-0 poll-user">{content.user.name}</p>
                        </div>
                        <div className="col-md-6 px-0 text-right">
                          {parseInt(content.user_id) ===
                            parseInt(this.props.userId) && (
                              <div className="ed-opts btn-group">
                                <button
                                  type="button"
                                  className="btn btn-secondary dropdown-toggle"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <FontAwesomeIcon icon="ellipsis-h" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link
                                    to={`/blogs/edit/${content.blog.id}`}
                                    className="dropdown-item"
                                    type="link"
                                  >
                                    Edit
                                  </Link>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() =>
                                      this.deleteBlog(content.blog.content_id)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="col-md-12 px-0">
                          {content.blog.title}
                        </div>
                        <div
                          className="col-md-12 px-0"
                          dangerouslySetInnerHTML={{
                            __html: content.blog.description
                          }}
                        />
                        <div className="col-md-12 px-0 mt-3">
                          <div className="col-md-2 px-0 mt-2">
                            <p
                              className="date-time-info"
                              dangerouslySetInnerHTML={{
                                __html: common.timeSince(content.added_on)
                              }}
                            />
                          </div>
                          <div className="col-md-1 pr-0 mt-2">
                            <Likes
                              totalLikes={content.total_likes}
                              likeArr={content.likes}
                              key={`like-key-${content.id}`}
                              feedId={content.id}
                              enableLike={false}
                              enableStore={false}
                            />
                          </div>
                          <div className="col-md-1 pr-0 mt-2">
                            <span className="comment-icons">
                              <span className="Comments commented">
                                <FontAwesomeIcon icon="comment-dots" />
                              </span>
                              <span className="ml-1">
                                <span className="total-Comments Total-count">
                                  {content.total_comments}
                                </span>
                              </span>
                            </span>
                          </div>

                          <div className="col-md-8 text-right">
                            <div className="tags poll-tag">
                              <a href="#" title="">
                                #mueller
                              </a>
                              <a href="#" title="">
                                #counsel
                              </a>
                              <a href="#" title="">
                                #russia
                              </a>
                              <a href="#" title="">
                                <FontAwesomeIcon icon="globe" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl
  };
};
export default connect(mapStateToProps)(Blogs);
