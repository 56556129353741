import React, { Component } from "react";
import user from "../../../services/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Row,
  Col,
  CustomInput
} from "reactstrap";
import { connect } from "react-redux";

class IssuesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      selected_issues: [],
      error: false,
      loader: false
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.selected_issues.length === 0) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false, disabled: true, loader: true });
      let data = {
        issues: this.state.selected_issues,
        user_id: this.props.userId
      };

      let that = this;
      user
        .saveIssues(data)
        .then(function(response) {
          if (response.data.error) {
            console.log(response.data.error);
          } else if (response.data.success) {
            that.props.handleToggleModal("save");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  };
  handleChange = e => {
    let issues = this.state.selected_issues;
    if (e.target.checked) {
      issues.push(e.target.value);
      this.setState({ selected_issues: issues });
    } else {
      let index_to_be_removed = issues.indexOf(e.target.value);
      issues.splice(index_to_be_removed, 1);
      this.setState({ selected_issues: issues });
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form action="" method="post" onSubmit={this.handleSubmit}>
          <ModalHeader>
            Please tell us which issues you are interested in. We will use this
            information to send you action alerts for the issues that are
            important to you.
          </ModalHeader>

          <ModalBody style={{ minHeight: 200 }}>
            {this.props.issueLoader && (
              <div className="text-center">
                <Spinner color="dark" />
              </div>
            )}
            {this.state.error && (
              <p style={{ color: "red" }}>Please choose at least one issue.</p>
            )}
            <Row className="modalCat issue-modal">
              {this.props.issues.length > 0
                ? this.props.issues.map(category => (
                    <Col md={4} className="mb-2" key={category.id}>
                      <CustomInput
                        type="checkbox"
                        label={category.name}
                        value={category.id}
                        id={category.id}
                        onChange={this.handleChange}
                      />
                    </Col>
                  ))
                : null}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              type="submit"
              disabled={this.state.disabled}
            >
              {this.state.loader && (
                <Spinner size="sm" color="#887d7d" className="mr-1" />
              )}
              Save
            </Button>
            <Button
              color="secondary"
              outline
              type="cancel"
              onClick={() => this.props.handleToggleModal("skip")}
              disabled={this.state.disabled}
            >
              Skip <FontAwesomeIcon icon="forward" className="ml-1" />
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: state.userId
  };
};

export default connect(mapStateToProps)(IssuesModal);
