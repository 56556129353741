import React, { Component } from "react";
import message from "../../../services/message";
import { Row, Col, Form, FormGroup, Label, Card, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { toast } from "react-toastify";

class Read extends Component {
  state = {
    message: {},
    isloader: true,
    recipients: [],
    submitted: false,
    attachments: []
  };
  componentDidMount() {
    if (this.props.match.params.id) {
      this.getMessage(this.props.match.params.id);
    }
  }
  getMessage = id => {
    let param = {
      id: id
    };
    message.getOne(param).then(response => {
      if (response.data.success) {
        this.setState({
          message: response.data.message,
          isloader: false,
          attachments: response.data.message.attachments,
          recipients: response.data.recipients.map(
            recipient => recipient.user.username
          )
        });
      }
    });
  };
  deleteMessage = (id, folder) => {
    this.setState({ submitted: true });
    let param = {
      id: id,
      folder: folder
    };
    message.delete(param).then(response => {
      if (response.data.success) {
        this.setState({ submitted: false });
        this.props.history.push("/messages");
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };
  render() {
    return (
      <div className="video-block section-padding">
        <Helmet>
          <title>Compose message : Civ Works</title>
        </Helmet>
        <div className="main-item">
          <Form method="post" onSubmit={this.handleSubmit}>
            <Row>
              <Col md={12} className="px-3">
                <Col
                  md={12}
                  className="px-0"
                  style={{ borderBottom: "2px solid" }}
                >
                  <Col md={6} className="px-0">
                    <h2>{this.state.message.subject}</h2>
                  </Col>
                  <Col md={6} className="px-0 text-right">
                    <Link
                      to={"/messages/reply/" + this.state.message.id}
                      className="btn blue-button mr-2"
                    >
                      Reply
                    </Link>
                    <button
                      type="button"
                      className="btn blue-button mr-2"
                      disabled={this.state.submitted}
                    >
                      {this.state.submitted && (
                        <FontAwesomeIcon
                          icon="spinner"
                          className="mr-1"
                          spin={true}
                        />
                      )}
                      Mark as read
                    </button>
                    <button
                      type="button"
                      className="btn blue-button mr-2"
                      disabled={this.state.submitted}
                    >
                      {this.state.submitted && (
                        <FontAwesomeIcon
                          icon="spinner"
                          className="mr-1"
                          spin={true}
                        />
                      )}
                      Mark as unread
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      disabled={this.state.submitted}
                      onClick={() =>
                        this.deleteMessage(
                          this.props.match.params.id,
                          this.props.match.params.folder
                        )
                      }
                    >
                      {this.state.submitted && (
                        <FontAwesomeIcon
                          icon="spinner"
                          className="mr-1"
                          spin={true}
                        />
                      )}
                      Delete
                    </button>
                  </Col>
                </Col>
                <Col md={12} className="px-0 mt-3">
                  <Card>
                    <LoadingOverlay
                      active={this.state.isloader}
                      spinner={<Spinner color="dark" />}
                      fadeSpeed={200}
                      classNamePrefix="mitiz"
                    >
                      <Row>
                        <Col md={12} className="p-4">
                          <FormGroup>
                            <Label for="tags">Recipients</Label>
                            {this.state.recipients.length > 0 &&
                              this.state.recipients.map((recipient, index) => (
                                <div key={`recipient-${index}`}>
                                  <strong>{recipient}</strong>
                                </div>
                              ))}
                          </FormGroup>
                          <FormGroup
                            dangerouslySetInnerHTML={{
                              __html: this.state.message.message
                            }}
                          />
                        </Col>
                      </Row>
                      {this.state.attachments.length > 0 && (
                        <Row>
                          <Col md={12} key={Math.random(2)} className="p-4">
                            {this.state.attachments.map(attachment => (
                              <img
                                src={
                                  this.props.baseUrl +
                                  "/images/" +
                                  attachment.name
                                }
                                width="150"
                                className="img-responsive"
                                key={Math.random(2)}
                              />
                            ))}
                          </Col>
                        </Row>
                      )}
                    </LoadingOverlay>
                  </Card>
                </Col>
                <Col md={12} className="px-0 text-right mb-3">
                  <Link to="/messages" className="btn btn-primary">
                    <FontAwesomeIcon icon="undo" className="mr-1" />
                    Back
                  </Link>
                </Col>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    baseUrl: state.baseUrl
  };
};
export default withRouter(connect(mapStateToProps)(Read));
