import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
import Avtar from "../Common/Avtar";
import UserDetails from "./UserDetails";

class RightBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="widgets widgets-coment">
          <div className="Navigation text-left mt-3 mb-3">
            <h4 className="Act text-left pl-3 mb-3 pt-2">
              <b>Navigation</b>
            </h4>
            <div className="menu-block">
              <ul>
                <li>
                  <NavLink
                    to={`/profile/${this.props.userDetails.username}`}
                    activeClassName="is-active"
                    exact={true}
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/profile/${this.props.userDetails.username}/feed-activity`}
                    activeClassName="is-active"
                    exact={true}
                  >
                    Feed/Activity
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/profile/${this.props.userDetails.username}/actions`}
                    activeClassName="is-active"
                    exact={true}
                  >
                    Actions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/profile/${this.props.userDetails.username}/discussions`}
                    activeClassName="is-active"
                    exact={true}
                  >
                    Discussions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/profile/${this.props.userDetails.username}/polls`}
                    activeClassName="is-active"
                    exact={true}
                  >
                    Polls
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to={`/profile/${this.props.userDetails.username}/blog`}
                    activeClassName="is-active"
                    exact={true}
                  >
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/profile/${this.props.userDetails.username}/bookmarks`}
                    activeClassName="is-active"
                    exact={true}
                  >
                    Bookmarks
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/profile/${this.props.userDetails.username}/files`}
                    activeClassName="is-active"
                    exact={true}
                  >
                    Files
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="widgets widgets-coment">
          <div className="sd-title">
            <h6 className="widgets-title text-left">
              <b>Friends </b>
            </h6>
            <div className="col-md-12 px-0">
              <div className="members-img">
                {this.props.friends.map((friend, index) => {
                  let user;
                  if (
                    parseInt(friend.firstuser.id) !==
                    parseInt(this.props.userDetails.id)
                  ) {
                    user = friend.firstuser;
                  } else {
                    user = friend.seconduser;
                  }
                  return (
                    <div
                      className={`member-auth-${index + 1}`}
                      key={`group-member-key-${index}`}
                    >
                      <Link to={`/profile/${user.username}`}>
                        <Avtar
                          image={user.image}
                          image_thumb={user.image_thumb}
                          size="3x"
                          name={user.username}
                          width="48"
                        />
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
            {this.props.friends.length > 5 && (
              <button
                type="button"
                className="btn btn-link action-history mt-3 mb-3"
              >
                view all
              </button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapPropsToState = state => {
  return {
    friendRequests: state.friendRequests,
    baseUrl: state.baseUrl
  };
};

export default connect(mapPropsToState)(RightBox);
