import http from "./http";

const news = {
  create: params => http.post("/news/create", params),
  update: params => http.post("/news/update", params),
  markFeatured: params => http.post("/news/mark-featured", params),
  list: param => http.get("/news/list", { params: param }),
  getOne: param => http.get("/news/get-one", { params: param }),
  delete: param => http.post("/news/delete", param)
};
export default news;
