import React, { Component } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import "../../Front.scss";
import routes from "../../front-routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import Notification from "../../views/Front/Notifications/Notification";
import { updateNotification, setSideMenu, saveFriendRequests } from "../../store/actions";
import { Card, CardFooter, Row } from "reactstrap";
import user from "../../services/user";
import page from "../../services/page";
import notification from "../../services/notification";
import content from "../../services/content";
import StripePricingModal from "../../views/Front/Common/StripePricingModal ";
import action from "../../services/action";

class FrontLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      leftMenu: [
        {
          icon: "book-open",
          title: "Dashboard",
          url: "/dashboard",
          new_window: "N",
        },
        {
          icon: "envelope-open",
          title: "Messages",
          url: "/messages",
          new_window: "N",
        },
        {
          icon: "bell",
          title: "Notifications",
          url: "/notifications",
          new_window: "N",
        },
        {
          icon: "user-friends",
          title: "Friends",
          url: "/friends",
          new_window: "N",
        },
        {
          icon: "users",
          title: "Groups",
          url: "/groups",
          new_window: "N",
        },
        {
          icon: "newspaper",
          title: "News",
          url: "/news",
          new_window: "N",
        },
        {
          icon: "chart-bar",
          title: "Polls",
          url: "/polls",
          new_window: "N",
        },
        {
          icon: "bullhorn",
          title: "Actions",
          url: "/actions",
          new_window: "N",
        },

        {
          icon: "chart-bar",
          title: "Activity ",
          url: "/activity",
          new_window: "N",
        },

      ],
      new: 0,
      subscriptionStatus: "",
      isfreeTrial:false,
      showSubsriptionModel: false,
      showPricingTable: false,
      email: ''
    };
  }
  componentWillMount() {
    this.props.setSideMenu(this.state.leftMenu);
  }
  componentDidMount = () => {
    this.getSubscriptionStatus();
    page.list().then(response => {
      if (response.data.success) {
        let additionalMenu = [];
        let j = 0;
        for (var i = 0; i < response.data.contents.length; i++) {
          if (response.data.contents[i].left_menu === "Y") {
            additionalMenu[j] = {
              icon: "circle",
              title: response.data.contents[i].name,
              url: response.data.contents[i].new_window === "N" ? `/page/${response.data.contents[i].url}` : response.data.contents[i].url,
              new_window: response.data.contents[i].new_window,
            }
            j++;
          }
        }
        let leftMenu = [...this.state.leftMenu, ...additionalMenu];
        this.props.setSideMenu(leftMenu);
      }
    }).catch(function (error) {
      console.log(error);
    });

    user.getFriendRequests().then(response => {
      if (response.data.success) {
        this.props.saveFriendRequests(response.data.requests);
      }
    });
  };

  getSubscriptionStatus = () => {
    content.getSubsriptionStatus().then((response) => {
      if (response.data.user) {
        const emailData = response.data.user.email
        this.setState({ email: emailData })
      }
      if (response.data.success) {

        // const subscription = response.data.user.subscription;
        // const today = new Date();
        // today.setHours(0, 0, 0, 0);
        if (response.data.showStripeModel == false) {
          const trailDate = new Date(response.data.freeTrialEndDate);
          const currentDate = new Date();
          this.setState({
            subscriptionStatus: "active",
            isfreeTrial: trailDate > currentDate ? true:false
          });
        }
      } else {
        this.setState({
          loader: false,
        });
      }
    }).catch((error) => {
      console.error("Error fetching subscription status:", error);
      this.setState({
        loader: false,
      });
    });
  };

  handleChange = (e) => {
    let keyword = e.target.value;
    this.setState({ keyword });
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.history.push("/search/" + encodeURIComponent(this.state.keyword));

  }

  handleLogout = () => {
    const userId = localStorage.getItem("userId");
    console.log(userId); // Check if userId is retrieved correctly

    action.logOut({ id: userId })
      .then(response => {
        if (response.data.success) {
          console.log("Logged out successfully");
          // Additional logout logic (clearing local storage, redirecting, etc.)
        }
      })
      .catch(error => {
        console.error("Logout failed:", error);
        // Handle logout error (show message, retry, etc.)
      });
  };

  markNotifiationRead = () => {
    notification.read().then(response => {
      if (response.data.success) {
        this.setState({ new: parseInt(response.data.new) });
      }
    })
  }
  handleClose = () => {
    this.setState({ showPricingTable: false });
  };
  render() {
    const emaildemo = this.state.email
    return (
      <div>
        <StripePricingModal
          show={this.state.showPricingTable}
          onClose={this.handleClose}
          customerEmail={emaildemo}
        />
        <ToastContainer />
        <header>
          <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark brand-header px-lg-5 px-md-5">
            <div className="container-fluid px-lg-5 pr-lg-0 nav-main">
              <a className="navbar-brand brand-logo" href="/dashboard">
                <img
                  src={require("../../assets/images/logo.png")}
                  alt="Civ.Works"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarsExample07"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarsExample07">
                <div className="searching">
                  <form className="form-inline" action="" onSubmit={this.handleSubmit}>
                    <button className="brand-serach-btn" type="button">
                      <FontAwesomeIcon icon="search" className="mr-2" />
                    </button>
                    <input
                      className="form-control"
                      type="text"
                      name="search"
                      placeholder="Search for people or issues..."
                      value={this.state.keyword}
                      onChange={this.handleChange}
                    />
                  </form>
                </div>
                <ul className="navbar-nav ml-auto brand-nav-icon">
                  <li className="nav-item">
                    <Link className="nav-link px-lg-3" to="/friends/invite">
                      <FontAwesomeIcon icon="user-plus" className="mr-2" />
                      Invite friends
                    </Link>
                  </li>
                  <li className="nav-item dropdown user-dropdown">
                    <a
                      className="nav-link px-lg-3 dropdown-toggle"
                      id="dropdown07"
                      data-toggle="dropdown"
                      href="#"
                    >
                      <FontAwesomeIcon icon="bell" className="mr-2" />
                      Notifications
                    </a>
                    {this.state.new > 0 && <span className="circle" />}
                    <div
                      className="dropdown-menu notify-dropdown dropdown-menu-left py-0"
                      aria-labelledby="dropdown07"
                    >
                      <div className="col-md-12 col-12 col-lg-12 px-0">
                        <Card
                          className="rounded-top border-top-0 mb-0"
                          id="notification-container"
                        >
                          <Notification scrollHeight={300} />
                          <CardFooter className="text-center border-top-0 py-0">
                            <Row className="justify-content-between align-items-center">
                              <div className="col-auto">
                                <button
                                  type="button"
                                  className="btn btn-link text-decoration-none"
                                  onClick={this.markNotifiationRead}
                                >
                                  Mark All as Read
                                </button>
                              </div>
                              <div className="col-auto">
                                <h6 className="card-header-title mb-0">
                                  <Link to="/notifications"
                                    className="card-link font-weight-normal"

                                  >
                                    See All
                                  </Link>
                                </h6>
                              </div>
                            </Row>
                          </CardFooter>
                        </Card>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="add-listing">
                      {this.state.subscriptionStatus === "active" ? (
                        <a
                          href="/account/Subscriptions"
                          className="btn btn-success supporter"
                        >
                           {this.state.isfreeTrial?"Trial Subscription":"Manage Subscription"}
                        </a>
                      ) : (
                        <button
                          className="btn btn-success supporter"
                          onClick={() => this.setState({ showPricingTable: true })}
                        >
                          Become a Subscriber
                        </button>
                      )}
                    </div>
                  </li>

                  <li className="nav-link user-toggle user-menu">
                    <div className="nav-link dropdown-toggle user-toggle btn-group">
                      {this.props.userImage !== null &&
                        this.props.userImage !== "null" ? (
                        <img
                          style={{ borderRadius: 15, cursor: "pointer", width: 45 }}
                          src={`${this.props.baseUrl}/images/${this.props.userImageThumb
                            ? this.props.userImageThumb
                            : this.props.userImage
                            }`}
                          alt={this.props.userName}
                          className="dropdown-toggle rounded-circle"
                          data-toggle="dropdown"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon="user-circle"
                          size="3x"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          style={{ cursor: "pointer" }}
                        />
                      )}

                      <div className="dropdown-menu dropdown-menu-right">
                        <Link
                          to="/account/profile-settings"
                          className="dropdown-item"
                        >
                          Settings
                        </Link>
                        <Link to="/logout" className="dropdown-item" onClick={this.handleLogout}>
                          Logout
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="nav-scroller bg-dark shadow-sm brand-scroller"></div>
        </header>
        <section>
          <div className="container">
            <Switch>
              {!localStorage.token && <Redirect to="/login" />}
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <route.component
                        {...props}
                        leftMenu={route.leftMenu}
                        rightBox={route.rightBox}
                      />
                    )}
                  />
                ) : null;
              })}
            </Switch>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications,
    userName: state.userName,
    userImage: state.userImage,
    userImageThumb: state.userImageThumb,
    baseUrl: state.baseUrl,
    sideMenu: state.sideMenu
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateNotification: notifications => {
      dispatch(
        updateNotification({
          notifications
        })
      );
    },
    saveFriendRequests: friendRequests => {
      dispatch(
        saveFriendRequests({
          friendRequests
        })
      );
    },
    setSideMenu: sideMenu => {
      dispatch(
        setSideMenu({
          sideMenu
        })
      );
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FrontLayout);
