import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Spinner,
    Button,
    InputGroup,
    InputGroupAddon,
} from "reactstrap";
import RightMenu from "./RightMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import account from "../../../services/account";
import { toast } from "react-toastify";
import content from "../../../services/content";
import Friend from "../Friends/Friend";
import SkeletonScreen from "../Common/SkeletonScreen";
import LeftMenu from "../Common/LeftMenu";
import NotFound from "../Common/NotFound";
import user from "../../../services/user";
import Request from "../Friends/Request";
import Avtar from "../Common/Avtar";
import { Link } from "react-router-dom";
import common from "../../../services/common";

class Connections extends Component {
    state = {
        issueLoader: false,
        referralCode: "",
        isLoader: false,
        errors: {},
        fields: {},
        activeTab: "home",
        friends: [],
        loader: false,
        requests: [],
        Invites: [],
        noInvites: false,
        requestNotFount: false,
        notFound: false,
        inputFieldValue: "",
    };

    handleInviteCode = (e) => {
        this.setState({ loader: true });
        content
            .getInviteCode()
            .then((response) => {
                if (response.data.success) {
                    window.location.reload();
                    this.setState({ loader: false });
                } else if (response.data.error) {
                    this.setState({ loader: false });
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                console.error("Error fetching invite code:", error);
                this.setState({ loader: false });
            });
    };

    handleChangeReferralCode = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields, inputFieldValue: e.target.value });
    };

    getReferralCode = () => {
        account.getReferrelCode().then((response) => {
            if (response.data.success) {
                this.setState({
                    referralCode: response.data.referralCode,
                    isLoader: false,
                });
            }
        }).catch(error => {
            console.error('Error fetching referral code:', error);
            this.setState({ isLoader: false });
        });
    };

    updateFriends = friends => {
        this.setState({ friends });
    };

    handleSubmitReferrelCode = (e) => {
        e.preventDefault();
        this.setState({ isLoader: true });
        const { fields } = this.state;
        const data = {
            ...fields,
        };
        content.postInvite_Code(data).then((response) => {
            this.setState({ isLoader: false }, () => {
                if (response.data.success) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else if (response.data.error) {
                    toast.error(response.data.message);
                }
            });
        });
    };

    componentDidMount = () => {
        this.getReferralCode();
        this.getFriends();
        this.getFriendRequests();
        this.getFriendInvite()
    };

    getFriendRequests = () => {
        if (this.state.pageNo > 1) {
            this.setState({ loader: true });
        }

        user.getFriendRequests().then(response => {
            if (response.data.success) {
                this.setState({
                    requests: response.data.requests,
                    loader: false,
                    requestNotFount: response.data.requests.length > 0 ? false : true
                });
            }
        });
    };
    getFriendInvite = () => {
        if (this.state.pageNo > 1) {
            this.setState({ loader: true });
        }

        user.getFriendInvite().then(response => {
            if (response.data.success) {
                this.setState({
                    Invites: response.data.requests,
                    loader: false,
                    noInvites: response.data.requests.length > 0 ? false : true
                });
            }
        });
    };

    updateRequests = requests => {
        this.setState({ requests });
    };
    getFriends = () => {
        let data = { keyword: this.state.keyword }
        user.getFriends(data).then(response => {
            if (response.data.success) {
                this.setState({
                    friends: response.data.friends,
                    loader: false,
                    notFound: response.data.friends.length > 0 ? false : true
                });
            }
        });
    }

    setActiveTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    handleCancelRequest = (userId) => {
        // Assuming you have a function to remove friend request in your user service, adjust this accordingly
        user.cancelFriendRequest({
            second_user_id: userId
        }).then(response => {
            if (response.data.success) {
                this.getFriendInvite()
                // const updatedInvites = [...this.state.Invites];
                // updatedInvites.splice(index, 1);
                // this.setState({ Invites: updatedInvites });
            } else {
                // Handle error
            }
        }).catch(error => {
            // Handle error
        });
    };
    render() {
        const { activeTab, friends, loader, notFound, requests, requestNotFount, inputFieldValue } = this.state;
        const isAddFriendButtonDisabled = inputFieldValue.trim() === "";

        return (
            <React.Fragment>
                <Helmet>
                    <title>Connections: Civ Works</title>
                </Helmet>
                <Row className="mt-4">
                    <Col md={7} lg={9} className="col-12 ml-lg-auto mt-5 pt-2">
                        <Row className="mb-2">
                            <Col md={12} lg={6} className="col-8">
                                <h4 className="text-left status">Connections</h4>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <div className="user-post central-meta create-new-group-form item mb-2 Interests">
                                <h4 className="mb-4">Invite Friends</h4>

                                {this.state.issueLoader && (
                                    <React.Fragment>
                                        <Spinner size="sm" color="dark" /> Please wait..
                                    </React.Fragment>
                                )}

                                <Row>
                                    <Col md={12}>
                                        <Label for="description">Invite Code</Label>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <InputGroup>
                                                <Input
                                                    bsSize="lg"
                                                    type="text"
                                                    name="getInviteCode"
                                                    id="getInviteCode"
                                                    value={this.state.referralCode}
                                                    className="input-bg"
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        color="success"
                                                        size="lg"
                                                        style={{ padding: "0px 32px" }}
                                                        onClick={this.handleInviteCode}
                                                        disabled={this.state.referralCode}
                                                    >
                                                        {this.state.loader ? (
                                                            <Spinner size="sm" color="light" />
                                                        ) : (
                                                            "Generate"
                                                        )}
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <Label for="description">Enter Invite Code</Label>
                                    </Col>

                                    <Col md={12}>
                                        <FormGroup>
                                            <InputGroup>
                                                <Input
                                                    bsSize="lg"
                                                    type="text"
                                                    name="referralCode"
                                                    id="referralCode"
                                                    value={this.state.fields["referralCode"]}
                                                    onChange={(event) =>
                                                        this.handleChangeReferralCode("referralCode", event)
                                                    }
                                                    invalid={
                                                        this.state.errors["referralCode"] ? true : false
                                                    }
                                                    className="input-bg"
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button
                                                        color="success"
                                                        size="lg"
                                                        style={{ padding: "0px 25px" }}
                                                        onClick={this.handleSubmitReferrelCode}
                                                        disabled={this.state.isLoader || isAddFriendButtonDisabled}
                                                    >
                                                        {this.state.isLoader && (
                                                            <FontAwesomeIcon
                                                                icon="spinner"
                                                                className="mr-1"
                                                                spin={true}
                                                            />
                                                        )}
                                                        Add Friend
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Row>

                        <Row className="mb-2">
                            <Col md={12}>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${activeTab === "home" ? "active" : ""}`}
                                            id="home-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#home"
                                            type="button"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected={activeTab === "home"}
                                            onClick={() => this.setActiveTab("home")}
                                        >
                                            Friends
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${activeTab === "profile" ? "active" : ""}`}
                                            id="profile-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="profile"
                                            aria-selected={activeTab === "profile"}
                                            onClick={() => this.setActiveTab("profile")}
                                        >
                                            Friends Request
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${activeTab === "contact" ? "active" : ""}`}
                                            id="contact-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#contact"
                                            type="button"
                                            role="tab"
                                            aria-controls="contact"
                                            aria-selected={activeTab === "contact"}
                                            onClick={() => this.setActiveTab("contact")}
                                        >
                                            Invite Send
                                        </button>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={12}>
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className={`tab-pane fade ${activeTab === "home" ? "show active" : ""}`}
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                    >
                                        <Row className="mt-2">
                                            <Col md={12} lg={12} className="col-12">
                                                <Row>
                                                    <Col md={12} lg={12} className="col-12 overflow-auto-class">
                                                        <div
                                                            className="central-meta create-new-group-form-setting mb-2"
                                                            style={{ minHeight: 500 }}
                                                        >
                                                            <Row>
                                                                <Col md={12} lg={12} className="col-12">
                                                                    <h5 className="mb-4">
                                                                        My Friends ({friends.length})
                                                                    </h5>
                                                                </Col>
                                                            </Row>
                                                            {loader && (
                                                                <div className="mt-4">
                                                                    <SkeletonScreen height={100} />
                                                                </div>
                                                            )}
                                                            {friends.length > 0 ? (
                                                                friends.map((friend, indx) => (
                                                                    <Friend
                                                                        key={`friends-key-${indx}`}
                                                                        friend={friend}
                                                                        friends={friends}
                                                                        removeFriend={this.removeFriend}
                                                                        index={indx}
                                                                        updateFriends={this.updateFriends}
                                                                    />
                                                                ))
                                                            ) : (
                                                                !loader && (
                                                                    <NotFound message="No friends found. 
                                                                    `." />
                                                                )
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div
                                        className={`tab-pane fade ${activeTab === "profile" ? "show active" : ""}`}
                                        id="profile"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                        <Row className="mt-2">
                                            <Col md={12} lg={12} className="col-12 ">
                                                <Row>
                                                    <Col md={12} lg={12} className="col-12 overflow-auto-class">
                                                        <div
                                                            className="central-meta create-new-group-form-setting mb-2"
                                                            style={{ minHeight: 500 }}
                                                        >
                                                            <Row>
                                                                <Col md={12} lg={12} className="col-12">
                                                                    <h5 className="mb-4">
                                                                        Friend Request ({requests.length})
                                                                    </h5>
                                                                </Col>
                                                            </Row>
                                                            {requests.length > 0 &&
                                                                requests.map((request, indx) => (
                                                                    <Request
                                                                        key={`friends-key-${indx}`}
                                                                        request={request}
                                                                        requests={requests}
                                                                        index={indx}
                                                                        confirmRequestButton={true}
                                                                        declineRequestButton={true}
                                                                        updateRequests={this.updateRequests}
                                                                    />
                                                                ))}
                                                            {this.state.requestNotFount && (
                                                                <NotFound message="Not friend requests found." />
                                                            )}
                                                            {this.state.loader && (
                                                                <div className="mt-4">
                                                                    <SkeletonScreen height={100} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div
                                        className={`tab-pane fade ${activeTab === "contact" ? "show active" : ""}`}
                                        id="contact"
                                        role="tabpanel"
                                        aria-labelledby="contact-tab"
                                    >
                                        <Row className="mt-2">
                                            <Col md={12} lg={12} className="col-12 overflow-auto-class" >
                                                <div className="central-meta create-new-group-form-setting mb-2" >
                                                    <Row>
                                                        <Col md={12} lg={12} className="col-12 invite-friend-custom-design">
                                                            <div className="">
                                                                <Row>
                                                                    <Col md={12} lg={12} className="col-12">
                                                                        <h5 className="mb-4">
                                                                            Invite Send ({this.state.Invites.length})
                                                                        </h5>
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                    {this.state.Invites.map(invite => (
                                                        <div key={invite.id} className="row align-items-center mb-3">
                                                            <div className="col-2 col-md-3 col-lg-1">
                                                                {invite.seconduser.image ? (
                                                                    <Avtar
                                                                        image={invite.seconduser.image}
                                                                        image_thumb={invite.seconduser.image_thumb}
                                                                        size="3x"
                                                                        name={invite.seconduser.username}
                                                                        width="45"
                                                                    />
                                                                ) : (
                                                                    <Avtar
                                                                        image={invite.seconduser.image}
                                                                        image_thumb={invite.seconduser.image_thumb}
                                                                        size="3x"
                                                                        name={invite.seconduser.username}
                                                                        width="45"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="col-5 request-info col-md-9 col-lg-5 friend-invite-custom">
                                                                <h5 className="mb-0">
                                                                <Link to={`/profile/${invite.seconduser.username}`}>
                                                                    {invite.seconduser.name !== "" ?
                                                                        common.ucwords(invite.seconduser.name) :
                                                                        invite.seconduser.name
                                                                    }
                                                                </Link>
                                                                </h5>
                                                                <span>{invite.friendCount} {invite.friendCount === 1 ? 'Friend' : 'Friends'} </span>
                                                            </div>
                                                            <div className="col-5 request-info text-lg-right col-md-12 col-lg-6 friend-invite-custom">
                                                                <button 
                                                                    type="button"
                                                                    className={`btn btn-danger cbd-color cp btn-sm  mr-3 ${this.props.disableButton ? 'disabled' : ''}`}
                                                                    onClick={() => this.handleCancelRequest(invite.second_user_id)}
                                                                    disabled={this.props.disableButton}
                                                                >
                                                                    {this.props.disableButton && (
                                                                        <FontAwesomeIcon icon="spinner" className="mr-1" spin={true} />
                                                                    )}
                                                                    Cancel Friend Request
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>

                    <Col
                        md={3}
                        lg={2}
                        className="col-12 right-section px-0 text-center mt-lg-5 pt-1 ml-lg-3 mt-md-5"
                    >
                        <RightMenu />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.userId,
        baseUrl: state.baseUrl,
        apiUrl: state.apiUrl,
    };
};

export default connect(mapStateToProps)(Connections);
