import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import user from "../../../services/user";
import SkeletonScreen from "../Common/SkeletonScreen";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import NotFound from "../Common/NotFound";
import LeftMenu from "../Common/LeftMenu";
import RightBox from "./RightBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateCollection from "./CreateCollection";
import { toast } from "react-toastify";

class Collections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: [],
      loader: true,
      notFound: false,
      showModal: false,
      collectionId: ""
    };
  }
  toggleModal = e => {
    if (e !== undefined) {
      this.setState({ collectionId: "" });
    }
    this.setState(prevState => ({
      showModal: !prevState.showModal,
      collectionId: prevState.collectionId
    }));
  };
  getFriendCollections = () => {
    user.getFriendCollections().then(response => {
      if (response.data.success) {
        this.setState({
          collections: response.data.collections,
          loader: false,
          notFound: response.data.collections.length > 0 ? false : true
        });
      }
    });
  };
  componentDidMount() {
    this.getFriendCollections();
  }
  editCollection = collectionId => {
    this.setState({ collectionId });
    this.toggleModal();
  };
  deleteFriendCollection = collection_id => {
    if (window.confirm("Are you sure to delete this collection?")) {
      user.deleteFriendCollection({ id: collection_id }).then(response => {
        if (response.data.success) {
          let collections = this.state.collections.filter(
            collection => collection.id !== collection_id
          );
          let notFound = false;
          this.setState({
            collections,
            notFound: collections.length > 0 ? false : true
          });
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Friends Collections : Civ Works</title>
        </Helmet>
        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={6} lg={8} className="col-12 mt-5 pt-lg-4">
            <Row>
              <Col md={12} lg={12} className="col-12">
                <h4 className="status"> Friends</h4>
                <div
                  className="central-meta create-new-group-form mb-2"
                  style={{ minHeight: 500 }}
                >
                  <Row>
                    <Col md={6} lg={6} className="col-12">
                      <h5 className="mb-4">
                        Friends Collections ({this.state.collections.length})
                      </h5>
                    </Col>
                    <Col md={6} lg={6} className="col-12 text-right">
                      <button
                        type="button"
                        className="btn btn-outline-danger New-Collection-btn"
                        onClick={this.toggleModal}
                      >
                        New Collection
                      </button>
                    </Col>
                  </Row>
                  {this.state.collections.length > 0 &&
                    this.state.collections.map(collection => (
                      <Card key={`collection-key-${collection.id}`}>
                        <CardBody className="py-3">
                          <Row>
                            <Col
                              md={3}
                              lg={1}
                              className="col-2 pt-2 Friends-Collect-icon"
                            >
                              <FontAwesomeIcon icon="users" />
                            </Col>
                            <Col
                              md={9}
                              lg={5}
                              className="col-5 pt-2 request-info"
                            >
                              <h5 className="mb-0"> {collection.name}</h5>
                              <span>
                                {collection.friends.length}
                                {collection.friends.length > 1
                                  ? " peoples"
                                  : " people"}
                              </span>
                            </Col>
                            <Col
                              md={12}
                              lg={6}
                              className="col-5 pt-2 text-right"
                            >
                              <button
                                type="button"
                                className="btn btn-link Friends-Collect-btn mr-1"
                                onClick={() =>
                                  this.editCollection(collection.id)
                                }
                              >
                                <FontAwesomeIcon icon="pencil-alt" />
                              </button>
                              <button
                                type="button"
                                className="btn btn-link  Friends-Collect-btn"
                                onClick={() =>
                                  this.deleteFriendCollection(collection.id)
                                }
                              >
                                <FontAwesomeIcon icon="trash" />
                              </button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ))}
                  {this.state.loader && (
                    <div className="mt-4">
                      <SkeletonScreen height={100} />
                    </div>
                  )}

                  {this.state.notFound && (
                    <NotFound message="No collections found." />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            md={3}
            lg={2}
            className="col-12 right-section px-0 text-center mt-lg-4 pt-lg-4"
          >
            <RightBox />
          </Col>
        </Row>
        {this.state.showModal && (
          <CreateCollection
            showModal={this.state.showModal}
            closeModal={this.toggleModal}
            collectionId={this.state.collectionId}
            toggleModal={this.toggleModal}
            getFriendCollections={this.getFriendCollections}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Collections);
