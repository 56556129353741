import React, { Component } from "react";
import poll from "../../../services/poll";
import common from "../../../services/common";
import NotFound from "../Common/NotFound";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LeftMenu from "../Common/LeftMenu";
import SkeletonScreen from "../Common/SkeletonScreen";
import Avtar from "../Common/Avtar";
import Poll from "../Poll/Poll";
import Likes from "../Likes";
import SocialShare from "../Feeds/SocialShare";
import CommentContainer from "../Feeds/CommentContainer";
import { updateFeed } from "../../../store/actions";

class PollDetails extends Component {
  state = {
    loader: true,
    content: [],
    socialShare: false
  };

  getPoll = id => {
    poll.getContent({ id: id }).then(response => {
      if (response.data.success) {
        let feeds = [response.data.content];
        this.props.updateFeed(feeds);
        this.setState({
          content: feeds,
          loader: false
        });
      } else if (response.data.error) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };
  componentDidMount() {
    if (this.props.match.params.id) {
      this.getPoll(this.props.match.params.id);
    }
  }
  toggleSocialShare = () => {
    this.setState(prevState => ({
      socialShare: !prevState.socialShare
    }));
  };
  toggleCommentLoader = () => {
    this.setState(prevState => ({
      commentLoader: !prevState.commentLoader
    }));
  };
  updateTotalComments = totalComments => {
    this.setState({ totalComments: totalComments });
  };
  render() {
    const content = this.state.content[0];
    return (
      <React.Fragment>
        <Helmet>
          <title> Poll : Civ Works</title>
        </Helmet>

        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={9} lg={10} className="col-12 pr-4 mt-3">
            <div className="central-meta create-new-group-form item mb-2 pt-3">
              {this.state.loader ? (
                <div className="mt-4">
                  <SkeletonScreen />
                </div>
              ) : (
                <Row className="multi-comment pb-3">
                  <Col md={12} lg={12} className="col-12 mb-3 pl-0">
                    <Link to="/polls" className="btn btn-link back-btn">
                      <FontAwesomeIcon icon="arrow-left" className="mr-1" />
                      Back
                    </Link>
                  </Col>
                  {this.state.NotFound ? (
                    <NotFound message="Polls not found." icon="poll" />
                  ) : (
                    <React.Fragment>
                      <Col md={12} lg={1} className="col-12">
                        <Link to={`/profile/${content.user.username}`}>
                          <Avtar
                            image={content.user.image}
                            image_thumb={content.user.image_thumb}
                            size="4x"
                            name={content.user.username}
                            width="57"
                          />
                        </Link>
                      </Col>
                      <Col md={12} lg={3} className="col-7">
                        <Row>
                          <Col md={12} lg={12} className="col-12">
                            <p className="mb-0 topic-auth">Posted By</p>
                          </Col>
                          <Col md={12} lg={12} className="col-12">
                            <h5 className="topic-user">
                              {content.user.name
                                ? common.ucwords(content.user.name)
                                : content.user.username}
                            </h5>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        md={12}
                        lg={8}
                        className="col-12 text-lg-right poll-link"
                      >
                        <p>
                          Poll closing date:
                          <span
                            className="ml-2"
                            dangerouslySetInnerHTML={{
                              __html:
                                `.` +
                                common.customeFormat(
                                  content.poll.close_on,
                                  true
                                )
                            }}
                          />
                        </p>
                      </Col>
                      <Col md={12} lg={12} className="col-12">
                        <h4 className="topic-user">{content.poll.question}</h4>
                      </Col>
                      <Col md={12} lg={12} className="col-12">
                        <ul className="grop-links p-0 m-0 mb-2">
                          <li>
                            <span
                              className="date-time-info"
                              style={{ color: "#007bff" }}
                              dangerouslySetInnerHTML={{
                                __html: common.timeSince(content.poll.added_on)
                              }}
                            />
                          </li>
                          <li>
                            {" | "}
                            <span style={{ color: "#007bff" }}>
                              {this.props.feeds[0].total_comments}
                              {this.props.feeds[0].total_comments > 1
                                ? " Comments"
                                : " Comment"}
                            </span>
                          </li>
                          <li>
                            {" | "}
                            <span style={{ color: "#007bff" }}>
                              {content.poll.votes.length}{" "}
                              {content.poll.votes.length > 1 ? "Votes" : "Vote"}
                            </span>
                          </li>
                        </ul>
                      </Col>
                      <Col md={12} lg={12} className="col-12 link-content">
                        <Poll poll={content.poll} />
                      </Col>
                      {content.poll.tags.length > 0 && (
                        <Col md={12} className="mt-4 group-abt-tag mb-3">
                          <p className="mb-2 mt-0 grp-time">Tags</p>
                          {content.poll.tags.map((tag, index) => (
                            <Link
                              to="/poll/search-by-tag/"
                              key={`poll-tag-key-${index}`}
                            >
                              {tag.tag.tag}
                            </Link>
                          ))}
                        </Col>
                      )}
                      <Col
                        md={6}
                        lg={2}
                        className="col-6 pr-0 mt-4 comment-counts"
                      >
                        <Likes
                          totalLikes={content.likes.length}
                          likeArr={content.likes}
                          key={`like-key-0`}
                          feedId={content.id}
                          enableLike={true}
                          enableStore={false}
                        />
                      </Col>
                      <Col
                        md={6}
                        lg={2}
                        className="col-6 pl-0  mt-4 comment-counts"
                      >
                        <span className="comment-icons">
                          <span className="Comments commented">
                            <FontAwesomeIcon icon="comment-dots" />
                          </span>
                          {this.props.feeds[0].total_comments > 0 && (
                            <span className="ml-1">
                              <span className="total-Comments Total-count like-count-info">
                                {this.props.feeds[0].total_comments}
                              </span>
                            </span>
                          )}
                        </span>
                      </Col>
                      <Col
                        md={8}
                        lg={6}
                        className="col-8 text-lg-right mt-lg-4 comment-counts"
                      >
                        <span className="like-count-info">
                          <FontAwesomeIcon icon="bookmark" className="mr-1" />
                          Bookmark this page
                        </span>
                      </Col>
                      <Col
                        md={4}
                        lg={2}
                        className="col-4 text-right  mt-lg-4 comment-counts"
                      >
                        <span
                          className="like-count-info"
                          onClick={this.toggleSocialShare}
                          style={{ cursor: "pointer" }}
                        >
                          <FontAwesomeIcon icon="share" className="mr-1" />
                          Share
                        </span>
                      </Col>

                      <Col md={12} lg={12} className="col-12 mt-2">
                        <SocialShare
                          socialShare={this.state.socialShare}
                          iconSize={40}
                          title={content.poll.question}
                          shareUrl={`${this.props.baseUrl}/polls/${content.poll.id}/details`}
                          media={
                            content.poll.image !== ""
                              ? `${this.props.baseUrl}/images/${content.poll.image}`
                              : ""
                          }
                        />
                      </Col>

                      <Col md={12} lg={12} className="col-12 mt-2">
                        {this.state.content.length > 0 && (
                          <CommentContainer
                            totalComments={this.props.feeds[0].total_comments}
                            handleCommentLoader={this.toggleCommentLoader}
                            contentId={content.id}
                            feedIndex={0}
                            key={`comment-container-${content.id}`}
                            handleTotalComments={this.updateTotalComments}
                            enableComment={true}
                            feedId={content.id}
                          />
                        )}
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    feeds: state.feeds,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFeed: feeds => {
      dispatch(
        updateFeed({
          feeds
        })
      );
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PollDetails);
