import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Row, Col, Spinner, FormGroup, Label, Input, Button, Modal, ModalBody } from "reactstrap";
import content from "../../../services/content";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Avtar from "../Common/Avtar";
import LoadingOverlay from "react-loading-overlay";
import TextareaAutosize from "react-autosize-textarea";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.css";

class EditCommentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      loader: true,
      submitted: false,
      fileUploader: false,
      attachments: [],
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    let data = {
      comment: this.state.fields["comment"],
      content_id: this.state.fields["content_id"],
      id: this.state.fields["id"],
      image: this.state.attachments[0],
      parent_id: this.state.fields["parent_id"]
    };
    if (this.state.comment === "" && this.state.attachments.length === 0) {
      toast.error("Comment can not be blank!", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      this.setState({ submitted: true });
      content.postComment(data).then(response => {
        if (response.data.success) {
          this.setState({ submitted: false }, () => {
            content.getComment({ id: this.props.commentId }).then(response => {
              if (response.data.success) {
                this.props.updateComment(response.data.content);
                //this.props.toggleModal();
              } else if (response.data.error) {
                toast.error(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
            });

          });
        }
      });
    }
  };
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  getComment = id => {
    content.getComment({ id: id }).then(response => {
      if (response.data.success) {
        let fields = response.data.content;
        this.setState({
          loader: false,
          fields
        });
      } else if (response.data.error) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };
  componentDidMount() {
    if (this.props.commentId) {
      this.getComment(this.props.commentId);
    }
  }
  toggleUploader = () => {
    this.setState(prevState => ({
      fileUploader: !prevState.fileUploader
    }));
  };
  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
      >
        <ModalBody className="post-comment-box mb-0 p-3">
          <LoadingOverlay
            active={this.state.loader}
            spinner={<Spinner color="dark" />}
            fadeSpeed={200}
            classNamePrefix="mitiz"
          >
            <form method="post" onSubmit={this.handleSubmit}>
              {this.state.fileUploader && (
                <Row>
                  <Col className="mb-1">
                    <FilePond
                      allowMultiple={false}
                      allowRemove={true}
                      ref={ref => (this.pond = ref)}
                      server={{
                        url: this.props.apiUrl,
                        process: {
                          url: "/content/upload",
                          headers: {
                            "X-Api-Key": `Bearer  ${this.props.token}`
                          },
                          onload: response => {
                            let response_josn = JSON.parse(response);
                            if (response_josn.success) {
                              let attachments = [
                                ...this.state.attachments,
                                response_josn.file_name
                              ];
                              this.setState({
                                attachments: attachments
                              });
                              return response_josn.file_name;
                            }
                          }
                        },
                        revert: {
                          url: "/content/delete-image",
                          headers: {
                            "X-Api-Key": `Bearer  ${this.props.token}`
                            //file_name: files
                          },
                          onload: response => {
                            //console.log(response);
                          }
                        }
                      }}
                      onprocessfilerevert={file => {
                        let uploaded_files = this.state.attachments.filter(
                          attachment => attachment != file.serverId
                        );
                        this.setState({
                          attachments: uploaded_files
                        });
                      }}
                      className="mb-1"
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={2} lg={2}>
                  <Avtar
                    image={this.props.userImage}
                    image_thumb={this.props.userImageThumb}
                    size="3x"
                    className="mr-2"
                    width="50"
                  />
                </Col>
                <Col md={10} lg={10} className="pl-0 comnt-txt-area">
                  <TextareaAutosize
                    value={
                      this.state.fields["comment"] ? this.state.fields["comment"] : ""
                    }
                    onChange={event => this.handleChange("comment", event)}
                    name="comment"
                  />
                  <FontAwesomeIcon
                    icon="paperclip"
                    onClick={this.toggleUploader}
                    className="upload_svg"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} lg={12} className="col-12 text-right">
                  <Button
                    type="button"
                    color="danger"
                    className="mr-1"
                    disabled={this.state.submitted}
                    onClick={this.props.toggleModal}
                  >Cancel</Button>
                  <Button
                    type="submit"
                    color="success"
                    className="post-comment"
                    disabled={this.state.submitted}
                  >
                    {this.state.submitted && (
                      <FontAwesomeIcon
                        icon="spinner"
                        className="mr-1"
                        spin={true}
                      />
                    )}
                    Update
                  </Button>
                </Col>
              </Row>
            </form>
          </LoadingOverlay>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    token: state.token,
    userImage: state.userImage,
    userImageThumb: state.userImageThumb,
    userName: state.userName,
    uName: state.uName
  };
};
export default connect(mapStateToProps)(EditCommentModal);
