import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import LeftMenu from "../Common/LeftMenu";

import SkeletonScreen from "../Common/SkeletonScreen";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  CustomInput
} from "reactstrap";
import page from "../../../services/page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotFound from "../Common/NotFound";

class MyRepresentatives extends Component {
  state = {
    loader: true,
    representatives: "",
    fields: {},
    errors: {},
    submitted: false,
    notFound: false
  };
  componentDidMount = () => {
    let data = {};
    page.myRepresentatives(data).then(response => {
      if (response.data.success) {
        let fields = { address: response.data.address };
        this.setState({
          fields,
          representatives: JSON.parse(response.data.representatives),
          loader: false,
          notFound: false
        });
      } else {
        let fields = { address: response.data.address };
        this.setState({
          fields,
          loader: false,
          notFound: true
        });
      }
    });
  };
  handleChange = (field, e) => {
    let fields = this.state.fields;
    if (e.target.type === "checkbox") {
      fields[field] = e.target.checked;
    } else {
      fields[field] = e.target.value;
    }
    this.setState({ fields });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ submitted: true });
      const params = {
        address: this.state.fields["address"],
        save_address: this.state.fields["save_address"]
      };
      this.setState({
        loader: true,
        notFound: false
      });
      page.getRepresentatives(params).then(response => {
        this.setState({ loader: false, submitted: false }, () => {
          if (response.data.success) {
            this.setState({
              representatives: JSON.parse(response.data.representatives),
              notFound: false
            });
          } else {
            this.setState({
              loader: false,
              notFound: true,
              representatives: ""
            });
          }
        });
      });
    }
  };
  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "Address can not be empty!";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>My Representatives</title>
        </Helmet>
        <div className="row mt-4">
          <div className="col-md-3 col-lg-2 col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </div>
          <div className="col-md-6 col-12 col-lg-10 mt-3">
            <div className="user-post central-meta create-new-group-form item mb-2 pt-3">
              <h3 className="pb-3">Who represents me?</h3>
              <Form
                name="add-edit-action-form"
                method="post"
                onSubmit={this.handleSubmit}
              >
                <FormGroup>
                  <Label for="address">Address</Label>
                  <Input
                    bsSize="lg"
                    type="text"
                    name="address"
                    id="address"
                    value={
                      this.state.fields["address"]
                        ? this.state.fields["address"]
                        : ""
                    }
                    onChange={event => this.handleChange("address", event)}
                    invalid={this.state.errors["address"] ? true : false}
                  />
                  {this.state.errors["address"] && (
                    <FormFeedback>{this.state.errors["address"]}</FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <CustomInput
                    type="checkbox"
                    id={`save_address`}
                    name="save_address"
                    label="Save address to profile for future searches"
                    className="mb-3"
                    onChange={event => this.handleChange("save_address", event)}
                  />
                </FormGroup>
                <FormGroup>
                  <button
                    type="submit"
                    className="btn btn-danger mb-2"
                    disabled={this.state.submitted}
                  >
                    {this.state.submitted && (
                      <FontAwesomeIcon
                        icon="spinner"
                        className="mr-1"
                        spin={true}
                      />
                    )}
                    Submit
                  </button>
                </FormGroup>
              </Form>
              <h4 className="mb-1">Elected Officials</h4>
              <p className="mb-3">
                These are the elected officials we found for the address
                supplied. To use a different location enter an address above.
              </p>
              {this.state.loader ? (
                <SkeletonScreen />
              ) : (
                <React.Fragment>
                  {this.state.representatives !== "" &&
                    this.state.representatives.offices.map((office, i) => (
                      <Card key={`representatives-key-${i}`}>
                        <CardHeader>
                          {office.name}-
                          <strong>
                            {
                              this.state.representatives.officials[
                                office.officialIndices[0]
                              ].name
                            }
                          </strong>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={3}>
                              <FontAwesomeIcon
                                icon="phone-alt"
                                className="mr-1"
                              />
                              {this.state.representatives.officials[
                                office.officialIndices[0]
                              ].phones
                                ? this.state.representatives.officials[
                                    office.officialIndices[0]
                                  ].phones[0]
                                : "No phone"}
                            </Col>
                            <Col md={4}>
                              <FontAwesomeIcon
                                icon="envelope-open"
                                className="mr-1"
                              />
                              {this.state.representatives.officials[
                                office.officialIndices[0]
                              ].emails
                                ? this.state.representatives.officials[
                                    office.officialIndices[0]
                                  ].emails[0]
                                : "No email"}
                            </Col>
                            <Col md={5}>
                              {this.state.representatives.officials[
                                office.officialIndices[0]
                              ].channels &&
                                this.state.representatives.officials[
                                  office.officialIndices[0]
                                ].channels.map(channel => (
                                  <React.Fragment
                                    key={`channel-key-${Math.random(0)}`}
                                  >
                                    <FontAwesomeIcon
                                      icon={["fab", channel.type.toLowerCase()]}
                                      className="mr-1 ml-2"
                                    />
                                    <a
                                      href={`http://${channel.type.toLowerCase()}.com/${
                                        channel.id
                                      }`}
                                      target="_blank"
                                    >
                                      {channel.id}
                                    </a>
                                  </React.Fragment>
                                ))}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ))}
                  {this.state.notFound && (
                    <NotFound message="Records not found." />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
          {/* <div className="col-md-3 col-12 col-lg-2 mt-3 right-section px-0 text-center mt-3">
            <RightBox />
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    userType: state.userType
  };
};
export default connect(mapStateToProps)(MyRepresentatives);
