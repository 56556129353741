import React, { Component } from "react";
import user from "../../../services/user";
import NotFound from "../Common/NotFound";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
  FormFeedback,
  Form
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-summernote/dist/react-summernote.css";
import "bootstrap/js/src/tooltip";
import "bootstrap/js/src/modal";
import "bootstrap/js/src/dropdown";
import ReactSummernote from "react-summernote";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Edit extends Component {
  state = {
    notFound: false,
    loader: true,
    userDetails: {},
    issues: [],
    fields: {},
    errors: {},
    dob: "",
    submitted: false,
    about_me: "",
    selected_issues: []
  };
  getUser = () => {
    user.get({ id: this.props.userId }).then(response => {
      if (response.data.error) {
        this.setState({ notFound: true, loader: false });
      } else {
        let selected_issues = response.data.user.issues.map(
          issue => issue.category_id
        );
        this.setState({
          loader: false,
          userDetails: response.data.user,
          fields: response.data.user,
          dob:
            response.data.user.dob !== "0000-00-00"
              ? new Date(response.data.user.dob)
              : "",
          selected_issues
        });
        this.getIssues();
      }
    });
  };
  getIssues = () => {
    user.getIssues().then(response => {
      if (response.data.error) {
        this.setState({ notFound: true, loader: false });
      } else {
        this.setState({ issues: response.data.categories });
      }
    });
  };

  componentDidMount() {
    this.getUser();
  }
  checkIssue = category_id => {
    const issues = this.state.userDetails.issues.filter(
      issue => issue.category_id === category_id
    );
    if (issues.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleAboutMe = about_me => {
    this.setState({ about_me });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ submitted: true });
      const params = {
        id: this.props.userId,
        name: this.state.fields["name"],
        email: this.state.fields["email"],
        dob: this.state.dob,
        website: this.state.fields["website"],
        zip_code: this.state.fields["zip_code"],
        facebook_url: this.state.fields["facebook_url"],
        twitter_url: this.state.fields["twitter_url"],
        linkedin_url: this.state.fields["linkedin_url"],
        phone: this.state.fields["phone"],
        about_me: this.state.about_me,
        selected_issues: this.state.selected_issues,
        city: this.state.fields["city"],
        state: this.state.fields["state"]
      };
      user.add(params).then(response => {
        this.setState({ submitted: false }, () => {
          if (response.data.success) {
            toast.success("Profile has been updated sucessfully.", {
              position: toast.POSITION.TOP_RIGHT
            });
            this.props.history.push("/profile/" + this.props.userName);
          } else if (response.data.error) {
            let errors = {};
            if (response.data.message.email) {
              errors["email"] = response.data.message.email[0];
            }
            if (response.data.message.phone) {
              errors["phone"] = response.data.message.phone[0];
            }
            this.setState({ errors: errors });
          }
        });
      });
    }
  };
  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Name can not be empty!";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email can not be empty!";
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Phone can not be empty!";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  handleDob = date => {
    this.setState({ dob: date });
  };
  handleIssueSelect = e => {
    let issues = this.state.selected_issues;
    if (e.target.checked) {
      issues.push(e.target.value);
      this.setState({ selected_issues: issues });
    } else {
      let index_to_be_removed = issues.indexOf(e.target.value);
      issues.splice(index_to_be_removed, 1);
      this.setState({ selected_issues: issues });
    }
  };
  render() {
    const userDetails = this.state.userDetails;
    return (
      <div className="video-block section-padding">
        <Helmet>
          <title>Edit Profile : Civ Works</title>
        </Helmet>
        <div className="main-item">
          {this.state.notFound ? (
            <NotFound />
          ) : (
              <React.Fragment>
                <Row>
                  <Col md={12} className="px-3">
                    <Form
                      name="edit-profile-form"
                      method="post"
                      onSubmit={this.handleSubmit}
                    >
                      <Col
                        md={12}
                        className="px-0"
                        style={{ borderBottom: "2px solid" }}
                      >
                        <Col md={6} className="px-0">
                          <h2>Edit Profile</h2>
                        </Col>
                        <Col md={6} className="px-0 text-right">
                          <button
                            type="submit"
                            className="btn btn-success"
                            disabled={this.state.submitted}
                          >
                            {this.state.submitted && (
                              <FontAwesomeIcon
                                icon="spinner"
                                className="mr-1"
                                spin={true}
                              />
                            )}
                            Save
                        </button>
                        </Col>
                      </Col>
                      <Col md={12} className="px-0">
                        <Card className="p-3 mt-3">
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="name">Display Name</Label>
                                <Input
                                  type="text"
                                  name="name"
                                  id="name"
                                  value={
                                    this.state.fields["name"]
                                      ? this.state.fields["name"]
                                      : ""
                                  }
                                  onChange={event =>
                                    this.handleChange("name", event)
                                  }
                                  invalid={
                                    this.state.errors["name"] ? true : false
                                  }
                                />
                                {this.state.errors["name"] && (
                                  <FormFeedback>
                                    {this.state.errors["name"]}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                  type="email"
                                  name="email"
                                  id="email"
                                  value={
                                    this.state.fields["email"]
                                      ? this.state.fields["email"]
                                      : ""
                                  }
                                  invalid={
                                    this.state.errors["email"] ? true : false
                                  }
                                  onChange={event =>
                                    this.handleChange("email", event)
                                  }
                                />
                                {this.state.errors["email"] && (
                                  <FormFeedback>
                                    {this.state.errors["email"]}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="website">Website</Label>
                                <Input
                                  type="url"
                                  name="website"
                                  id="website"
                                  value={
                                    this.state.fields["website"]
                                      ? this.state.fields["website"]
                                      : ""
                                  }
                                  onChange={event =>
                                    this.handleChange("website", event)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="phone">Phone</Label>
                                <Input
                                  type="phone"
                                  name="phone"
                                  id="phone"
                                  value={
                                    this.state.fields["phone"]
                                      ? this.state.fields["phone"]
                                      : ""
                                  }
                                  invalid={
                                    this.state.errors["phone"] ? true : false
                                  }
                                  onChange={event =>
                                    this.handleChange("phone", event)
                                  }
                                />
                                {this.state.errors["phone"] && (
                                  <FormFeedback>
                                    {this.state.errors["phone"]}
                                  </FormFeedback>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="dob">Date Of Birth</Label>
                                <DatePicker
                                  showYearDropdown
                                  showMonthDropdown
                                  className="form-control"
                                  style={{ width: 100 + "%", float: "left" }}
                                  selected={this.state.dob}
                                  onChange={this.handleDob}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="zip_code">Zip Code</Label>
                                <Input
                                  type="text"
                                  name="zip_code"
                                  id="zip_code"
                                  value={
                                    this.state.fields["zip_code"]
                                      ? this.state.fields["zip_code"]
                                      : ""
                                  }
                                  onChange={event =>
                                    this.handleChange("zip_code", event)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="city">City</Label>
                                <Input
                                  type="text"
                                  name="city"
                                  id="city"
                                  value={
                                    this.state.fields["city"]
                                      ? this.state.fields["city"]
                                      : ""
                                  }
                                  onChange={event =>
                                    this.handleChange("city", event)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="state">State</Label>
                                <Input
                                  type="text"
                                  name="state"
                                  id="state"
                                  value={
                                    this.state.fields["state"]
                                      ? this.state.fields["state"]
                                      : ""
                                  }
                                  onChange={event =>
                                    this.handleChange("state", event)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <Label for="phone">About Me</Label>
                                <ReactSummernote
                                  options={{
                                    height: 150,
                                    dialogsInBody: true,
                                    toolbar: [
                                      ["style", ["style"]],
                                      ["font", ["bold", "underline", "clear"]],
                                      ["fontname", ["fontname"]],
                                      ["para", ["ul", "ol", "paragraph"]],
                                      ["table", ["table"]],
                                      /* ["insert", ["link", "picture", "video"]], */
                                      ["view", ["fullscreen", "codeview"]]
                                    ]
                                  }}
                                  value={this.state.fields["about_me"]}
                                  onChange={this.handleAboutMe}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Card>
                        <Card className="p-3 mt-3">
                          <h3>Social</h3>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="facebook_url">Facebook</Label>
                                <Input
                                  type="url"
                                  name="facebook_url"
                                  id="facebook_url"
                                  value={
                                    this.state.fields["facebook_url"]
                                      ? this.state.fields["facebook_url"]
                                      : ""
                                  }
                                  onChange={event =>
                                    this.handleChange("facebook_url", event)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="linkedin_url">LinkedIn</Label>
                                <Input
                                  type="url"
                                  name="linkedin_url"
                                  id="linkedin_url"
                                  value={
                                    this.state.fields["linkedin_url"]
                                      ? this.state.fields["linkedin_url"]
                                      : ""
                                  }
                                  onChange={event =>
                                    this.handleChange("linkedin_url", event)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <Label for="twitter_url">Twitter</Label>
                                <Input
                                  type="url"
                                  name="twitter_url"
                                  id="twitter_url"
                                  value={
                                    this.state.fields["twitter_url"]
                                      ? this.state.fields["twitter_url"]
                                      : ""
                                  }
                                  onChange={event =>
                                    this.handleChange("twitter_url", event)
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Card>
                        <Card className="p-3 mt-3">
                          <h3>Issues</h3>
                          <Col md={12}>
                            {this.state.issues.length > 0
                              ? this.state.issues.map(category => (
                                <Col md={4} className="mb-2" key={category.id}>
                                  <CustomInput
                                    type="checkbox"
                                    label={category.name}
                                    id={category.id}
                                    value={category.id}
                                    defaultChecked={this.checkIssue(
                                      category.id
                                    )}
                                    onChange={this.handleIssueSelect}
                                  />
                                </Col>
                              ))
                              : null}
                          </Col>
                        </Card>
                      </Col>
                      <Col md={12} className="px-0 text-right mb-3">
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={this.state.submitted}
                        >
                          {this.state.submitted && (
                            <FontAwesomeIcon
                              icon="spinner"
                              className="mr-1"
                              spin={true}
                            />
                          )}
                          Save
                      </button>
                      </Col>
                    </Form>
                  </Col>
                </Row>
              </React.Fragment>
            )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};
export default connect(mapStateToProps)(Edit);
