import React, { Component } from "react";
import poll from "../../../services/poll";
import common from "../../../services/common";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter, NavLink } from "react-router-dom";
import SkeletonScreen from "../Common/SkeletonScreen";
import { toast } from "react-toastify";
import LeftMenu from "../Common/LeftMenu";
import NotFound from "../Common/NotFound";

class Polls extends Component {
  state = {
    notFound: false,
    loader: true,
    polls: [],
    pageNo: 1,
    pageSize: 20,
    hasMore: false,
    allButtonActive: true,
    friendButtonActive: false,
    mineButtonActive: false
  };
  getPolls = type => {
    if (type === "A") {
      this.setState({
        allButtonActive: true,
        friendButtonActive: false,
        mineButtonActive: false
      });
    } else if (type === "F") {
      this.setState({
        allButtonActive: false,
        friendButtonActive: true,
        mineButtonActive: false
      });
    } else if (type === "M") {
      this.setState({
        allButtonActive: false,
        friendButtonActive: false,
        mineButtonActive: true
      });
    }
    this.setState({ loader: true });
    let polls = [];
    let data = {
      type: type,
      page: this.state.pageNo,
      pageSize: this.state.pageSize
    };
    poll.list(data).then(response => {
      if (response.data.success) {
        this.setState({
          loader: false,
          polls: response.data.polls,
          notFound: response.data.polls.length === 0 ? true : false
        });
      }
    });
  };

  deletePoll = (e, content_id) => {
    if (window.confirm("Are you sure to delete this Poll?")) {
      poll.delete({ id: content_id }).then(response => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          let polls = [];
          polls = this.state.polls.filter(poll => poll.id !== content_id);
          this.setState({ polls });
        }
      });
    }
  };
  componentDidMount = () => {
    //console.log(this.props.match.path);
    if (this.props.match.path.includes("mine")) {
      this.getPolls("M");
    } else if (this.props.match.path.includes("friends")) {
      this.getPolls("F");
    } else {
      this.getPolls("A");
    }
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Polls : Civ Works</title>
        </Helmet>

        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={6} lg={8} className="col-12 pr-4 mt-5 pt-lg-4">
            <Row className="mb-2">
              <Col md={12} lg={12} className="col-12">
                <h4 className="text-left status">Polls </h4>
              </Col>
            </Row>
            {this.state.loader ? (
              <div className="mt-4">
                <SkeletonScreen />
              </div>
            ) : (
              this.state.polls.map((poll, index) => (
                <div
                  className="user-post central-meta item mb-2 create-new-group-form pt-2 pb-5"
                  key={`poll-index-key-${index}`}
                >
                  {parseInt(poll.user_id) === parseInt(this.props.userId) && (
                    <Row>
                      <Col md={12} className="px-0 text-right Discussion-sahre">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle btn-sm"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <FontAwesomeIcon icon="ellipsis-h" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link
                            to={`/polls/edit/${poll.poll.id}`}
                            className="dropdown-item"
                            type="link"
                          >
                            Edit
                          </Link>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={event =>
                              this.deletePoll(event, poll.poll.content_id)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col
                      md={1}
                      lg={1}
                      className="col-12 pr-lg-0 group-bookmark-link"
                    >
                      <FontAwesomeIcon icon="poll" />
                    </Col>
                    <Col md={11} lg={11} className="col-12 pl-lg-0">
                      <h4>
                        <Link
                          to={`/polls/${poll.id}/details`}
                          style={{ color: "#212529" }}
                        >
                          {poll.poll.question}
                        </Link>
                      </h4>
                      <Col md={12} lg={12} className="px-0">
                        <ul className="grop-links p-0 m-0 mb-2">
                          <li>
                            <span style={{ color: "#007bff" }}>By</span>
                            <span className="ml-1">
                              {common.ucwords(poll.user.name)}
                            </span>
                          </li>
                          <li>
                            {" | "}
                            <span
                              className="date-time-info"
                              style={{ color: "#007bff" }}
                              dangerouslySetInnerHTML={{
                                __html: common.timeSince(poll.added_on)
                              }}
                            />
                          </li>
                          <li>
                            {" | "}
                            <span style={{ color: "#007bff" }}>
                              {poll.total_comments}{" "}
                              {poll.total_comments > 1 ? "Comments" : "Comment"}
                            </span>
                          </li>
                        </ul>
                      </Col>
                      {poll.tags.length > 0 && (
                        <Col md={12} lg={12} className="group-abt-tag px-0">
                          <p className="mb-2 mt-0 grp-time">Tags</p>
                          {poll.tags.map((tag, index) => (
                            <Link
                              to="/poll/search-by-tag/"
                              key={`poll-tag-key-${index}`}
                            >
                              {tag.tag.tag}
                            </Link>
                          ))}
                        </Col>
                      )}
                    </Col>
                  </Row>
                </div>
              ))
            )}
            {this.state.notFound && (
              <div className="user-post central-meta item mb-2 create-new-group-form pt-2 pb-5">
                <NotFound message="Polls not found." icon="poll" />
              </div>
            )}
          </Col>
          <Col
            md={3}
            lg={2}
            className="col-12 right-section px-0 text-center mt-lg-4 pt-lg-4"
          >
            <Col md={12} className="px-0">
              <Link
                to="/polls/create"
                className="btn btn-danger new-group-btn mt-3 mb-3"
              >
                <FontAwesomeIcon icon="plus" className="mr-1" />
                Create a new poll
              </Link>
            </Col>
            <div className="widgets widgets-coment">
              <div className="Navigation text-left mt-3 mb-3">
                <h4 className="Act text-left pl-3 mb-3 pt-2">
                  <b>Navigation</b>
                </h4>
                <div className="menu-block">
                  <ul>
                    <li>
                      <NavLink
                        to="/polls"
                        activeClassName="is-active"
                        exact={true}
                      >
                        All
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/polls/mine"
                        activeClassName="is-active"
                        exact={true}
                      >
                        Mine
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/polls/friends"
                        activeClassName="is-active"
                        exact={true}
                        strict
                      >
                        Friends
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="widgets widgets-coment">
              <div className="Navigation text-left mt-3 mb-3">
                <h4 className="Act text-left pl-3 mb-3 pt-2">
                  <b>Search by hashtag</b>
                </h4>
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control input-hashtag"
                      id=" #typehere"
                      placeholder=" #typehere"
                    />
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    baseUrl: state.baseUrl
  };
};
export default withRouter(connect(mapStateToProps)(Polls));
