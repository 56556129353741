import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  Label,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Card,
  CardBody,
  Row,
  Col,
  ModalFooter
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SkeletonScreen from "../Common/SkeletonScreen";
import NotFound from "../Common/NotFound";
import user from "../../../services/user";
import Avtar from "../Common/Avtar";
import { toast } from "react-toastify";
import { connect } from "react-redux";

class CreateCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      loader: true,
      notFound: false,
      showModal: false,
      friends: [],
      submitted: false,
      selectedFriends: [],
      id: ""
    };
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ submitted: true });
      const params = {
        name: this.state.fields["name"],
        friends: this.state.selectedFriends,
        id: this.state.id
      };

      user.saveFriendCollection(params).then(response => {
        this.setState({ submitted: false }, () => {
          if (response.data.success) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            this.props.toggleModal();
            this.props.getFriendCollections();
          } else if (response.data.error) {
            let errors = {};
            if (response.data.message.name) {
              errors["name"] = response.data.message.name[0];
              this.setState({ errors });
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT
              });
            }
          }
        });
      });
    }
  };
  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Collection name can not be empty!";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  componentDidMount() {
    user.getFriends().then(response => {
      if (response.data.success) {
        this.setState({
          friends: response.data.friends,
          loader: false,
          notFound: response.data.friends.length > 0 ? false : true
        });
        if (this.props.collectionId !== "") {
          user
            .getFriendCollection({ id: this.props.collectionId })
            .then(response => {
              if (response.data.success) {
                let selectedFriends = [];
                response.data.collection.friends.forEach((friend, index) => {
                  selectedFriends[index] = friend.friend_id;
                });
                this.setState({
                  fields: { name: response.data.collection.name },
                  selectedFriends,
                  id: response.data.collection.id
                });
              }
            });
        }
      }
    });
  }

  checkSelectedFriend = user_id => {
    let selectedFriend = this.state.selectedFriends.filter(
      friend => friend === user_id
    );
    if (selectedFriend.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  toggleSelect = e => {
    let selectedFriends = this.state.selectedFriends;
    let friendid = e.target.attributes.friendid.value;
    if (e.target.innerHTML === "Select") {
      selectedFriends.push(friendid);
    } else {
      let index_to_be_removed = selectedFriends.indexOf(friendid);
      selectedFriends.splice(index_to_be_removed, 1);
    }
    this.setState({ selectedFriends });
  };
  deSelectAll = () => {
    this.setState({ selectedFriends: [] });
  };
  render() {
    return (
      <Modal isOpen={this.props.showModal} className="custom-model">
        <ModalBody style={{ padding: 2 }}>
          <h4 className="modal-title text-center">
            <b>New Friends Collection</b>
          </h4>
          <p className="text-center">
            You can divide your friends into groups. For example, by political
            activity, hobby or interests.
          </p>
          <Form
            name="edit-profile-form"
            method="post"
            onSubmit={this.handleSubmit}
          >
            <FormGroup>
              <Label for="question">Collection name</Label>
              <Input
                bsSize="lg"
                type="text"
                name="name"
                id="name"
                value={
                  this.state.fields["name"] ? this.state.fields["name"] : ""
                }
                onChange={event => this.handleChange("name", event)}
                invalid={this.state.errors["name"] ? true : false}
                className="input-bg"
              />
              {this.state.errors["name"] && (
                <FormFeedback>{this.state.errors["name"]}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup className="mb-1">
              <span>
                Select Friends ({this.state.selectedFriends.length} of{" "}
                {this.state.friends.length})
              </span>
              <span style={{ float: "right" }}>
                <button
                  type="button"
                  className="btn btn-link text-decoration-none p-0 Deselect-btn"
                  onClick={this.deSelectAll}
                  disabled={
                    this.state.selectedFriends.length > 0 ? false : true
                  }
                >
                  Deselect all
                </button>
              </span>
            </FormGroup>
            <div className="searching friend-search form-inline">
              <button className="brand-serach-btn" type="button">
                <FontAwesomeIcon icon="search" className="text-dark" />
              </button>
              <input
                className="form-control"
                type="text"
                name="search"
                placeholder="Search"
                aria-label="Search"
              />
            </div>
            {this.state.friends.map(friend => {
              let friendDetails;
              if (
                parseInt(friend.firstuser.id) !== parseInt(this.props.userId)
              ) {
                friendDetails = friend.firstuser;
              } else {
                friendDetails = friend.seconduser;
              }
              return (
                <Card
                  className="mb-1"
                  key={`friend-id-popup-${friendDetails.id}`}
                >
                  <CardBody className="pr-2 pl-2 pt-2 pb-2">
                    <Row>
                      <Col md={3} lg={2} className="col-2 px-lg-0 text-center">
                        <Avtar
                          image={friendDetails.image}
                          image_thumb={friendDetails.image_thumb}
                          size="3x"
                          name={friendDetails.username}
                          width="45"
                        />
                      </Col>
                      <Col md={9} lg={5} className="col-5 request-info pl-lg-0">
                        <h5 className="mb-0">{friendDetails.name}</h5>
                        <span>24 peoples</span>
                      </Col>
                      <Col
                        md={12}
                        lg={5}
                        className="col-5 request-info text-right align-self-center"
                      >
                        {this.checkSelectedFriend(friendDetails.id) ? (
                          <button
                            type="button"
                            className="btn btn-outline-secondary text-uppercase"
                            onClick={this.toggleSelect}
                            friendid={friendDetails.id}
                          >
                            De-Select
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outline-danger text-uppercase"
                            onClick={this.toggleSelect}
                            friendid={friendDetails.id}
                          >
                            Select
                          </button>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              );
            })}

            {this.state.loader && (
              <div className="mt-4">
                <SkeletonScreen height={100} />
              </div>
            )}

            {this.state.notFound && (
              <NotFound message="Not friends found." minHeight={30} />
            )}
            <ModalFooter className="border-top-0 pr-0 pb-0">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={this.props.closeModal}
                disabled={this.state.submitted}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-danger post-comment"
                disabled={this.state.submitted}
              >
                {this.state.submitted && (
                  <FontAwesomeIcon
                    icon="spinner"
                    className="mr-1"
                    spin={true}
                  />
                )}
                Save
              </button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl
  };
};
export default connect(mapStateToProps)(CreateCollection);
