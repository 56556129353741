import React, { Component } from "react";
import poll from "../../../services/poll";
import common from "../../../services/common";
import NotFound from "../Common/NotFound";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner
} from "reactstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import withTag from "../Common/withTag";
import { Link } from "react-router-dom";
import LeftMenu from "../Common/LeftMenu";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.css";
import LoadingOverlay from "react-loading-overlay";

class CreatePoll extends Component {
  state = {
    loader: false,
    fields: { shared_as: "Public" },
    errors: {},
    close_on: "",
    submitted: false,
    description: "",
    options: [],
    close_time: "",
    id: this.props.id ? this.props.id : "",
    preview: "",
    image: ""
  };
  addOption = e => {
    let options = this.state.options;
    options.push(this.state.options.length);
    this.setState({ options: options });
  };
  removeOption = index => {
    let options = this.state.options;
    options.splice(index, 1);
    this.setState({ options: options });
  };

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleDateFeild = date => {
    this.setState({
      close_on: date
      //close_time: new Date(date).getTime()
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ submitted: true });
      let options = [];
      if (this.state.options.length > 0) {
        for (let i = 0; i < this.state.options.length; i++) {
          options[i] = this.state.fields["option-" + i];
        }
      }
      const params = {
        question: this.state.fields["question"],
        description: this.state.description,
        answer_allowed: this.state.fields["answer_allowed"],
        close_on: this.state.close_on,
        close_time: this.state.close_time,
        tags: this.props.tags,
        options: options,
        shared_as: this.state.fields["shared_as"],
        id: this.props.id ? this.props.id : "",
        image: this.state.image
      };

      poll.create(params).then(response => {
        this.setState({ submitted: false }, () => {
          if (response.data.success) {
            toast.success("Poll has been created sucessfully.", {
              position: toast.POSITION.TOP_RIGHT
            });
            this.props.redirect.push("/polls");
          } else if (response.data.error) {
            let errors = {};
            if (response.data.message.question) {
              errors["question"] = response.data.message.question[0];
            }
            this.setState({ errors: errors });
          }
        });
      });
    }
  };
  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["question"]) {
      formIsValid = false;
      errors["question"] = "Question can not be empty!";
    }
    if (!fields["answer_allowed"]) {
      formIsValid = false;
      errors["answer_allowed"] =
        "Please enter the number of response choices someone can select.";
    }
    if (this.state.close_on === "" || fields["close_on"] === "") {
      formIsValid = false;
      errors["close_on"] = "Poll must have it's closing date.";
    }
    if (this.state.options.length > 0) {
      for (let i = 0; i < this.state.options.length; i++) {
        if (!fields["option-" + i]) {
          formIsValid = false;
          errors["option-" + i] = "Option can not be empty.";
        }
      }
    }
    if (this.state.options.length < parseInt(fields["answer_allowed"])) {
      formIsValid = false;
      errors["answer_allowed"] =
        "There was a mismatch of the number of poll choices to be saved and the expected total number of poll choices or at least one of the poll choices contained no text.";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  getPoll = id => {
    this.setState({ loader: true });
    poll.getOne({ id: id }).then(response => {
      if (response.data.success) {
        let fields = {
          question: response.data.poll.question,
          description: response.data.poll.description,
          answer_allowed: response.data.poll.answer_allowed,
          close_on: response.data.poll.close_on,
          shared_as: response.data.poll.content.shared_as
        };
        if (response.data.poll.options.length > 0) {
          response.data.poll.options.forEach((option, index) => {
            fields[`option-${index}`] = option.option_text;
          });
        }

        let tags = [];
        if (response.data.poll.tags.length > 0) {
          tags = response.data.poll.tags.map(tag => tag.tag.tag);
        }
        this.props.updateTagsInHoc(tags);

        this.setState({
          fields,
          close_on: new Date(fields.close_on),
          options: response.data.poll.options,
          preview: this.props.baseUrl + "/images/" + response.data.poll.image
        });
      } else if (response.data.error) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      this.setState({ loader: false });
    });
  };
  componentDidMount() {
    if (this.props.id) {
      this.getPoll(this.props.id);
    }
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Create New Poll : Civ Works</title>
        </Helmet>

        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={9} lg={10} className="col-12 px-lg-5 mt-5 pt-2">
            <Row className="mb-2">
              <Col md={12} lg={12} className="col-12">
                <h2>{this.state.id === "" ? "Create New" : "Update"} Poll</h2>
              </Col>
            </Row>
            <LoadingOverlay
              active={this.state.loader}
              spinner={<Spinner color="dark" />}
              fadeSpeed={200}
              classNamePrefix="mitiz"
            >
              <div className="central-meta create-new-group-form">
                <Form
                  name="edit-profile-form"
                  method="post"
                  onSubmit={this.handleSubmit}
                >
                  <FormGroup>
                    <Row className="justify-content-between align-items-end">
                      <Col md={3}>
                        {this.state.preview !== "" ? (
                          <img src={this.state.preview} />
                        ) : (
                          <img
                            src={require("../../../assets/images/no-image.png")}
                            className="rounded post-image"
                          />
                        )}
                      </Col>
                      <Col md={9}>
                        <FilePond
                          className="mb-0"
                          allowMultiple={false}
                          allowRemove={true}
                          ref={ref => (this.pond = ref)}
                          server={{
                            url: this.props.apiUrl,
                            process: {
                              url: "/poll/upload",
                              headers: {
                                "X-Api-Key": `Bearer  ${this.props.token}`
                              },
                              onload: response => {
                                let response_josn = JSON.parse(response);
                                if (response_josn.success) {
                                  this.setState({
                                    image: response_josn.file_name,
                                    preview:
                                      this.props.baseUrl +
                                      "/images/temp/" +
                                      response_josn.file_name
                                  });
                                  return response_josn.file_name;
                                }
                              }
                            },
                            revert: {
                              url: "/content/delete-image",
                              headers: {
                                "X-Api-Key": `Bearer  ${this.props.token}`
                                //file_name: files
                              },
                              onload: response => {
                                //console.log(response);
                              }
                            }
                          }}
                          onprocessfilerevert={file => {
                            this.setState({
                              icon: "",
                              preview: ""
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label for="question">Poll question</Label>
                    <Input
                      type="text"
                      name="question"
                      id="question"
                      value={
                        this.state.fields["question"]
                          ? this.state.fields["question"]
                          : ""
                      }
                      onChange={event => this.handleChange("question", event)}
                      invalid={this.state.errors["question"] ? true : false}
                      className="input-bg"
                    />
                    {this.state.errors["question"] && (
                      <FormFeedback>
                        {this.state.errors["question"]}
                      </FormFeedback>
                    )}
                  </FormGroup>

                  {this.state.options.map((option, index) => (
                    <Row key={`poll-options-${index}`}>
                      <Col md={10} lg={10} className="col-12 mb-md-2 pr-lg-0">
                        <FormGroup key={`option_${index}`}>
                          <Label for={`option-${index}`}>
                            {`Option ${index + 1}`}
                          </Label>
                          <Input
                            type="text"
                            name="options[]"
                            id={`option-${index}`}
                            value={
                              this.state.fields[`option-${index}`]
                                ? this.state.fields[`option-${index}`]
                                : ""
                            }
                            onChange={event =>
                              this.handleChange(`option-${index}`, event)
                            }
                            invalid={
                              this.state.errors[`option-${index}`]
                                ? true
                                : false
                            }
                            className="input-bg"
                          />
                          {this.state.errors[`option-${index}`] && (
                            <FormFeedback>
                              {this.state.errors[`option-${index}`]}
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col
                        md={2}
                        lg={2}
                        className="col-12 align-self-center text-lg-right"
                      >
                        <span
                          style={{
                            color: "#E9384F",
                            fontZize: 14,
                            cursor: "pointer"
                          }}
                          onClick={() => this.removeOption(index)}
                        >
                          Remove
                        </span>
                      </Col>
                    </Row>
                  ))}
                  <FormGroup>
                    <span
                      className="btn btn-link text-danger text-decoration-none pl-0"
                      style={{ cursor: "pointer", color: "#E9384F" }}
                      onClick={this.addOption}
                    >
                      <FontAwesomeIcon icon="plus" className="mr-1" /> Add Vote
                      Choice
                    </span>
                    <p
                      style={{
                        lineHeight: "18px",
                        marginTop: 3,
                        color: "#9F9F9F",
                        fontSize: 12
                      }}
                    >
                      <strong>Note:</strong> modifying the poll choices of an
                      existing poll (adding / deleting choices or changing the
                      text of an existing choice) will result in a reset of the
                      votes for the poll to allow all members to re-vote based
                      on the new set of choices.
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <Label for="answer_allowed">
                      Allow multiple selection of choices per participant up to
                      a maximum of
                    </Label>
                    <Input
                      type="number"
                      name="answer_allowed"
                      id="answer_allowed"
                      value={
                        this.state.fields["answer_allowed"]
                          ? this.state.fields["answer_allowed"]
                          : ""
                      }
                      onChange={event =>
                        this.handleChange("answer_allowed", event)
                      }
                      invalid={
                        this.state.errors["answer_allowed"] ? true : false
                      }
                      min={1}
                      className="input-bg"
                    />
                    {this.state.errors["answer_allowed"] && (
                      <FormFeedback>
                        {this.state.errors["answer_allowed"]}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="close_on">Poll closing date</Label>
                    <DatePicker
                      className="form-control input-bg"
                      minDate={new Date()}
                      style={{ width: 100 + "%", float: "left" }}
                      selected={this.state.close_on}
                      onChange={this.handleDateFeild}
                      dateFormat="yyyy-MM-dd HH:mm"
                      timeFormat="HH:mm"
                      showTimeSelect
                    />

                    <FormFeedback
                      style={{
                        display: this.state.errors["close_on"]
                          ? "block"
                          : "none"
                      }}
                    >
                      {this.state.errors["close_on"]}
                    </FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label for="tags">Tags</Label>
                    <TagsInput
                      value={this.props.tags}
                      onChange={this.props.handleTagChange}
                      onlyUnique={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="shared_as">Shared As</Label>
                    <Input
                      style={{ width: 200 }}
                      type="select"
                      bsSize="lg"
                      name="shared_as"
                      id="shared_as"
                      onChange={event => this.handleChange("shared_as", event)}
                      value={
                        this.state.fields["shared_as"]
                          ? this.state.fields["shared_as"]
                          : ""
                      }
                      className="input-bg"
                    >
                      <option value="Public">Public</option>
                      <option value="Private">Private</option>
                      <option value="Friends">Friends</option>
                    </Input>
                  </FormGroup>
                  <Row>
                    <Col md={6}>
                      <Link
                        to="/polls"
                        className="btn btn-outline-dark cp"
                        disabled={this.state.submitted}
                      >
                        Cancel
                      </Link>
                    </Col>
                    <Col md={6} className="text-right">
                      <button
                        type="submit"
                        className="btn btn-danger cbd-color cp"
                        disabled={this.state.submitted}
                      >
                        {this.state.submitted && (
                          <FontAwesomeIcon
                            icon="spinner"
                            className="mr-1"
                            spin={true}
                          />
                        )}
                        {this.state.id === "" ? "Create" : "Update"}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </LoadingOverlay>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl,
    token: state.token
  };
};
export default withTag(connect(mapStateToProps)(CreatePoll));
