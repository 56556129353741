import http from "./http";

const blog = {
  create: params => http.post("/blog/create", params),
  list: param => http.get("/blog/list", { params: param }),
  getOne: param => http.get("/blog/get-one", { params: param }),
  delete: param => http.post("/blog/delete", param)
};

export default blog;
