import React, { Component } from "react";
import action from "../../../services/action";
import common from "../../../services/common";
import { connect, dispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col, Card } from "reactstrap";
import SkeletonScreen from "../Common/SkeletonScreen";
import LeftMenu from "../Common/LeftMenu";
import { Link, withRouter } from "react-router-dom";
import NotFound from "../Common/NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialShare from "../Feeds/SocialShare";
import Follow from "./Follow";
import Rating from "./Rating";
import { toast } from "react-toastify";

class ActionDetails extends Component {
  state = {
    notFound: false,
    loader: true,
    action: {},
    markCompletedButton: true,
    submitted: false,
  };
  getAction = id => {
    this.setState({ loader: true });
    action.getOne({ id: id }).then(response => {
      if (response.data.success) {
        let markCompletedButton = true;
        if (response.data.action.completed !== null) {
          let completedUsers = response.data.action.completed.filter(user => {
            if (parseInt(user.user_id) === parseInt(this.props.userId)) {
              return user;
            }
          });
          if (completedUsers.length > 0) {
            markCompletedButton = false;
          }
        }
        this.setState({
          loader: false,
          action: response.data.action,
          markCompletedButton
        });
      } else {
        this.setState({
          loader: false,
          notFound: true
        });
      }
    });
  };

  componentDidMount = () => {
    this.getAction(this.props.match.params.id);
  };

  markComplete = () => {
    this.setState({
      submitted: true
    });
    action.markComplete({ action_id: this.props.match.params.id }).then(response => {
      if (response.data.success) {
        this.setState({
          submitted: false,
          markCompletedButton: false
        });
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        this.setState({
          submitted: false,
        });
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  }

  render() {
    const action = this.state.action;
    return (
      <React.Fragment>
        <Helmet>
          <title>Action Details : Civ Works</title>
        </Helmet>
        <div className="row mt-4">
          <div className="col-md-3 col-lg-3 col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </div>
          <div className="col-md-9 col-12 col-lg-9 pr-2 mt-3 ml-auto">
            <div className="user-post central-meta create-new-group-form item mb-2 pt-3">
              {this.state.loader ? (
                <div className="mt-4">
                  <SkeletonScreen height={400} />
                </div>
              ) : (
                  <Row className="multi-comment pb-3">
                    <Col md={12} lg={12} className="col-12 mb-3 pl-0">
                      <Link
                        to="/actions/upcoming"
                        className="btn btn-link back-btn"
                      >
                        <FontAwesomeIcon icon="arrow-left" /> Back
                    </Link>
                    </Col>
                    <Col md={12} lg={3} className="col-12">
                      <figure>
                        {action.image !== null &&
                          action.image !== "null" &&
                          action.image !== "" ? (
                            <img
                              src={`${this.props.baseUrl}/images/${action.image}`}
                              alt={action.title}
                              className="rounded post-image w-100 h-100"
                            />
                          ) : (
                            <img
                              src={require("../../../assets/images/no-image.png")}
                              className="rounded post-image w-100 h-100"
                            />
                          )}
                      </figure>
                    </Col>
                    <Col md={12} lg={9} className="col-12">
                      <Row>
                        <Col md={12} lg={12} className="col-12">
                          <h5 className="topic-user mb-0">
                            Ends in {common.dateDiff(action.end_on)} days
                        </h5>
                        </Col>
                        <Col md={12} lg={12} className="col-12">
                          <Rating action={action} />
                        </Col>
                        <Col md={6} lg={5} className="col-12 mt-2">
                          {this.state.markCompletedButton ? <button
                            type="button"
                            className="btn btn-danger Mark-Completed mb-2"
                            disabled={this.state.submitted}
                            onClick={this.markComplete}
                          >
                            {this.state.submitted && (
                              <FontAwesomeIcon
                                icon="spinner"
                                className="mr-1"
                                spin={true}
                              />
                            )}
                            Mark Completed
                        </button> : <button
                              type="button"
                              className="btn btn-outline-danger disabled mb-2"
                            >
                              <FontAwesomeIcon icon="check-circle" /> Completed
                        </button>}


                        </Col>
                        <Col md={6} lg={7} className="col-12 mt-2">
                          {parseInt(this.props.userId) !==
                            parseInt(action.user_id) && (
                              <Follow action={action} />
                            )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} lg={12} className="col-12">
                      <h4 className="topic-user">{action.title}</h4>
                    </Col>
                    <Col md={12} lg={12} className="col-12">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: action.description
                        }}
                      />
                    </Col>
                    <Col
                      md={12}
                      lg={12}
                      className="col-12 group-abt-tag mt-3 mb-3"
                    >
                      {action.issues.map(issue => (
                        <span key={`issue-key-${issue.issue.id}`}>
                          {issue.issue.name}
                        </span>
                      ))}
                    </Col>
                    <Col md={12} lg={12} className="col-12">
                      <SocialShare
                        iconSize={35}
                        title={action.title}
                        shareUrl={`${this.props.baseUrl}/actions/details/${action.id}`}
                      />
                    </Col>
                  </Row>
                )}
              {this.state.notFound && <NotFound message="Action not found." />}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    userId: state.userId,
    userName: state.userName,
    baseUrl: state.baseUrl
  };
};

export default connect(mapStateToProps)(ActionDetails);
