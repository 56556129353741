import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Row, Col, Card, Spinner, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LeftMenu from "../Common/LeftMenu";
import message from "../../../services/message";
import common from "../../../services/common";
import Messages from "./Messages";
import LoadingOverlay from "react-loading-overlay";
import Avtar from "../Common/Avtar";
import { connect } from "react-redux";

class Inbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      users: [],
      pageNo: 1,
      pageSize: 20,
      hasMore: false,
      userLoader: true,
      messageLoader: false,
      toUser: {},
      maxMessageId: ""
    };
  }

  componentDidMount() {
    let data = {
      page: this.state.pageNo,
      pageSize: this.state.pageSize
    };
    message.inbox(data).then(response => {
      if (response.data.success) {
        this.setState({
          users: response.data.inbox,
          userLoader: false
        });
        if (response.data.inbox.length > 0) {
          const user = response.data.inbox[0].message.user;
          this.setState({
            toUser: user
          });
          this.getMessages(user.id);
        }
      }
    });
  }
  getMessages = (userId, maxMessageId = "") => {
    this.setState({ messageLoader: maxMessageId !== "" ? false : true });
    const data = { user_id: userId, maxMessageId: maxMessageId };
    message.getMessages(data).then(response => {
      if (response.data.success) {
        if (response.data.messages.length > 0) {
          let messages = response.data.messages;
          if (maxMessageId !== "") {
            messages = [...this.state.messages, ...messages];
          }
          this.setState({
            messages: messages,
            messageLoader: false,
            maxMessageId:
              response.data.messages[response.data.messages.length - 1].id,
            users: response.data.inbox
          });
          this.scrollToBottom();
        } else {
          this.setState({
            users: response.data.inbox
          });
        }
      }
    });
  };
  scrollToBottom() {
    document.getElementById(
      "conversation-box"
    ).scrollTop = document.getElementById("conversation-box").scrollHeight;
  }

  updateMaxMessageId = maxMessageId => {
    this.setState({
      maxMessageId
    });
  };
  updateConversation = messages => {
    this.setState({ messages });
  };
  selectUser = user => {
    this.setState({ toUser: user });
    this.getMessages(user.id);
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> Messages : Civ Works</title>
        </Helmet>

        <Row className="mt-4">
          <Col md={3} lg={2} className="col-12 pl-sm-0 pl-lg-2">
            <LeftMenu links={this.props.leftMenu} />
          </Col>
          <Col md={8} lg={10} className="col-12 px-lg-5 mt-5 pt-2">
            <Row>
              <Col md={6}>
                <h4 className="create-new-group mb-4">Private Massages</h4>
              </Col>
              <Col md={6} className="text-right">
                <Link to="/messages/compose" className="btn btn-danger">
                  New Private Message
                </Link>
              </Col>
            </Row>
            <Row>
              <Col md={5} lg={5} className="pr-lg-0 pr-md-0 mb-lg-5">
                <Card className="card-chat rounded-0">
                  <Row className="no-gutters border-bottom">
                    <Col md={12} lg={12} className="pt-2 pl-3">
                      <div className="search-area">
                        <button className="brand-serach-btn" type="button">
                          <FontAwesomeIcon icon="search" />
                        </button>
                        <input
                          className="form-control d-inline-block"
                          type="text"
                          name="search"
                          placeholder="Search for people..."
                          aria-label="Search"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="no-gutters border-bottom justify-content-between align-items-center p-3">
                    <Col
                      md={8}
                      lg={6}
                      className="col-6 d-flex align-items-center"
                    >
                      <div className="form-group mb-0 pl-1">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck1"
                          >
                            Select All
                          </label>
                        </div>
                      </div>
                    </Col>
                    <div className="col-auto">
                      <button
                        className="btn btn-sm btn-outline-danger mr-2"
                        type="button"
                        disabled={false}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    </div>
                  </Row>
                  <div className="contacts-list-scrollbar bg-white">
                    <LoadingOverlay
                      active={this.state.userLoader}
                      spinner={<Spinner color="dark" className="mt-5" />}
                      classNamePrefix="mitiz"
                      fadeSpeed={200}
                    >
                      {this.state.users.length > 0 &&
                        this.state.users.map((user, index) => (
                          <Row
                            className={`no-gutters border-bottom align-items-center p-3 ${this.state.toUser.id === user.message.user.id
                              ? `active-user bg-light`
                              : ``
                              }`}
                            key={`inbox-key-${index}`}
                          >
                            <Col
                              md={12}
                              lg={1}
                              className="col-1 d-flex align-items-center"
                            >
                              <input type="checkbox" />
                            </Col>
                            <Col
                              md={10}
                              lg={10}
                              className="col-10"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.selectUser(user.message.user)}
                            >
                              <div className="media align-items-center">
                                <div className="Leaderboard-img mr-2">
                                  <Avtar
                                    image={user.message.user.image}
                                    image_thumb={user.message.user.image_thumb}
                                    size="3x"
                                    name={user.message.user.username}
                                    width="30"
                                  ></Avtar>
                                </div>
                                <div className="media-body request-info mb-0">
                                  <h5 className="mb-1">
                                    {user.message.user.name
                                      ? common.ucwords(user.message.user.name)
                                      : common.ucwords(
                                        user.message.user.username
                                      )}
                                    <span
                                      className="ml-1 message-time font-weight-normal"
                                      dangerouslySetInnerHTML={{
                                        __html: common.timeSince(
                                          user.message.added_on
                                        )
                                      }}
                                    />
                                  </h5>
                                  <div
                                    className="card-read-more font-weight-normal"
                                    dangerouslySetInnerHTML={{
                                      __html: user.message.message
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                            {/* <Col md={2} lg={1} className="col-1 text-right">
                              <Badge className="badge-danger custom-message-badge">
                                2
                              </Badge>
                            </Col> */}
                          </Row>
                        ))}
                    </LoadingOverlay>
                  </div>
                </Card>
              </Col>
              <Col md={7} lg={7} className="pl-md-0 pl-lg-0">
                <Messages
                  messageLoader={this.state.messageLoader}
                  handleChange={this.handleChange}
                  toUser={this.state.toUser}
                  scrollToBottom={this.scrollToBottom}
                  maxMessageId={this.state.maxMessageId}
                  getMessages={this.getMessages}
                  updateMaxMessageId={this.updateMaxMessageId}
                  updateConversation={this.updateConversation}
                  messages={this.state.messages}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    apiUrl: state.apiUrl,
    baseUrl: state.baseUrl,
    userId: state.userId
  };
};

export default connect(mapStateToProps)(Inbox);
