import React, { Component } from "react";
import { Col, Row, FormGroup } from "reactstrap";
import common from "../../../services/common";
import content from "../../../services/content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { updateFeed } from "../../../store/actions";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.css";
import TextareaAutosize from "react-autosize-textarea";
import StripePricingModal from "../Common/StripePricingModal ";

const mapStateToProps = state => {
  return {
    feeds: state.feeds,
    baseUrl: state.baseUrl,
    apiUrl: state.apiUrl,
    userId: state.userId,
    token: state.token
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFeed: feeds => {
      dispatch(
        updateFeed({
          feeds
        })
      );
    }
  };
};
class PostCommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploader: false,
      attachments: [],
      submitted: false,
      comment: "",
      showSubsriptionModel: false,
      showPricingTable: false,
      freeTrialState: false,
      email: ""
    };
  }
  toggleUploader = () => {
    this.setState(prevState => ({
      fileUploader: !prevState.fileUploader
    }));
  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.showSubsriptionModel === true) {

      this.setState({ showPricingTable: true });
    } else {
      let data = {
        comment: this.state.comment,
        content_id: this.props.contentId,
        id: this.state.comment_id,
        image: this.state.attachments[0],
        parent_id: this.props.parentId
      };
      if (this.state.comment === "" && this.state.attachments.length === 0) {
        toast.error("Comment can not be blank!", {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        this.setState({
          submitted: true
        });
        content.postComment(data).then(response => {
          if (response.data.success) {
            this.setState(
              {
                comment: "",
                submitted: false,
                fileUploader: false,
                attachments: []
              },
              () => {
                if (this.props.toggleReply) {
                  this.props.toggleReply();
                }
                this.props.handleUpdateComment(
                  response.data.comment,
                  this.props.parentId
                );
              }
            );
            if (this.pond) {
              this.pond.removeFiles();
            }
          } else {
            toast.error(response.data.message)
            this.setState(
              {
                comment: "",
                submitted: false,
                fileUploader: false,
                attachments: []
              },
            )
          }
        });
      }
    }
  };
  handleClose = () => {
    this.setState({ showPricingTable: false });
  };

  componentDidMount() {
    this.getSubscriptionStatus()
  }

  getSubscriptionStatus = () => {
    content.getSubsriptionStatus().then((response) => {
      if (response.data.success) {
        const isUserSubscription = response.data.showStripeModel
        const subscription = response.data.user;
        if (subscription) {
          const emailData = response.data.user.email
          this.setState({ email: emailData })
        }
        this.setState({
          showSubsriptionModel: isUserSubscription,
        });
        // 

        // if (isAdminSubscription == 1) {
        //   if (subscription) {
        //     const emailData = response.data.user.email
        //     this.setState({ email: emailData })
        //     const freeTrialEndDate = new Date(response.data.freeTrialEndDate);
        //     const today = new Date();
        //     today.setHours(0, 0, 0, 0);
        //     if (today < freeTrialEndDate) {
        //       const hasVisitedBefore = sessionStorage.getItem('hasVisitedBefore');
        //       if (!hasVisitedBefore) {
        //         sessionStorage.setItem('hasVisitedBefore', 'true');
        //         toast.success("You are on trial period for 7 days")
        //       }
        //       this.setState({
        //         freeTrialState: true,
        //       });
        //     }


        //     // Check if subscription exists and is not null
        //     if (subscription && today > freeTrialEndDate) {
        //       const expiryDate = new Date(subscription.expiry_date);
        //       const today = new Date();
        //       today.setHours(0, 0, 0, 0); // Set the time to the start of the day

        //       if (subscription.status === "active" || expiryDate > today) {
        //         this.setState({
        //           showSubsriptionModel: true,
        //         });
        //       }
        //     }
        //   } else {
        //     this.setState({
        //       freeTrialState: false
        //     })
        //   }
        // }


      }
    });
  };


  render() {
    const emaildemo = this.state.email
    return (
      <React.Fragment>
        <StripePricingModal
          show={this.state.showPricingTable}
          onClose={this.handleClose}
          customerEmail={emaildemo}
        />
        <form
          method="post"
          onSubmit={this.handleSubmit}
          className="post-comt-box mt-2"
        >
          {this.state.fileUploader && (
            <Row>
              <Col className="mt-2">
                <FilePond
                  allowMultiple={false}
                  allowRemove={true}
                  ref={ref => (this.pond = ref)}
                  server={{
                    url: this.props.apiUrl,
                    process: {
                      url: "/content/upload",
                      headers: {
                        "X-Api-Key": `Bearer  ${this.props.token}`
                      },
                      onload: response => {
                        let response_josn = JSON.parse(response);
                        if (response_josn.success) {
                          let attachments = [
                            ...this.state.attachments,
                            response_josn.file_name
                          ];
                          this.setState({
                            attachments: attachments
                          });
                          return response_josn.file_name;
                        }
                      }
                    },
                    revert: {
                      url: "/content/delete-image",
                      headers: {
                        "X-Api-Key": `Bearer  ${this.props.token}`
                        //file_name: files
                      },
                      onload: response => {
                        //console.log(response);
                      }
                    }
                  }}
                  onprocessfilerevert={file => {
                    let uploaded_files = this.state.attachments.filter(
                      attachment => attachment != file.serverId
                    );
                    this.setState({
                      attachments: uploaded_files
                    });
                  }}
                  className="mb-1"
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={this.props.md1} className="pr-lg-0">
              <TextareaAutosize
                name="comment"
                placeholder="Write comment"
                onChange={this.handleChange}
                value={this.state.comment}
              />
              <FontAwesomeIcon
                icon="paperclip"
                onClick={this.toggleUploader}
                className="upload_svg"
              />
            </Col>
            <Col
              md={this.props.md2}
              className={this.props.level === 3 ? "pr-lg-0" : ""}
            >
              <button
                type="submit"
                className="btn btn-danger"
                disabled={this.state.submitted}
              >
                {this.state.submitted && (
                  <FontAwesomeIcon
                    icon="spinner"
                    className="mr-1"
                    spin={true}
                  />
                )}
                {this.props.buttonText}
              </button>
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostCommentForm);
